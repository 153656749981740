import { isEmpty } from 'lodash';
import moment from 'moment';

export function dateRangeOverlaps(
  startDateA: any,
  endDateA: any,
  startDateB: any,
  endDateB: any,
) {
  if (startDateA < startDateB && startDateB < endDateA) {
    return true;
  }
  if (startDateA < endDateB && endDateB < endDateA) {
    return true;
  }
  if (startDateB < startDateA && endDateA < endDateB) {
    return true;
  }
  if (startDateA === startDateB || endDateA === endDateB) {
    return true;
  }
  return false;
}

export function multipleDateRangeOverlaps(timeEntries: any) {
  let i = 0;
  let j = 0;
  const timeIntervals = timeEntries.filter(
    (entry: any) =>
      !isEmpty(entry) && entry.startDate !== null && entry.endDate !== null,
  );
  if (timeIntervals !== null && timeIntervals.length > 0) {
    for (i = 0; i < timeIntervals.length - 1; i += 1) {
      for (j = i + 1; j < timeIntervals.length; j += 1) {
        if (
          dateRangeOverlaps(
            moment(timeIntervals[i].startDate).format('x'),
            moment(timeIntervals[i].endDate).format('x'),
            moment(timeIntervals[j].startDate).format('x'),
            moment(timeIntervals[j].endDate).format('x'),
          )
        ) {
          return true;
        }
      }
    }
  }
  return false;
}
