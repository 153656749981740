import { createBridge, JSONSchema } from '../../lib/utils';

const schemaWeight: JSONSchema = {
  title: 'HealthRecords',
  type: 'object',
  properties: {
    weights: { type: 'number', minimum: 0 },
  },
  required: ['weights'],
};
const schemaHeight: JSONSchema = {
  title: 'HealthRecords',
  type: 'object',
  properties: {
    heights: { type: 'integer', minimum: 0 },
  },
  required: ['heights'],
};

export const HealthRecordsFormSchemaWeight = createBridge(schemaWeight);
export const HealthRecordsFormSchemaHeight = createBridge(schemaHeight);
