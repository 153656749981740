import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import { get, concat } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorField, SelectField, ListItemField } from 'uniforms-material';

import { useRadioCategories, useRadioServices } from '../../api/usages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      '& button': {
        display: 'none',
      },
    },
  }),
);

interface ListItemProps {
  services?: any[];
  name: string;
  onSelectAll: Function;
}

const RadioListItem = ({ services, name, onSelectAll }: ListItemProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const { radioCategories } = useRadioCategories();
  const categoryId = get(services, `[${name}].categoryId`, '');
  const label = radioCategories.find(({ id }) => id === categoryId)?.name;
  const { radioServices } = useRadioServices(
    [
      {
        fieldPath: 'radioCategoryId',
        opStr: '==',
        value: categoryId,
      },
    ],
    { skip: !categoryId },
  );

  const servicesArr = radioServices.map(service => {
    return service.id;
  });

  const [checked, toggleChecked] = useState(false);
  const handleSelectAll = () => {
    onSelectAll({ categoryId, tests: checked ? [] : servicesArr });
    toggleChecked(!checked);
  };

  return (
    <ListItemField name={name} removeIcon={null}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleSelectAll}
            color="primary"
          />
        }
        label={label}
      />
      {radioServices && servicesArr.length > 0 && (
        <Box className={cx.root}>
          <SelectField
            name="tests"
            checkboxes
            allowedValues={servicesArr}
            transform={value =>
              radioServices.filter(item => item.id === value)[0].name || ''
            }
            color="primary"
          />
          <ErrorField name="tests" />
        </Box>
      )}
    </ListItemField>
  );
};

export default RadioListItem;
