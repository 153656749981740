/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Booking, Doctor, Prescription, Language } from '../../../api/types';
import { useDocument } from '../../../api/usages';
import getDoctorName from '../../../lib/utils/getDoctorName';
import Avatar from '../../Avatar';
import BookingInfo from '../../BookingList/BookingInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  }),
);

interface PrescriptionItemProps {
  prescription?: Prescription;
}

const PrescriptionItem = ({ prescription }: PrescriptionItemProps) => {
  const cx = useStyles();
  const { i18n } = useTranslation();
  const bookingId = prescription!.bookingId;
  const doctorId = prescription!?.doctorId;

  const { result: booking, error, loading } = useDocument<Booking>(
    {
      path: `bookings/${bookingId}`,
    },
    { skip: !bookingId },
  );

  const { result: doctor, error: docErr, loading: docLoad } = useDocument<
    Doctor
  >(
    {
      path: `doctors/${doctorId}`,
    },
    { skip: !doctorId },
  );

  if (error || docErr || docLoad || loading) {
    return null;
  }

  const lang = i18n.language as Language;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem className={cx.listItem}>
            <ListItemAvatar style={{ marginRight: '15px' }}>
              <Avatar
                photoURL={doctor?.photoURL}
                name={getDoctorName(doctor, lang)}
              />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              secondary={<BookingInfo booking={booking!} />}
            />
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PrescriptionItem;
