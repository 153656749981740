import i18next from 'i18next';
import queryString from 'query-string';
import React from 'react';

import { Specialty } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import SetBooking from '../components/SetBooking';
import { popBookingFromSessionData } from '../lib/sessionStorage';

// Consider moving to lib/helpers
export const getTranslatedSpeciality = (
  specialty: Specialty,
): { id: string; name: string } => {
  const name = specialty.translations[i18next.language as 'ar' | 'en'];
  return name
    ? { id: specialty.id, name }
    : { id: specialty.id, name: specialty.translations['en'] };
};

// Consider moving to lib/helpers
export const getSpecialtyId = (
  translatedSpecialties: { id: string; name: string }[],
  specialtyName: string | null,
): string => {
  if (!specialtyName) {
    return '';
  }

  let id = '';
  translatedSpecialties.forEach(s => {
    if (s.name === specialtyName) {
      id = s.id;
    }
  });
  return id;
};

const Specialists = () => {
  const result = popBookingFromSessionData();
  const { specialtyId } = queryString.parse(window.location.search) ?? {};

  return (
    <LayoutDefault>
      {result ? (
        <SetBooking
          withSpecialties
          stepNo={result.step || 1}
          model={result.model}
          doctor={result.doctor}
        />
      ) : (
        <SetBooking withSpecialties specialtyId={specialtyId} />
      )}
    </LayoutDefault>
  );
};

export default Specialists;
