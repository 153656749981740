import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useVideoCall, useVideoCallEnd } from '../api/usages/video';
import LayoutDefault from '../components/LayoutDefault';
import VideoEndContext from '../components/VideoCall/Controls/VideoContext';
import LeaveCallDialog from '../components/VideoCall/LeaveDialog';
import Participants from '../components/VideoCall/Participants';
import Toolbar from '../components/VideoCall/Toolbar';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc(100vh - ${theme.spacing(11)}px)`,
  },
}));

export default function VideoCall() {
  const cx = useStyles();
  const { bookingId } = useParams();
  const { room, state, error, RoomProvider } = useVideoCall(bookingId);
  const [openDialog, setOpenDialog] = useState(false);
  const connected = room && state === 'connected';

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  useVideoCallEnd(bookingId);

  if (error) {
    return <LayoutDefault>{error.message}</LayoutDefault>;
  }

  if (!connected) {
    return (
      <LayoutDefault>
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      </LayoutDefault>
    );
  }

  return (
    <LayoutDefault fullSize>
      <div className={cx.container}>
        <RoomProvider value={room}>
          <VideoEndContext.Provider
            value={{
              state: openDialog,
              handleDialog,
              bookingId,
            }}
          >
            <LeaveCallDialog />
            <Participants />
            <Toolbar />
          </VideoEndContext.Provider>
        </RoomProvider>
      </div>
    </LayoutDefault>
  );
}
