import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Language,
  ReferralTypes,
  DoctorReferral,
  Specialty,
} from '../../api/types';
import { useDocument } from '../../api/usages';

const useStyles = makeStyles(() =>
  createStyles({
    note: {
      color: '#00000060',
      marginTop: 0,
      marginBottom: '1em',
    },
    buttonMarginLeft: {
      marginLeft: '2em',
    },
    buttonMarginRight: {
      marginRight: '2em',
    },
  }),
);

interface ReferralModalItemProps {
  referral: DoctorReferral;
}

const DoctorReferralModalItem = ({ referral }: ReferralModalItemProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const isReferralOpen = referral.status === ReferralTypes.OPEN;

  const { result: specialty } = useDocument<Specialty>({
    path: `specialties/${referral?.specialtyId}`,
  });

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        {t('common.speciality')}:{' '}
        {specialty?.translations?.[i18n.language as Language]}
        <br />
        {t('profile.medTitle')}:{' '}
        {referral?.medTitle
          ? t(`profile.title_${referral?.medTitle}`)
          : t('common.anyMedTitle')}
      </Grid>
      <Grid item>
        {isReferralOpen ? (
          <Button
            className={
              direction === 'ltr' ? cx.buttonMarginLeft : cx.buttonMarginRight
            }
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `/doctors/${referral.specialtyId}/${referral.medTitle}`,
                state: { referralId: referral.id },
              });
            }}
          >
            {t('callSummary.bookVisit')}
          </Button>
        ) : (
          <Button
            disabled
            className={
              direction === 'ltr' ? cx.buttonMarginLeft : cx.buttonMarginRight
            }
            variant="contained"
          >
            {t('callSummary.booked')}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} className={cx.note}>
        <Typography variant="caption">{referral?.note ?? ''}</Typography>
      </Grid>
    </Grid>
  );
};

export default DoctorReferralModalItem;
