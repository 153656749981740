import { Paper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorField, ListItemField, LongTextField } from 'uniforms-material';

import { Specialty, ReferralTypes } from '../../api/types';
import { extraMedTitleOptions } from '../../constants/ConfigProfile';
import CustomAutocompleteField from '../CustomAutocompleteField';
import CustomSelectField from '../CustomSelectField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      '& button': {
        display: 'none',
      },
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }),
);

interface ListItemProps {
  name: string;
  specialties: Specialty[];
  doctorReferrals: any[];
}

const DoctorReferralListItem = ({
  name,
  specialties,
  doctorReferrals,
}: ListItemProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const index = parseInt(name);
  const status = doctorReferrals[index]?.status;
  const disabled = status && status !== ReferralTypes.OPEN;

  return (
    <ListItemField name={name} removeIcon={disabled ? null : <Delete />}>
      <Paper className={cx.root}>
        <CustomAutocompleteField
          disabled={disabled}
          label="common.speciality"
          options={specialties}
          name="specialtyId"
        />
        <ErrorField name="specialtyId" />
        <CustomSelectField
          disabled={disabled}
          label={t('profile.medTitle')}
          name="medTitle"
          options={extraMedTitleOptions}
          fullWidth
        />
        <ErrorField name="medTitle" />
        <LongTextField
          disabled={disabled}
          label={t<string>('doctorNotes.note')}
          name="note"
        />
      </Paper>
    </ListItemField>
  );
};

export default DoctorReferralListItem;
