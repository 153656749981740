import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';

import SummaryDialog, { useToggle } from './SummaryDialog';

const useStyles = makeStyles(() =>
  createStyles({
    spacing: {
      margin: 16,
    },
  }),
);

export type ReferralProps = {
  title: string;
  extraContent?: () => JSX.Element;
  disabled?: boolean;
  patientVerified?: boolean;
};

export const Referral = ({
  title,
  extraContent,
  disabled,
  patientVerified,
}: ReferralProps) => {
  const cx = useStyles();
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={cx.spacing}
        disabled={disabled || !patientVerified}
        onClick={toggle}
      >
        {title}
      </Button>
      <SummaryDialog
        title={title}
        open={isOpen}
        onClose={toggle}
        extraContent={extraContent}
      />
    </>
  );
};
