import { CircularProgress } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { cloneDeep, isEmpty, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm } from 'uniforms';
import { ErrorField, ListField } from 'uniforms-material';

import { saveDoctorReferrals } from '../../../api/actions/doctorNotes';
import { docRefsSchema } from '../../../api/schemas/DoctorNotes';
import { useDoctorReferrals, useSpecialties } from '../../../api/usages';
import DoctorReferralListItem from '../../SessionSummary/DoctorReferralListItem';
import DoctorNotesSection from './DoctorNotesSection';

const transformFetchedDoctorRefs = ({
  bookingRes,
  doctorReferralsRes,
}: any) => ({
  booking: bookingRes.result,
  doctorReferrals: doctorReferralsRes.doctorReferrals,
});

interface DoctorReferralsProps {
  bookingIdQuery: object;

  loading: string;
  bookingRes: any;
}

const DoctorReferrals = ({
  loading,
  bookingIdQuery,
  bookingRes,
}: DoctorReferralsProps) => {
  const { t } = useTranslation();
  const { specialties } = useSpecialties();

  const doctorReferralsRes = useDoctorReferrals(bookingIdQuery);

  const doctorRefsLoadedRef = useRef<boolean>(false);
  const [doctorRefModel, setDoctorRefModel] = useState<any>({});

  useEffect(() => {
    if (doctorRefsLoadedRef.current) {
      return;
    }

    if ([bookingRes, doctorReferralsRes].every(res => !res.loading)) {
      doctorRefsLoadedRef.current = true;

      const { doctorReferrals } = transformFetchedDoctorRefs({
        bookingRes,
        doctorReferralsRes,
      });

      setDoctorRefModel((prevModel: any) => ({
        ...prevModel,
        doctorReferrals,
      }));
    }
  }, [bookingRes, doctorReferralsRes]);

  const handleSubmitDoctorRefs = (submitModel: any) => {
    const fetchedData = transformFetchedDoctorRefs({
      bookingRes,
      doctorReferralsRes,
    });

    saveDoctorReferrals({
      booking: {
        id: fetchedData.booking?.id,
        patientId: fetchedData.booking?.patientId,
        complaint: submitModel.complaint ?? '',
      },
      doctorReferrals: submitModel.doctorReferrals,
      fetchedDoctorReferrals: fetchedData.doctorReferrals,
    });
  };

  const handleFieldChangeDoctorRefs = (field: string, value: any) => {
    const newModel = set(cloneDeep(doctorRefModel), field, value);
    setDoctorRefModel(newModel);
    return newModel;
  };

  if (isEmpty(doctorRefModel)) {
    return (
      <div className={loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <AutoForm
      schema={docRefsSchema}
      onChange={handleFieldChangeDoctorRefs}
      model={doctorRefModel}
      onSubmit={handleSubmitDoctorRefs}
      autosave
      autosaveDelay={700}
    >
      <DoctorNotesSection
        label={t('doctorNotes.doctorReferral')}
        count={doctorRefModel.doctorReferrals.length}
      >
        <ListField name="doctorReferrals" label="" addIcon={<Add />}>
          <DoctorReferralListItem
            name="$"
            specialties={specialties}
            doctorReferrals={doctorRefModel.doctorReferrals}
          />
        </ListField>
        <ErrorField name="doctorReferrals" />
      </DoctorNotesSection>
    </AutoForm>
  );
};

export default DoctorReferrals;
