import TextField from '@material-ui/core/TextField';
import React, { useEffect } from 'react';
import { connectField } from 'uniforms';

function CertificateTextField(props: any) {
  const { value = '', onChange, label, disabled, required, initValue } = props;

  const onSelectChange = ({ target: { value } }: any) => {
    onChange(value);
  };

  useEffect(() => {
    if (initValue) {
      onChange(initValue);
    }
  }, [initValue, onChange]);

  if (initValue && initValue !== 'Other certificate') {
    return (
      <TextField
        fullWidth
        required={required}
        style={{ width: '100%' }}
        disabled={disabled}
        label={label}
        value={value}
        onChange={onSelectChange}
      />
    );
  }

  return (
    <TextField
      fullWidth
      required={required}
      disabled={disabled}
      label={label}
      value={value}
      onChange={onSelectChange}
    />
  );
}

export default connectField(CertificateTextField);
