import firebase from 'firebase/app';
import { LocationListener, LocationState } from 'history';
import snakeCase from 'lodash/snakeCase';
import 'firebase/storage';
import 'firebase/analytics';
import { Subscription } from 'rxjs';

import { history } from '../utils';
import { userId$ } from './auth';
let analytics: firebase.analytics.Analytics;

export const init = () => {
  analytics = firebase.analytics(firebase.app());
  const sub = new Subscription();
  sub.add(
    userId$.subscribe((userId: string | null) =>
      firebase.analytics().setUserId(userId as string),
    ),
  );

  const listener: LocationListener<LocationState> = location => {
    analytics && analytics.setCurrentScreen(location.pathname);
  };

  sub.add(history.listen(listener));
  return () => sub.unsubscribe();
};

export const logEvent = (type: string, paramsData: any = null) => {
  analytics && analytics.logEvent(snakeCase(type), paramsData);
};
