import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Doctor } from '../../api/types';
import SetBooking from '../../components/SetBooking';

interface FromLinkUserLogoutProps {
  doctor: Doctor | null;
}
const FromLinkUserLogout = ({ doctor }: FromLinkUserLogoutProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);

  useEffect(() => {
    // @ts-ignore
    if (Object.keys(doctor).length < 10) {
      setLoadingStatus(true);
    } else {
      setLoadingStatus(false);
    }
    return;
    // @ts-ignore
  }, [doctor]);

  useEffect(() => {
    // @ts-ignore
    if (Object.keys(doctor).length > 5) {
      const verifiedDoctor = doctor?.verified;
      const testingDoctor = doctor?.testing;

      if (!verifiedDoctor || testingDoctor) {
        history.push('/');
      }
    }
    return;
  }, [doctor, history]);

  if (loadingStatus) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (!doctor?.name) {
    return <h1>{t('booking.errorDoctorNotFound')}</h1>;
  }

  return <SetBooking stepNo={2} doctor={doctor} withSpecialties />;
};

export default FromLinkUserLogout;
