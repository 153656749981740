import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm, AutoField, SubmitField } from 'uniforms-material';

import { doPasswordReset } from '../../api/actions/auth';
import { ForgotPassByEmailSchema } from '../../api/schemas/ForgotPass';
import { UserRoleTypes } from '../../api/types';

const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      margin: `8px 0`,
    },
  }),
);

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

type EmailModeFormProps = {
  profileType: UserRoleTypes;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
};

export const EmailModeForm = ({
  profileType,
  onAuthorized,
}: EmailModeFormProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const handleSubmit = useCallback(
    values => {
      doPasswordReset({ ...values, profileType, onAuthorized });
    },
    [profileType, onAuthorized],
  );
  return (
    <AutoForm schema={ForgotPassByEmailSchema} onSubmit={handleSubmit}>
      <AutoField name="email" label={t('profile.email')} />
      <Box>
        <SubmitField
          color="primary"
          label={t('auth.sendVerificationEmail')}
          className={cx.submit}
        />
      </Box>
    </AutoForm>
  );
};
