import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useVerificationStatus } from '../../api/usages/utils/useVerificationStatus';
import TabPanel from '../TabPanel';
import PastBookingsCallendarView from './PastBookingsCallendarView';
import TodaysBookingsListView from './TodaysBookingsListView';
import UpcomingBookingsCallendarView from './UpcomingBookingsCallendarView';

const BookingListForDoctor = () => {
  const { t } = useTranslation();
  const isVerified = useVerificationStatus();
  const [currentTab, setCurrentTab] = React.useState(1);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          <Tab label={t('booking.past')} disabled={!isVerified} />
          <Tab label={t('booking.today')} />
          <Tab label={t('booking.upcoming')} />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={0}>
        <PastBookingsCallendarView />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <TodaysBookingsListView />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <UpcomingBookingsCallendarView />
      </TabPanel>
    </>
  );
};

export default BookingListForDoctor;
