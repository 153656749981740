import { JSONSchema7Definition } from 'json-schema';

import CustomDateField from '../../components/CustomDateField';
import { createBridge, JSONSchema } from '../../lib/utils';
import GeoPointSchema from './GeoPointSchema';

const propertiesCommon: {
  [key: string]: JSONSchema7Definition;
} = {
  name: { type: 'string', minLength: 1 },
  phoneNumber: { type: 'string' },
  birthday: {
    type: 'string',
    format: 'date',
    uniforms: {
      component: CustomDateField,
    },
  },
  gender: {
    type: 'string',
  },
  idNumber: { type: 'string' },
  idOrPassportPhoto: {
    type: 'string',
    uniforms: {
      acceptedFiles: ['image/*', 'application/pdf'],
    },
  },
  photoURL: {
    type: 'string',
  },
  resellerCode: {
    type: 'string',
    maxLength: 4,
    pattern: '[a-zA-Z0-9]{4}|^$',
  },
  nationality: { type: 'string' },
  nativeLanguage: { type: 'string' },
  arabicProficiency: {
    type: 'string',
  },
  englishProficiency: {
    type: 'string',
  },
  frenchProficiency: {
    type: 'string',
  },
  address: {
    type: 'array',
    minItems: 1,
    uniqueItems: true,
    items: {
      type: 'object',
      properties: {
        governorateId: {
          type: 'string',
          minLength: 1,
        },
        areaId: {
          type: 'string',
          minLength: 1,
        },
        buildingNumberName: {
          type: 'string',
          minLength: 1,
        },
        streetBlockCompound: {
          type: 'string',
          minLength: 1,
        },
        districtTownVillage: {
          type: 'string',
          minLength: 1,
        },
        floorNumber: {
          type: 'string',
        },
        appartmentNumber: {
          type: 'string',
        },
        directions: {
          type: 'string',
        },
        geolocation: GeoPointSchema,
      },
      required: [
        'governorateId',
        'areaId',
        'buildingNumberName',
        'streetBlockCompound',
        'districtTownVillage',
      ],
    },
  },
  married: { type: 'boolean' },
  workType: {
    type: 'string',
  },
};

const schemaPATIENT: JSONSchema = {
  title: 'Guest Create Profile',
  type: 'object',
  properties: propertiesCommon,
  required: [
    'name',
    'gender',
    'phoneNumber',
    'birthday',
    'photoURL',
    'address',
  ],
};

export const CreatePatientProfileWithAuth = createBridge(schemaPATIENT);

const schemaEmailPATIENT: JSONSchema = {
  title: 'Guest Create Profile',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
    },
    password: {
      type: 'string',
      minLength: 6,
      pattern: '^[^\\s]*$',
      uniforms: {
        type: 'password',
      },
    },
    confirmPassword: {
      const: {
        $data: '1/password',
      },
      type: 'string',
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'password', 'confirmPassword'],
};

export const CreatePatientEmail = createBridge(schemaEmailPATIENT);

const schemaPatientNoAuth: JSONSchema = {
  title: 'Guest Create Profile with no auth',
  type: 'object',
  properties: propertiesCommon,
  required: [
    'name',
    'gender',
    'phoneNumber',
    'birthday',
    'photoURL',
    'address',
  ],
};

export const CreatePatientProfileNoAuth = createBridge(schemaPatientNoAuth);

export const CodeFormSchema = createBridge({
  title: 'Guest',
  type: 'object',
  properties: {
    code: { type: 'string', maxLength: 6, minLength: 6 },
  },
  required: ['code'],
});
