import { List } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router-dom';

import { RadioBooking, LabOrRadioBookingStatus } from '../../api/types';
import { Radio } from '../../api/types/Radio';
import { useRadioBookings, useUserId } from '../../api/usages';
import { useVerifiedRadios } from '../../api/usages/radios';
import ResultList from './common/ResultList';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

interface UniqueIdBookings {
  radioId: string;
  status: LabOrRadioBookingStatus;
}
function getUniqueRadiosIds(
  radios: Array<RadioBooking>,
): Array<UniqueIdBookings> {
  const uniqueBookings: Set<UniqueIdBookings> = new Set();
  radios.forEach((radio: RadioBooking) =>
    uniqueBookings.add({ radioId: radio.radioId, status: radio.status }),
  );

  return Array.from(uniqueBookings);
}

function removeUsedRadios(
  radios: Array<Radio>,
  usedRadios: Array<UniqueIdBookings>,
): Array<Radio> {
  const uniqueId: Set<string> = new Set();

  usedRadios.forEach(radio =>
    radio.status !== LabOrRadioBookingStatus.CANCELED
      ? uniqueId.add(radio.radioId)
      : null,
  );
  const result: Array<string> = Array.from(uniqueId);

  return radios.filter(radio => !result.includes(radio.id));
}

interface RadiosListProps {
  bookingId: string;
  referralId: string;
}

const RadiosList = ({ bookingId, referralId }: RadiosListProps) => {
  const cx = useStyles();
  const { serviceId } = useParams();
  const userId = useUserId();
  const { radios, loading, error } = useVerifiedRadios();

  const {
    error: radioError,
    loading: radioLoading,
    radioBookings,
  } = useRadioBookings(
    [
      {
        fieldPath: 'patientId',
        opStr: '==',
        value: userId,
      },
      {
        fieldPath: 'serviceId',
        opStr: '==',
        value: serviceId,
      },
      {
        fieldPath: 'referralId',
        opStr: '==',
        value: referralId,
      },
    ],
    {
      skip: !referralId,
    },
  );

  const usedRadios = getUniqueRadiosIds(radioBookings);
  const freeRadios = removeUsedRadios(radios, usedRadios);

  if (loading || error || radioError || radioLoading) {
    return null;
  }

  return (
    <List className={cx.root}>
      <ResultList
        serviceId={serviceId}
        type="radio"
        result={freeRadios}
        bookingId={bookingId}
        referralId={referralId}
      />
    </List>
  );
};

export default RadiosList;
