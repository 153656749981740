import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentOrder } from '../../../api/types/Payments';

type Props = {
  order: PaymentOrder | null;
};

const useStyles = makeStyles({
  iframe: { width: '100%', height: '1000px' },
});

const PaymobGateway = ({ order }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { iframeUrl, redirectUrl, referenceNumber, type } = order || {};

  useEffect(() => {
    if (redirectUrl) {
      // Payment trough a full page redirection
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  if (referenceNumber) {
    // Payment trough a kiosk, just display information
    return (
      <Paper>
        <Box textAlign="center" paddingY={4}>
          <Box padding={2}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t('payments.info')}
            </Typography>
          </Box>
          <Typography variant="h4">
            {type === 'cash-in'
              ? t('payments.kioskInstructionsHeader')
              : t('payments.kioskInstructionsHeaderPayout')}
          </Typography>
          <Box padding={4}>
            <Typography variant="body1">
              {type === 'cash-in'
                ? t('payments.kioskInstructionsText1')
                : t('payments.kioskInstructionsText1Payout')}
            </Typography>
          </Box>
          <Typography variant="h1" color="primary">
            {order?.referenceNumber}
          </Typography>
          <Box padding={4}>
            <Typography variant="body1">
              {t('payments.kioskInstructionsText2')}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }

  if (iframeUrl) {
    // Payment trough an iframe
    return (
      <iframe
        title="Paymob Payment"
        seamless
        frameBorder={0}
        scrolling="no"
        src={iframeUrl}
        className={classes.iframe}
      />
    );
  }

  return null;
};

export default PaymobGateway;
