import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Alert } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createBooking } from '../../api/actions/bookings';
import { Booking, BookingTypes, Testable, UserProfile } from '../../api/types';
import {
  Currency,
  PaymentMethod,
  PaymentWallet,
  Price,
} from '../../api/types/Payments';
import {
  usePaymentWalletAccounts,
  useUserId,
  useProfile,
} from '../../api/usages';
import { formatPrice } from '../../lib/utils';
import PaymentMethods from '../Payments/PaymentMethods';

type Props = {
  booking: Booking;
  doctor?: any;
  timeslot: number;
};

const useStyles = makeStyles({
  alert: {
    margin: '24px',
  },
});

const Payment = ({ booking, doctor, timeslot }: Props) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const userId = useUserId();
  const userProfile = useProfile() as
    | (UserProfile & { phoneNumber: string })
    | null;
  const { wallet } = usePaymentWalletAccounts(userId);
  const makeBooking = useCallback(
    (
      paymentMethod: PaymentMethod,
      billingInfo?: PaymentWallet['billingInfo'],
    ) => {
      createBooking({
        bookingModel: booking,
        timeslot,
        paymentMethod,
        billingInfo,
      });
    },
    [booking, timeslot],
  );
  // Get booking price from doctor's profile
  const { amount, currency } = (doctor?.price || {}) as Partial<Price>;

  if (typeof currency !== 'string' || typeof amount !== 'number') {
    // Error
    return null;
  }
  const isTesting = doctor.testing && (userProfile as Testable)?.testing;

  // Follow-ups and testing users with testing doctors are free, other types may also be free in the future
  const isFree = booking.type === BookingTypes.FOLLOW_UP || isTesting;

  // The patient should not have the possibility to make a booking to doctor with the same phone number as patient
  const patientAndDoctorSharePhoneNumber =
    userProfile?.phoneNumber === doctor.phoneNumber;

  return (
    <>
      {patientAndDoctorSharePhoneNumber && (
        <Alert className={cx.alert} severity="error">
          {t('booking.cannotBookYourself')}
        </Alert>
      )}

      <Box textAlign="center" m={4}>
        <h1>{t('payments.payForVisit')}</h1>
        <h2>{formatPrice({ amount, currency })}</h2>
        <Divider />
      </Box>

      <PaymentMethods
        allowedMethods={
          isFree
            ? [PaymentMethod.FREE]
            : [
                PaymentMethod.WALLET,
                PaymentMethod.PAYMOB_WALLET,
                PaymentMethod.PAYMOB_CARD,
                PaymentMethod.PAYMOB_KIOSK,
              ]
        }
        disabled={patientAndDoctorSharePhoneNumber}
        onPay={makeBooking}
        currency={Currency.EGP}
        wallet={wallet}
        price={{ amount, currency }}
        isTesting={isTesting}
        withConfirm
      />
    </>
  );
};

export default Payment;
