import { AppBar } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tab from '@material-ui/core/Tab';
import TabsComponent from '@material-ui/core/Tabs';
import BankIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../api/types';
import { Currency } from '../api/types/Payments';
import {
  useContextRole,
  usePaymentOrders,
  usePaymentWalletAccounts,
  usePaymentWalletLogs,
  useUserId,
} from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import OrdersListing from '../components/Payments/OrdersListing';
import PayoutDialog from '../components/Payments/PayoutDialog';
import WalletListing from '../components/Payments/WalletListing';
import WalletStatus from '../components/Payments/WalletStatus';
import ReauthWithPasswordDialog from '../components/ReauthWithPassword';

enum Tabs {
  HISTORY,
  ORDERS,
}

const PaymentWallet = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  const role = useContextRole();
  const { loading: loadingOrders, orders = [] } = usePaymentOrders(userId);
  const { loading: loadingWallet, wallet } = usePaymentWalletAccounts(userId);
  const { loading: loadingWalletLogs, logs = [] } = usePaymentWalletLogs(
    userId,
  );
  const [activeTab, setTab] = useState<Tabs>(Tabs.HISTORY);
  const [payoutCurrency, setPayout] = useState<Currency | null>(null);
  const [openReauth, setOpenReauth] = useState<boolean>(false);

  const isDoctor = role === UserRoleTypes.DOCTOR || false;

  if (loadingOrders || loadingWallet || loadingWalletLogs) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <LayoutDefault>
      <Box marginBottom={4}>
        <h1>
          {isDoctor ? t('payments.earningsTitle') : t('payments.walletTitle')}
        </h1>
        {isDoctor ? (
          <WalletStatus wallet={wallet} onPayout={setPayout} />
        ) : (
          <WalletStatus wallet={wallet} />
        )}
      </Box>
      <Box>
        <AppBar color="transparent" position="static">
          <TabsComponent
            variant="fullWidth"
            value={activeTab}
            onChange={(ev, value) => setTab(value)}
          >
            <Tab
              icon={<ReceiptIcon />}
              label={t('payments.walletLogsListing')}
            />
            <Tab icon={<BankIcon />} label={t('payments.ordersListing')} />
          </TabsComponent>
        </AppBar>

        {activeTab === Tabs.HISTORY && <WalletListing logs={logs} />}
        {activeTab === Tabs.ORDERS && <OrdersListing orders={orders} />}
      </Box>

      <PayoutDialog
        open={openReauth}
        onClose={() => {
          setPayout(null);
          setOpenReauth(false);
        }}
        currency={payoutCurrency}
        wallet={wallet}
      />
      <ReauthWithPasswordDialog
        isOpen={!!payoutCurrency}
        onAuth={setOpenReauth}
        onClose={() => setPayout(null)}
      />
    </LayoutDefault>
  );
};

export default PaymentWallet;
