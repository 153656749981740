import { FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { AutoForm } from 'uniforms';
import {
  AutoField,
  SubmitField,
  ErrorField,
  NumField,
} from 'uniforms-material';

import { showSuccessToast, showErrorToast } from '../api/actions/uiControls';
import { UpdateProfileDoctor } from '../api/schemas/UpdateProfileDoctor';
import { Doctor } from '../api/types';
import { useDocument, useSpecialties, useNationalities } from '../api/usages';
import CustomPriceField from '../components/CustomPriceField';
import CustomSelectField from '../components/CustomSelectField';
import DownloadPDFBtn from '../components/DownloadPDFBtn';
import LayoutDefault from '../components/LayoutDefault';
import PhoneNumberField from '../components/PhoneNumberField';
import AddressAreaList from '../components/Profile/AddressAreaList';
import BilingualAutocompleteField from '../components/Profile/BilingualAutocompleteField';
import CertificatesListField from '../components/Profile/CertificatesListField';
import SectionTitle from '../components/SectionTitle';
import { getTranslatedSpeciality } from '../components/SpecialtyAutocomplete';
import Title from '../components/Title';
import UploadAreaUniformField from '../components/UploadAreaUniformField';
import {
  proficiencyOptions,
  countriesOptions,
  medTitleOptions,
  genderOptions,
  askForArabicAbout,
} from '../constants/ConfigProfile';
import RouteTypes from '../constants/routes';
import { editUser } from '../lib/admin';
import { momentToFormat } from '../lib/utils';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';
import { mapAddress } from '../lib/utils/mapAddress';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '700px',
      margin: '40px auto 20px',
    },
    buttons: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    spacing: {
      marginBottom: 10,
    },
    label: {
      marginBottom: '5px',
      marginTop: 0,
    },
  }),
);

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminEditDoctor = () => {
  const { doctorId } = useParams<{ doctorId: string }>();
  const { t, i18n } = useTranslation();
  const cx = useStyles();

  const { specialties } = useSpecialties();
  const { nationalities } = useNationalities();

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const translatedSpecialties = specialties.map(specialty => {
    return getTranslatedSpeciality(specialty);
  });

  const [updateInProgress, setUpdateInProgress] = useState(false);

  const { result: doctor } = useDocument<Doctor>({
    path: `doctors/${doctorId}`,
  });

  useRedirectWhenNotExists(`doctors/${doctorId}`, '/manage-doctors');

  const { price } = doctor ?? {};

  const model = {
    ...doctor,
    birthday: momentToFormat(
      i18n.language,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (doctor as any)?.birthday ?? '',
      'YYYY-MM-DD',
    ),
    price: {
      ...(price ?? {}),
      amount: (price?.amount ?? 0) / 100,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (data: any) => {
    setUpdateInProgress(true);

    data.address = (data.address ?? []).map(mapAddress);

    data.price = {
      ...data.price,
      amount: (data.price?.amount ?? 0) * 100,
    };

    const { success, message } = await editUser({
      id: doctorId,
      collection: 'doctors',
      data,
    });
    if (success) {
      showSuccessToast(t('admin.doctorEdited'));
    } else {
      showErrorToast(message ?? t('admin.errorEditingDoctor'));
    }

    setUpdateInProgress(false);
  };

  if (!doctor) {
    return null;
  }

  return (
    <LayoutDefault>
      <Title title={t('admin.editDoctor')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <AutoForm
            schema={UpdateProfileDoctor}
            disabled={updateInProgress}
            model={model}
            onSubmit={handleSubmit}
          >
            <SectionTitle title={t('profile.personalInfo')} />
            <AutoField name="email" label={t('profile.email')} margin="dense" />
            <ErrorField name="email" errorMessage={t('profile.emailError')} />
            <PhoneNumberField
              name="phoneNumber"
              label={t('profile.phoneNumber')}
            />
            <ErrorField name="phoneNumber" />
            <AutoField name="name" label={t('profile.name')} />
            <ErrorField name="name" />
            <AutoField name="birthday" label={t('profile.birthday')} />
            <ErrorField name="birthday" />
            <CustomSelectField
              name="gender"
              label={t('profile.gender')}
              options={genderOptions}
              fullWidth
            />
            <ErrorField name="gender" />
            <BilingualAutocompleteField
              id="nationality"
              name="nationality"
              options={nationalitiesSorted}
              label={t('profile.nationality')}
            />
            <ErrorField name="nationality" />
            <AutoField name="idNumber" label={t('profile.iDNumber')} />
            <ErrorField name="idNumber" />
            <UploadAreaUniformField
              name="idOrPassportPhoto"
              text={t(`profile.uploadID`)}
              filesLimit={1}
              acceptedFiles={['image/*']}
            />
            <ErrorField name="idOrPassportPhoto" />
            <DownloadPDFBtn
              url={doctor.idOrPassportPhoto || ''}
              className={cx.spacing}
            />
            <AutoField
              name="resellerCode"
              label={t('profile.resellerCode')}
              inputProps={{ maxLength: 4 }}
            />
            <ErrorField
              name="resellerCode"
              errorMessage={t('profile.resellerError')}
            />
            <AutoField
              name="nativeLanguage"
              label={t('profile.nativeLanguage')}
            />
            <CustomSelectField
              name="arabicProficiency"
              label={t('englishOnly.profile_arabicProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="englishProficiency"
              label={t('englishOnly.profile_englishProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="frenchProficiency"
              label={t('englishOnly.profile_frenchProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <AddressAreaList
              name="address"
              address={doctor.address ?? []}
              disabled={updateInProgress}
            />
            <SectionTitle
              title={t('englishOnly.profile_professionalSection')}
            />
            <CustomSelectField
              name="specialtyId"
              label={t('profile.specialtyId')}
              options={translatedSpecialties}
              fullWidth
            />
            <ErrorField name="specialtyId" />
            <CustomSelectField
              name="medTitle"
              label={t('englishOnly.profile_medTitle')}
              options={medTitleOptions}
              fullWidth
            />
            <ErrorField name="medTitle" />
            <CertificatesListField downloadButton />
            <AutoField
              name="about"
              label={t('profile.about')}
              placeholder={askForArabicAbout}
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CustomSelectField
              name="countries"
              label={t('profile.practiceCountries')}
              options={countriesOptions}
              fullWidth
            />
            <ErrorField name="countries" />
            <NumField
              name="yearsOfExperience"
              type="number"
              min={0}
              max={100}
            />
            <ErrorField name="yearsOfExperience" />
            <SectionTitle title={t('englishOnly.profile_priceSection')} />
            <FormLabel component="p" className={cx.label}>
              {t('profile.consultPice')}
            </FormLabel>
            <CustomPriceField
              name="price"
              label={`${t('englishOnly.profile_priceAmount')}`}
            />
            <ErrorField
              name="price.amount"
              errorMessage={t('profile.primaryPriceValidate')}
            />
            <Box className={cx.buttons}>
              <SubmitField color="primary" />
              <Button
                color="secondary"
                component={Link}
                to={RouteTypes.MANAGE_DOCTORS}
              >
                {t('common.back')}
              </Button>
            </Box>
          </AutoForm>
        </Container>
      </Card>
    </LayoutDefault>
  );
};
export default AdminEditDoctor;
