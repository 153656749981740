import moment from 'moment';
import React from 'react';

import { useDocument } from '../../api/usages';
import WeekSchedule from './WeekSchedule';

const WeekScheduleWithData = ({
  defaultWeek,
  weekNo,
  startDate,
  endDate,
  userId,
  timeslot,
}: any) => {
  const weekId = `${weekNo}-${moment(startDate).weekYear()}`;

  const { result: doc, loading } = useDocument<any>({
    path: `schedules/${userId}/weeks/${weekId}`,
    noId: true,
  });

  const week = {
    ...defaultWeek,
    startDate,
    endDate,
    weekNo,
    ...doc,
  };

  if (loading) {
    return null;
  }
  const asDefaultInfo = !doc || !Object.keys(doc).length;
  return (
    <WeekSchedule
      key={week.updatedAt}
      isTemplate={false}
      week={week}
      asDefaultInfo={asDefaultInfo}
      defaultWeek={defaultWeek}
      timeslot={timeslot}
    />
  );
};

export default WeekScheduleWithData;
