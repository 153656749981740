const locale = {
  skip: 'joyride.skip',
  next: 'joyride.next',
  back: 'joyride.back',
};

const commonInitialSteps = [
  {
    content: 'joyride.step_1',
    locale,
    placement: 'center',
    target: 'body',
  },
  {
    content: 'joyride.step_2',
    locale,
    target: '.joyride_lang',
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    content: 'joyride.step_3',
    locale,
    target: '.joyride_card_0',
    title: 'home.primaryDoctor',
  },
  {
    content: 'joyride.step_4',
    locale,
    target: '.joyride_card_1',
    title: 'home.specialistDoctor',
  },
];

const commonLastSteps = [
  {
    content: 'joyride.step_5',
    locale,
    target: '.common_signUp',
    placement: 'right',
  },
  {
    content: 'joyride.step_6',
    locale,
    target: '.common_signIn',
    placement: 'right',
  },
  {
    content: 'joyride.step_7',
    locale,
    target: '.common_doctor',
    placement: 'right',
  },
  {
    content: 'joyride.step_8',
    locale,
    target: '.common_lab',
    placement: 'right',
  },
  {
    content: 'joyride.step_9',
    locale,
    target: '.common_radio',
    placement: 'right-end',
  },
];

export const mobileStep = [
  {
    content: 'joyride.step_10',
    locale,
    target: '.joyride_menu',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

export const desktopSteps = [...commonInitialSteps, ...commonLastSteps];
export const mobileSteps = [
  ...commonInitialSteps,
  ...mobileStep,
  ...commonLastSteps,
];
