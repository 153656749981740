import React from 'react';
import { useParams } from 'react-router-dom';

import DoctorVisitDetails from '../components/HealthRecords/DoctorVisits/DoctorVisitDetails';
import LayoutDefault from '../components/LayoutDefault';

const HealthRecordsVisit = () => {
  const { bookingId } = useParams();
  return (
    <LayoutDefault>
      <DoctorVisitDetails bookingId={bookingId} />
    </LayoutDefault>
  );
};

export default HealthRecordsVisit;
