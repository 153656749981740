import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-use-promise';
import {
  AutoField,
  AutoForm,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import { doProfileUpdate } from '../../api/actions/auth';
import { UpdateProfile } from '../../api/schemas/UpdateProfile';
import { useNationalities } from '../../api/usages';
import {
  genderOptions,
  proficiencyOptions,
  workOptions,
} from '../../constants/ConfigProfile';
import { functions } from '../../lib/firebase';
import { momentToFormat } from '../../lib/utils';
import { i18next } from '../../locales';
import CustomSelectField from '../CustomSelectField';
import PhoneNumberField from '../PhoneNumberField';
import SectionTitle from '../SectionTitle';
import UploadAreaUniformField from '../UploadAreaUniformField';
import AddressAreaList from './AddressAreaList';
import BilingualAutocompleteField from './BilingualAutocompleteField';

const useStyles = makeStyles({
  root: {
    display: 'block',
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiInput-root': {
      marginBottom: '16px',
    },
    '& fieldset': {
      margin: '0 0 16px',
    },
  },
  spaceBottom: {
    marginBottom: '10px',
  },
});

const ProfileInfo = (props: any) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const { nationalities } = useNationalities();
  const lang = i18next.language;
  const profile = get(props, 'profile', {});
  const user = get(props, 'user', {});
  const fetchedBirthday = get(profile, 'birthday', '');
  const address = get(profile, 'address', []);
  const email = get(user, 'email', '');
  const emailVerified = get(user, 'emailVerified', false);
  const resellerCode = get(profile, 'resellerCode', ' ');
  const [nationalityId, setNationalityId] = useState<string>(
    get(profile, 'nationality', ''),
  );
  const [fromSubmit, setFromSubmit] = useState(false);
  const [idNumber, setIdNumber] = useState<string>(
    get(profile, 'idNumber', ''),
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const model = {
    ...profile,
    birthday: momentToFormat(lang, fetchedBirthday, 'YYYY-MM-DD'),
    nationality: nationalityId,
  };

  const handleNationalityIdChange = (value: string) => {
    setNationalityId(value);
  };

  const [result, error, state] = usePromise(
    () =>
      functions.httpsCallable('checkPatientIdNumber')({
        idNumber,
        isCreatingProfile: false,
      }),
    [idNumber],
  );

  useEffect(() => {
    if (error || state !== 'resolved') {
      return;
    }
    const isDuplicated = result?.data.isDuplicated;

    if (fromSubmit && isDuplicated) {
      openDialog();
      setFromSubmit(false);
    }
  }, [fromSubmit, idNumber, result, error, state]);

  const handleSubmit = (newValues: object) => {
    const birthday = get(newValues, 'birthday', '');
    const newBirthday = momentToFormat(lang, birthday, '');
    const updatedProfile = {
      ...newValues,
      birthday: newBirthday,
      testing: false,
    };

    const newIdNumber = get(newValues, 'idNumber', '');
    setIdNumber(newIdNumber);

    doProfileUpdate(updatedProfile);
    setFromSubmit(true);
  };

  return (
    <>
      <AutoForm
        className={cx.root}
        model={model}
        schema={UpdateProfile}
        onSubmit={(profile: object) => handleSubmit(profile)}
      >
        <SectionTitle title={t('profile.personalInfo')} />
        <AutoField
          name="email"
          inputProps={{
            readOnly: email,
          }}
          label={t('profile.email')}
          margin="dense"
          helperText={
            emailVerified
              ? '✅ ' + t('profile.emailVerified')
              : t('profile.needEmailVerificationInfo')
          }
        />
        <div className={cx.spaceBottom} />
        <PhoneNumberField
          name="phoneNumber"
          label={t('profile.phoneNumber')}
          readOnly
        />
        <AutoField name="name" label={t('profile.name')} />
        <ErrorField name="name" />
        <CustomSelectField
          name="gender"
          label={t('profile.gender')}
          options={genderOptions}
          fullWidth
        />
        <ErrorField name="gender" />
        <AutoField name="birthday" label={t('profile.birthday')} />
        <ErrorField name="birthday" />
        <AutoField
          name="resellerCode"
          label={t('profile.resellerCode')}
          inputProps={{
            readOnly: resellerCode,
          }}
        />
        <ErrorField
          name="resellerCode"
          errorMessage={t('profile.resellerError')}
        />
        <AutoField name="idNumber" label={t('profile.iDNumber')} />
        <ErrorField name="idNumber" />
        <UploadAreaUniformField
          name="idOrPassportPhoto"
          text={t(`profile.uploadID`)}
        />
        <ErrorField name="idOrPassportPhoto" />
        <BilingualAutocompleteField
          id="nationality"
          name="nationality"
          options={nationalitiesSorted}
          label={t('profile.nationality')}
          handleFunc={handleNationalityIdChange}
        />
        <ErrorField name="nationality" />
        <AutoField name="nativeLanguage" label={t('profile.nativeLanguage')} />
        <CustomSelectField
          name="englishProficiency"
          label={t('profile.englishProficiency')}
          options={proficiencyOptions}
          fullWidth
        />
        <CustomSelectField
          name="arabicProficiency"
          label={t('profile.arabicProficiency')}
          options={proficiencyOptions}
          fullWidth
        />
        <CustomSelectField
          name="frenchProficiency"
          label={t('profile.frenchProficiency')}
          options={proficiencyOptions}
          fullWidth
        />
        <AddressAreaList name="address" address={address} />
        <SectionTitle title={t('profile.lifestyleSection')} />
        <CustomSelectField
          name="workType"
          label={t('profile.workType')}
          options={workOptions}
          fullWidth
        />
        <AutoField
          name="married"
          label={t('profile.married')}
          color="primary"
        />
        <SubmitField color="primary" />
      </AutoForm>
      <Dialog onClose={closeDialog} open={dialogOpen}>
        <DialogContent>
          <Typography variant="subtitle1" align="justify">
            {t('profile.duplicatedIdNumberMessage')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileInfo;
