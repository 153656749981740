import { createBridge, JSONSchema } from '../../lib/utils';

const schema: JSONSchema = {
  title: 'Doctor notes',
  type: 'object',
  properties: {
    complaint: {
      type: 'string',
    },
    observations: {
      type: 'string',
    },
    diagnosis: {
      type: 'string',
    },
    prescription: {
      type: 'string',
    },
    doctorReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          specialtyId: {
            type: 'string',
          },
          medTitle: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
    labReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
    radioReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const oneSchema = createBridge(schema);

const mainSchema: JSONSchema = {
  title: 'Doctor notes',
  type: 'object',
  properties: {
    complaint: {
      type: 'string',
    },
    observations: {
      type: 'string',
    },
    diagnosis: {
      type: 'string',
    },
    prescription: {
      type: 'string',
    },
  },
  required: ['complaint', 'observations', 'diagnosis', 'prescription'],
};

const doctorReferralsSchema: JSONSchema = {
  title: 'Doctor referrals ',
  type: 'object',
  properties: {
    doctorReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          specialtyId: {
            type: 'string',
          },
          medTitle: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
  },
};

const labReferralsSchema: JSONSchema = {
  title: 'Lab referrals ',
  type: 'object',
  properties: {
    labReferrals: {
      type: 'array',
      maxItems: 12,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
  },
};

const radioReferralsSchema: JSONSchema = {
  title: 'Radio referrals ',
  type: 'object',
  properties: {
    radioReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const docNotesSchema = createBridge(mainSchema);
export const docRefsSchema = createBridge(doctorReferralsSchema);
export const labRefsSchema = createBridge(labReferralsSchema);
export const radioRefsSchema = createBridge(radioReferralsSchema);
