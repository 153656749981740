import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updateTimeslot } from '../../api/actions/schedule';
import { useDocument } from '../../api/usages';
import { auth } from '../../lib/firebase';
import { i18next } from '../../locales';
import TabPanel from '../TabPanel';
import WeekSchedule from './WeekSchedule';
import Weeks from './Weeks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      'background-color': '#f2f2f2',
    },
    formCtrl: {
      margin: '24px 0',
    },
    select: {
      'padding-left': '12px',
      'background-color': '#fff',
    },
    radioWrapper: {
      'margin-bottom': '32px',
    },
    radioGroup: {
      display: 'flex',
      'flex-direction': 'row',
    },
  }),
);

export const getMonths = () => {
  const lang = i18next.language;
  const startOfYear = moment();
  const months = [];
  moment.locale('en');
  for (let i = 0; i < 12; i++) {
    const month = moment(startOfYear).add(i, 'month').startOf('month');
    months.push({
      label: month.format('MMMM YYYY'),
      value: month.toDate(),
    });
    moment.locale(lang);
  }
  return months;
};

const Scheduler = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const months = getMonths();
  const userId = get(auth, 'currentUser.uid', '');
  const { result: defaultWeek, loading } = useDocument<any>({
    path: `schedules/${userId}`,
    noId: true,
  });

  const initTimeslot = get(defaultWeek, 'timeslot', 15);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(months[0].label);
  const [timeslot, setTimeslot] = useState<number>(initTimeslot);
  const month = months.filter(month => month.label === selectedMonth);

  useEffect(() => {
    setTimeslot(initTimeslot);
  }, [initTimeslot, setTimeslot]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };
  const handleTimeslotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeslot = (event.target as HTMLInputElement).value;
    setTimeslot(parseInt(timeslot));
    updateTimeslot(timeslot);
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: any }>) => {
      setSelectedMonth(event.target.value as string);
    },
    [setSelectedMonth],
  );

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Box className={cx.root}>
      <Paper square>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          <Tab label={t('schedule.repeatedWeek')} />
          <Tab label={t('schedule.allWeeks')} />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={0}>
        <FormControl className={cx.radioWrapper}>
          <FormControl component="fieldset" color="primary">
            <Typography variant="body1">{t('schedule.timeslot')}</Typography>
            <RadioGroup
              className={cx.radioGroup}
              aria-label="timeslots"
              name="timeslots"
              value={timeslot.toString()}
              onChange={handleTimeslotChange}
            >
              <FormControlLabel
                value="15"
                control={<Radio color="primary" />}
                label="15 mins"
              />
              <FormControlLabel
                value="20"
                control={<Radio color="primary" />}
                label="20 mins"
              />
              <FormControlLabel
                value="45"
                control={<Radio color="primary" />}
                label="45 mins"
              />
            </RadioGroup>
          </FormControl>
        </FormControl>
        <Grid container>
          <WeekSchedule
            isTemplate
            week={{ ...defaultWeek }}
            timeslot={timeslot}
            fromIndex
          />
        </Grid>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <FormControl className={cx.formCtrl} fullWidth variant="filled">
          <InputLabel id="months">{t('schedule.months')}</InputLabel>
          <Select
            className={cx.select}
            labelId="months"
            id="months"
            value={selectedMonth}
            onChange={handleChange}
          >
            {months.map(({ label, value }: any) => {
              return (
                <MenuItem key={value} value={label}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {month[0] && (
          <Grid container spacing={2}>
            <Weeks
              defaultWeek={{ ...defaultWeek }}
              month={month[0].value}
              userId={userId}
              timeslot={timeslot}
            />
          </Grid>
        )}
      </TabPanel>
    </Box>
  );
};

export default Scheduler;
