import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

interface ExpansionPanelProps {
  langs: Array<{ lang: any }>;
  title: string;
}

const ExpansionPanel = ({ langs, title }: ExpansionPanelProps) => {
  const cx = useStyles();

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={cx.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            {langs.map((lang, index) => (
              <Grid
                container
                direction="row"
                justify="space-evenly"
                key={index}
              >
                <ListItemText primary={lang.lang} />
                <Divider />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExpansionPanel;
