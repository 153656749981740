import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Booking,
  BookingStatuses,
  DoctorWithSpecialty,
  Language,
} from '../../../api/types';
import { useDocument } from '../../../api/usages';
import getDoctorName from '../../../lib/utils/getDoctorName';
import { t } from '../../../locales';
import Avatar from '../../Avatar';
import BookingInfo from '../../BookingList/BookingInfo';
import BookingInfoItem from '../../BookingList/BookingInfoItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    bookingInfo: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        minWidth: '70%',
      },
      minWidth: '70%',
      maxWidth: '70%',
    },
  }),
);

interface DoctorVisitsItemProps {
  booking: Booking;
}

const DoctorVisitsItem = ({ booking }: DoctorVisitsItemProps) => {
  const cx = useStyles();
  const { i18n } = useTranslation();
  const { result: doctor, error, loading } = useDocument<DoctorWithSpecialty>(
    {
      path: `doctors/${booking.doctorId}`,
      dependencies: [
        {
          collectionPath: 'specialties',
          mapFrom: 'specialtyId',
          mapTo: 'specialty',
        },
      ],
    },
    { skip: !booking.doctorId },
  );

  const visitedByDoctor = booking?.visitedByDoctor ?? false;
  const visitedByPatient = booking?.visitedByPatient ?? false;
  const bookingCanceled = booking.status === BookingStatuses.CANCELED;

  if (error || loading) {
    return null;
  }

  const lang = i18n.language as Language;
  const doctorName =
    getDoctorName(doctor, lang) || getDoctorName(booking.doctorDetails, lang);

  const specialty =
    doctor?.specialty?.translations?.[lang] ||
    booking.doctorDetails?.specialty?.translations?.[lang];

  const medTitle = doctor?.medTitle || booking.doctorDetails?.medTitle;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem key={booking.id} className={cx.listItem}>
            <ListItemAvatar style={{ marginRight: '15px' }}>
              <Avatar photoURL={doctor?.photoURL} name={doctorName} />
            </ListItemAvatar>
            <ListItemText
              className={cx.bookingInfo}
              disableTypography
              primary={
                <Typography variant="h6">
                  {t('videoCall.dr')} {doctorName}
                </Typography>
              }
              secondary={
                <React.Fragment>
                  <BookingInfoItem primaryText={specialty} />
                  <BookingInfoItem
                    primaryText={t(`profile.title_${medTitle}`)}
                  />
                  <BookingInfo booking={booking} />
                </React.Fragment>
              }
            />
            <ListItemText
              disableTypography
              primary={
                <React.Fragment>
                  {bookingCanceled && (
                    <Typography color="error" variant="body1">
                      {t('booking.canceled')}
                    </Typography>
                  )}
                  {!visitedByDoctor && (
                    <Typography color="error" variant="body1">
                      {t('booking.doctorNoShow')}
                    </Typography>
                  )}
                  {!visitedByPatient && (
                    <Typography color="error" variant="body1">
                      {t('booking.patientNoShow')}
                    </Typography>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DoctorVisitsItem;
