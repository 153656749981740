import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { i18next } from '../../../locales';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface BookButtonProps {
  createBooking?: any;
  label: string;
  phoneNumber: string;
}
const BookButton = ({ createBooking, label, phoneNumber }: BookButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openExtraInfo, setOpenExtraInfo] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleBooking = () => {
    createBooking();
    history.push('/');
  };

  const handleConfirm = () => {
    setOpenExtraInfo(!openExtraInfo);
  };

  const handleChangeInst = () => {
    history.goBack();
  };
  const lang = i18next.language;
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        endIcon={direction === 'rtl' ? <ArrowBackIos /> : <ArrowForwardIos />}
        onClick={() => {
          handleClick();
        }}
      >
        {label || t('booking.changeDoctor')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClick}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">
          {t('labs.confirmBook')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleChangeInst} color="primary">
            {t('labs.changeInstitution')}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {t('labs.book')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openExtraInfo} TransitionComponent={Transition}>
        <DialogTitle id="alert-dialog-title">
          {t('labs.forYourComfort')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t('labs.extraInfo_1', { phoneNumber })}
          </Typography>
          <Typography gutterBottom>{t('labs.extraInfo_2')}</Typography>
          <Typography gutterBottom>{t('labs.extraInfo_3')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBooking} color="primary">
            {t('labs.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BookButton;
