import React from 'react';

import { useAuthReady, useUser } from '../api/usages/auth';
import ChangePassword from '../components/ChangePassword';
import ForgotPassword from '../components/ForgotPassword';
import LayoutDefault from '../components/LayoutDefault';

const ForgotPass = () => {
  const isReady = useAuthReady();
  const user = useUser();
  if (!isReady) {
    return <LayoutDefault />;
  }
  if (!user) {
    return (
      <LayoutDefault>
        <ForgotPassword />
      </LayoutDefault>
    );
  }

  return (
    <LayoutDefault>
      <ChangePassword />
    </LayoutDefault>
  );
};

export default ForgotPass;
