import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabOrRadioBookingStatus, UserRoleTypes } from '../api/types';
import { useContextRole, useUserId } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import OrdersList from '../components/Orders/OrdersList';
import TabPanel from '../components/TabPanel';

const Orders = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  const userRole = useContextRole();
  const [currentTab, setCurrentTab] = React.useState(
    userRole === UserRoleTypes.PATIENT ? 0 : 1,
  );

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <LayoutDefault>
      <Paper square>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          <Tab label={t('orders.delivered')} />
          <Tab label={t('orders.undelivered')} />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={0}>
        {userRole && (
          <OrdersList
            userId={userId}
            role={userRole}
            status={LabOrRadioBookingStatus.DELIVERED}
          />
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        {userRole && (
          <OrdersList
            userId={userId}
            role={userRole}
            status={LabOrRadioBookingStatus.UNDELIVERED}
          />
        )}
      </TabPanel>
    </LayoutDefault>
  );
};

export default Orders;
