import firebase from 'firebase';
import React from 'react';

import { UserRoleTypes } from '../../api/types';
import PhoneVerification from '../PhoneVerification';

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

type MobileModeFormProps = {
  profileType: UserRoleTypes;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
};

export const MobileModeForm = ({
  profileType,
  onAuthorized,
}: MobileModeFormProps) => {
  return (
    <PhoneVerification
      profileType={profileType}
      onAuthorized={(...arg) => {
        if (typeof onAuthorized === 'function') {
          return onAuthorized(...arg);
        }

        return null;
      }}
    />
  );
};
