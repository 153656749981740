import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cashInPayment } from '../api/actions/payments';
import { Currency, PaymentMethod, PaymentWallet } from '../api/types/Payments';
import { usePaymentWalletAccounts, useUserId } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import PaymentMethods from '../components/Payments/PaymentMethods';

const PaymentCashIn = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  const { loading, wallet } = usePaymentWalletAccounts(userId);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [method, setMethod] = useState<PaymentMethod | null>(null);
  const [billingInfo, setBillingInfo] = useState<
    PaymentWallet['billingInfo'] | null
  >(null);
  const [amount, setAmount] = useState<number | null>(150);
  const currency = Currency.EGP; // hardcoded

  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);

  if (loading || !wallet) {
    return null;
  }

  return (
    <LayoutDefault>
      <Box textAlign="center" m={4}>
        <h1>{t('payments.cashIn')}</h1>
        <Divider />
      </Box>

      <PaymentMethods
        allowedMethods={[
          PaymentMethod.PAYMOB_CARD,
          PaymentMethod.PAYMOB_WALLET,
          PaymentMethod.PAYMOB_KIOSK,
        ]}
        onPay={(method, billingInfo) => {
          setMethod(method);
          setBillingInfo(billingInfo);
          openDialog();
        }}
        currency={currency}
        wallet={wallet}
        price={{ amount, currency }}
      />

      <Dialog open={dialogOpen && !!method}>
        <DialogContent>
          <DialogContentText>
            <h4>{t('payments.cashIn')}</h4>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('payments.amount')}</InputLabel>
              <OutlinedInput
                value={amount}
                type="number"
                onChange={ev => {
                  const value = parseFloat(ev.target.value);
                  setAmount(isNaN(value) ? null : value);
                }}
                endAdornment={
                  <InputAdornment position="end">{currency}</InputAdornment>
                }
                labelWidth={60}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!amount || amount < 6}
            onClick={() => {
              amount &&
                cashInPayment({
                  price: { amount: amount * 100, currency },
                  paymentMethod: method,
                  billingInfo,
                });
            }}
            color="primary"
          >
            {t('payments.pay')}
          </Button>
          <Button onClick={closeDialog}>{t('common.close')}</Button>
        </DialogActions>
      </Dialog>
    </LayoutDefault>
  );
};

export default PaymentCashIn;
