/* eslint-disable complexity */
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  List,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FullQuery,
  SimpleQuery,
  LabOrRadioBookingStatus,
  BookingStatuses,
} from '../../api/types';
import {
  useUserId,
  useBookings,
  useLabBookings,
  useRadioBookings,
  useUnrealizedBookings,
} from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import OrderListItem from '../Orders/OrderListItem';
import BookingListItem from './BookingListItem';

const PastBookingsListView = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  // currentTime is in state to avoid infinite updates
  const [currentTime] = useState(moment().toDate());
  const [limit, setLimit] = useState(20);

  const queryOrders: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: '==',
      value: LabOrRadioBookingStatus.DELIVERED,
    },
  ];

  const fullQueryOrders: FullQuery = {
    where: queryOrders,
    orderBy: ['updatedAt', 'desc'],
    limit,
  };

  const { error: labError, loading: labLoading, labBookings } = useLabBookings(
    fullQueryOrders,
    {
      skip: !userId,
    },
  );

  const {
    error: radioError,
    loading: radioLoading,
    radioBookings,
  } = useRadioBookings(fullQueryOrders, {
    skip: !userId,
  });

  const queryUnrealized: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'endDate',
      opStr: '<',
      value: Timestamp.fromDate(currentTime),
    },
  ];

  const fullQueryUnrealized: FullQuery = {
    where: queryUnrealized,
    orderBy: ['endDate', 'desc'],
    limit,
  };

  const queryPast: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [
        BookingStatuses.FINISHED,
        BookingStatuses.FAILED,
        BookingStatuses.CANCELED,
      ],
    },
  ];

  const fullQueryPast: FullQuery = {
    where: queryPast,
    orderBy: ['endDate', 'desc'],
    limit,
  };

  // Bookings visited by no one or only the patient
  // are visible only one hour after their completion,
  // therefore we fetch them separately.
  const queryDoctorMissedBookings: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [BookingStatuses.OPENED, BookingStatuses.GOING],
    },
    {
      fieldPath: 'visitedByDoctor',
      opStr: '==',
      value: false,
    },
    {
      fieldPath: 'endDate',
      opStr: '<',
      value: Timestamp.fromDate(new Date()),
    },
  ];

  const fullQueryDoctorMissedBookings: FullQuery = {
    where: queryDoctorMissedBookings,
    orderBy: ['endDate', 'desc'],
    limit,
  };

  const {
    error: bookingsError,
    loading: bookingsLoading,
    bookings,
  } = useBookings(fullQueryPast, {
    skip: !userId,
  });

  const {
    error: doctorMissedBookingsError,
    loading: doctorMissedBookingsLoading,
    bookings: doctorMissedBookings,
  } = useBookings(fullQueryDoctorMissedBookings, { skip: !userId });

  const {
    error: unrealizedError,
    loading: unrealizedLoading,
    unrealizedBookings,
  } = useUnrealizedBookings(fullQueryUnrealized, {
    skip: !userId,
  });

  if (
    bookingsError ||
    labError ||
    radioError ||
    unrealizedError ||
    doctorMissedBookingsError
  ) {
    return null;
  }

  if (
    bookingsLoading ||
    labLoading ||
    radioLoading ||
    unrealizedLoading ||
    doctorMissedBookingsLoading
  ) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  const allBookings = labBookings
    .concat(radioBookings)
    .concat(bookings)
    .concat(unrealizedBookings)
    .concat(doctorMissedBookings);

  if (!allBookings.length) {
    return <h1>{t('booking.emptyPastVisits')}</h1>;
  }

  allBookings.sort((a, b) => {
    const aDate = moment((a.startDate || a.updatedAt).toDate());
    const bDate = moment((b.startDate || b.updatedAt).toDate());

    return bDate.diff(aDate);
  });

  const canLoadMore =
    bookings.length === limit ||
    labBookings.length === limit ||
    radioBookings.length === limit;

  const handleLoadMore = () => {
    setLimit(limit => limit + 20);
  };

  return (
    <>
      <List>
        {allBookings.map((booking: any) =>
          booking.labId || booking.radioId ? (
            <OrderListItem
              key={booking.id}
              order={booking}
              withLabOrRadioInfo
              withDownloadButton
            />
          ) : (
            <BookingListItem
              key={booking.id}
              booking={booking}
              withDoctorInfo
            />
          ),
        )}
      </List>
      {canLoadMore && (
        <Grid container justify="center" style={{ marginTop: '15px' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
            >
              {t('booking.loadMore')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PastBookingsListView;
