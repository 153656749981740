import { Divider, Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  createLabBooking,
  updateLabRadioReferralWithId,
} from '../../../api/actions/labBooking';
import { createRadioBooking } from '../../../api/actions/radioBooking';
import { AreaWithGovernorate } from '../../../api/types/Address';
import { Lab } from '../../../api/types/Lab';
import { useDocument } from '../../../api/usages';
import Avatar from '../../Avatar';
import BackButton from '../../BackButton';
import GeoPicker from '../../GeoPicker';
import BookButton from './BookButton';
import ExpansionPanel from './ExpansionPanel';
import FieldsPanel from './FieldsPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    firstRow: {
      marginDown: theme.spacing(3),
    },
    expansionPanels: {
      '&>*': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

const getTranslatedName = (name: any): string => {
  if (!name) {
    return '';
  }
  const translatedName = name[i18next.language];
  return translatedName || name['en'];
};

interface DetailInfoProps {
  type: 'lab' | 'radio';
  id: string;
  serviceId: string;
  bookingId: string;
  offerDeiliver: boolean;
  areaId: string;
  referralId: string;
}

const DetailInfo = ({
  type,
  id,
  serviceId,
  bookingId,
  areaId,
  referralId,
}: DetailInfoProps) => {
  const cx = useStyles();
  const { t } = useTranslation();

  const { result: area } = useDocument<AreaWithGovernorate>(
    {
      path: `areas/${areaId}`,
      dependencies: [
        {
          collectionPath: 'governorates',
          mapFrom: 'governorateId',
          mapTo: 'governorate',
        },
      ],
    },
    { skip: !areaId },
  );

  const govName = area?.governorate.translations ?? '';
  const areaName = area?.translations ?? '';

  const { result: lab, loading, error } = useDocument<Lab>(
    {
      path: `${type === 'lab' ? 'labs' : 'radios'}/${id}`,
    },
    { skip: !id },
  );

  const labId = id;
  const radioId = id;
  const createNewLabBooking = () => {
    createLabBooking({ labId, serviceId, bookingId, referralId });
    updateLabRadioReferralWithId(referralId, labId, 'labReferrals');
  };

  const createNewRadioBooking = () => {
    createRadioBooking({ radioId, serviceId, bookingId, referralId });
    updateLabRadioReferralWithId(referralId, radioId, 'radioReferrals');
  };

  const name = lab?.name ?? '';
  const address = lab?.address?.[0].streetBlockCompound ?? '';
  const email = lab?.email ?? '';
  const photoURL = lab?.photoURL ?? '';
  const about = lab?.about ?? '';
  const deliverIcon = lab?.offerDelivery ?? '';
  const geolocation = lab?.address?.[0].geolocation ?? '';
  const number = lab?.receptionPhone ?? '';

  const languages = [
    {
      lang:
        lab?.arabicProficiency !== 'noKnowledge' ? t('booking.arabic') : null,
    },
    {
      lang:
        lab?.englishProficiency !== 'noKnowledge' ? t('booking.english') : null,
    },
    {
      lang:
        lab?.frenchProficiency !== 'noKnowledge' ? t('booking.french') : null,
    },
  ];

  if (error || loading) {
    return null;
  }
  return (
    <Paper className={cx.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={cx.firstRow}
      >
        <FieldsPanel label={t('labs.name')} value={name} />
        <Avatar photoURL={photoURL} />
      </Grid>
      <Divider />
      {lab?.address && (
        <>
          <Grid container justify="space-between">
            <FieldsPanel
              label={t('labs.address')}
              value={`${getTranslatedName(govName)}, ${getTranslatedName(
                areaName,
              )}`}
            />
            <FieldsPanel label={t('labs.street')} value={address} />
          </Grid>
          <Divider />
        </>
      )}
      <Grid container justify="space-between">
        <FieldsPanel label={t('labs.email')} value={email} />
        <FieldsPanel label={t('labs.receptionPhone')} value={number} />
      </Grid>
      <Divider />
      <Grid container justify="space-between">
        <FieldsPanel label={t('labs.about')} value={about} />
      </Grid>
      <Divider />
      <Grid container justify="space-between">
        <FieldsPanel label={t('labs.offerDelivery')} />
        <FieldsPanel value={deliverIcon ? <CheckIcon /> : <ClearIcon />} />
      </Grid>
      <div className={cx.expansionPanels}>
        <ExpansionPanel langs={languages} title={t('booking.languages')} />
      </div>
      <GeoPicker value={geolocation} readOnly />
      <div className={cx.buttons}>
        {' '}
        <BackButton />
        <BookButton
          phoneNumber={number}
          createBooking={
            type === 'lab' ? createNewLabBooking : createNewRadioBooking
          }
          label={t('labs.bookVisit')}
        />
      </div>
    </Paper>
  );
};

export default DetailInfo;
