import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Booking, BookingTypes, Doctor, Language, Patient } from '../api/types';
import { Timestamp } from '../api/types/Timestamp';
import { useDocument, useUserId } from '../api/usages';
import SummarySection from '../components/CallSummary/SummaryDetails';
import SummaryDialog from '../components/CallSummary/SummaryDialog';
import SummaryReferrals from '../components/CallSummary/SummaryReferrals';
import PrescriptionDetails from '../components/HealthRecords/Prescriptions/PrescriptionDetails';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { centsToDollars } from '../lib/utils';
import getDoctorName from '../lib/utils/getDoctorName';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 700,
      padding: 16,
    },
    currency: {
      fontSize: theme.typography.pxToRem(16),
      margin: 10,
    },
    price: {
      fontSize: theme.typography.pxToRem(32),
    },
    duration: {
      fontSize: theme.typography.pxToRem(28),
    },
    time: {
      fontSize: theme.typography.pxToRem(24),
    },
    spacing: {
      margin: 16,
    },
    spacingTop: {
      marginTop: 16,
    },
    stars: {
      marginLeft: 8,
    },
    closeButton: {
      alignSelf: 'flex-end',
      marginRight: 16,
    },
    note: {
      color: '#00000060',
      marginTop: 0,
      marginBottom: 16,
    },
    noShowInfo: {
      width: '80%',
      margin: '10px auto',
    },
  }),
);

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState(open => !open), []);

  return [state, toggle] as const;
};

// eslint-disable-next-line complexity
const CallSummary = () => {
  const cx = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const userId = useUserId();

  const { result: patient } = useDocument<Patient>({
    path: `patients/${userId}`,
  });

  const verified = patient?.verified;
  const lang = i18n.language as Language;
  const direction = lang === 'ar' ? 'rtl' : 'ltr';

  useRedirectWhenNotExists(`bookings/${id}`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {
    result: booking,
    error: errorBooking,
    loading: loadingBooking,
  } = useDocument<Booking>({ path: `bookings/${id}` });
  const {
    result: doctor,
    error: errorDoctor,
    loading: loadingDoctor,
  } = useDocument<Doctor>({
    path: `doctors/${booking?.doctorId}`,
  });

  const visitedByDoctor = booking?.visitedByDoctor ?? false;
  const visitedByPatient = booking?.visitedByPatient ?? false;
  const doctorId = doctor?.id;
  const doctorName = getDoctorName(doctor, lang);
  const disabled = !visitedByDoctor || !visitedByPatient;

  const [prescriptionDialog, togglePrescriptionDialog] = useToggle();

  const rating = booking?.rating;
  const price = {
    amount: visitedByDoctor ? booking?.price?.amount ?? 0 : 0,
    currency: t(`common.currency${booking?.price?.currency ?? 'EGP'}`),
  };

  const priceAmountString = centsToDollars(price.amount);

  const duration =
    !disabled && booking && booking.endDate
      ? (booking.endDate.seconds - booking.startDate.seconds) / 60
      : 0;

  const startTime = ((booking?.startDate as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      dateStyle: 'medium',
      timeStyle: 'short',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const weekday = ((booking?.startDate as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      weekday: 'long',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const dateToDisplay = `${weekday}, ${startTime}`;
  const rawBookingType = booking?.type;
  const bookingType = rawBookingType ? t(`booking.type_${rawBookingType}`) : '';

  const handleClose = useCallback(() => {
    history.push('/');
  }, [history]);

  if (errorBooking || loadingBooking || errorDoctor || loadingDoctor) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <LayoutDefault>
      <Title title={t('callSummary.callSummary')} />
      {!visitedByDoctor && (
        <Typography
          variant="subtitle1"
          align="center"
          color="error"
          className={cx.noShowInfo}
        >
          {t('callSummary.doctorNoShowForPatient')}
        </Typography>
      )}
      {!visitedByPatient && (
        <Typography
          variant="subtitle1"
          align="center"
          color="error"
          className={cx.noShowInfo}
        >
          {t('callSummary.patientNoShowForPatient')}
        </Typography>
      )}
      <Grid container direction="row" justify="center" alignItems="center">
        <Card className={cx.root}>
          <Box display="flex" justifyContent="space-evenly">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography className={cx.currency}>{price.currency}</Typography>
              <Typography className={cx.price}>{priceAmountString}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box className={cx.duration}>{duration}</Box>
              <Box className={cx.time}>{t('callSummary.timeConsult')}</Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            {!disabled && (
              <Paper>
                {rawBookingType === BookingTypes.INITIAL ? (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5" className={cx.spacing}>
                      {t('callSummary.freeFollowupCall')}
                    </Typography>
                    <Button
                      component={Link}
                      to={'/follow-up/' + booking?.id}
                      style={{ alignSelf: 'flex-end' }}
                      endIcon={
                        direction === 'rtl' ? <ArrowBack /> : <ArrowForward />
                      }
                      color="primary"
                      disabled={!verified}
                    >
                      {t('callSummary.bookFollowUp')}
                    </Button>
                  </Box>
                ) : (
                  <Typography variant="h5" className={cx.spacing}>
                    {t('callSummary.itWasFollowUp')}
                  </Typography>
                )}
              </Paper>
            )}
            <Divider flexItem className={cx.spacing} />
            <Button
              variant="contained"
              color="primary"
              className={cx.spacing}
              onClick={togglePrescriptionDialog}
              disabled={disabled}
            >
              {t('common.prescription')}
            </Button>
            <SummaryDialog
              title={t('common.prescription')}
              content={<PrescriptionDetails bookingId={id} disableButton />}
              open={prescriptionDialog}
              onClose={togglePrescriptionDialog}
              maxWidth="md"
            />
            <SummaryReferrals
              bookingId={id}
              disabled={disabled}
              patientVerified={verified}
            />
            <Typography className={cx.spacingTop}>
              {t('callSummary.paymentMethodBy', {
                method: t('payments.walletTitle'),
              })}
            </Typography>
            <SummarySection
              data={[
                [t('callSummary.dateAndTime'), dateToDisplay],
                [t('callSummary.serviceType'), bookingType],
                [t('callSummary.complaint'), booking?.complaint || ''],
              ]}
            />
            <Box display="flex" flexDirection="row" className={cx.spacing}>
              <Typography>
                {t('callSummary.youRatedDoctor', { doctorName })}
              </Typography>
              <Rating value={rating || 0} className={cx.stars} readOnly />
            </Box>
            <SummarySection
              data={[
                ['', doctorName],
                ['', doctor?.about?.[lang]],
              ]}
              withButton
              path="/doctor/"
              params={doctorId}
              translation={t('callSummary.bookAgain', { doctorName })}
            />
            <Button
              color="secondary"
              className={cx.closeButton}
              onClick={handleClose}
            >
              {t('common.close')}
            </Button>
          </Box>
        </Card>
      </Grid>
    </LayoutDefault>
  );
};

export default CallSummary;
