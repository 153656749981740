import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const AdminHome = () => {
  const profile = useProfile();
  const { t } = useTranslation();

  return (
    <LayoutDefault>
      <Title title={t('admin.adminPanel', { name: profile?.name })} />
    </LayoutDefault>
  );
};

export default AdminHome;
