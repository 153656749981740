import { auth, db, mergeData, timestampNow } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { ObservationRaw } from '../types';

export async function upsertObservationCallback({
  id: observationId,
  bookingId,
  patientId,
  content,
}: ObservationRaw) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const observationsRef = db.collection('observations');

  const observationData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    content,
    updatedAt: timestampNow(),
  };

  if (!observationId) {
    return observationsRef.add({
      ...observationData,
      createdAt: timestampNow(),
    });
  }

  return mergeData(`observations/${observationId}`, observationData);
}

export const upsertObservation = createAction({
  type: 'upsertObservation',
  callback: upsertObservationCallback,
});
