import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { removeLabRadioBookingFromList } from '../../api/actions/labBooking';

interface DeleteOrderDialogProps {
  bookingCollection: string;
  onClose: any;
  orderId: string;
  open: boolean;
}

const DeleteOrderDialog = ({
  bookingCollection,
  onClose,
  orderId,
  open,
}: DeleteOrderDialogProps) => {
  const { t } = useTranslation();

  // @ts-ignore
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <h2>{t('orders.confirmDeleteInfo')}</h2>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            removeLabRadioBookingFromList(orderId, bookingCollection)
          }
          color="primary"
          variant="contained"
        >
          {t('orders.confirmDelete')}
        </Button>
        <Button onClick={onClose} color="secondary">
          {t('orders.discardDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrderDialog;
