import { BehaviorSubject, Subject, merge, of, timer } from 'rxjs';
import { debounce, mapTo, switchMap } from 'rxjs/operators';
import { Synth } from 'tone';

import { CURRENT_RUNTIME, RuntimeTypes } from '../../constants/app';
import { initializingAuth$ } from '../../lib/firebase';
import {
  forAllNotifications$,
  forTypeNotifications$,
} from '../../lib/firebase/notifications';

export type InitialisingProgressEvent = {
  variant: 'determinate' | 'indeterminate';
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value?: number;
} | null;

type ToastConfig = {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  title?: string;
  time?: number;
};

export type ToastEvent = ToastConfig | null;

const synth = new Synth().toDestination();

const initialising$ = new BehaviorSubject<InitialisingProgressEvent>(null);
const toast$ = new Subject<ToastEvent>();

const indeterminateValue: InitialisingProgressEvent = {
  variant: 'indeterminate',
};

export const clearInitialisingProgress = () => initialising$.next(null);

/**
 * Display/Hide or update progress for global ui loader
 */
export const updateInitialisingProgress = (value?: number | boolean) => {
  if (!value || value >= 100) {
    clearInitialisingProgress();
  } else if (typeof value === 'number' && value > 0) {
    initialising$.next({ variant: 'determinate', value });
  } else {
    initialising$.next({ ...indeterminateValue });
  }
};

// Toast API

const showToast = (type: ToastConfig['type']) => (
  config: string | Omit<ToastConfig, 'type'>,
) =>
  toast$.next(
    typeof config === 'string'
      ? {
          type,
          message: config,
        }
      : {
          type,
          ...config,
        },
  );

export const hideToast = () => toast$.next(null);

export const showSuccessToast = showToast('success');
export const showInfoToast = showToast('info');
export const showWarningToast = showToast('warning');
export const showErrorToast = showToast('error');

// Streams for components and other integrations

forAllNotifications$.subscribe(notification => {
  if (notification.body && CURRENT_RUNTIME === RuntimeTypes.Browser) {
    synth.triggerRelease(0);
    synth.triggerAttackRelease('C4', '8n');
    showInfoToast({ ...notification, message: notification.body });
  }
});

forTypeNotifications$.subscribe(notification => {
  if (notification.body && CURRENT_RUNTIME === RuntimeTypes.Browser) {
    synth.triggerRelease(0);
    synth.triggerAttackRelease('C4', '8n');
    showInfoToast({ ...notification, message: notification.body });
  }
});

export const initialisingProgress$ = initializingAuth$.pipe(
  switchMap(isAuthInit => {
    if (isAuthInit) {
      return of({ ...indeterminateValue });
    }
    return initialising$;
  }),
);

export const toastNotifications$ = merge<ToastEvent>(
  toast$,
  toast$.pipe(
    debounce(ev => timer(ev?.time ?? 5000)),
    mapTo(null),
  ),
);
