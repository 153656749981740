import Delete from '@material-ui/icons/Delete';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'uniforms';
import { ErrorField, ListItemField, SelectField } from 'uniforms-material';

import {
  useCertificatesCategories,
  useCertificatesTypes,
} from '../../api/usages';
import CustomSelectField from '../CustomSelectField';
import DownloadPDFBtn from '../DownloadPDFBtn';
import UploadAreaUniformField from '../UploadAreaUniformField';
import CertificateTextField from './CertificateTextField';

export type Props = {
  name: string;
  readOnly?: boolean;
  formRef?: RefObject<any>;
  downloadButton?: boolean;
};

const CertificatesListItemField = (props: Props) => {
  const { name, readOnly = false, formRef, downloadButton = false } = props;

  const { t } = useTranslation();

  const {
    model: { certificates },
  } = useForm<{ certificates: any[] }>();

  const categoryId = get(certificates, `[${name}].certificateCategoryId`, '');
  const initTypeId = get(certificates, `[${name}].certificateTypeId`, '');
  const certificateUrl = get(certificates, `[${name}].certificateUrl`, '');
  const { certificateCategories } = useCertificatesCategories();

  const [certModel, setCertModel] = useState<any>({});
  const { certificateTypes } = useCertificatesTypes(
    [
      {
        fieldPath: 'certificateCategoryId',
        opStr: '==',
        value: categoryId,
      },
    ],
    { skip: !categoryId },
  );

  const handleCategoryChange = (value: any) => {
    setCertModel({});
    formRef?.current?.change(`certificates[${name}].certificateTypeId`, '');
    formRef?.current?.change(`certificates[${name}].certificateUrl`, '');
    formRef?.current?.change(`certificates[${name}].name`, '');
  };

  const updateCertModel = (value: any) => {
    const type = certificateTypes.filter((type: any) => type.id === value);
    const newCertificates = get(type, '[0].certificates', []);
    const name = get(type, '[0].name', '');
    setCertModel({ ...certModel, certificates: newCertificates, name });
  };

  const handleTypeChange = (value: any) => {
    updateCertModel(value);
  };

  useEffect(() => {
    if (categoryId && initTypeId) {
      updateCertModel(initTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, initTypeId, certificateTypes]);

  return (
    <ListItemField name={name} removeIcon={<Delete />}>
      <CustomSelectField
        label={t('englishOnly.profile_typeOfTitles')}
        name="certificateCategoryId"
        options={certificateCategories}
        handleChange={handleCategoryChange}
        readOnly={readOnly}
        fullWidth
      />
      <ErrorField name="certificateCategoryId" />
      <CustomSelectField
        label={t('englishOnly.profile_titles')}
        name="certificateTypeId"
        options={categoryId ? certificateTypes : []}
        handleChange={handleTypeChange}
        readOnly={readOnly}
        fullWidth
      />
      <ErrorField name="certificateTypeId" />
      {!isEmpty(certModel) && certModel.certificates.length > 0 && (
        <SelectField
          label={t('englishOnly.profile_certificateName')}
          name="name"
          allowedValues={certModel.certificates}
          inputProps={{ readOnly }}
        />
      )}
      {!isEmpty(certModel) &&
        certModel.name &&
        certModel.certificates.length === 0 && (
          <>
            <CertificateTextField
              label={t('englishOnly.profile_certificateName')}
              name="name"
              initValue={certModel.name}
            />
            <ErrorField name="name" />{' '}
          </>
        )}
      <UploadAreaUniformField
        name="certificateUrl"
        text={t(`englishOnly.profile_uploadCertificates`)}
        filesLimit={1}
        acceptedFiles={['image/*', 'application/pdf']}
        disabled={readOnly}
      />
      <ErrorField
        name="certificateUrl"
        errorMessage={t('profile.certificateUrl')}
      />
      {downloadButton && (
        <DownloadPDFBtn
          url={certificateUrl}
          style={{
            display: 'block',
            alignSelf: 'flex-start',
            marginBottom: 10,
          }}
        />
      )}
    </ListItemField>
  );
};

export default CertificatesListItemField;
