import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: red[500],
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

type AvatarProps = {
  name?: string | null | undefined;
  photoURL?: string | null | undefined;
};

const AvatarCustom = ({ name = '', photoURL }: AvatarProps) => {
  const cx = useStyles();

  if (photoURL) {
    return <Avatar aria-label="avatar" className={cx.avatar} src={photoURL} />;
  }

  return (
    <Avatar aria-label="avatar" className={cx.avatar}>
      {name ? name.trim().charAt(0) : 'A'}
    </Avatar>
  );
};

export default AvatarCustom;
