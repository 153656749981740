import { Backdrop, CircularProgress, List } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FullQuery,
  SimpleQuery,
  LabOrRadioBookingStatus,
  Booking,
  BookingStatuses,
} from '../../api/types';
import {
  useBookings,
  useUserId,
  useLabBookings,
  useRadioBookings,
  useUnrealizedBookings,
} from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import OrderListItem from '../Orders/OrderListItem';
import BookingListItem from './BookingListItem';

const UpcomingBookingsListView = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  // currentTime is in state to avoid infinite updates
  const [currentTime] = useState(moment().toDate());
  const queryOrders: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: '==',
      value: LabOrRadioBookingStatus.UNDELIVERED,
    },
  ];

  const { error: labError, loading: labLoading, labBookings } = useLabBookings(
    queryOrders,
    {
      skip: !userId,
    },
  );

  const {
    error: radioError,
    loading: radioLoading,
    radioBookings,
  } = useRadioBookings(queryOrders, {
    skip: !userId,
  });

  const queryUpcoming: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'endDate',
      opStr: '>=',
      value: Timestamp.fromDate(currentTime),
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [
        BookingStatuses.OPENED,
        BookingStatuses.GOING,
        BookingStatuses.CANCELED,
      ],
    },
  ];

  const fullQueryUpcoming: FullQuery = {
    where: queryUpcoming,
    orderBy: ['endDate', 'asc'],
  };

  const {
    error: bookingsError,
    loading: bookingsLoading,
    bookings,
  } = useBookings(fullQueryUpcoming, {
    skip: !userId,
  });

  const {
    error: unErr,
    loading: unLoading,
    unrealizedBookings,
  } = useUnrealizedBookings(fullQueryUpcoming, {
    skip: !userId,
  });

  if (bookingsError || labError || radioError || unErr) {
    return null;
  }

  if (bookingsLoading || labLoading || radioLoading || unLoading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  let allOrders = labBookings.concat(radioBookings);
  allOrders = orderBy(allOrders, 'createdAt.seconds');
  const allBookings = bookings.concat(unrealizedBookings);

  return allBookings.length || allOrders.length ? (
    <>
      <List>
        {allOrders.map((order: any) => (
          <OrderListItem
            key={order.id}
            order={order}
            withLabOrRadioInfo
            withDeleteButton
          />
        ))}
      </List>
      <List>
        {allBookings.map((booking: Booking) => (
          <BookingListItem
            key={booking.id}
            booking={booking}
            withDoctorInfo
            withCallButton
          />
        ))}
      </List>
    </>
  ) : (
    <h1>{t('booking.emptyTodayVisits')}</h1>
  );
};

export default UpcomingBookingsListView;
