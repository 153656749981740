import { FormControl, InputLabel, Input } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root': {
      direction: (props: any) => props.directionLabel,
    },
    '& .MuiInputBase-input': {
      textAlign: (props: any) => props.textAlign,
      direction: (props: any) => props.directionInput,
    },
  },
});

function PhoneNumberField(props: any) {
  const { value = '', onChange, label, disabled, readOnly } = props;
  const { i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const styleProps = {
    textAlign: dir === 'rtl' ? 'right' : 'left',
    directionLabel: dir === 'rtl' ? 'rtl' : 'ltr',
    directionInput: dir === 'rtl' ? 'ltr' : 'ltr',
  };
  const cx = useStyles(styleProps);
  function handleChange({ target: { value } }: any) {
    onChange(value);
  }

  return (
    <Box className={cx.root}>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel htmlFor="phone-number">{label}</InputLabel>
        <Input
          id="phone-number"
          type="tel"
          value={value}
          onChange={handleChange}
          inputProps={{
            readOnly,
          }}
        />
      </FormControl>
    </Box>
  );
}

export default connectField(PhoneNumberField);
