import { Grid } from '@material-ui/core';
import React from 'react';

import { termsCounts, fillArray } from '../../constants/ConfigHelp';
import { Point } from './common/Helpers';

const Terms = () => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      {Object.entries(termsCounts).map(([key, value]) => (
        <Point
          key={key}
          section="terms"
          title={`title_${key}`}
          texts={fillArray(value, key)}
        />
      ))}
    </Grid>
  );
};

export default Terms;
