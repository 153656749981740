import React from 'react';

import { Prescription } from '../../../../api/types';
import CardText from '../CardText';

interface DoctorPrescriptionProps {
  prescriptions: Prescription[];
}

const DoctorPrescription = ({ prescriptions }: DoctorPrescriptionProps) => {
  const records = [
    {
      label: 'healthRecords.prescription',
      text: prescriptions[0]?.content,
    },
  ];

  return <CardText records={records} />;
};

export default DoctorPrescription;
