import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { UserRoleTypes } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import SignInForm from '../components/SignInForm';
import StandardList from '../components/StandardList';
import Title from '../components/Title';
import ROUTES from '../constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      padding: theme.spacing(3),
      maxWidth: 1024,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    headline: {
      wordBreak: 'break-word',
    },
    register: {
      marginTop: theme.spacing(4),
    },
  }),
);

const list1ItemsKeys = Array.from(
  { length: 9 },
  (_, i) => `englishOnly.forDoctor_list1_item${i + 1}`,
);
const list2ItemsKeys = Array.from(
  { length: 21 },
  (_, i) => `englishOnly.forDoctor_list3_item${i + 1}`,
);

const ForDoctor = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  const getListItems = (keys: string[]) =>
    keys.map((key: string) => ({ key, value: t(key) }));

  return (
    <LayoutDefault>
      <Card className={cx.root} style={{ direction: 'ltr' }}>
        <div className={cx.header}>
          <Title title={t('englishOnly.forDoctor_title')} />
        </div>
        <Typography className={cx.headline} variant="body1" gutterBottom>
          {t('englishOnly.forDoctor_headline')}
          <Link href={t('englishOnly.forDoctor_headline_link')}>
            {t('englishOnly.forDoctor_headline_link')}
          </Link>
        </Typography>
        <StandardList
          subheader={t('englishOnly.forDoctor_list1_header')}
          items={getListItems(list1ItemsKeys)}
          ignoreRTL
        />
        <StandardList
          subheader={t('englishOnly.forDoctor_list3_header')}
          items={getListItems(list2ItemsKeys)}
          ignoreRTL
        />
        <div className={cx.root}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="h5" gutterBottom>
                {t('englishOnly.signUp')}
              </Typography>
              <div style={{ marginTop: '16px' }}>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('englishOnly.sign_in_text1')}</strong>{' '}
                  {t('englishOnly.sign_in_text2')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('englishOnly.sign_in_text3')}</strong>{' '}
                  {t('englishOnly.sign_in_text4')}
                </Typography>
                <Button
                  component={RouterLink}
                  to={ROUTES.SIGN_UP_DOCTOR}
                  variant="contained"
                  color="primary"
                >
                  {t('englishOnly.signUp')}
                </Button>
              </div>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h5" gutterBottom>
                {t('englishOnly.signIn')}
              </Typography>
              <SignInForm profileType={UserRoleTypes.DOCTOR} />
            </Grid>
          </Grid>
        </div>
      </Card>
    </LayoutDefault>
  );
};

export default ForDoctor;
