import { Card, Typography, Box, Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Share } from '@material-ui/icons';
import get from 'lodash/get';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { useProfile, useUser } from '../api/usages/auth';
import LayoutDefault from '../components/LayoutDefault';
import ProfileInfoDoctor from '../components/Profile/ProfileInfoDoctor';
import UploadAvatar from '../components/UploadAvatar';
import RouteTypes from '../constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 700,
    },
    avatar: {
      backgroundColor: red[500],
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    headerWrapper: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const ProfileDoctor = () => {
  const profile: { [index: string]: any } | null = useProfile();
  const user = useUser();
  const cx = useStyles();
  const { t } = useTranslation();
  const profileType = get(profile, 'kind', '');
  const profileName = get(profile, 'name.en') || get(profile, 'name', '');

  const doctorLinkFragment = '/doctor/';
  const baseLink = window.location.href.split(RouteTypes.PROFILE)[0];
  const doctorLink = baseLink + doctorLinkFragment + user?.uid;

  return (
    <LayoutDefault>
      {profile !== null && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Card className={cx.root}>
            <Box p={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={cx.headerWrapper}
              >
                <Box display="flex" alignItems="center">
                  <UploadAvatar {...profile} />
                  <Typography style={{ padding: '10px' }} variant="h5">
                    {profileName ? profileName : t('profile.anonymous')}
                  </Typography>
                </Box>
                {user && (
                  <CopyToClipboard text={doctorLink}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '1em' }}
                      color="primary"
                      startIcon={<Share />}
                    >
                      {t('booking.copyYourPageLink')}
                    </Button>
                  </CopyToClipboard>
                )}
              </Box>
              <Box style={{ marginBottom: '8px' }}>
                <Typography variant="body2" color="error">
                  {t('profile.languageInfo')}
                </Typography>
              </Box>
              <ProfileInfoDoctor
                profile={profile}
                user={user}
                profileType={profileType}
              />
            </Box>
          </Card>
        </Box>
      )}
    </LayoutDefault>
  );
};
export default ProfileDoctor;
