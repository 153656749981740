import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  ErrorField,
  SubmitField,
} from 'uniforms-material';

import { proficiencyOptions } from '../../constants/ConfigProfile';
import CustomSelectField from '../CustomSelectField';
import SectionTitle from '../SectionTitle';
import UploadAreaUniformField from '../UploadAreaUniformField';
import AddressAreaList from './AddressAreaList';

interface BasicInfo {
  model: object;
  phoneNumber: string | null;
  email: string | null;
  emailVerified: boolean;
  validateBasicInfo: Function;
  schema: object;
}

const BasicInfoForm = ({
  model,
  phoneNumber,
  email,
  validateBasicInfo,
  schema,
}: BasicInfo) => {
  const { t } = useTranslation();
  return (
    <AutoForm
      model={{
        phoneNumber,
        email,
        ...model,
      }}
      schema={schema}
      onSubmit={validateBasicInfo}
    >
      <SectionTitle title={t('englishOnly.profile_businessInfo')} />
      <AutoField
        name="contactPerson"
        label={t('englishOnly.profile_contactPerson')}
      />
      <ErrorField name="contactPerson" />
      <AutoField
        name="receptionPhone"
        label={t('englishOnly.profile_receptionPhone')}
      />
      <ErrorField
        name="receptionPhone"
        errorMessage={t('englishOnly.profile_receptionPhoneError')}
      />
      <AutoField name="openTimes" label={t('englishOnly.profile_openTimes')} />
      <ErrorField name="openTimes" />
      <AutoField name="name" label={t('profile.fullBusinessName')} />
      <ErrorField name="name" />
      <UploadAreaUniformField
        name="photoURL"
        text={t(`profile.uploadPhoto`)}
        filesLimit={1}
        acceptedFiles={['image/*']}
        margin="16px"
      />
      <ErrorField name="photoURL" />
      <AutoField name="about" label={t('profile.about')} />
      <ErrorField name="about" />
      <AutoField
        name="commercialRegistration"
        placeholder={t('englishOnly.profile_commercialRegistrationPlaceholder')}
        label={t('englishOnly.profile_commercialRegistration')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ErrorField name="commercialRegistration" />
      <UploadAreaUniformField
        name="commercialRegistrationUpload"
        text={t('englishOnly.profile_commercialRegistrationUpload')}
        filesLimit={3}
        acceptedFiles={['image/*', 'application/pdf']}
        margin="16px"
      />
      <ErrorField name="commercialRegistrationUpload" />
      <AutoField
        name="mohLicence"
        label={t('englishOnly.profile_mohLicence')}
      />
      <ErrorField name="mohLicence" />
      <UploadAreaUniformField
        name="mohLicenceUpload"
        text={t('englishOnly.profile_mohLicenceUpload')}
        filesLimit={3}
        acceptedFiles={['image/*', 'application/pdf']}
        margin="16px"
      />
      <ErrorField name="mohLicenceUpload" />
      <AutoField
        name="resellerCode"
        label={t('profile.resellerCode')}
        inputProps={{ maxLength: 4 }}
      />
      <ErrorField
        name="resellerCode"
        errorMessage={t('profile.resellerError')}
      />
      <CustomSelectField
        name="arabicProficiency"
        label={t('englishOnly.profile_arabicProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="englishProficiency"
        label={t('englishOnly.profile_englishProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="frenchProficiency"
        label={t('englishOnly.profile_frenchProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <AutoField name="otherLang" label={t('profile.otherLang')} />
      <ErrorField name="otherLang" />
      <AutoField name="offerDelivery" label={t('labs.offerDelivery')} />
      <ErrorField name="offerDelivery" />
      <AddressAreaList />
      <Box>
        <SubmitField color="primary" label={t('common.next')} />
      </Box>
    </AutoForm>
  );
};

export default BasicInfoForm;
