import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { get, reject } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AutoForm,
  ListField,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import { doProfileUpdate } from '../../api/actions/auth';
import { CreateLabServices } from '../../api/schemas/CreateProfileLabRadio';
import { useRadioCategories, useLabCategories } from '../../api/usages';
import { arrayToObject, objectToArray } from '../../lib/transformHelpers';
import LabListItem from './LabListItem';
import RadioListItem from './RadioListItem';

interface Services {
  model: object;
  role: string;
  editDisabled: boolean;
  onSubmit?: Function;
  backTo?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiListSubheader-root': {
        display: 'none',
      },
      '& .MuiListItem-root': {
        padding: '0',
        flexDirection: 'column',
      },
      '& > .MuiFormControl-root': {
        width: 'auto',
        display: 'block',
        margin: '0 auto',
        textAlign: 'center',
      },
    },
  }),
);

const UpdateServices = ({
  model,
  role,
  editDisabled,
  onSubmit,
  backTo,
}: Services) => {
  const { t } = useTranslation();
  const cx = useStyles();

  const [services, setServices] = useState<any>([]);
  const { radioCategories } = useRadioCategories();
  const { labCategories } = useLabCategories();
  useEffect(() => {
    const categories = role === 'lab' ? labCategories : radioCategories;
    const rawServices = categories.map(({ id }) => ({ categoryId: id }));
    const fullServices = rawServices.concat(get(model, 'services', []));
    setServices(objectToArray(fullServices));
  }, [labCategories, radioCategories, model, role]);

  const handleChange = (data: any) => {
    const newServices = get(data, 'services', []);
    setServices(newServices);
  };

  const handleSelectAll = (data: any) => {
    const { categoryId } = data;
    const newServices = services.map((o: any) =>
      o.categoryId === categoryId ? data : o,
    );
    setServices(newServices);
  };

  const handleSubmit = (data: any) => {
    const initServices = get(data, 'services', []);
    const services = arrayToObject(initServices);
    const fullProfile = { ...model, services };
    doProfileUpdate(fullProfile, role);
  };

  const hideAddIcon =
    role === 'lab'
      ? services.length >= labCategories.length
      : services.length >= radioCategories.length;

  return (
    <AutoForm
      disabled={editDisabled}
      schema={CreateLabServices}
      onSubmit={onSubmit ? () => onSubmit({ services }, true) : handleSubmit}
      className={cx.root}
      model={{ services }}
      onChangeModel={handleChange}
    >
      <ListField
        name="services"
        initialCount={1}
        addIcon={hideAddIcon ? null : <Add />}
      >
        {role === 'lab' ? (
          <LabListItem
            name="$"
            services={services}
            onSelectAll={handleSelectAll}
          />
        ) : (
          <RadioListItem
            name="$"
            services={services}
            onSelectAll={handleSelectAll}
          />
        )}
      </ListField>
      <ErrorField name="services" />
      <Box display="flex" justifyContent="space-between">
        <SubmitField color="primary" label={t('common.submit')} />
        {backTo && (
          <Button color="secondary" component={Link} to={backTo}>
            {t('common.back')}
          </Button>
        )}
      </Box>
    </AutoForm>
  );
};

export default UpdateServices;
