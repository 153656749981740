import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  Language,
  ReferralTypes,
  LabServiceWithCategory,
  RadioServiceWithCategory,
} from '../../api/types';
import { LabReferral } from '../../api/types/LabReferral';
import { RadioReferral } from '../../api/types/RadioReferral';
import { useDocument } from '../../api/usages';

const useStyles = makeStyles(() =>
  createStyles({
    note: {
      color: '#00000060',
      marginTop: 0,
      marginBottom: '1em',
    },
    buttonMarginLeft: {
      marginLeft: '2em',
    },
    buttonMarginRight: {
      marginRight: '2em',
    },
  }),
);

interface ReferralModalItemProps {
  referral: LabReferral | RadioReferral;
  isLab?: boolean;
  bookingId: string;
}

const LabOrRadioReferralModalItem = ({
  referral,
  isLab,
  bookingId,
}: ReferralModalItemProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const isReferralOpen = referral.status === ReferralTypes.OPEN;

  const path = isLab ? 'labs' : 'radios';
  const collectionName = isLab ? 'labServices' : 'radioServices';
  const depsCollectionPath = isLab ? 'labCategories' : 'radioCategories';
  const depsMapFrom = isLab ? 'labCategoryId' : 'radioCategoryId';

  const { result: service } = useDocument<
    LabServiceWithCategory | RadioServiceWithCategory
  >({
    path: `${collectionName}/${referral?.serviceId}`,
    dependencies: [
      {
        collectionPath: depsCollectionPath,
        mapFrom: depsMapFrom,
        mapTo: 'category',
      },
    ],
  });

  const buttonText = isLab
    ? t('callSummary.bookLab')
    : t('callSummary.bookRadio');

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        {`${service?.category?.translations?.[i18n.language as Language]}: `}
        {service?.translations?.[i18n.language as Language]}
      </Grid>
      <Grid item>
        {isReferralOpen ? (
          <Button
            className={
              direction === 'ltr' ? cx.buttonMarginLeft : cx.buttonMarginRight
            }
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `/${path}/${referral.serviceId}`,
                state: { bookingId, referralId: referral.id },
              });
            }}
          >
            {buttonText}
          </Button>
        ) : (
          <Button
            disabled
            className={
              direction === 'ltr' ? cx.buttonMarginLeft : cx.buttonMarginRight
            }
            variant="contained"
          >
            {t('callSummary.booked')}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} className={cx.note}>
        <Typography variant="caption">{referral?.note ?? ''}</Typography>
      </Grid>
    </Grid>
  );
};

export default LabOrRadioReferralModalItem;
