import {
  auth,
  db,
  mergeData,
  deleteData,
  timestampNow,
} from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { RadioReferralRaw } from '../types';

export async function upsertRadioReferralCallback({
  id: referralId,
  bookingId,
  patientId,
  categoryId = null,
  serviceId = null,
  note = '',
  status,
}: RadioReferralRaw) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const labReferralsRef = db.collection('radioReferrals');

  const referralData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    categoryId,
    serviceId,
    note,
    status,
    updatedAt: timestampNow(),
  };

  if (!referralId) {
    return labReferralsRef.add({
      ...referralData,
      status: 'open',
      createdAt: timestampNow(),
    });
  }

  return mergeData(`radioReferrals/${referralId}`, referralData);
}

export async function deleteRadioReferralCallback(referralId: string) {
  return deleteData(`radioReferrals/${referralId}`);
}

export const upsertRadioReferral = createAction({
  type: 'upsertRadioReferral',
  callback: upsertRadioReferralCallback,
});

export const deleteRadioReferral = createAction({
  type: 'deleteRadioReferral',
  callback: deleteRadioReferralCallback,
});
