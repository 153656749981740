import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface BookingInfoItemProps {
  primaryText?: string;
  secondaryText?: string | React.ReactNode;
}

const BookingInfoItem = ({
  primaryText,
  secondaryText,
}: BookingInfoItemProps) => (
  <Grid container>
    <Grid
      item
      lg={secondaryText ? 4 : 12}
      md={secondaryText ? 4 : 12}
      sm={12}
      xs={12}
    >
      <Typography color="textPrimary">
        <b>{primaryText}</b>
      </Typography>
    </Grid>
    {secondaryText && (
      <Grid
        item
        style={{
          wordBreak: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        }}
        lg={8}
        md={8}
        sm={12}
        xs={12}
      >
        <Typography component="span" variant="body2" color="textPrimary">
          {secondaryText}
        </Typography>
      </Grid>
    )}
  </Grid>
);

export default BookingInfoItem;
