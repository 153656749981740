import { createBridge, JSONSchema } from '../../lib/utils';

const schema: JSONSchema = {
  title: 'SendNotification.ts',
  type: 'object',
  properties: {
    toTopic: {
      type: 'string',
    },
    inLanguage: { type: 'string' },
    title: { type: 'string', maxLength: 168 },
    body: { type: 'string', maxLength: 256, minLength: 1 },
  },
  required: ['body'],
};

export default createBridge(schema);
