import moment, { Moment } from 'moment';
import React from 'react';

import WeekScheduleWithData from './WeekScheduleWithData';

type Week = { weekNo: number; startDate: Date; endDate: Date };

const getWeeksForMonth = (month: Date | Moment) => {
  const weeks = [];
  let weekStart = moment(month).startOf('week');
  let weekEnd = moment(month).endOf('week');

  while (weekStart.isSame(month, 'month') || weekEnd.isSame(month, 'month')) {
    weeks.push({
      weekNo: moment(weekStart).week(),
      startDate: moment(weekStart).toDate(),
      endDate: moment(weekEnd).toDate(),
    });
    weekStart = weekStart.add(1, 'weeks');
    weekEnd = weekEnd.add(1, 'weeks');
  }

  return weeks;
};

const Weeks = ({ month, defaultWeek, userId, timeslot }: any) => {
  const weeks = getWeeksForMonth(month);

  return (
    <>
      {weeks.map(({ weekNo, startDate, endDate }: Week) => {
        return (
          <WeekScheduleWithData
            key={userId + weekNo}
            weekNo={weekNo}
            startDate={startDate}
            endDate={endDate}
            defaultWeek={defaultWeek}
            userId={userId}
            timeslot={timeslot}
          />
        );
      })}
    </>
  );
};

export default Weeks;
