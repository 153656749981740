import Fab from '@material-ui/core/Fab';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import React, { useRef } from 'react';
import { LocalVideoTrack, LocalParticipant } from 'twilio-video';

// Types in @types/twilio-video are missing this method which is
// actually present
interface CorrectLocalVideoTrack extends LocalVideoTrack {
  restart: (constraints: MediaStreamConstraints['video']) => Promise<void>;
}

type Props = {
  localParticipant: LocalParticipant;
  className?: string;
};

type FacingMode = 'home' | 'environment';

const ReverseCamerBtn = ({ className, localParticipant }: Props) => {
  const facingModeRef = useRef<FacingMode>('home');

  const handleReverse = () => {
    try {
      const videoTrack = Array.from(localParticipant.videoTracks.values())[0]
        .track as CorrectLocalVideoTrack;

      const newFacingMode =
        facingModeRef.current === 'environment' ? 'home' : 'environment';

      facingModeRef.current = newFacingMode;

      videoTrack.stop();

      videoTrack.restart({
        facingMode: newFacingMode,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Fab className={className} onClick={handleReverse}>
      <SwitchVideoIcon />
    </Fab>
  );
};

export default ReverseCamerBtn;
