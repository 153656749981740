import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Query } from '../api/types';
import { useSpecialties } from '../api/usages/specialties';
import LayoutDefault from '../components/LayoutDefault';
import SetBooking from '../components/SetBooking';
import { popBookingFromSessionData } from '../lib/sessionStorage';
import { i18next } from '../locales';

const PrimaryDoctors = () => {
  const { t } = useTranslation();
  const en = i18next.getFixedT('en');
  const primaryCareName = en('common.primaryCare');

  const query: Query = [
    {
      fieldPath: 'name',
      opStr: '==',
      value: primaryCareName,
    },
  ];

  const { error, loading, specialties } = useSpecialties(query);

  const [booking, setBooking] = useState<any>();

  useEffect(() => {
    setBooking(popBookingFromSessionData());
  }, []);

  if (error) {
    return <LayoutDefault />;
  }

  if (loading) {
    return (
      <LayoutDefault>
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      </LayoutDefault>
    );
  }

  return (
    <LayoutDefault>
      {specialties.length ? (
        booking ? (
          <SetBooking
            specialtyId={specialties[0].id}
            stepNo={booking?.step || 1}
            model={booking?.model}
            doctor={booking?.doctor}
          />
        ) : (
          <SetBooking specialtyId={specialties[0].id} />
        )
      ) : (
        <h1>{t('booking.errorNoPrimaryDoctors')}</h1>
      )}
    </LayoutDefault>
  );
};

export default PrimaryDoctors;
