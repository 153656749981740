import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Patient, Doctor } from '../../api/types';
import { useDocument } from '../../api/usages';
import SetBooking from '../../components/SetBooking';

interface FromLinkUserLoginProps {
  userId: string;
  doctor: Doctor | null;
}

export default function FromLinkUserLogin({
  userId,
  doctor,
}: FromLinkUserLoginProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);

  const { result: patient, loading: loading, error: error } = useDocument<
    Patient
  >(
    {
      path: `patients/${userId}`,
    },
    { skip: !userId },
  );

  useEffect(() => {
    if (!doctor?.verified || !patient?.verified) {
      setLoadingStatus(true);
    } else {
      setLoadingStatus(false);
    }
    return;
  }, [doctor?.verified, patient?.verified]);

  useEffect(() => {
    if (doctor && patient) {
      const verifiedDoctor = doctor.verified;
      const verifiedPatient = patient.verified;
      const testingDoctor = doctor.testing;
      const testingPatient = patient.testing;

      if (!verifiedDoctor || !verifiedPatient) {
        history.push('/');
      }

      if (testingDoctor !== testingPatient) {
        history.push('/');
      }
    }
    return;
  }, [doctor, patient, history]);

  if (error) {
    return null;
  }

  if (loadingStatus || loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (!doctor?.name) {
    return <h1>{t('booking.errorDoctorNotFound')}</h1>;
  }

  return <SetBooking stepNo={2} doctor={doctor} withSpecialties />;
}
