import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleQuery } from '../../api/types';
import { useUserId, useBookings } from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import { multipleDateRangeOverlaps } from '../../lib/validationHelpers';
import CancelAvailability from './CancelAvailability';
import DayRange from './DayRange';

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'space-between',
    },
    errorMsg: {
      'text-align': 'center',
      color: '#f44336',
    },
  }),
);

type ScheduleDayProp = {
  isTemplate?: boolean;
  timeslot: number;
  day: any;
  asRepeated: boolean;
  dateKey?: string;
  ranges?: any[];
  checked: boolean;
  handleDayDataChange: Function;
  fromIndex?: boolean;
};

const EMPTY_RANGES = [{}];

const ScheduleDay = ({
  isTemplate,
  timeslot,
  day,
  asRepeated,
  handleDayDataChange,
  ranges,
  checked: initChecked,
  dateKey,
  fromIndex,
}: ScheduleDayProp) => {
  const { t } = useTranslation();
  const cx = useStyles();
  ranges = ranges || EMPTY_RANGES;
  const lastRangeEndDate = get(ranges, `${[ranges.length - 1]}.endDate`, day);
  const initHideAddButton = moment(lastRangeEndDate).hours() === 0;
  const [checked, setChecked] = useState(initChecked);
  const [hideAddButton, setHideAddButton] = useState(initHideAddButton);
  const [isDialogOpened, setDialogState] = React.useState(false);
  const [validationError, setValidationError] = useState(false);
  const dayName = moment(day).format('dddd');
  const initStartDate = moment(day).set({ hour: 8, minute: 0, second: 0 });
  const initEndDate = moment(day).set({ hour: 12, minute: 0, second: 0 });
  const userId = useUserId();
  const midnightTomorrow = moment(day).startOf('day').add(1, 'days');

  const queryUpcoming: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'startDate',
      opStr: '>=',
      value: Timestamp.fromDate(moment(day).toDate()),
    },
    {
      fieldPath: 'startDate',
      opStr: '<=',
      value: Timestamp.fromDate(midnightTomorrow.toDate()),
    },
  ];

  const queryUpcomingDefault: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'startDate',
      opStr: '>=',
      value: Timestamp.fromDate(moment(day).toDate()),
    },
  ];

  const query = isTemplate ? queryUpcomingDefault : queryUpcoming;
  const isPast = moment(day).isSameOrBefore(moment());

  const { bookings } = useBookings(query, {
    skip: !userId,
  });

  const defaultDayBooking = bookings.filter(
    b => moment(day).day() === moment(b.startDate.toDate()).day(),
  );

  const handleCancelation = () => {
    setChecked(false);
    setHideAddButton(true);
    setValidationError(false);
    handleDayDataChange(day, { checked: false, ranges: EMPTY_RANGES });
  };

  const toggleDayOff = () => {
    const newChcecked = !checked;
    if (newChcecked) {
      setChecked(newChcecked);
      setHideAddButton(false);
      const newRanges = [
        {
          startDate: moment(initStartDate.toDate()).format(
            'YYYY-MM-DDTHH:mm:ss',
          ),
          endDate: moment(initEndDate.toDate()).format('YYYY-MM-DDTHH:mm:ss'),
        },
      ];
      handleDayDataChange(day, { checked: newChcecked, ranges: newRanges });
    } else if (
      !newChcecked &&
      bookings.length > 0 &&
      defaultDayBooking.length > 0
    ) {
      setDialogState(true);
    } else {
      handleCancelation();
    }
  };
  const addRange = () => {
    setHideAddButton(false);
    let newRange = {};
    let endDate;
    setValidationError(false);

    if (ranges) {
      const index = ranges.length - 1;
      const maxTime = moment(ranges[index].endDate)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
      const midnight = moment(ranges[index].endDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss');
      const endHour = moment(ranges[index].endDate)
        .add(4, 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');
      endDate = moment(ranges[index].endDate)
        .add(4, 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');
      if (moment(ranges[index].endDate).isSame(moment(midnight))) {
        setHideAddButton(true);
        return;
      }
      if (moment(endHour).isAfter(moment(maxTime))) {
        endDate = moment(day)
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss');
        setHideAddButton(true);
      }

      newRange = {
        startDate: moment(ranges[index].endDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate,
      };
    }
    handleDayDataChange(day, { checked: true, ranges: [...ranges, newRange] });
  };

  const deleteRange = (index: number) => {
    if (bookings.length > 0 && defaultDayBooking.length > 0) {
      setDialogState(true);
    } else {
      setHideAddButton(false);
      setValidationError(false);
      const newRanges = [...ranges];
      newRanges.splice(index, 1);
      handleDayDataChange(day, { checked, ranges: newRanges });
    }
  };

  const changeRange = (range: any, i: number) => {
    const newRanges = [...ranges];
    newRanges[i] = range;
    const isError = multipleDateRangeOverlaps(newRanges);
    if (isError) {
      setValidationError(true);
      return;
    }
    if (bookings.length > 0 && defaultDayBooking.length > 0) {
      setValidationError(false);
      setDialogState(true);
    } else {
      setValidationError(false);
      handleDayDataChange(day, { checked, ranges: newRanges });
    }
  };

  const handleDialog = (agree: boolean) => {
    if (agree) {
      setDialogState(false);
      handleCancelation();
    } else {
      setDialogState(false);
    }
  };

  return (
    <>
      <Box className={cx.row}>
        <Box>
          <Typography variant="body2">
            <strong>{dayName}</strong>
          </Typography>
        </Box>
        <Box>
          <Switch
            checked={checked}
            onChange={toggleDayOff}
            color="primary"
            name="checked"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={asRepeated || (!asRepeated && !fromIndex && isPast)}
          />
        </Box>
      </Box>
      {!checked && (
        <Typography variant="body2" color="textSecondary" align="center">
          {t('schedule.freeDay')}
        </Typography>
      )}
      {checked && (
        <Box>
          {ranges.map((range: any, index: number) => {
            range = range || {};
            const key = dateKey + range.startDate + range.endDate + index;
            return (
              <DayRange
                key={key}
                index={index}
                isTemplate={isTemplate}
                initStartDate={initStartDate}
                initEndDate={initEndDate}
                asRepeated={asRepeated}
                timeslot={timeslot}
                range={range}
                changeRange={changeRange}
                deleteRange={deleteRange}
                error={validationError}
                isPast={isPast}
                fromIndex={fromIndex}
              />
            );
          })}
          {validationError && (
            <Box className={cx.errorMsg}>{t('schedule.errorMsg')}</Box>
          )}
          {checked && !hideAddButton && !asRepeated && (
            <Box display="flex" justifyContent="center">
              <IconButton
                aria-label="add"
                onClick={addRange}
                disabled={hideAddButton}
              >
                <Add fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      <CancelAvailability
        open={isDialogOpened}
        handleCloseDialog={handleDialog}
        bookings={bookings}
        isTemplate={isTemplate}
      />
    </>
  );
};

export default ScheduleDay;
