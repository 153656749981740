import React from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleQuery, Language } from '../../../../../api/types';
import {
  useCollection,
  UseCollectionOptions,
  UseCollectionParams,
} from '../../../../../api/usages/collection';
import CardText from '../../CardText';

interface ReferralToDoctorProps {
  bookingId: string;
  query?: SimpleQuery;
}

const ReferralToDoctor = ({ bookingId, query }: ReferralToDoctorProps) => {
  const { t, i18n } = useTranslation();

  const params: UseCollectionParams = {
    collectionPath: 'doctorReferrals',
    query,
    dependencies: [
      {
        collectionPath: 'specialties',
        mapFrom: 'specialtyId',
        mapTo: 'specialties',
      },
    ],
  };

  const options: UseCollectionOptions = {
    skip: !bookingId,
  };

  const { results: referrals, error, loading } = useCollection<any>(
    params,
    options,
  );

  const createReferrals = (referrals: any) => {
    const refs: any = [];
    referrals.map((ref: any) => {
      return refs.push([
        {
          label: t('profile.medSpeciality'),
          text: ref.specialties.translations?.[i18n.language as Language],
        },
        {
          label: t('profile.medTitle'),
          text: t(`profile.title_${ref.medTitle}`),
        },
        { label: t('healthRecordsVisit.note'), text: ref.note },
      ]);
    });
    return refs;
  };

  const referralsResult = createReferrals(referrals);

  if (loading || error) {
    return null;
  }

  return referralsResult.map((ref: any) => (
    <CardText key={ref.note} records={ref} />
  ));
};

export default ReferralToDoctor;
