import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef } from 'react';

import { useCurrentGeoLocation } from '../api/usages/geo';
import { GeoPoint } from '../lib/firebase';

type GeoPickerProps = {
  value?: GeoPoint;
  zoom?: number;
  onChange?(newPoint: GeoPoint): void;
  onZoom?(zoom: number): void;
  readOnly?: boolean;
};

const defaultPointForRead = new GeoPoint(
  30.045241950590007,
  31.229787606227585,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      width: '100%',
      height: '25vw',
      [theme.breakpoints.down('sm')]: {
        height: '35vw',
      },
      [theme.breakpoints.down('xs')]: {
        height: '50vh',
      },
    },
  }),
);

const GeoPicker = ({
  value,
  readOnly = false,
  zoom = 15,
  onChange,
  onZoom,
}: GeoPickerProps) => {
  const cx = useStyles();
  const googleMaps = google.maps;
  const nodeRef = useRef(null);
  const map = useRef<google.maps.Map>(null);
  const marker = useRef<google.maps.Marker>(null);
  const defaultPoint = useCurrentGeoLocation();

  if (!value || value.longitude === undefined || value.latitude === undefined) {
    value = readOnly ? defaultPointForRead : defaultPoint;
  }

  React.useEffect(() => {
    function handlePointChange() {
      if (readOnly) {
        return;
      }
      if (onChange && marker.current) {
        const currentLocation = marker.current.getPosition();
        const newPoint =
          currentLocation &&
          new GeoPoint(currentLocation.lat(), currentLocation.lng());
        newPoint && onChange(newPoint);
      }
    }

    // @ts-ignore
    const point = { lat: value.latitude, lng: value.longitude };

    if (!map.current) {
      // @ts-ignore
      map.current = new googleMaps.Map(nodeRef.current, {
        center: point,
        zoom,
      });
    }

    if (!marker.current) {
      // @ts-ignore
      marker.current = new googleMaps.Marker({
        position: point,
        map: map.current || undefined,
        draggable: !readOnly,
      });
      googleMaps.event.addListener(
        marker.current,
        'dragend',
        handlePointChange,
      );
    } else {
      // @ts-ignore
      marker.current.setPosition(point);
      marker.current.setDraggable(!readOnly);
      googleMaps.event.addListener(
        marker.current,
        'dragend',
        handlePointChange,
      );
    }

    // @ts-ignore
    const clickHandle = map.current.addListener('click', (event: any) => {
      if (readOnly || !marker.current) {
        return;
      }
      const clickedLocation = event.latLng;
      marker.current.setPosition(clickedLocation);
      handlePointChange();
    });
    // @ts-ignore
    const handleZoom = map.current.addListener(
      'zoom_changed',
      () => onZoom && map.current && onZoom(map.current.getZoom()),
    );

    return () => {
      if (clickHandle) {
        clickHandle.remove();
      }
      if (handleZoom) {
        handleZoom.remove();
      }
      if (marker.current) {
        googleMaps.event.clearListeners(marker.current, 'dragend');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, value, onZoom, onChange]);

  React.useEffect(() => {
    if (marker.current && map.current) {
      // @ts-ignore
      const point = { lat: value.latitude, lng: value.longitude };
      map.current.setCenter(point);
      marker.current.setPosition(point);
    }
  }, [value]);

  React.useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  return <div className={cx.mapContainer} ref={nodeRef} />;
};

export default GeoPicker;
