import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSpecialties } from '../../api/usages';
import SpecialtyAutocomplete from '../SpecialtyAutocomplete';

interface SpecialtiesProps {
  choosenSpecialtyId?: string;
  setSpecialtyId: (specialtyId: string) => void;
  primaryCareSpecialtyId: string;
}

const Specialties = ({
  choosenSpecialtyId,
  setSpecialtyId,
  primaryCareSpecialtyId,
}: SpecialtiesProps) => {
  const { t } = useTranslation();

  const { specialties, error, loading } = useSpecialties();

  const filteredSpecialties = specialties.filter(
    spec => spec.id !== primaryCareSpecialtyId,
  );

  if (error || loading) {
    return null;
  }

  return (
    <div>
      <h1>{t('booking.selectSpeciality')}</h1>
      <SpecialtyAutocomplete
        choosenSpecialtyId={choosenSpecialtyId}
        specialties={filteredSpecialties}
        setSpecialtyId={setSpecialtyId}
        textFieldProps={{ variant: 'outlined' }}
      />
    </div>
  );
};

export default Specialties;
