import {
  auth,
  db,
  mergeData,
  deleteData,
  timestampNow,
} from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { LabReferralRaw } from '../types';

export async function upsertLabReferralCallback({
  id: referralId,
  bookingId,
  patientId,
  categoryId = null,
  serviceId = null,
  note = '',
  status,
}: LabReferralRaw) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const labReferralsRef = db.collection('labReferrals');

  const referralData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    categoryId,
    serviceId,
    note,
    status,
    updatedAt: timestampNow(),
  };

  if (!referralId) {
    return labReferralsRef.add({
      ...referralData,
      status: 'open',
      createdAt: timestampNow(),
    });
  }

  return mergeData(`labReferrals/${referralId}`, referralData);
}

export async function deleteLabReferralCallback(referralId: string) {
  return deleteData(`labReferrals/${referralId}`);
}

export const upsertLabReferral = createAction({
  type: 'upsertLabReferral',
  callback: upsertLabReferralCallback,
});

export const deleteLabReferral = createAction({
  type: 'deleteLabReferral',
  callback: deleteLabReferralCallback,
});
