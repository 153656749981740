import { Button, Grid, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  FullQuery,
  Prescription,
  SimpleQuery,
  UserRoleTypes,
} from '../../../api/types';
import { useUserId } from '../../../api/usages';
import {
  useCollection,
  UseCollectionOptions,
  UseCollectionParams,
} from '../../../api/usages/collection';
import { useContextRole } from '../../../api/usages/sessionData';
import PrescriptionItem from './PrescriptionItem';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  link: {
    textDecoration: 'none',
  },
});

interface PrescriptionListProps {
  patientId?: string | undefined;
}

const PrescriptionList = ({ patientId }: PrescriptionListProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const userId = useUserId();
  const role = useContextRole();
  const isDoctor = role === UserRoleTypes.DOCTOR ? '_blank' : '';

  const [limit, setLimit] = useState(20);
  const handleLoadMore = () => {
    setLimit(limit => limit + 20);
  };

  const query: SimpleQuery = [
    { fieldPath: 'patientId', opStr: '==', value: patientId || userId },
  ];

  const fullQuery: FullQuery = {
    where: query,
    orderBy: ['createdAt', 'desc'],
    limit,
  };

  const params: UseCollectionParams = {
    collectionPath: 'prescriptions',
    query: fullQuery,
  };

  const options: UseCollectionOptions = {
    skip: !patientId || !userId,
  };

  const { results: prescriptions, error, loading } = useCollection<
    Prescription
  >(params, options);

  const canLoadMore = prescriptions.length === limit;

  if (error || loading) {
    return null;
  }

  if (prescriptions.length === 0) {
    return <h1>{t('healthRecords.noPrescriptions')}</h1>;
  }

  return (
    <List className={cx.root}>
      {prescriptions.map((prescription: any) => (
        <Link
          className={cx.link}
          to={'/health_records_prescription/' + prescription.bookingId}
          key={prescription.id}
          target={isDoctor}
        >
          <PrescriptionItem prescription={prescription} />
        </Link>
      ))}
      {canLoadMore && (
        <Grid container justify="center" style={{ marginTop: '15px' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
            >
              {t('booking.loadMore')}
            </Button>
          </Grid>
        </Grid>
      )}
    </List>
  );
};

export default PrescriptionList;
