import {
  Card,
  Box,
  Divider,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Title from '../Title';
import { EmailModeForm } from './EmailModeForm';
import { MobileModeForm } from './MobileModeForm';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '40px auto 0',
    },
    wrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },
    header: {
      marginBottom: `32px`,
    },
    otherMethodsLabel: {
      display: 'block',
      marginTop: '20px',
    },
  }),
);

enum FormModes {
  EMAIL,
  MOBILE,
  ID,
}

const ForgotPassword = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  const { profileType, onAuthorized } = useParams();
  const [mode, setMode] = useState(FormModes.EMAIL);

  const setMobileMode = useCallback(() => {
    setMode(FormModes.MOBILE);
  }, [setMode]);
  const setIDMode = useCallback(() => {
    setMode(FormModes.ID);
  }, [setMode]);
  const setEmailMode = useCallback(() => {
    setMode(FormModes.EMAIL);
  }, [setMode]);

  return (
    <Card className={cx.root}>
      <Box p={3} className={cx.wrapper}>
        <Box className={cx.header}>
          <Title title={t('auth.forgotPassword')} />
        </Box>
        {FormModes.EMAIL === mode && (
          <EmailModeForm
            profileType={profileType}
            onAuthorized={onAuthorized}
          />
        )}
        {FormModes.MOBILE === mode && (
          <MobileModeForm
            profileType={profileType}
            onAuthorized={onAuthorized}
          />
        )}
        <Typography
          color="textSecondary"
          variant="caption"
          className={cx.otherMethodsLabel}
        >
          {t('auth.otherOptions')}
        </Typography>
        <Divider flexItem />
        <List component="nav" aria-label="main folders">
          {mode !== FormModes.EMAIL && (
            <ListItem button onClick={setEmailMode}>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText>{t('auth.emailVerification')}</ListItemText>
            </ListItem>
          )}
          {mode !== FormModes.MOBILE && (
            <ListItem button onClick={setMobileMode}>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText>{t('auth.mobileVerification')}</ListItemText>
            </ListItem>
          )}
          {mode !== FormModes.ID && (
            <ListItem button onClick={setIDMode} disabled title="coming soon">
              <ListItemIcon>
                <PermIdentityIcon />
              </ListItemIcon>
              <ListItemText>
                {t('auth.verificationWithPassportOrID')}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
    </Card>
  );
};

export default ForgotPassword;
