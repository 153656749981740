import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  ErrorField,
  TextField,
  LongTextField,
  SubmitField,
} from 'uniforms-material';

import { sendNotificationAction } from '../../api/actions/notifications';
import schema from '../../api/schemas/AdminSendNotification';
import { UserRoleTypes } from '../../api/types';
import { useLanguage } from '../../api/usages';
import { availableLangs } from '../../locales';
import CustomSelectField from '../CustomSelectField';

const topics = ['ALL', ...Object.keys(UserRoleTypes)];

const SendNotification = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  if (!language) {
    return null;
  }
  return (
    <AutoForm
      schema={schema}
      onSubmit={(model: any) => {
        sendNotificationAction({ toTopic: 'ALL', ...model });
      }}
    >
      <Box>
        <CustomSelectField
          label={t('admin.languageGroup')}
          name="inLanguage"
          defaultValue={language}
          options={availableLangs.map(({ code, labelShort }) => ({
            label: labelShort,
            value: code,
          }))}
          fullWidth
        />
        <ErrorField name="inLanguage" />
      </Box>
      <Box>
        <CustomSelectField
          label={t('admin.toTopic')}
          name="toTopic"
          defaultValue="ALL"
          options={topics.map(item => ({
            value: item,
            label: t(`admin.recipientsTopics_${item.toLowerCase()}`),
          }))}
          fullWidth
        />
        <ErrorField name="toTopic" />
      </Box>

      <TextField name="title" />
      <ErrorField name="title" />
      <LongTextField name="body" />
      <ErrorField name="body" />
      <SubmitField color="primary" />
    </AutoForm>
  );
};

export default SendNotification;
