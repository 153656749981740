import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { cloneDeep, set } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  ErrorField,
  SubmitField,
} from 'uniforms-material';

import { CashOutParams, cashOutPayment } from '../../api/actions/payments';
import { payoutFormSchema } from '../../api/schemas/Payments';
import { Currency, PaymentWallet } from '../../api/types/Payments';
import { getWalletAmount } from '../../lib/utils';

type Props = {
  open: boolean;
  onClose: () => void;
  currency?: Currency | null;
  wallet?: PaymentWallet | null;
};

type Model = Partial<CashOutParams>;

const PayoutDialog = ({ open, onClose, currency, wallet }: Props) => {
  const { t } = useTranslation();

  const walletAmount = getWalletAmount(wallet, currency);
  const [model, setModel] = useState<any>({
    // from cents to main currency
    amount: Math.floor(walletAmount / 100),
    // reuse billing info if exists
    msisdn: wallet?.billingInfo?.phoneNumber?.slice(-11),
    firstName: wallet?.billingInfo?.firstName,
    lastName: wallet?.billingInfo?.lastName,
    email: wallet?.billingInfo?.email,
  });

  const handleFieldChange = (field: string, value: any) => {
    const newModel = set(cloneDeep(model), field, value);
    setModel(newModel);
    return newModel;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AutoForm
        model={model}
        schema={payoutFormSchema}
        onChange={handleFieldChange}
        onSubmit={(model: Model) => {
          cashOutPayment({
            ...model,
            currency,
            // Convert from main currency back to cents
            amount: (model.amount || 0) * 100,
          });
          onClose();
        }}
      >
        <DialogContent>
          <AutoField
            key="issuer"
            name="issuer"
            label={t('payments.payoutIssuer')}
          />
          <AutoField
            key="msisdn"
            name="msisdn"
            label={t('billingInfo.phoneNumber')}
          />
          <ErrorField
            name="msisdn"
            errorMessage={t('billingInfo.phoneNumberError')}
          />
          <AutoField
            key="firstName"
            name="firstName"
            label={t('billingInfo.firstName')}
          />
          <AutoField
            key="lastName"
            name="lastName"
            label={t('billingInfo.lastName')}
          />
          <AutoField key="email" name="email" label={t('billingInfo.email')} />
          <AutoField key="amount" name="amount" label={t('payments.amount')} />
        </DialogContent>
        <DialogActions>
          <SubmitField color="primary" label={t('payments.payout')} />
          <Button onClick={onClose}>{t('common.close')}</Button>
        </DialogActions>
      </AutoForm>
    </Dialog>
  );
};

export default PayoutDialog;
