import { createBridge, JSONSchema } from '../../lib/utils';

const schema: JSONSchema = {
  title: 'Guest',
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'password'],
};

export default createBridge(schema);
