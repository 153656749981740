import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Patient } from '../../../api/types';
import { Booking } from '../../../api/types/Booking';
import { useDocument } from '../../../api/usages';
import HealthRecordsForm from '../../HealthRecords/HealthRecordsForm';

type BookingWithPatient = Omit<Booking, 'patientId'> & {
  patient: Patient;
};

const CallHealthRecords = () => {
  const { bookingId } = useParams();
  const { result: booking } = useDocument<BookingWithPatient>({
    path: `bookings/${bookingId}`,
    dependencies: [
      {
        collectionPath: 'patients',
        mapFrom: 'patientId',
        mapTo: 'patient',
      },
    ],
  });
  const patientId = booking?.patient?.id;

  if (!booking) {
    return <CircularProgress color="primary" />;
  }

  return (
    <HealthRecordsForm
      profile={booking.patient}
      patientId={patientId || ''}
      readonly
    />
  );
};

export default CallHealthRecords;
