import { Grid } from '@material-ui/core';
import React from 'react';

import { aboutCounts, fillArray } from '../../constants/ConfigHelp';
import { Point } from './common/Helpers';

const About = () => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      {Object.entries(aboutCounts).map(([key, value]) => (
        <Point
          key={key}
          section="about"
          title={`title_${key}`}
          texts={fillArray(value, key)}
        />
      ))}
    </Grid>
  );
};

export default About;
