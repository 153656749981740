import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListField, ErrorField } from 'uniforms-material';

import SectionTitle from '../SectionTitle';
import CertificatesListItemField, {
  Props as CertificatesListItemFieldProps,
} from './CertificatesListItemField';

const useStyles = makeStyles<Theme, { color: string; hide: string }>({
  root: {
    '& .MuiListSubheader-root': {
      display: 'none',
    },
    '& .MuiListSubheader-gutters': {
      padding: 0,
    },
    '& .MuiListItem-gutters': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > .MuiFormControl-root': {
      width: 'auto',
      margin: '0 auto',
      textAlign: 'center',
      display: 'block',
    },
    '& .MuiButtonBase-root': {
      display: props => props.hide,
    },
  },
  label: {
    marginBottom: '5px',
  },
});

type Props = Omit<CertificatesListItemFieldProps, 'name'>;

const CertificatesListField = (props: Props) => {
  const { t } = useTranslation();
  const styleProps = {
    hide: props.readOnly ? 'none' : 'block',
    color: props.readOnly ? 'rgba(0, 0, 0, 0.38)' : 'inherit',
  };

  const cx = useStyles(styleProps);

  return (
    <Box className={cx.root}>
      <SectionTitle title={t('englishOnly.profile_highCertificates')} />
      <Typography component="p" className={cx.label} color="error">
        {t('englishOnly.profile_highCertificatesHelper')}
      </Typography>
      <ListField name="certificates" initialCount={1} addIcon={<Add />}>
        <CertificatesListItemField name="$" {...props} />
      </ListField>
      <ErrorField name="certificates" />
    </Box>
  );
};

export default CertificatesListField;
