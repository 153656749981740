import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { updateBooking } from '../../api/actions/bookings';
import { upsertDiagnosis } from '../../api/actions/diagnoses';
import { upsertObservation } from '../../api/actions/observations';
import { Query } from '../../api/types';
import { Booking } from '../../api/types/Booking';
import { useObservations, useDiagnoses, useUserId } from '../../api/usages';

type BasicNotesProps = {
  booking: Booking;
  disabled: boolean;
};

const BasicNotes = ({ booking, disabled }: BasicNotesProps) => {
  const userId = useUserId();

  const bookingIdQuery: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: booking.id,
    },
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  const { observations } = useObservations(bookingIdQuery);
  const { diagnoses } = useDiagnoses(bookingIdQuery);

  const observation = observations?.[0];
  const diagnosis = diagnoses?.[0];
  const complaint = booking?.complaint ?? '';

  const [complaintText, setComplaint] = useState(complaint);
  const [observationText, setObservation] = useState(observation?.content);
  const [diagnosisText, setDiagnosis] = useState(diagnosis?.content);

  const complaintRef = useRef<{ text: string }>();
  complaintRef.current = {
    text: complaintText,
  };

  const observationRef = useRef<{ text: string; id: string }>();
  observationRef.current = {
    text: observationText,
    id: observation?.id,
  };
  const diagnosisRef = useRef<{ text: string; id: string }>();
  diagnosisRef.current = {
    text: diagnosisText,
    id: diagnosis?.id,
  };

  useEffect(() => {
    setComplaint(complaint);
  }, [complaint]);

  useEffect(() => {
    setObservation(observation?.content);
  }, [observation]);

  useEffect(() => {
    setDiagnosis(diagnosis?.content);
  }, [diagnosis]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateObservation = useCallback(
    debounce(() => {
      const { id, text } = observationRef.current ?? {};
      upsertObservation({
        id,
        bookingId: booking.id,
        patientId: booking.patientId,
        content: text,
      });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateComplaint = useCallback(
    debounce(() => {
      const { text } = complaintRef.current ?? {};
      updateBooking(booking.id, { complaint: text });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDiagnosis = useCallback(
    debounce(() => {
      const { id, text } = diagnosisRef.current ?? {};
      upsertDiagnosis({
        id,
        bookingId: booking.id,
        patientId: booking.patientId,
        content: text,
      });
    }, 500),
    [],
  );

  const handleComplaintChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const complaint = event.target.value;

    if (complaint) {
      setComplaint(complaint);
      updateComplaint();
    }
  };

  const handleObservationChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const observation = event.target.value;

    if (observation) {
      setObservation(observation);
      updateObservation();
    }
  };

  const handleDiagnosisChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const diagnosis = event.target.value;

    if (diagnosis) {
      setDiagnosis(diagnosis);
      updateDiagnosis();
    }
  };

  return (
    <Box display="grid" gridGap="50px" width="100%">
      <Box width="100%">
        <Typography>
          <b>History and Symptoms: *</b>
        </Typography>
        <TextField
          id="standard-multiline-flexible"
          fullWidth
          multiline
          value={complaintText}
          onChange={handleComplaintChange}
          InputProps={{
            readOnly: disabled,
          }}
        />
      </Box>

      <Box>
        <Typography>
          <b>Findings: *</b>
        </Typography>
        <TextField
          id="standard-multiline-flexible"
          fullWidth
          multiline
          value={observationText}
          onChange={handleObservationChange}
          InputProps={{
            readOnly: disabled,
          }}
        />
      </Box>

      <Box>
        <Typography>
          <b>Diagnosis & Treatment: *</b>
        </Typography>
        <TextField
          id="standard-multiline-flexible"
          fullWidth
          multiline
          value={diagnosisText}
          onChange={handleDiagnosisChange}
          InputProps={{
            readOnly: disabled,
          }}
        />
      </Box>
    </Box>
  );
};
export default BasicNotes;
