import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import { useParams } from 'react-router-dom';
import { connectField } from 'uniforms';

import { Entry, getLoggedInStorageRef } from '../lib/firebase';
import UploadArea from './UploadArea';

const UploadAreaUniformField = connectField(
  ({
    onChange,
    value,
    disabled,
    name,
    maxItems,
    filesLimit,
    margin,
    className,
    ...props
  }: any) => {
    //  For admins edit - edited profile will be owner of pdfs/images
    const params = useParams();
    const editedPerson: unknown = Object.values(params)[0];
    // For lab/radio orders
    const patientId: string | undefined = props.patientId;
    const editorId: string | undefined = props.editorId;

    let initEntries: Entry[] = [];
    if (typeof value === 'string') {
      initEntries = [{ url: value }];
    } else if (Array.isArray(value)) {
      initEntries = value.map(url => ({ url }));
    }
    props.filesLimit =
      props.fieldType === Array ? maxItems || filesLimit || 3 : 1;

    if (props.filesLimit === 1) {
      props.onUploaded = (url: string | string[]) => {
        onChange(url, name);
      };
      props.alwaysReplace = true;
    } else {
      props.onUpdatedAll = (args: Array<{ url: string }>) => {
        const urls = args.map(i => i.url);

        onChange(urls, name);
      };
    }

    return (
      <FormControl
        style={{ display: 'block', width: '100%', marginBottom: margin }}
        disabled={disabled}
        className={className}
      >
        {props.text && (
          <Typography
            variant={'subtitle1'}
            color={'textSecondary'}
            component="span"
          >
            {props.text}
          </Typography>
        )}
        <UploadArea
          disabled={disabled}
          storageRef={getLoggedInStorageRef(
            name,
            editedPerson,
            patientId,
            editorId,
          )}
          initEntries={initEntries}
          {...props}
        />
      </FormControl>
    );
  },
);

export default UploadAreaUniformField;
