import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../api/actions/uiControls';
import { Patient } from '../api/types';
import ManagementButtons from '../components/Admin/ManagementButtons';
import AvatarCustom from '../components/Avatar';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { getPatients, getPatientsCount } from '../lib/admin';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '700px',
    margin: '40px auto 0',
  },
  avatar: {
    marginRight: 8,
  },
  paginationContainer: {
    display: 'grid',
    placeContent: 'center',
  },
});

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const PATIENTS_PER_PAGE = 50;

const AdminManagePatients = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [patients, setPatients] = useState<Patient[]>([]);

  useEffect(() => {
    getPatientsCount({
      orderBy: { fieldPath: 'name', directionStr: 'asc' },
    }).then(({ success, totalCount }) => {
      if (success) {
        totalCount && setTotalCount(totalCount);
      } else {
        showErrorToast({ message: `Couldn't fetch patients` });
      }
    });
  }, []);

  useEffect(() => {
    totalCount && fetchPatients(page);
  }, [page, totalCount]);

  async function fetchPatients(page: number) {
    const { success, patients } = await getPatients({
      limit: PATIENTS_PER_PAGE,
      offset: PATIENTS_PER_PAGE * (page - 1),
      orderBy: { fieldPath: 'name', directionStr: 'asc' },
    });

    if (!success) {
      showErrorToast({ message: `Couldn't fetch patients` });
    } else {
      patients && setPatients(patients);
    }
  }

  const numberOfPages = Math.ceil(totalCount / PATIENTS_PER_PAGE);

  return (
    <LayoutDefault>
      <Title title={t('admin.managePatients')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <List>
            {patients.map(patient => (
              <ListItem key={patient.id}>
                <ListItemAvatar className={cx.avatar}>
                  <AvatarCustom
                    photoURL={patient.photoURL}
                    name={patient.name}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={patient.name}
                  secondary={patient.email}
                />
                <ManagementButtons
                  collection="patients"
                  id={patient.id}
                  verified={!!patient.verified}
                  testing={!!patient.testing}
                  onSuccess={() => fetchPatients(page)}
                />
              </ListItem>
            ))}
          </List>
          {!!patients.length && (
            <Box display="flex">
              <Pagination
                className={cx.paginationContainer}
                count={numberOfPages}
                onChange={(_, page) => setPage(page)}
                page={page}
              />
              <Autocomplete
                options={range(1, numberOfPages + 1)}
                getOptionLabel={option => option.toString()}
                value={page}
                onChange={(event: any, newValue: number | null) => {
                  newValue && setPage(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('pagination.goTo')}
                    variant="outlined"
                  />
                )}
                noOptionsText={t('pagination.noOptions')}
              />
            </Box>
          )}
        </Container>
      </Card>
    </LayoutDefault>
  );
};

export default AdminManagePatients;
