import { Box, Card, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createSchedule, removeSchedule } from '../../api/actions/schedule';
import { momentToFormat } from '../../lib/utils';
import { i18next } from '../../locales';
import DaySchedule from './DaySchedule';

export const getDays = (weekStart: Date) => {
  const days = [];
  if (!weekStart) {
    weekStart = moment().startOf('week').toDate();
  }
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate());
  }
  return days;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&$selected': {
        background: 'transparent',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        '&:hover': {
          background: 'transparent',
          color: theme.palette.text.primary,
        },
      },
      '&:hover': {
        background: 'transparent',
        color: theme.palette.text.secondary,
      },
      border: 'none',
      textTransform: 'none',
      width: 'calc(50%)',
      paddingTop: 0,
    },
    toggleBtnGrp: {
      display: 'flex',
      justifyContent: 'center',
    },
    selected: {},
    divider: {
      padding: 0,
      margin: '2px',
    },
    weekShift: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    weekShiftContent: {
      padding: '24px',
    },
    heading: {
      'text-align': 'center',
      'margin-bottom': '24px',
    },
    submenu: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      minHeight: '50px',
    },
    clickableText: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

type WeekScheduleProps = {
  isTemplate?: boolean;
  week: any;
  asDefaultInfo?: boolean;
  defaultWeek?: any;
  timeslot: number;
  fromIndex?: boolean;
};
const WeekSchedule = ({
  isTemplate,
  week,
  asDefaultInfo,
  defaultWeek,
  timeslot,
  fromIndex,
}: WeekScheduleProps) => {
  const lang = i18next.language;
  const cx = useStyles();
  const { t } = useTranslation();
  // INITIAL VALUES SETUP
  let startDate = get(week, 'startDate', moment().startOf('week').toDate());
  let endDate = get(week, 'endDate', moment().endOf('week').toDate());

  if (startDate.toDate) {
    startDate = moment(startDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
  }
  if (endDate.toDate) {
    endDate = moment(endDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
  }
  const weekNo = get(week, 'weekNo', moment().week());
  const initDaysData = get(week, 'daysData', {});

  // STATE
  const [daysData, setDaysData] = useState<any>(initDaysData);
  const [asRepeatedInfo, setAsRepeatedInfo] = useState<boolean>(
    asDefaultInfo || false,
  );

  const days = getDays(startDate);

  const weekStartDM = momentToFormat(lang, startDate, 'DD.MM');
  const weekEndDM = momentToFormat(lang, endDate, 'DD.MM');

  const handleDayDataChange = (day: Date, data: any) => {
    const newDaysData = { ...daysData, [moment(day).format('dd')]: data };
    setDaysData(newDaysData);
    createSchedule(
      {
        isTemplate,
        weekNo,
        startDate,
        endDate,
        daysData: newDaysData,
      },
      timeslot,
    );
  };

  const backToDefaultHandle = () => {
    setDaysData(get(defaultWeek, 'daysData', {}));
    removeSchedule({ startDate });
    setAsRepeatedInfo(true);
    return;
  };

  return (
    <Grid item xs={12} sm={isTemplate ? 7 : 6} lg={isTemplate ? 5 : 4}>
      <Card className={cx.weekShift}>
        <Box className={cx.weekShiftContent}>
          <Box className={cx.heading}>
            {isTemplate ? (
              <Typography variant="h5">{t('schedule.defaultWeek')}</Typography>
            ) : (
              <Typography variant="h5">
                {t('schedule.weekNo', { weekNo, weekStartDM, weekEndDM })}
              </Typography>
            )}
            {!isTemplate && (
              <ToggleButtonGroup
                className={cx.toggleBtnGrp}
                exclusive
                size="small"
                value={asRepeatedInfo ? 'sameAsDefault' : 'customizable'}
              >
                <ToggleButton
                  value="sameAsDefault"
                  onClick={backToDefaultHandle}
                  classes={{
                    root: cx.root,
                    selected: cx.selected,
                  }}
                  disableRipple
                  disableFocusRipple
                >
                  {t('schedule.sameAsDefault')}
                </ToggleButton>
                <Divider
                  className={cx.divider}
                  flexItem
                  orientation="vertical"
                />
                <ToggleButton
                  onClick={() => setAsRepeatedInfo(false)}
                  classes={{
                    root: cx.root,
                    selected: cx.selected,
                  }}
                  value="customizable"
                  disableRipple
                  disableFocusRipple
                >
                  {t('schedule.revertToDefault')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
          {days.map((day: Date) => {
            const dateKey = moment(day).format('dd');
            const { ranges, checked = false } = daysData[dateKey] || {};
            return (
              <DaySchedule
                isTemplate={isTemplate}
                key={dateKey + asDefaultInfo}
                day={day}
                dateKey={dateKey}
                asRepeated={asRepeatedInfo}
                ranges={ranges}
                checked={checked}
                handleDayDataChange={handleDayDataChange}
                timeslot={timeslot}
                fromIndex={fromIndex}
              />
            );
          })}
        </Box>
      </Card>
    </Grid>
  );
};

export default WeekSchedule;
