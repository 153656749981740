import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import MicOn from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import React from 'react';
import { LocalAudioTrack } from 'twilio-video';

import { useToggleTrack } from '../../../api/usages/video';

type Props = {
  track?: LocalAudioTrack;
  className?: string;
};

const AudioButton = ({ track, className }: Props) => {
  const [isEnabled, toggleTrack] = useToggleTrack(track);
  return (
    <Tooltip title="Mute" placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={className} onClick={() => toggleTrack()}>
        {isEnabled ? <MicOn /> : <MicOff color="error" />}
      </Fab>
    </Tooltip>
  );
};

export default AudioButton;
