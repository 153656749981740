import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../api/actions/uiControls';
import { useAdmins } from '../api/usages/admins';
import CreateAdminForm from '../components/Admin/CreateAdminForm';
import AvatarCustom from '../components/Avatar';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '40px auto 0',
    },
    avatar: {
      marginRight: 8,
    },
    wrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },
  }),
);

const AdminManageAdmins = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  const { admins, loading, error } = useAdmins();

  useEffect(() => {
    error && showErrorToast(error.message);
  }, [error]);

  return (
    <LayoutDefault>
      <Title title={t('admin.manageAdmins')} />
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <CreateAdminForm />
        </Box>
        <Box p={3} className={cx.wrapper}>
          <Typography variant="h4">{t('admin.existingAdmins')}</Typography>
          <List>
            {loading
              ? null
              : admins.map(admin => (
                  <ListItem key={admin.id}>
                    <ListItemAvatar className={cx.avatar}>
                      <AvatarCustom
                        photoURL={admin.photoURL}
                        name={admin.name}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={admin.name}
                      secondary={admin.email}
                    />
                  </ListItem>
                ))}
          </List>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default AdminManageAdmins;
