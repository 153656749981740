import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import { withStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { useContextRole, useLanguage } from '../api/usages';
import { availableLangs, changeLang, i18next } from '../locales';

const BootstrapInput = withStyles((theme: Theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function LanguageSelector() {
  const language = useLanguage() || i18next.language;
  const handleChange = async (event: React.ChangeEvent<{ value: string }>) => {
    const lang = event.target.value;
    try {
      await changeLang(lang);
    } catch (e) {
      console.error(e);
    }
  };

  const role = useContextRole();

  if (['doctor', 'lab', 'radio'].some(enOnlyRole => enOnlyRole === role)) {
    return null;
  }

  return (
    <FormControl>
      <NativeSelect
        className="joyride_lang"
        value={language}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {availableLangs.map(({ code, labelShort }) => (
          <option key={code} value={code}>
            {labelShort}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
