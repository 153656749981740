import Button from '@material-ui/core/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { i18next } from '../../locales';

const BackButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const lang = i18next.language;
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />}
      onClick={() => history.goBack()}
    >
      {t('booking.changeDoctor')}
    </Button>
  );
};

export default BackButton;
