import firebase from 'firebase';
import React, { useState, useCallback } from 'react';

import { doPhoneUpdate, verifyPhone } from '../../api/actions/auth';
import { UserRoleTypes } from '../../api/types';
import ConfirmCode from './ConfirmCode';
import EnterPhoneNumber from './EnterPhoneNumber';

type ConfirmState = {
  phoneNumber: string;
  onConfirmCode: Function;
};

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

type PhoneVerificationProps = {
  profileType: string;
  updateNumber?: boolean;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
};

const PhoneVerification = ({
  profileType,
  onAuthorized,
  updateNumber,
}: PhoneVerificationProps) => {
  const [confirmState, setConfirmState] = useState<ConfirmState | null>(null);
  const handleSendSms = useCallback(
    async phoneNumber => {
      const method = updateNumber ? doPhoneUpdate : verifyPhone;
      try {
        const { complete } = await method({
          phoneNumber,
          profileType,
          onAuthorized,
        });
        setConfirmState({ onConfirmCode: complete, phoneNumber });
      } catch (err) {
        console.warn(err);
        setConfirmState(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileType, onAuthorized, updateNumber],
  );

  return (
    <div>
      {confirmState ? (
        <ConfirmCode {...confirmState} />
      ) : (
        <EnterPhoneNumber canSend onSentCode={handleSendSms} />
      )}
    </div>
  );
};

export default PhoneVerification;
