/* eslint-disable no-console */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useUserName } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import SvgHeart from '../components/SvgHeart';
import { emergencyIcon, homeButtons } from '../constants/ConfigHome';
import { logoWithoutText } from '../constants/ConfigLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeContainer: {
      height: '85vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      boxShadow: '0px 4px 7px #888888',
      width: '50vw',
      margin: 10,
      [theme.breakpoints.down('sm')]: {
        width: '70vw',
      },
      [theme.breakpoints.down('xs')]: {
        width: '90vw',
      },
    },
    homeCards: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    cardItem: {
      padding: '0px 20px 5px 20px',
      justifySelf: 'center',
      color: theme.palette.primary.contrastText,
    },
    welcomeContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 10,
    },
    logo: {
      marginLeft: 10,
      height: '12vh',
      width: '12vh',
      padding: 0,
    },
    welcomeText: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
    emergencyContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
      marginBottom: 0,
    },
    emergencyIcon: {
      marginBottom: 10,
      height: '5vh',
      width: '5vh',
    },
    heartLogo: {
      width: '19vh',
      height: '12vh',
    },
  }),
);

const Home = () => {
  const cx = useStyles();
  const name = useUserName();
  const { t } = useTranslation();

  return (
    <LayoutDefault>
      <Box className={cx.homeContainer}>
        <br />
        <Box className={cx.welcomeContainer}>
          <SvgHeart className={cx.heartLogo} filled color="white" />
          <Typography
            component="div"
            variant="body2"
            className={cx.welcomeText}
          >
            <big>
              {t('home.hello')} <b>{name || t('profile.anonymous')}</b>
            </big>
            <p>{t('home.hopeYouWillFeelBetterSoon')}</p>
          </Typography>
        </Box>
        {homeButtons.map(({ title, text, route }, index) => (
          // @ts-ignore
          <Card key={title} className={`joyride_card_${index} ${cx.card}`}>
            <Link to={route} style={{ textDecoration: 'none' }}>
              <Box bgcolor="primary.main">
                <CardActionArea>
                  <CardContent className={cx.homeCards}>
                    <Typography
                      variant="h5"
                      component="h2"
                      className={cx.cardItem}
                    >
                      <b>{t(title)}</b>
                    </Typography>
                    <Typography
                      variant="inherit"
                      component="p"
                      className={cx.cardItem}
                      align="center"
                    >
                      {t(text)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Box>
            </Link>
          </Card>
        ))}
        <Box className={cx.emergencyContainer}>
          <img
            alt={emergencyIcon.alt}
            src={emergencyIcon.src}
            className={cx.emergencyIcon}
          />
          <Typography
            component="div"
            variant="inherit"
            color="textSecondary"
            align="center"
          >
            {t('home.emergencyCase')} <br />
            <b>{t('home.emergencyRecommendation')}</b>
          </Typography>
        </Box>
      </Box>
    </LayoutDefault>
  );
};

export default Home;
