import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListField } from 'uniforms-material';

import { askForArabic } from '../../constants/ConfigProfile';
import SectionTitle from '../SectionTitle';
import AddressArea from './AddressArea';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      '& .MuiListSubheader-root': {
        display: 'none',
      },
      '& .MuiInput-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& button': {
        display: 'none',
      },
      '& > .MuiFormControl-root': {
        display: 'none',
      },
      '& .MuiListItem-root': {
        padding: '0',
        flexDirection: 'column',
      },
      '& .MuiAutocomplete-root': {
        width: '100%',
      },
    },
  }),
);

const AddressAreaList = (props: any) => {
  const cx = useStyles();
  const { t } = useTranslation();

  return (
    <FormGroup className={cx.root}>
      <SectionTitle title={t('profile.addressSection')} />
      <Typography component="p" color="error" style={{ marginBottom: '5px' }}>
        {askForArabic}
      </Typography>
      <ListField name="address" initialCount={1}>
        <AddressArea {...props} name="$" />
      </ListField>
    </FormGroup>
  );
};

export default AddressAreaList;
