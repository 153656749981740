import { compact } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { fromTask } from 'rxfire/storage';
import { v4 as uuidv4 } from 'uuid';

import { showErrorToast } from '../../api/actions/uiControls';
import {
  StorageReference,
  UploadTask,
  FileEntry,
  Entry,
  FileBlob,
  ExtendedFileEntry,
  storage,
} from '../../lib/firebase';
import PreviewList from './PreviewList';
import { UploadAreaBasic } from './UploadAreaBasic';

type UploadAreaProp = {
  initEntries?: Entry[];
  acceptedFiles?: string[];
  filesLimit?: number;
  maxFileSize?: number; //in bytes
  hideFileNames?: boolean;
  showPreviews?: boolean;
  alwaysReplace?: boolean;
  disabled?: boolean;
  disabledDelete?: boolean;
  hideDefaultContentForPreviews?: boolean;
  inputProps?: any;
  style?: any;
  storageRef: StorageReference;
  onUploaded?: (url: string, file: FileBlob) => void;
  onUpdatedAll?: (items: Array<{ url: string; file: FileBlob }>) => void;
  onDeleted?: (url: string, file: FileBlob) => void;
};

const isFileEntry = (entry: Entry): entry is FileEntry => {
  return !!(entry as FileEntry).file;
};

export const UploadArea: FunctionComponent<UploadAreaProp> = ({
  style,
  filesLimit = 1,
  acceptedFiles = ['image/*', 'video/*', 'application/*'],
  hideFileNames = false,
  showPreviews = true,
  hideDefaultContentForPreviews = true,
  storageRef,
  children,
  onUploaded,
  onUpdatedAll,
  onDeleted,
  initEntries = [],
  disabled = false,
  disabledDelete,
  alwaysReplace = false,
}) => {
  const [entries, setEntry] = useState<FileEntry[]>([]);
  useEffect(() => {
    if (!initEntries?.length) {
      return;
    }
    // preparing fake Blob with metadata info for preview
    Promise.all(
      initEntries.map(async ({ url }) => {
        const ref = storage.refFromURL(url);
        const { name, contentType, size } = await ref.getMetadata();

        return {
          url: await ref.getDownloadURL(),
          file: { name, size, type: contentType },
        } as FileEntry;
      }),
    ).then(fileEntries => setEntry(fileEntries));
  }, [initEntries, setEntry]);

  const handleDelete = useCallback(
    fileIndex => {
      const { url, file, task } = entries[fileIndex] as ExtendedFileEntry;
      delete entries[fileIndex];
      const newEntries = compact(entries);
      setEntry(newEntries);
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (task) {
        task.cancel();
      }
      if (onDeleted) {
        onDeleted(url, file);
      }
      if (onUpdatedAll) {
        onUpdatedAll(newEntries);
      }
    },
    [entries, onDeleted, onUpdatedAll],
  );

  const handleAdd = useCallback(
    (fileObjs: FileEntry[]) => {
      const list = fileObjs.map(({ file, url }) => {
        // we use unique identifier here because it's not possible
        // to have multiple objects share the same exact name at a given path
        const extension = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${extension}`;

        const task: UploadTask = storageRef.child(fileName).put(file);
        const upload$ = fromTask(task);
        upload$.subscribe(
          null,
          err => {
            console.error(err);
            showErrorToast(err.toString());
          },
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          async () => {
            const url = await storageRef.child(fileName).getDownloadURL();
            onUploaded && onUploaded(url, file as File);
          },
        );
        return {
          file,
          fileName,
          url,
          task,
          upload$,
        };
      });
      if (onUpdatedAll) {
        Promise.all(list.map(i => i.upload$.toPromise())).then(async () => {
          const args = await Promise.all(
            list.map(async el => {
              const url = await storageRef.child(el.fileName).getDownloadURL();
              return { url, file: el.file };
            }),
          );

          if (alwaysReplace) {
            onUpdatedAll(args);
          } else {
            onUpdatedAll([...entries, ...args]);
          }
        });
      }
      setEntry(list);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setEntry,
      storageRef,
      onUploaded,
      onUpdatedAll,
      entries,
      filesLimit,
      alwaysReplace,
    ],
  );

  const isContent = entries.length === 0 || !hideDefaultContentForPreviews;
  const isPreviews = showPreviews && entries.length > 0;

  return (
    <UploadAreaBasic
      disabled={disabled}
      onAdd={handleAdd}
      onDelete={handleDelete}
      filesLimit={filesLimit}
      acceptedFiles={acceptedFiles}
      fileEntries={entries.filter(isFileEntry)}
      style={style}
    >
      {isContent && children}
      {isPreviews && (
        <PreviewList
          disabledDelete={disabledDelete}
          fileEntries={entries}
          onDelete={handleDelete}
          hideFileNames={hideFileNames}
          disabled={disabled}
        />
      )}
    </UploadAreaBasic>
  );
};

export default UploadArea;
