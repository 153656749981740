import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import CallEnd from '@material-ui/icons/CallEnd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { updateBooking } from '../../../api/actions/bookings';
import { showErrorToast } from '../../../api/actions/uiControls';
import {
  Booking,
  Diagnosis,
  LabReferralRaw,
  Observation,
  Prescription,
  RadioReferralRaw,
  UserRoleTypes,
} from '../../../api/types';
import { useContextRole } from '../../../api/usages';
import { db } from '../../../lib/firebase';
import VideoEndContext from './VideoContext';

type Props = {
  className?: string;
};

async function checkNotesFilled(bookingId: string) {
  const booking = (
    await db.doc(`bookings/${bookingId}`).get()
  ).data() as Booking;

  const doctorId = booking?.doctorId;

  if (!booking.complaint) {
    showErrorToast("Can't leave the call. Complaint is not filled");
    return false;
  }

  async function getDoctorNotesEntity<T>(collection: string) {
    const ref = await db
      .collection(collection)
      .where('bookingId', '==', bookingId)
      .where('doctorId', '==', doctorId)
      .get();

    return ref.docs?.[0]?.data() as T | undefined;
  }

  async function getDoctorNotesArrayEntity<T>(collection: string) {
    const refs = await db
      .collection(collection)
      .where('bookingId', '==', bookingId)
      .where('doctorId', '==', doctorId)
      .get();

    const arrayOfRefs: Array<T> = [];
    refs.forEach((ref: any) => arrayOfRefs.push(ref.data()));

    return arrayOfRefs;
  }

  const observation = await getDoctorNotesEntity<Observation>('observations');
  if (!observation || !observation.content) {
    showErrorToast("Can't leave the call. Observation is not filled");
    return false;
  }

  const diagnosis = await getDoctorNotesEntity<Diagnosis>('diagnoses');
  if (!diagnosis || !diagnosis.content) {
    showErrorToast("Can't leave the call. Diagnosis is not filled");
    return false;
  }

  const prescriptions = await getDoctorNotesEntity<Prescription>(
    'prescriptions',
  );

  if (!prescriptions || !prescriptions.content) {
    showErrorToast("Can't leave the call. Prescriptions are not filled");
    return false;
  }
  const labReferrals = await getDoctorNotesArrayEntity<LabReferralRaw>(
    'labReferrals',
  );

  const testLab = labReferrals.every(
    labRef => labRef.serviceId && labRef.categoryId,
  );
  if (!testLab) {
    showErrorToast(
      "Can't leave the call. Some of the lab category or service  is not filled",
    );
    return false;
  }

  const radioReferrals = await getDoctorNotesArrayEntity<RadioReferralRaw>(
    'radioReferrals',
  );

  const testRadio = radioReferrals.every(
    radioRef => radioRef.serviceId && radioRef.categoryId,
  );
  if (!testRadio) {
    showErrorToast(
      "Can't leave the call. Some of the radio category or service  is not filled",
    );
    return false;
  }

  return true;
}

const EndButton = ({ className }: Props) => {
  const { bookingId } = useParams();
  const role = useContextRole();
  const { handleDialog } = useContext(VideoEndContext);

  const handleEndClick = async () => {
    if (role === UserRoleTypes.DOCTOR) {
      if (await checkNotesFilled(bookingId)) {
        updateBooking(bookingId, { status: 'finished' });
      }
    } else {
      handleDialog();
    }
  };

  return (
    <Tooltip
      title="End Call"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={className} color="secondary" onClick={handleEndClick}>
        <CallEnd />
      </Fab>
    </Tooltip>
  );
};

export default EndButton;
