import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import * as firebase from 'firebase';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm, SubmitField } from 'uniforms-material';

import {
  updateLabBooking,
  updateLabRadioReferral,
} from '../../api/actions/labBooking';
import { updateRadioBooking } from '../../api/actions/radioBooking';
import { pdfSchema } from '../../api/schemas/Booking';
import { Order, LabOrRadioBookingStatus } from '../../api/types';
import { Timestamp } from '../../api/types/Timestamp';
import { useDocument, useUserId } from '../../api/usages';
import { timestampNow } from '../../lib/firebase';
import { getTranslation } from '../../lib/utils';
import Avatar from '../Avatar';
import BookingInfoItem from '../BookingList/BookingInfoItem';
import DownloadPDFBtn from '../DownloadPDFBtn';
import UploadAreaUniformField from '../UploadAreaUniformField';
import DeleteOrderDialog from './DeleteOrderDialog';

const useStyles = makeStyles(theme => ({
  listItem: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    flexShrink: 0,
  },
}));

interface OrderListItemProps {
  order: Order;
  withLabOrRadioInfo?: boolean;
  withDownloadButton?: boolean;
  withUploadButton?: boolean;
  withDeleteButton?: boolean;
}

// eslint-disable-next-line complexity
const OrderListItem = ({
  order,
  withLabOrRadioInfo,
  withDownloadButton,
  withUploadButton,
  withDeleteButton,
}: OrderListItemProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const isLab = !!order.labId;
  const collectionName = withLabOrRadioInfo
    ? isLab
      ? 'labs'
      : 'radios'
    : 'patients';
  const serviceCollectionName = isLab ? 'labServices' : 'radioServices';
  const categoryCollectionName = isLab ? 'labCategories' : 'radioCategories';
  const bookingCollection = isLab ? 'labBookings' : 'radioBookings';
  const accountId = withLabOrRadioInfo
    ? order.labId || order.radioId
    : order.patientId;
  const editorId = useUserId();

  const {
    result: account,
    error: accountError,
    loading: accountLoading,
  }: any = useDocument(
    {
      path: `${collectionName}/${accountId}`,
    },
    { skip: !accountId },
  );

  const {
    result: service,
    error: serviceError,
    loading: serviceLoading,
  }: any = useDocument(
    {
      path: `${serviceCollectionName}/${order.serviceId}`,
    },
    { skip: !accountId },
  );

  const labOrRadioCategoryId = isLab
    ? service?.labCategoryId
    : service?.radioCategoryId;

  const {
    result: category,
    error: categoryError,
    loading: categoryLoading,
  }: any = useDocument(
    {
      path: `${categoryCollectionName}/${labOrRadioCategoryId}`,
    },
    { skip: !labOrRadioCategoryId },
  );

  const governorateId = account?.address?.[0].governorateId;
  const areaId = account?.address?.[0].areaId;

  const {
    result: governorate,
    error: governorateError,
    loading: governorateLoading,
  } = useDocument(
    {
      path: `governorates/${governorateId}`,
    },
    {
      skip: !governorateId,
    },
  );

  const { result: area, error: areaError, loading: areaLoading } = useDocument(
    {
      path: `areas/${areaId}`,
    },
    {
      skip: !areaId,
    },
  );

  if (
    accountError ||
    accountLoading ||
    serviceError ||
    serviceLoading ||
    governorateError ||
    areaError ||
    categoryError ||
    categoryLoading
  ) {
    return null;
  }

  let address = '';

  if (account?.address && !governorateLoading && !areaLoading) {
    const {
      apartmentNumber,
      buildingNumberName,
      directions,
      districtTownVillage,
      floorNumber,
      streetBlockCompound,
    } = account?.address?.[0];

    address = `${getTranslation(governorate)}, ${getTranslation(
      area,
    )}, ${districtTownVillage}, ${streetBlockCompound}, ${buildingNumberName}${
      floorNumber ? ', ' + floorNumber : ''
    }${apartmentNumber ? ', ' + apartmentNumber : ''}${
      directions ? ' (' + directions + ')' : ''
    }`;
  }

  const startTime = ((order?.createdAt as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      dateStyle: 'medium',
      timeStyle: 'short',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const weekday = ((order?.createdAt as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      weekday: 'long',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const dateToDisplay = `${weekday}, ${startTime}`;

  const deliverOrder = (model: { pdfUrl: string }) => {
    const deliveredModel = {
      ...model,
      status: LabOrRadioBookingStatus.DELIVERED,
      updatedAt: timestampNow(),
    };

    if (isLab) {
      updateLabRadioReferral(order.referralId, 'labReferrals');
      updateLabBooking(order.id, deliveredModel);
    } else {
      updateLabRadioReferral(order.referralId, 'radioReferrals');
      updateRadioBooking(order.id, deliveredModel);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem className={cx.listItem} key={order.id}>
            <ListItemAvatar style={{ marginRight: '15px' }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Avatar photoURL={account.photoURL} name={account.name} />
                </Grid>
                <Grid item>
                  {' '}
                  <BookingInfoItem
                    primaryText={
                      isLab ? t('callSummary.lab') : t('callSummary.radio')
                    }
                  />
                </Grid>
              </Grid>
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={<Typography variant="h6">{account.name}</Typography>}
              secondary={
                <React.Fragment>
                  <BookingInfoItem
                    primaryText={t(
                      `doctorNotes.${isLab ? 'labCategory' : 'radioCategory'}`,
                    )}
                    secondaryText={getTranslation(category)}
                  />
                  <BookingInfoItem
                    primaryText={t('orders.service')}
                    secondaryText={getTranslation(service)}
                  />
                  {order.note && (
                    <BookingInfoItem
                      primaryText={t('doctorNotes.note')}
                      secondaryText={order.note}
                    />
                  )}
                  {account.address && (
                    <BookingInfoItem
                      primaryText={
                        withLabOrRadioInfo
                          ? t('profile.address')
                          : t('orders.patientsAddress')
                      }
                      secondaryText={address}
                    />
                  )}
                  <BookingInfoItem
                    primaryText={t('orders.createdAt')}
                    secondaryText={dateToDisplay}
                  />
                  <BookingInfoItem
                    primaryText={t('orders.receptionPhone')}
                    secondaryText={account.receptionPhone}
                  />
                  {withDownloadButton && (
                    <BookingInfoItem
                      primaryText={t('orders.deliveredAt')}
                      secondaryText={order.updatedAt
                        .toDate()
                        .toLocaleString([i18next.language], {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        })}
                    />
                  )}
                </React.Fragment>
              }
            />
            {withDownloadButton && (
              <DownloadPDFBtn
                url={order?.pdfUrl ?? ''}
                className={cx.button}
                style={{ marginRight: withUploadButton ? '15px' : '0px' }}
              />
            )}
            {withDeleteButton && (
              <Button
                onClick={() => setOpen(true)}
                color="primary"
                variant="contained"
              >
                {t('orders.deleteBooking')}
              </Button>
            )}
            {withUploadButton && (
              <AutoForm schema={pdfSchema} onSubmit={deliverOrder}>
                <UploadAreaUniformField
                  patientId={order?.patientId}
                  editorId={editorId}
                  name="pdfUrl"
                  text={t('orders.uploadPDF')}
                  filesLimit={1}
                  acceptedFiles={['image/*', 'application/pdf']}
                />
                <SubmitField color="primary" />
              </AutoForm>
            )}
          </ListItem>
        </Paper>
      </Grid>
      <DeleteOrderDialog
        onClose={() => setOpen(false)}
        orderId={order.id}
        bookingCollection={bookingCollection}
        open={open}
      />
    </Grid>
  );
};

export default OrderListItem;
