import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Booking, DoctorWithSpecialty, Language } from '../../../api/types';
import { useDocument } from '../../../api/usages';
import getDoctorName from '../../../lib/utils/getDoctorName';
import { i18next } from '../../../locales';
import Avatar from '../../Avatar';
import BookingInfoItem from '../../BookingList/BookingInfoItem';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

interface DoctorVisitsItemProps {
  booking: Booking;
}

const DoctorDetailsScreenInfo = ({ booking }: DoctorVisitsItemProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const { result: doctor, error, loading } = useDocument<DoctorWithSpecialty>(
    {
      path: `doctors/${booking.doctorId}`,
      dependencies: [
        {
          collectionPath: 'specialties',
          mapFrom: 'specialtyId',
          mapTo: 'specialty',
        },
      ],
    },
    { skip: !booking.doctorId },
  );

  if (error || loading) {
    return null;
  }

  const lang = i18n.language as Language;
  const doctorName =
    getDoctorName(doctor, lang) || getDoctorName(booking.doctorDetails, lang);
  const specialty = doctor?.specialty || booking.doctorDetails?.specialty;
  const medTitle = doctor?.medTitle || booking.doctorDetails?.medTitle;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem key={booking.id} className={cx.listItem}>
            <ListItemAvatar style={{ marginRight: '15px' }}>
              <Avatar photoURL={doctor?.photoURL} name={doctorName} />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={<Typography variant="h6">{doctorName}</Typography>}
              secondary={
                <React.Fragment>
                  <BookingInfoItem
                    primaryText={specialty?.translations?.[lang]}
                  />
                  <BookingInfoItem
                    primaryText={t('profile.medTitle')}
                    secondaryText={t(`profile.title_${medTitle}`)}
                  />
                  <BookingInfoItem
                    primaryText={t('booking.dateAndTime')}
                    secondaryText={booking.startDate
                      .toDate()
                      .toLocaleString([i18next.language], {
                        // @ts-ignore
                        dateStyle: 'medium',
                        timeStyle: 'short',
                      })}
                  />
                </React.Fragment>
              }
            />
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DoctorDetailsScreenInfo;
