import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useState } from 'react';

import { RecordType } from '../../../api/types';
import TableHistoryHead from './TableHead';
import TableToolbar from './TableToolbar';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
    },
  }),
);

interface TableHistoryProps {
  rows: any;
  type: RecordType;
  unit?: string;
  readonly?: boolean;
}

const TableHistory = ({ rows, type, unit, readonly }: TableHistoryProps) => {
  const cx = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const dates: object[] = rows.dates.map((date: any) => date.toDate());
  const values: number[] = rows.values;

  const convertToReadableDates = (date: any) =>
    moment(date).format('YYYY / MM / DD');

  const convertToArrayOfObject = (dates: any, values: any) => {
    const result = [];

    for (let i = 0; i < values.length; i++) {
      result.push({ date: dates[i], value: values[i] });
    }

    return result;
  };

  let rowsToDisplay = convertToArrayOfObject(dates, values);
  rowsToDisplay = orderBy(rowsToDisplay, ['date'], ['desc']);
  const isSelected = (date: any) => selected.includes(date);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowsToDisplay.map((n: any) =>
        moment(n.date).valueOf().toString(),
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleDelete = () => {
    setSelected([]);
  };

  return (
    <div>
      <TableToolbar type={type} selected={selected} onDelete={handleDelete} />
      <TableContainer className={cx.table} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHistoryHead
            onSelectAllClick={handleSelectAllClick}
            rowCount={rowsToDisplay.length}
            type={type}
            selectedLength={selected.length}
            readonly={readonly}
          />
          <TableBody>
            {rowsToDisplay.map((row, index) => {
              const isItemSelected = isSelected(
                moment(row.date).valueOf().toString(),
              );
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  aria-checked={isItemSelected}
                  onClick={(event: any) =>
                    !readonly
                      ? handleClick(
                          event,
                          moment(row.date).valueOf().toString(),
                        )
                      : undefined
                  }
                  key={moment(row.date).valueOf().toString()}
                  selected={isItemSelected}
                  tabIndex={-1}
                >
                  {!readonly && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row">
                    {convertToReadableDates(row.date)}
                  </TableCell>
                  <TableCell align="right">
                    {row.value} {unit}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableHistory;
