import { Box, Card } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  SubmitField,
  AutoField,
  ErrorField,
} from 'uniforms-material';

import { doEmailUpdate } from '../api/actions/auth';
import { ChangeEmailsSchema } from '../api/schemas/ChangePassAndEmail';
import { useUser } from '../api/usages';
import Title from '../components/Title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: '0 auto',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(2),
      },
      '& .Mui-error': {
        marginBottom: theme.spacing(1),
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    submitButton: {
      marginTop: theme.spacing(3),
    },
  }),
);

const ChangeEmail = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const user = useUser();
  if (!user) {
    return null;
  }
  return (
    <Card className={cx.root}>
      <Box p={3} className={cx.wrapper}>
        <Box className={cx.header}>
          <Title variant="h3" title={t('auth.changeEmail')} />
        </Box>
        <AutoForm
          model={{ phoneNumber: user.phoneNumber, email: user.email }}
          className={cx.root}
          schema={ChangeEmailsSchema}
          onSubmit={doEmailUpdate}
        >
          <AutoField
            name="email"
            inputProps={{
              readOnly: user.email,
            }}
            label={t('profile.email')}
          />
          <ErrorField name="email" errorMessage={t('profile.emailError')} />

          <AutoField name="password" label={t('auth.currentPassword')} />
          <ErrorField name="password" />

          <AutoField name="newEmail" label={t('auth.newEmail')} />
          <ErrorField name="newEmail" errorMessage={t('profile.emailError')} />
          <AutoField name="confirmEmail" label={t('auth.confirmEmail')} />
          <ErrorField name="confirmEmail" />
          <Box>
            <SubmitField
              color="primary"
              label={t('common.submit')}
              className={cx.submitButton}
            />
          </Box>
        </AutoForm>
      </Box>
    </Card>
  );
};

export default ChangeEmail;
