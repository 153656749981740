import { Query, LabCategory, LabService } from '../types';
import { Lab } from '../types/Lab';
import { UseCollectionOptions, useCollection } from './collection';
import { useVerifiedTestingQuery } from './utils/useVerifiedTestingQuery';

export const useLabCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labCategories, loading, error } = useCollection<LabCategory>(
    {
      collectionPath: 'labCategories',
      query,
    },
    options,
  );

  return { labCategories, loading, error };
};

export const useLabServices = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: labServices, loading, error } = useCollection<LabService>(
    {
      collectionPath: 'labServices',
      query,
    },
    options,
  );

  return { labServices, loading, error };
};

export function useLabs(query?: Query, options?: UseCollectionOptions) {
  const { results, loading, error } = useCollection<Lab>(
    {
      collectionPath: 'labs',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    labs: results,
  };
}

export function useVerifiedLabs(query?: Query, options?: UseCollectionOptions) {
  const newQuery = useVerifiedTestingQuery(query);

  return useLabs(newQuery, options);
}
