import { Box, Card, FormLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  ErrorField,
  NumField,
  SubmitField,
} from 'uniforms-material';

import { doCreateProfile, doProfileUpdate } from '../api/actions/auth';
import { showInfoToast } from '../api/actions/uiControls';
import {
  CreateDoctorProfileNoAuth,
  CreateDoctorProfileWithAuth,
} from '../api/schemas/CreateProfileDoctor';
import { UserRoleTypes } from '../api/types';
import { useNationalities, useUser } from '../api/usages';
import { useSpecialties } from '../api/usages/specialties';
import CustomPriceField from '../components/CustomPriceField';
import CustomSelectField from '../components/CustomSelectField';
import LayoutDefault from '../components/LayoutDefault';
import AddressAreaList from '../components/Profile/AddressAreaList';
import BilingualAutocompleteField from '../components/Profile/BilingualAutocompleteField';
import CertificatesListField from '../components/Profile/CertificatesListField';
import ReauthWithPhoneDialog from '../components/ReauthWithPhoneDialog';
import SectionTitle from '../components/SectionTitle';
import Title from '../components/Title';
import UploadAreaUniformField from '../components/UploadAreaUniformField';
import {
  askForArabicAbout,
  countriesOptions,
  genderOptions,
  medTitleOptions,
  proficiencyOptions,
} from '../constants/ConfigProfile';
import { timestampNow } from '../lib/firebase';
import { goToDoctorsSchedulePage } from '../lib/goToRouteHelpers';
import { momentToFormat } from '../lib/utils';
import { i18next } from '../locales';
import { getTranslatedSpeciality } from '../screens/Specialists';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '0 auto',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: '16px',
      },
      '& .Mui-error': {
        marginBottom: '12px',
      },
    },
    submitButton: {
      marginTop: '24px',
    },
    header: {
      marginBottom: '32px',
    },
    spaceBottom: {
      marginBottom: '16px',
    },
    label: {
      marginBottom: '5px',
      marginTop: 0,
    },
    aboutField: {
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.error.main,
      },
    },
  }),
);

const CreateProfileDoctor = () => {
  const { t } = useTranslation();
  const { specialties } = useSpecialties();
  const { nationalities } = useNationalities();
  const lang = i18next.language;
  const translatedSpecialties = specialties.map(specialty => {
    return getTranslatedSpeciality(specialty);
  });
  const user = useUser();
  const cx = useStyles();
  const formRef = useRef(null);
  const [reAuthNeeded, setReauthNeeded] = useState<boolean>(false);

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  useEffect(() => {
    const phoneNumber = get(user, 'phoneNumber', '');
    const email = get(user, 'email', '');
    const profileData = {
      kind: 'doctor',
      email,
      phoneNumber,
      testing: false,
    };
    doProfileUpdate(profileData);
  }, [user]);

  const handleSubmit = (profile: object) => {
    const price = get(profile, 'price', {});
    const birthday = get(profile, 'birthday', '');
    const amount = get(profile, 'price.amount', {});
    const dollarsToCents = amount * 100;
    const newPrice = { ...price, amount: dollarsToCents };
    const convertedBirthday = momentToFormat(
      lang,
      birthday,
      'YYYY-MM-DDTHH:mm:ss',
    );
    const updatedProfile = {
      ...profile,
      birthday: convertedBirthday,
      price: newPrice,
      testing: false,
      createdAt: timestampNow(),
    };

    const onLinkCredsErr = (e: any) => {
      if (e.code === 'auth/requires-recent-login') {
        setReauthNeeded(true);
      }
    };

    doCreateProfile(
      updatedProfile,
      UserRoleTypes.DOCTOR,
      goToDoctorsSchedulePage,
      onLinkCredsErr,
    );
  };

  const closeReauth = () => {
    setReauthNeeded(false);
  };

  const onReauthenticated = () => {
    closeReauth();
    showInfoToast(t('common.tryResubmit'));
  };

  if (!user) {
    return (
      <LayoutDefault>
        <Card className={cx.root} />
      </LayoutDefault>
    );
  }
  const schema = user.email
    ? CreateDoctorProfileNoAuth
    : CreateDoctorProfileWithAuth;
  return (
    <LayoutDefault>
      <Card className={cx.root}>
        {reAuthNeeded && (
          <ReauthWithPhoneDialog
            onClose={closeReauth}
            onReauthenticated={onReauthenticated}
          />
        )}
        <Box p={3}>
          <Box className={cx.header}>
            <Title title={t('common.registration')} />
          </Box>
          <Box style={{ marginBottom: '10px' }}>
            <Typography variant="body2" color="error">
              {t('profile.languageInfo')}
            </Typography>
          </Box>
          <AutoForm
            ref={formRef}
            model={{
              phoneNumber: user.phoneNumber,
              email: user.email,
              name: { en: '' },
              address: [{}],
            }}
            schema={schema}
            onSubmit={handleSubmit}
          >
            <SectionTitle title={t('profile.personalInfo')} />
            <Box display="flex">
              <Box flex={1}>
                <AutoField name="name.en" label={t('profile.nameEn')} />
                <ErrorField name="name.en" />
              </Box>
              <Box flex={1}>
                <AutoField name="name.ar" label={t('profile.nameAr')} />
                <ErrorField name="name.ar" />
              </Box>
            </Box>
            <UploadAreaUniformField
              name="photoURL"
              text={t(`profile.uploadPhoto`)}
              filesLimit={1}
              acceptedFiles={['image/*']}
              className={cx.spaceBottom}
            />
            <ErrorField name="photoURL" />
            <AutoField name="birthday" label={t('profile.birthday')} />
            <ErrorField name="birthday" />
            <CustomSelectField
              name="gender"
              label={t('profile.gender')}
              options={genderOptions}
              fullWidth
            />
            <ErrorField name="gender" />
            <BilingualAutocompleteField
              id="nationality"
              name="nationality"
              options={nationalitiesSorted}
              label={t('profile.nationality')}
            />
            <ErrorField name="nationality" />
            <AutoField name="idNumber" label={t('profile.iDNumber')} />
            <ErrorField name="idNumber" />
            <UploadAreaUniformField
              name="idOrPassportPhoto"
              text={t(`profile.uploadID`)}
              filesLimit={1}
              acceptedFiles={['image/*', 'application/pdf']}
            />
            <ErrorField name="idOrPassportPhoto" />
            <AutoField
              name="resellerCode"
              label={t('profile.resellerCode')}
              inputProps={{ maxLength: 4 }}
            />
            <ErrorField
              name="resellerCode"
              errorMessage={t('profile.resellerError')}
            />
            <AutoField
              name="nativeLanguage"
              label={t('profile.nativeLanguage')}
            />
            <ErrorField name="nativeLanguage" />
            <CustomSelectField
              name="arabicProficiency"
              label={t('englishOnly.profile_arabicProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="englishProficiency"
              label={t('englishOnly.profile_englishProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="frenchProficiency"
              label={t('englishOnly.profile_frenchProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <AddressAreaList name="address" />
            <SectionTitle
              title={t('englishOnly.profile_professionalSection')}
            />
            <CustomSelectField
              name="specialtyId"
              label={t('profile.specialtyId')}
              options={translatedSpecialties}
              fullWidth
            />
            <ErrorField name="specialtyId" />
            <CustomSelectField
              name="medTitle"
              label={t('englishOnly.profile_medTitle')}
              options={medTitleOptions}
              fullWidth
            />
            <ErrorField name="medTitle" />
            <CertificatesListField formRef={formRef} />
            <Box display="flex">
              <Box flex={1}>
                <AutoField
                  name="about.en"
                  label={t('profile.aboutEn')}
                  placeholder={askForArabicAbout}
                  multiline
                  rows={2}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  classes={{ root: cx.aboutField }}
                />
                <ErrorField name="about.en" />
              </Box>
              <Box flex={1}>
                <AutoField
                  name="about.ar"
                  label={t('profile.aboutAr')}
                  placeholder={askForArabicAbout}
                  multiline
                  rows={2}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  classes={{ root: cx.aboutField }}
                />
                <ErrorField name="about.ar" />
              </Box>
            </Box>
            <CustomSelectField
              name="countries"
              label={t('profile.practiceCountries')}
              options={countriesOptions}
              fullWidth
            />
            <ErrorField name="countries" />
            <NumField name="yearsOfExperience" min={0} max={100} />
            <ErrorField name="yearsOfExperience" />
            <SectionTitle title={t('englishOnly.profile_priceSection')} />
            <FormLabel component="p" className={cx.label}>
              {t('profile.consultPice')}
            </FormLabel>
            <CustomPriceField
              name="price"
              label={`${t('englishOnly.profile_priceAmount')}`}
            />
            <ErrorField
              name="price.amount"
              errorMessage={t('profile.primaryPriceValidate')}
            />
            <Box>
              <SubmitField
                color="primary"
                label={t('common.submit')}
                className={cx.submitButton}
              />
            </Box>
          </AutoForm>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default CreateProfileDoctor;
