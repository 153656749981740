import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import * as colors from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigate } from '../../api/actions/navigation';
import { PaymentOrder } from '../../api/types/Payments';
import RouteTypes from '../../constants/routes';
import { formatPrice } from '../../lib/utils';

type Props = {
  order: PaymentOrder;
};

const PaymentSuccess = ({ order }: Props) => {
  const { t } = useTranslation();
  const { price } = order;

  return (
    <Box textAlign="center" m={4}>
      <h1>{t('payments.success')}</h1>
      <CheckCircleIcon fontSize="large" style={{ color: colors.green[500] }} />
      <h2>{formatPrice(price)}</h2>
      <Box m={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(RouteTypes.BOOKINGS)}
        >
          {t('payments.goToBookings')}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
