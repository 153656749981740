import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { useRoom, useMediaRefs, useNotifyDoctor } from '../../api/usages/video';

const useStyles = makeStyles<Theme>(theme => ({
  video: {
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: '#000',
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      objectFit: 'cover',
    },
    transform: 'rotateY(180deg)',
  },
}));

const Preview = () => {
  useNotifyDoctor();
  const room = useRoom();
  const { videoRef } = useMediaRefs(room?.localParticipant);
  const cx = useStyles();

  return (
    <>
      <video ref={videoRef} className={cx.video} />
    </>
  );
};

export default Preview;
