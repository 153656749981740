import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, styled } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../api/actions/uiControls';
import { Radio } from '../api/types/Radio';
import { useRadios } from '../api/usages';
import ManagementButtons from '../components/Admin/ManagementButtons';
import AvatarCustom from '../components/Avatar';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { Timestamp } from '../lib/firebase';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '700px',
    margin: '40px auto 0',
  },
  avatar: {
    marginRight: 8,
  },
});

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminManageRadios = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  const { radios, loading, error } = useRadios();

  const radiosCreatedAt: any = radios.map((radio: Radio) => {
    let createdAt = null;
    if (radio.createdAt) {
      createdAt = radio.createdAt.seconds
        ? new Timestamp(radio.createdAt.seconds, radio.createdAt.nanoseconds)
        : radio.createdAt;
    }

    return createdAt
      ? { ...radio, createdAt: createdAt?.toDate() }
      : { ...radio, createdAt: new Date(190000000000) };
  });

  const sortedRadios = orderBy(radiosCreatedAt, ['createdAt', 'asc']);

  useEffect(() => {
    error && showErrorToast(error.message);
  }, [error]);

  return (
    <LayoutDefault>
      <Title title={t('admin.manageRadios')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <List>
            {loading
              ? null
              : sortedRadios.map(radio => (
                  <ListItem key={radio.id}>
                    <ListItemAvatar className={cx.avatar}>
                      <AvatarCustom
                        photoURL={radio.photoURL}
                        name={radio.name}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={radio.name}
                      secondary={radio.email}
                    />
                    <ManagementButtons
                      collection="radios"
                      id={radio.id}
                      verified={!!radio.verified}
                      testing={!!radio.testing}
                    />
                  </ListItem>
                ))}
          </List>
        </Container>
      </Card>
    </LayoutDefault>
  );
};

export default AdminManageRadios;
