import About from '../components/Help/About';
import Contact from '../components/Help/Contact';
import PrivacyPolicy from '../components/Help/PrivacyPolicy';
import Terms from '../components/Help/Terms';

export default [
  {
    title: 'help.termsAndConditions',
    Component: Terms,
  },
  {
    title: 'help.privacyPolicies',
    Component: PrivacyPolicy,
  },
  {
    title: 'help.about',
    Component: About,
  },
  {
    title: 'help.contactUs',
    Component: Contact,
  },
];

export const fillArray = (length: number, key: string) =>
  Array(length)
    .fill('')
    .map((_, index) => `title_${key}_text_${index + 1}`);

export const privacyCounts = {
  1: 4,
  2: 3,
  3: 2,
  4: 3,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
};

export const termsCounts = {
  1: 1,
  2: 2,
  3: 3,
  4: 7,
  5: 2,
  6: 2,
  7: 1,
  8: 2,
  9: 5,
  10: 8,
  11: 2,
  12: 2,
  13: 4,
  14: 1,
  15: 3,
  16: 1,
  17: 7,
  18: 3,
  19: 6,
  20: 1,
  21: 3,
  22: 1,
  23: 1,
  24: 1,
  25: 2,
};

export const aboutCounts = {
  1: 2,
  2: 1,
};

export const contactCounts = {
  2: 1,
};
