import { get, omit, uniqBy } from 'lodash';

export const arrayToObject = (arr: any) => {
  const newArray: any[] = [];
  arr.forEach((obj: any) => {
    const tests = get(obj, 'tests', []);
    tests.forEach((item: any) => {
      const newObj = { categoryId: obj.categoryId, serviceId: item };
      return newArray.push(newObj);
    });
  });
  return newArray;
};

export const objectToArray = (arr: any) => {
  const uniqIdArr = uniqBy(arr, 'categoryId');
  const transform = uniqIdArr.map((obj: any) => {
    return { ...omit(obj, ['serviceId']), tests: [] };
  });
  arr.forEach((el: any) => {
    transform.forEach((item: any) => {
      if (el.serviceId && el.categoryId === item.categoryId) {
        item.tests.push(el.serviceId);
      }
    });
  });

  return transform;
};
