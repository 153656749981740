import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/ar-sa';
import { initReactI18next } from 'react-i18next';

import { AvailableLanguages } from '../constants/app';
import { auth } from '../lib/firebase';
import { setSessionData, getCurrentSessionData } from '../lib/sessionData';
import _translations from './_translations.json';
import newTranslations from './newTranslations.json';

Object.keys(newTranslations as any).forEach(key => {
  if (typeof (newTranslations as any)[key] === 'string') {
    (_translations as any)[AvailableLanguages.EN][
      key
    ] = (newTranslations as any)[key];
  } else {
    (_translations as any)[AvailableLanguages.EN][key] = Object.assign(
      {},
      (_translations as any)[AvailableLanguages.EN][key],
      (newTranslations as any)[key],
    );
  }
});

// if (AvailableLanguages.XX) {
//   (_translations as any)[AvailableLanguages.XX] = {};

//   Object.keys(_translations.en).forEach(code => {
//     (_translations as any)[AvailableLanguages.XX][code] = {};
//     Object.keys((_translations as any).en[code]).forEach(subcode => {
//       (_translations as any)[AvailableLanguages.XX][code][
//         subcode
//       ] = `${code}.${subcode}`;
//     });
//   });
// }

const fallbackLang = AvailableLanguages.EN;
const defaultLang = AvailableLanguages.AR;
moment.locale(defaultLang);

i18next
  .use({
    type: 'backend',
    read: (
      lng: string,
      ns: any,
      cb: (err: Error | null, arg1?: any) => any,
    ) => {
      (_translations as any)[lng]
        ? cb(null, (_translations as any)[lng])
        : cb(new Error(`Invalid language: ${lng}`));
    },
  })
  .use(initReactI18next)
  .init({
    lng: defaultLang,
    fallbackLng: fallbackLang,
    whitelist: Object.keys(_translations),
    nonExplicitWhitelist: true,
    lowerCaseLng: true,
    // debug: __DEV__, // Uncomment to print localization info into console
    react: {
      wait: true,
      nsMode: 'default',
    },
    initImmediate: false,
  });

const t = i18next.t.bind(i18next);
const changeLang = i18next.changeLanguage.bind(i18next);
let isLoadingReady = false;

getCurrentSessionData('language').then((lng: AvailableLanguages) => {
  isLoadingReady = true;
  if (!lng) {
    // setting default language for current session
    changeLang(defaultLang);
    return;
  }
  changeLang(lng);
});

i18next.on('languageChanged', lng => {
  // eslint-disable-next-line no-console
  console.log('🔣 language: ' + lng);
  moment.locale(lng === 'ar' ? 'ar-sa' : lng);
  auth.languageCode = lng;
  if (isLoadingReady) {
    setSessionData({ language: lng }).catch(e => console.warn(e));
  }
});

const availableLangs = Object.entries(_translations as any).map(
  // @ts-ignore
  ([code, { languageDisplayName }]) => ({
    code,
    label: languageDisplayName,
    labelShort: code.toUpperCase(),
  }),
);

export { availableLangs, changeLang, i18next, t };
