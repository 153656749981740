import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { UserRoleTypes } from './api/types';
import { useContextRole, useLanguage, useProfile } from './api/usages';
import { useRuntime } from './api/usages/uiControls';
import CustomRoute from './components/CustomRoute';
import ROUTES from './constants/routes';
import { init } from './lib/firebase/analytics';
import AdminDataDumps from './screens/AdminDataDumps';
import AdminEditDoctor from './screens/AdminEditDoctor';
import AdminEditLab from './screens/AdminEditLab';
import AdminEditPatient from './screens/AdminEditPatient';
import AdminEditRadio from './screens/AdminEditRadio';
import AdminHome from './screens/AdminHome';
import AdminManageAdmins from './screens/AdminManageAdmins';
import AdminManageDoctors from './screens/AdminManageDoctors';
import AdminManageLabs from './screens/AdminManageLabs';
import AdminManagePatients from './screens/AdminManagePatients';
import AdminManageRadios from './screens/AdminManageRadios';
import AdminNotifications from './screens/AdminNotifications';
import AdminSignIn from './screens/AdminSignIn';
import BookingSuccess from './screens/BookingSuccess';
import Bookings from './screens/Bookings';
import CallReview from './screens/CallReview';
import CallSummary from './screens/CallSummary';
import ChangeEmail from './screens/ChangeEmail';
import ChangePass from './screens/ChangePass';
import ChangePhone from './screens/ChangePhone';
import ComingSoon from './screens/ComingSoon';
import CreateEmailDoctor from './screens/CreateEmailDoctor';
import CreateEmailLabRadio from './screens/CreateEmailLabRadio';
import CreateEmailProfile from './screens/CreateEmailProfile';
import CreateProfile from './screens/CreateProfile';
import CreateProfileDoctor from './screens/CreateProfileDoctor';
import CreateProfileLabRadio from './screens/CreateProfileLabRadio';
import DoctorDetailsFromLink from './screens/DoctorDetailsFromLink';
import Favourites from './screens/Favourites';
import FilteredDoctors from './screens/FilteredDoctors';
import FollowUpFromLink from './screens/FollowUpFromLink';
import ForDoctor from './screens/ForDoctor';
import ForLab from './screens/ForLab';
import ForRadio from './screens/ForRadio';
import ForgotPass from './screens/ForgotPass';
import HealthRecords from './screens/HealthRecords';
import HealthRecordsPrescription from './screens/HealthRecordsPrescription';
import HealthRecordsVisit from './screens/HealthRecordsVisit';
import Help from './screens/Help';
import Home from './screens/Home';
import LabDetails from './screens/LabRadio/LabDetails';
import Labs from './screens/LabRadio/Labs';
import Radios from './screens/LabRadio/Radio';
import RadioDetails from './screens/LabRadio/RadioDetails';
import Orders from './screens/Orders';
import PaymentCashIn from './screens/PaymentCashIn';
import PaymentPay from './screens/PaymentPay';
import PaymentWallet from './screens/PaymentWallet';
import Prescriptions from './screens/Prescriptions';
import PrimaryDoctors from './screens/PrimaryDoctors';
import Profile from './screens/Profile';
import ProfileDoctor from './screens/ProfileDoctor';
import ProfileLabRadio from './screens/ProfileLabRadio';
import Schedule from './screens/Schedule';
import SessionSummary from './screens/SessionSummary';
import Settings from './screens/Settings';
import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import SignUpDoctor from './screens/SignUpDoctor';
import SignUpLab from './screens/SignUpLab';
import SignUpRadio from './screens/SignUpRadio';
import Specialists from './screens/Specialists';
import VideoCall from './screens/VideoCall';

if (
  window.location.host === 'stg.shoofdoctor.com' ||
  window.location.host === 'www.shoofdoctor.com'
) {
  window.location.href = 'https://shoofdoctor.com';
}

const HomeRedirect = () => (
  <Route path="*" component={() => <Redirect to={ROUTES.HOME} />} />
);

// eslint-disable-next-line complexity
export default function AppRouter() {
  const role = useContextRole() ?? 'no-user';
  const profile = useProfile() || {};
  const language = useLanguage();
  const { isMobileApp } = useRuntime();

  useEffect(init, []);

  if (!language) {
    return null;
  }

  switch (role) {
    case UserRoleTypes.DOCTOR:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.SCHEDULE}
            component={Schedule}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.PROFILE}
            component={ProfileDoctor}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.DOCTOR}
            component={DoctorDetailsFromLink}
            profile={profile}
          />
          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.VIDEO_CALL}
            component={VideoCall}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HOME}
            component={Bookings}
            profile={profile}
          />
          <Route path={ROUTES.SESSION_SUMMARY} component={SessionSummary} />
          <Route path={ROUTES.PROFILE_CREATE} component={CreateProfileDoctor} />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailDoctor}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_VISIT}
            component={HealthRecordsVisit}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_PRESCRIPTION}
            component={HealthRecordsPrescription}
            profile={profile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.PAYMENT_WALLET} component={PaymentWallet} />
          <Route path={ROUTES.PAYMENT_PAY} component={PaymentPay} />
          <Route path={ROUTES.INDEX} component={Bookings} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.LAB:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.PROFILE}
            component={ProfileLabRadio}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.HOME}
            component={Orders}
            profile={profile}
          />
          <Route
            path={ROUTES.PROFILE_CREATE}
            component={CreateProfileLabRadio}
          />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailLabRadio}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Orders} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.RADIO:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.PROFILE}
            component={ProfileLabRadio}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.HOME}
            component={Orders}
            profile={profile}
          />
          <Route
            path={ROUTES.PROFILE_CREATE}
            component={CreateProfileLabRadio}
          />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailLabRadio}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Orders} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.PATIENT:
      return (
        <Switch>
          <CustomRoute
            path={ROUTES.BOOKINGS}
            component={Bookings}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.DOCTORS}
            component={FilteredDoctors}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.PROFILE}
            component={Profile}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.PRESCRIPTIONS}
            component={Prescriptions}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS}
            component={HealthRecords}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_VISIT}
            component={HealthRecordsVisit}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.HEALTH_RECORDS_PRESCRIPTION}
            component={HealthRecordsPrescription}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.FAVOURITES}
            component={Favourites}
            profile={profile}
          />
          <CustomRoute path={ROUTES.LABS} component={Labs} profile={profile} />
          <CustomRoute
            path={ROUTES.LAB_DETAILS}
            component={LabDetails}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.RADIOS}
            component={Radios}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.RADIO_DETAILS}
            component={RadioDetails}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.SETTINGS}
            component={Settings}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.DOCTOR}
            component={DoctorDetailsFromLink}
            profile={profile}
          />
          <CustomRoute
            path={ROUTES.FOLLOW_UP}
            component={FollowUpFromLink}
            profile={profile}
          />

          <CustomRoute path={ROUTES.HELP} component={Help} profile={profile} />
          <CustomRoute
            path={ROUTES.VIDEO_CALL}
            component={VideoCall}
            profile={profile}
          />
          <Route path={ROUTES.CALL_SUMMARY} component={CallSummary} />
          <Route path={ROUTES.CALL_REVIEW} component={CallReview} />
          <Route path={ROUTES.PRIMARY_DOCTORS} component={PrimaryDoctors} />
          <Route path={ROUTES.SPECIALISTS} component={Specialists} />
          <CustomRoute path={ROUTES.HOME} component={Home} profile={profile} />
          <Route path={ROUTES.PROFILE_CREATE} component={CreateProfile} />
          <Route
            path={ROUTES.PROFILE_CREATE_EMAIL}
            component={CreateEmailProfile}
          />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.PAYMENT_CASHIN} component={PaymentCashIn} />
          <Route path={ROUTES.PAYMENT_WALLET} component={PaymentWallet} />
          <Route path={ROUTES.PAYMENT_PAY} component={PaymentPay} />
          <Route path={ROUTES.BOOKING_SUCCESS} component={BookingSuccess} />
          <Route path={ROUTES.INDEX} component={Home} exact />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    case UserRoleTypes.ADMIN:
      return (
        <Switch>
          <Route
            path={ROUTES.MANAGE_PATIENTS_EDIT}
            component={AdminEditPatient}
          />
          <Route
            path={ROUTES.MANAGE_PATIENTS}
            component={AdminManagePatients}
          />
          <Route
            path={ROUTES.MANAGE_DOCTORS_EDIT}
            component={AdminEditDoctor}
          />
          <Route path={ROUTES.MANAGE_DOCTORS} component={AdminManageDoctors} />
          <Route path={ROUTES.MANAGE_LABS_EDIT} component={AdminEditLab} />
          <Route path={ROUTES.MANAGE_LABS} component={AdminManageLabs} />
          <Route path={ROUTES.MANAGE_RADIOS_EDIT} component={AdminEditRadio} />
          <Route path={ROUTES.MANAGE_RADIOS} component={AdminManageRadios} />
          <Route path={ROUTES.MANAGE_ADMINS} component={AdminManageAdmins} />
          <Route path={ROUTES.DUMPS} component={AdminDataDumps} />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route
            path={ROUTES.SEND_NOTIFICATIONS}
            component={AdminNotifications}
          />
          <Route path={ROUTES.HOME} component={AdminHome} />
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
    default:
      // unlogged-in should be a condition before this `switch - case of user role` ,
      // another way is make no user as a default case (but this can be controversial too).
      // There is one more, but in my feelings bad too, making it next option in enum user roles.
      return (
        <Switch>
          <Route path={ROUTES.SIGN_IN} component={SignIn} />
          <Route path={ROUTES.COMING_SOON} component={ComingSoon} />
          <Route path={ROUTES.SIGN_UP} component={SignUp} />
          <Route path={ROUTES.HELP} component={Help} />
          {!isMobileApp && (
            <Route path={ROUTES.FOR_DOCTOR} component={ForDoctor} />
          )}
          {!isMobileApp && <Route path={ROUTES.FOR_LAB} component={ForLab} />}
          {!isMobileApp && (
            <Route path={ROUTES.FOR_RADIO} component={ForRadio} />
          )}
          <Route path={ROUTES.SIGN_UP_DOCTOR} component={SignUpDoctor} />
          <Route path={ROUTES.SIGN_UP_LAB} component={SignUpLab} />
          <Route path={ROUTES.SIGN_UP_RADIO} component={SignUpRadio} />
          <Route path={ROUTES.PRIMARY_DOCTORS} component={PrimaryDoctors} />
          <Route path={ROUTES.SPECIALISTS} component={Specialists} />
          <Route path={ROUTES.DOCTOR} component={DoctorDetailsFromLink} />
          <Route path={ROUTES.HOME} component={Home} />
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPass} />
          <Route path={ROUTES.CHANGE_PASSWORD} component={ChangePass} />
          <Route path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
          <Route path={ROUTES.CHANGE_PHONE} component={ChangePhone} />
          <Route path={ROUTES.INDEX} component={Home} exact />
          {!isMobileApp && (
            <Route path={ROUTES.ADMIN_SIGN_IN} component={AdminSignIn} />
          )}
          <Route path="*" component={HomeRedirect} />
        </Switch>
      );
  }
}
