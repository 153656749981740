import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import React, { useState } from 'react';

import FavouriteDoctorRow from './FavouriteDoctorRow';

interface FavouriteDoctorsTableProps {
  onChooseDoctor: (doctor: any) => void;
  favouriteDoctorsIds: string[];
  testing: boolean;
}

const FavouriteDoctorsTable = ({
  onChooseDoctor,
  favouriteDoctorsIds,
  testing,
}: FavouriteDoctorsTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {favouriteDoctorsIds.map((id: string) => (
              <FavouriteDoctorRow
                key={id}
                doctorIdForQuery={id}
                onChooseDoctor={onChooseDoctor}
                testing={testing}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={favouriteDoctorsIds.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default FavouriteDoctorsTable;
