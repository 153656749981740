import { AppBar, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleQuery } from '../../../../../api/types';
import { useUserId } from '../../../../../api/usages';
import TabPanel from '../../../../../components/TabPanel';
import { isMobile } from '../../../../../lib/utils';
import ReferralToDoctor from './ReferralToDoctor';
import ReferralToLab from './ReferralToLabRadio';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: '#fafafa',
    },
    active: {
      color: '#ff00ff',
    },
    inActive: {
      color: 'black',
    },
  }),
);

export const getTranslatedName = (name: any): string => {
  if (!name) {
    return '';
  }
  const translatedName = name[i18next.language];
  return translatedName || name['en'];
};

interface ReferralsProps {
  bookingId: string;
  patientId?: string;
}
// eslint-disable-next-line complexity
const Referrals = ({ bookingId, patientId }: ReferralsProps) => {
  const cx = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const userId = useUserId();
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getStyle = (isActive: any) => {
    return !isActive ? cx.inActive : cx.active;
  };

  const query: SimpleQuery = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: patientId || userId,
    },
  ];

  return (
    <div className={cx.root}>
      <AppBar position="static" color="inherit">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant={isMobile ? 'scrollable' : 'fullWidth'}
        >
          <Tab
            className={getStyle(value === 0)}
            label={t('healthRecords.refToDoctor')}
          />
          <Tab
            className={getStyle(value === 1)}
            label={t('healthRecords.refToLab')}
          />
          <Tab
            className={getStyle(value === 2)}
            label={t('healthRecords.refToRadio')}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ReferralToDoctor bookingId={bookingId} query={query} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReferralToLab bookingId={bookingId} query={query} isLab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReferralToLab bookingId={bookingId} query={query} />
      </TabPanel>
    </div>
  );
};

export default Referrals;
