import { makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Patient, UserRoleTypes, Doctor, Language } from '../../api/types';
import { useContextRole, useDocument } from '../../api/usages';
import { calcAge } from '../../lib/utils';
import getDoctorName from '../../lib/utils/getDoctorName';

type Props = {
  uid: string;
  children: ReactNode;
};

const useStyles = makeStyles<Theme>(theme => ({
  overlayText: {
    position: 'absolute',
    background: 'rgba(0,0,0,0.5)',
    fontSize: theme.typography.subtitle1.fontSize,
    color: '#fff',
    padding: 5,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      bottom: 'unset',
    },
  },
}));

const DoctorOverlay = ({ uid, children }: Props) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const { result: doctor } = useDocument<Doctor>(
    {
      path: `doctors/${uid}`,
    },
    { skip: !uid },
  );

  const lang = i18n.language as Language;

  const info = doctor
    ? `${t('videoCall.dr')} ${getDoctorName(doctor, lang)}, ${t(
        `profile.title_${doctor.medTitle}`,
      )}`
    : '';

  return (
    <>
      <div className={cx.overlayText}>{info}</div>
      {children}
    </>
  );
};

const PatientOverlay = ({ uid, children }: Props) => {
  const cx = useStyles();
  const { result: patient } = useDocument<Patient>(
    {
      path: `patients/${uid}`,
    },
    { skip: !uid },
  );

  const info = patient ? `${patient.name}, ${calcAge(patient.birthday)}` : '';

  return (
    <>
      <div className={cx.overlayText}>{info}</div>
      {children}
    </>
  );
};

export const ParticipantOverlay = (props: Props) => {
  const role = useContextRole();

  const Component =
    role === UserRoleTypes.DOCTOR ? PatientOverlay : DoctorOverlay;

  return <Component {...props} />;
};
