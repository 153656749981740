import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserId } from '../api/usages/auth';
import PrescriptionList from '../components/HealthRecords/Prescriptions';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const Prescriptions = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userId = useUserId()!;
  return (
    <LayoutDefault>
      <Title title={t('common.prescriptions')} />
      <PrescriptionList patientId={userId} />
    </LayoutDefault>
  );
};

export default Prescriptions;
