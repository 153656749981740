import React from 'react';

import { useAuthReady, useUser } from '../api/usages/auth';
import ChangeEmail from '../components/ChangeEmail';
import LayoutDefault from '../components/LayoutDefault';

const ChangePass = () => {
  const isReady = useAuthReady();
  const user = useUser();
  if (!isReady || !user) {
    return <LayoutDefault />;
  }
  return (
    <LayoutDefault>
      <ChangeEmail />
    </LayoutDefault>
  );
};

export default ChangePass;
