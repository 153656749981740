import { auth, db, mergeData, timestampNow } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { ReferralTypes } from '../types';
import {
  LabOrRadioBookingStatus,
  LabBooking,
  LabBookingRaw,
} from '../types/Booking';

export const createLabBooking = createAction({
  type: 'createLabBooking',
  async callback(labBookingRaw: LabBookingRaw) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const patientId = user.uid;
    const { labId, serviceId, bookingId, referralId } = labBookingRaw;

    const newLabBookingRef = db.collection('labBookings').doc();
    const referralRef = db.collection('labReferrals').doc(referralId);

    const newLabBooking = {
      referralId,
      patientId,
      labId,
      serviceId,
      bookingId,
      createdAt: timestampNow(),
      updatedAt: timestampNow(),
      status: LabOrRadioBookingStatus.UNDELIVERED,
    };

    try {
      await db.runTransaction(async (t: any) => {
        const referralDoc = await t.get(referralRef);
        const referralStatus = referralDoc.data()?.status;
        const referralNote = referralDoc.data()?.note;

        if (referralStatus !== ReferralTypes.OPEN) {
          throw new Error('Referral already used.');
        }

        t.set(newLabBookingRef, { ...newLabBooking, note: referralNote });
      });
    } catch (e) {
      console.error('Error creating booking:', e);
    }
  },
});

export const updateLabBooking = createAction({
  type: 'updateLabBooking',
  callback(labBookingId: string, data: Omit<LabBooking, 'id'>) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user');
    }

    return mergeData(`labBookings/${labBookingId}`, data);
  },
});

export const updateLabRadioReferral = createAction({
  type: 'updateLabRadioReferral',
  async callback(
    referralId: string,
    collection: 'labReferrals' | 'radioReferrals',
  ) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user');
    }

    return mergeData(`${collection}/${referralId}`, {
      status: ReferralTypes.USED,
    });
  },
});

export const removeLabRadioBookingFromList = createAction({
  type: 'removeLabRadioBookingFromList',
  async callback(
    bookingId: string,
    collection: 'labBookings' | 'radioBookings',
  ) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user');
    }

    return mergeData(`${collection}/${bookingId}`, {
      status: LabOrRadioBookingStatus.CANCELED,
    });
  },
});

export const updateLabRadioReferralWithId = createAction({
  type: 'updateLabRadioReferralWithId',
  async callback(
    referralId: string,
    institutionId: string,
    collection: 'labReferrals' | 'radioReferrals',
  ) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user');
    }

    const refData = (
      await db.collection(collection).doc(referralId).get()
    ).data();

    return mergeData(`${collection}/${referralId}`, {
      institutionsId: [...(refData?.institutionsId ?? ''), institutionId],
    });
  },
});
