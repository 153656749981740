import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  SubmitField,
  AutoField,
  ErrorField,
} from 'uniforms-material';

import { doPasswordUpdate } from '../api/actions/auth';
import { ChangePasswordsSchema } from '../api/schemas/ChangePassAndEmail';
import { ForgotPasswordsSchema } from '../api/schemas/ForgotPass';
import { useUser } from '../api/usages/auth';
import Title from '../components/Title';

const useStyles = makeStyles({
  root: {
    maxWidth: '700px',
    margin: '0 auto',
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiInput-root': {
      marginBottom: '16px',
    },
    '& .Mui-error': {
      marginBottom: '12px',
    },
  },
  wrapper: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  header: {
    marginBottom: `28px`,
  },
  submitButton: {
    marginTop: `24px`,
  },
});

type ChangePasswordProps = {
  reauthentication?: boolean;
};
const ChangePassword = ({ reauthentication = false }: ChangePasswordProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const user = useUser();
  if (!user) {
    return null;
  }
  return (
    <Card className={cx.root}>
      <Box p={3} className={cx.wrapper}>
        <Box className={cx.header}>
          <Title variant="h3" title={t('auth.changePassword')} />
        </Box>
        <AutoForm
          model={{ phoneNumber: user.phoneNumber, email: user.email }}
          className={cx.root}
          schema={
            reauthentication ? ChangePasswordsSchema : ForgotPasswordsSchema
          }
          onSubmit={doPasswordUpdate}
        >
          <AutoField
            name="email"
            inputProps={{
              readOnly: user.email,
            }}
            label={t('profile.email')}
          />
          <ErrorField name="email" errorMessage={t('profile.emailError')} />
          {reauthentication && (
            <>
              <AutoField
                name="currentPassword"
                label={t('auth.currentPassword')}
              />
              <ErrorField name="currentPassword" />
            </>
          )}
          <AutoField name="password" label={t('auth.newPassword')} />
          <ErrorField name="password" />
          <AutoField
            name="confirmPassword"
            label={t('profile.confirmPassword')}
          />
          <ErrorField name="confirmPassword" />
          <Box>
            <SubmitField
              color="primary"
              label={t('common.submit')}
              className={cx.submitButton}
            />
          </Box>
        </AutoForm>
      </Box>
    </Card>
  );
};

export default ChangePassword;
