import { Timestamp } from '../../lib/firebase';
import { Doctor } from './Doctor';
import { Price } from './Payments';
import { Specialty } from './Specialty';

export enum BookingTypes {
  INITIAL = 'initial',
  FOLLOW_UP = 'followUp',
}

export enum BookingStatuses {
  OPENED = 'opened',
  GOING = 'going',
  FINISHED = 'finished',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export type Booking = {
  readonly id: string;
  doctorId: string;
  doctorDetails?: {
    specialty: Specialty;
    name: Doctor['name'];
    medTitle: Doctor['medTitle'];
  };
  patientId: string;
  complaint?: string;
  type: BookingTypes.INITIAL | BookingTypes.FOLLOW_UP;
  startDate: Timestamp;
  endDate: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  status:
    | BookingStatuses.OPENED
    | BookingStatuses.GOING
    | BookingStatuses.FINISHED
    | BookingStatuses.FAILED
    | BookingStatuses.CANCELED;
  price?: Price;
  rating?: number;
  visitedByDoctor?: boolean;
  visitedByPatient?: boolean;
  referralId?: string;
};

export type UnrealizedBooking = Booking & {
  canceledAt: Timestamp;
  status: BookingStatuses.CANCELED;
};

export type BookingWithFirebase = Omit<Booking, 'startDate'> & {
  startDate: any;
};

export enum LabOrRadioBookingStatus {
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  CANCELED = 'canceled',
}

type LabOrRadioBookingCommon = {
  serviceId: string;
  bookingId: string;
  referralId: string;
};

export type LabBookingRaw = LabOrRadioBookingCommon & { labId: string };
export type RadioBookingRaw = LabOrRadioBookingCommon & { radioId: string };

export type LabBooking = {
  readonly id: string;
  referralId: string;
  patientId: string;
  serviceId: string;
  labId: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  bookingId?: string;
  status:
    | LabOrRadioBookingStatus.DELIVERED
    | LabOrRadioBookingStatus.UNDELIVERED
    | LabOrRadioBookingStatus.CANCELED;
  pdfUrl?: string;
  note?: string;
};

export type RadioBooking = Omit<LabBooking, 'labId'> & { radioId: string };

export type Order = LabBooking &
  RadioBooking & { labId?: string; radioId?: string };
