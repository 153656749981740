import { Backdrop, Box, Card, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { cloneDeep, set } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-use-promise';
import {
  AutoField,
  AutoForm,
  ErrorField,
  SubmitField,
} from 'uniforms-material';

import { doCreateProfile } from '../api/actions/auth';
import * as navigation from '../api/actions/navigation';
import { showErrorToast, showInfoToast } from '../api/actions/uiControls';
import { CreateDoctorEmail } from '../api/schemas/CreateProfileDoctor';
import { useContextRole, useUser } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import ReauthWithPhoneDialog from '../components/ReauthWithPhoneDialog';
import SectionTitle from '../components/SectionTitle';
import Title from '../components/Title';
import RouteTypes from '../constants/routes';
import { functions } from '../lib/firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '0 auto',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: '16px',
      },
      '& .Mui-error': {
        marginBottom: '12px',
      },
    },
    submitButton: {
      marginTop: '24px',
    },
    header: {
      marginBottom: '32px',
    },
    spaceBottom: {
      marginBottom: '16px',
    },
    label: {
      marginBottom: '5px',
      marginTop: 0,
    },
    aboutField: {
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.error.main,
      },
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  }),
);

const CreateEmailLabRadio = () => {
  const { t } = useTranslation();
  const user = useUser();
  const role = useContextRole() || '';
  const cx = useStyles();
  const formRef = useRef(null);
  const [reAuthNeeded, setReauthNeeded] = useState<boolean>(false);
  const [model, setModel] = useState<any>({ email: user?.email });

  const [result, error, state] = usePromise(
    () =>
      functions.httpsCallable('checkIfEmailAvailable')({
        email: model?.email,
        phoneNumber: user?.phoneNumber,
        collection: `${role}s`,
      }),
    [model?.email],
  );

  const handleFieldChange = (field: string, value: any) => {
    const newModel = set(cloneDeep(model), field, value);
    setModel(newModel);
    return newModel;
  };

  const handleSubmit = (authData: {
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    const onLinkCredsErr = (e: any) => {
      if (e.code === 'auth/requires-recent-login') {
        setReauthNeeded(true);
      }
    };

    if (result?.data) {
      const updateProfile = {
        ...authData,
        testing: false,
      };
      doCreateProfile(
        updateProfile,
        role,
        () => navigation.replace(RouteTypes.PROFILE_CREATE),
        onLinkCredsErr,
      );
    } else {
      showErrorToast(t('common.emailExistError'));
    }
  };

  const closeReauth = () => {
    setReauthNeeded(false);
  };

  const onReauthenticated = () => {
    closeReauth();
    showInfoToast(t('common.tryResubmit'));
  };

  if (!user) {
    return (
      <LayoutDefault>
        <Card className={cx.root} />
      </LayoutDefault>
    );
  }

  if (error) {
    return null;
  }

  if (!state) {
    return (
      <Backdrop classes={{ root: cx.backdrop }} open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <LayoutDefault>
      {reAuthNeeded && (
        <ReauthWithPhoneDialog
          onClose={closeReauth}
          onReauthenticated={onReauthenticated}
        />
      )}
      <Card className={cx.root}>
        <Box p={3}>
          <Box className={cx.header}>
            <Title title={t('common.registration')} />
          </Box>

          <AutoForm
            ref={formRef}
            schema={CreateDoctorEmail}
            onSubmit={handleSubmit}
            model={model}
            onChange={handleFieldChange}
          >
            <SectionTitle title={t('profile.authData')} />
            <AutoField
              name="email"
              inputProps={{
                readOnly: user.email,
              }}
              label={t('profile.email')}
              helperText={
                user.emailVerified
                  ? '✅ ' + t('profile.emailVerified')
                  : t('profile.needEmailVerificationInfo')
              }
            />
            <ErrorField name="email" errorMessage={t('profile.emailError')} />
            {!user.email && (
              <>
                <AutoField name="password" label={t('profile.password')} />
                <ErrorField
                  name="password"
                  errorMessage={t('profile.passwordError')}
                />
                <AutoField
                  name="confirmPassword"
                  label={t('profile.confirmPassword')}
                />
                <ErrorField
                  name="confirmPassword"
                  errorMessage={t('profile.passwordConfirmError')}
                />
              </>
            )}
            <Box>
              <SubmitField
                color="primary"
                label={t('common.submit')}
                className={cx.submitButton}
              />
            </Box>
          </AutoForm>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default CreateEmailLabRadio;
