import { Query } from '../types';
import { Admin } from '../types/Admin';
import { useCollection, UseCollectionOptions } from './collection';

export function useAdmins(query?: Query, options?: UseCollectionOptions) {
  const { results, error, loading } = useCollection<Admin>(
    {
      collectionPath: 'admins',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    admins: results,
  };
}
