import { Plugins } from '@capacitor/core';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  dialogContent: {
    padding: 0,
  },
  iframe: {
    width: '100%',
    height: '90vh',
  },
});

interface Props extends ButtonProps {
  url: string;
}

function DownloadPDFBtn({ url, ...props }: Props) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const cx = useStyles();
  const { Browser } = Plugins;
  const isAndroid = Capacitor.platform === 'android';
  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);

  return (
    <>
      <Button
        onClick={
          isAndroid ? async () => await Browser.open({ url }) : openDialog
        }
        color="primary"
        variant="contained"
        {...props}
      >
        {t('admin.download')}
      </Button>
      <Dialog fullScreen onClose={closeDialog} open={dialogOpen} fullWidth>
        <DialogContent className={cx.dialogContent}>
          <iframe title="pdf-viewer" className={cx.iframe} src={url} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DownloadPDFBtn;
