import { Address } from '../../api/types/Lab';

/**
 * Changes `geolocation` address field from `GeoPoint` to `{ latitude: number, longitude: number }`
 *
 * This helper exist solely because latituge and longitude getters doesn't get called when serializing to JSON
 */
export const mapAddress = ({
  geolocation: { latitude, longitude },
  ...address
}: Required<Address>) => ({
  ...address,
  geolocation: { latitude, longitude },
});
