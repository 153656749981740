import {
  AppBar,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Query, UserRoleTypes } from '../../../api/types';
import {
  useContextRole,
  useDocument,
  usePrescriptions,
  useUserId,
} from '../../../api/usages';
import TabPanel from '../../../components/TabPanel';
import { isMobile } from '../../../lib/utils';
import BackButton from '../BackButton';
import DoctorDetailsScreenInfo from './DoctorDetailsScreenInfo';
import DoctorNotes from './DoctorVisitDetails/DoctorNotes';
import DoctorPrescription from './DoctorVisitDetails/DoctorPrescription';
import Referrals from './DoctorVisitDetails/Referrals';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#fafafa',
  },
  active: {
    color: '#ff00ff',
  },
  inActive: {
    color: 'black',
  },
});

interface DoctorVisitDetailsProps {
  bookingId: string;
}
// eslint-disable-next-line complexity
const DoctorVisitDetails = ({ bookingId }: DoctorVisitDetailsProps) => {
  const cx = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslation();
  const role = useContextRole();
  const userId = useUserId();
  const isPatient = role === UserRoleTypes.PATIENT;

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getStyle = (isActive: any) => {
    return !isActive ? cx.inActive : cx.active;
  };

  const query: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: isPatient ? 'patientId' : 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  const { result: booking, loading: bLoading, error: bErr } = useDocument<any>(
    {
      path: `bookings/${bookingId}`,
    },
    { skip: !bookingId },
  );
  const complaint = booking?.complaint;
  const {
    prescriptions,
    loading: presLoading,
    error: presErr,
  } = usePrescriptions(query);
  const patientId = booking?.patientId;

  if (presLoading || bLoading || booking === null) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (presErr || bErr) {
    return null;
  }

  return (
    <div className={cx.root}>
      <AppBar position="static" color="inherit" style={{ marginBottom: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          variant={isMobile ? 'scrollable' : 'fullWidth'}
        >
          <Tab
            className={getStyle(selectedTab === 0)}
            label={t('healthRecords.doctorNotes')}
          />
          <Tab
            className={getStyle(selectedTab === 1)}
            label={t('healthRecords.prescription')}
            disabled={prescriptions.length === 0}
          />
          <Tab
            className={getStyle(selectedTab === 2)}
            label={t('healthRecords.referrals')}
          />
        </Tabs>
      </AppBar>
      {selectedTab !== 2 && <DoctorDetailsScreenInfo booking={booking} />}
      <TabPanel value={selectedTab} index={0}>
        <DoctorNotes query={query} complaint={complaint} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <DoctorPrescription prescriptions={prescriptions} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <Referrals bookingId={bookingId} patientId={patientId} />
      </TabPanel>

      {isPatient && <BackButton />}
    </div>
  );
};

export default DoctorVisitDetails;
