import { createBridge, JSONSchema } from '../../lib/utils';

const billingForm: JSONSchema = {
  title: 'Billing form',
  type: 'object',
  properties: {
    apartment: { type: 'string', minLength: 1 },
    building: { type: 'string', minLength: 1 },
    city: { type: 'string', minLength: 1 },
    country: { type: 'string', minLength: 1 },
    email: {
      type: 'string',
      pattern:
        '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
      minLength: 1,
    },
    firstName: { type: 'string', minLength: 1 },
    floor: { type: 'string', minLength: 1 },
    lastName: { type: 'string', minLength: 1 },
    phoneNumber: {
      type: 'string',
      pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
      minLength: 1,
    },
    postalCode: { type: 'string', minLength: 1 },
    street: { type: 'string', minLength: 1 },
  },
  required: [
    'apartment',
    'building',
    'city',
    'country',
    'email',
    'firstName',
    'floor',
    'lastName',
    'phoneNumber',
    'street',
  ],
};

const payoutForm: JSONSchema = {
  title: 'Payout form',
  type: 'object',
  properties: {
    amount: { type: 'number', minimum: 6 },
    msisdn: {
      /* Kindly be informed that the Egyptian numbers consist of main 11 digits and may start with [ 010 | 011 | 012 ] ********.
       * Vodafone cash wallet numbers starts with 010
       * Etisalat cash wallet numbers starts with 011
       * Orange cash wallet numbers starts with 012
       * */
      type: 'string',
      minLength: 11,
      maxLength: 11,
      pattern: '^01[0-2][0-9]{8}',
    },
    email: { type: 'string', minLength: 1 },
    firstName: { type: 'string', minLength: 1 },
    lastName: { type: 'string', minLength: 1 },
    issuer: {
      type: 'string',
      enum: ['AMAN', 'VODAFONE', 'ETISALAT', 'ORANGE'],
    },
  },
  required: ['amount', 'msisdn', 'issuer', 'email', 'firstName', 'lastName'],
};

const reauthPassword: JSONSchema = {
  title: 'Reauth with password',
  type: 'object',
  properties: {
    password: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['password'],
};

export const reauthPasswordSchema = createBridge(reauthPassword);
export const billingFormSchema = createBridge(billingForm);
export const payoutFormSchema = createBridge(payoutForm);
