import { Paper, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TabPanel from '../TabPanel';
import PastBookingsListView from './PastBookingsListView';
import UpcomingBookingsListView from './UpcomingBookingsListView';

const BookingListForPatient = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = React.useState(1);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          <Tab label={t('booking.past')} />
          <Tab label={t('booking.upcoming')} />
        </Tabs>
      </Paper>
      <TabPanel value={currentTab} index={0}>
        <PastBookingsListView />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <UpcomingBookingsListView />
      </TabPanel>
    </>
  );
};

export default BookingListForPatient;
