import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  Badge,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles({
  section: {
    '& .MuiIconButton-root': {
      margin: '0 auto',
    },
  },
  label: {
    paddingRight: 15,
  },
  list: {
    flexDirection: 'column',
  },
});

interface SectionProps {
  label: string;
  count?: number;
  children: React.ReactNode;
}

const DoctorNotesSection = ({ label, count, children }: SectionProps) => {
  const cx = useStyles();

  return (
    <Accordion className={cx.section} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Badge badgeContent={count} color="primary">
          <Typography component="div" variant="h5" className={cx.label}>
            {label}
          </Typography>
        </Badge>
      </AccordionSummary>
      <AccordionDetails className={cx.list}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default DoctorNotesSection;
