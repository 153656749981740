import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useProfile, useUser, useUserId } from '../api/usages/auth';
import { useVerifiedDoctors } from '../api/usages/doctors';
import FavouriteDoctorsTable from '../components/Doctors/FavouriteDoctorsTable';
import LayoutDefault from '../components/LayoutDefault';

const Favourites = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile: { [index: string]: any } | null = useProfile();
  let favouriteDoctorsIds = get(profile, 'favouriteDoctorsIds', []);
  const myId = useUserId();

  // A doctor with the same email address as a patient shouldn't be available
  favouriteDoctorsIds = favouriteDoctorsIds.filter((id: string) => id !== myId);

  const onChooseDoctor = (doctor: any) => {
    history.push('/doctor/' + doctor.id);
  };

  return (
    <LayoutDefault>
      {favouriteDoctorsIds.length ? (
        <>
          <h1>{t('favourites.yourFavouriteDoctors')}</h1>
          <FavouriteDoctorsTable
            onChooseDoctor={onChooseDoctor}
            favouriteDoctorsIds={favouriteDoctorsIds}
            testing={!!profile?.testing}
          />
        </>
      ) : (
        <h1>{t('favourites.errorNoFavouriteDoctors')}</h1>
      )}
    </LayoutDefault>
  );
};

export default Favourites;
