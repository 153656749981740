import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import BackButton from '../../components/BackButton';
import LabsList from '../../components/LabRadio/LabsList';
import LayoutDefault from '../../components/LayoutDefault';
import Title from '../../components/Title';

const Labs = () => {
  const { t } = useTranslation();
  const {
    state: { bookingId, referralId },
  } = useLocation();

  return (
    <LayoutDefault>
      <Title title={t('labs.labs')} />
      <LabsList bookingId={bookingId} referralId={referralId} />
      <BackButton label={t('labs.backToSumm')} />
    </LayoutDefault>
  );
};

export default Labs;
