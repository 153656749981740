import { Backdrop, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Query } from '../api/types';
import { useDocument, useUserId, useBookings } from '../api/usages';
import FollowUpBooking from '../components/FollowUpBooking';
import LayoutDefault from '../components/LayoutDefault';
import BookingFinished from '../components/SetBooking/BookingFinished';

const FollowUpFromLink = () => {
  const { t } = useTranslation();
  const bookingId = window.location.href.split('/follow-up/')[1];
  const userId = useUserId();

  const { result: booking, loading, error } = useDocument(
    {
      path: `bookings/${bookingId}`,
    },
    { skip: !bookingId },
  );
  const query: Query = [
    {
      fieldPath: 'prevBookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: userId,
    },
  ];

  const {
    error: prevError,
    loading: prevLoading,
    bookings: prevBookings,
  } = useBookings(query, { skip: !userId });

  if (error || prevError) {
    return <LayoutDefault />;
  }

  if (loading || prevLoading) {
    return (
      <LayoutDefault>
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      </LayoutDefault>
    );
  }

  // User is not logged in or user wasn't a patient in a previous booking.
  if (!userId || (booking as any).patientId !== userId) {
    return (
      <LayoutDefault>
        <h1>{t('booking.errorBookingNotFound')}</h1>
      </LayoutDefault>
    );
  }

  // This booking is already a follow-up visit.
  if ((booking as any).type === 'followUp') {
    return (
      <LayoutDefault>
        <h1>{t('booking.errorBookingIsAlreadyFollowUp')}</h1>
      </LayoutDefault>
    );
  }

  // Follow-up from this visit is already created.
  if (prevBookings.length > 0) {
    return (
      <LayoutDefault>
        <BookingFinished />
      </LayoutDefault>
    );
  }

  const tooLateToCreateFollowUp = (booking: any) => {
    const now = moment();
    const bookingDate = moment(booking.startDate.toDate());
    const twoWeeksFromBookingDate = moment(bookingDate)
      .add(14, 'days')
      .endOf('day');

    return !now.isBetween(bookingDate, twoWeeksFromBookingDate);
  };

  if (tooLateToCreateFollowUp(booking)) {
    return (
      <LayoutDefault>
        <h1>{t('booking.errorTooLateToCreateFollowUp')}</h1>
      </LayoutDefault>
    );
  }

  return (
    <LayoutDefault>
      <FollowUpBooking
        doctorId={(booking as any).doctorId}
        bookingId={bookingId}
      />
    </LayoutDefault>
  );
};

export default FollowUpFromLink;
