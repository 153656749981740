import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  List,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FullQuery,
  SimpleQuery,
  UserRoleTypes,
  LabOrRadioBookingStatus,
  Order,
} from '../../api/types';
import {
  useCollection,
  UseCollectionOptions,
  UseCollectionParams,
} from '../../api/usages/collection';
import OrderListItem from './OrderListItem';

type OrdersListProps = {
  userId: string | null;
  role: UserRoleTypes;
  status: string;
};

// eslint-disable-next-line complexity
const OrdersList = ({ userId, role, status }: OrdersListProps) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(20);

  const isLab = role === UserRoleTypes.LAB;
  const isDelivered = status === LabOrRadioBookingStatus.DELIVERED;

  const fieldToOrderBy = isDelivered ? 'updatedAt' : 'createdAt';
  const orderDirection = isDelivered ? 'desc' : 'asc';

  const query: SimpleQuery = [
    {
      fieldPath: isLab ? 'labId' : 'radioId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: '==',
      value: status,
    },
  ];

  const fullQuery: FullQuery = {
    where: query,
    orderBy: [fieldToOrderBy, orderDirection],
    limit,
  };

  const params: UseCollectionParams = {
    collectionPath: isLab ? 'labBookings' : 'radioBookings',
    query: fullQuery,
  };

  const options: UseCollectionOptions = {
    skip: !userId || !role,
  };

  const { results: orders, error, loading } = useCollection<Order>(
    params,
    options,
  );

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (orders.length === 0) {
    return <h1>{t('orders.emptyOrders', { status })}</h1>;
  }

  const handleLoadMore = () => {
    setLimit(limit => limit + 20);
  };

  const canLoadMore = orders.length === limit;

  return (
    <>
      <List>
        {orders.map((order: Order) => (
          <OrderListItem
            key={order.id}
            order={order}
            withDownloadButton={isDelivered}
            withUploadButton={!isDelivered}
          />
        ))}
      </List>
      {canLoadMore && (
        <Grid container justify="center" style={{ marginTop: '15px' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
            >
              {t('booking.loadMore')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OrdersList;
