import { functions } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { i18next } from '../../locales';
import { UserRoleTypes } from '../types';

type SendNotificationParams = {
  toTopic: 'ALL' | UserRoleTypes;
  inLanguage: string;
  title?: string;
  body: string;
};

export const sendNotificationAction = createAction({
  type: 'sendNotification',
  async callback({
    toTopic = 'ALL',
    inLanguage = i18next.language,
    title = '',
    body,
  }: SendNotificationParams) {
    await functions.httpsCallable('sendNotification')({
      toTopic,
      inLanguage,
      title,
      body,
    });
  },
});
