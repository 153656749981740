import {
  auth,
  db,
  mergeData,
  deleteData,
  timestampNow,
} from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { DoctorReferralRaw } from '../types';

export async function upsertDoctorReferralCallback({
  id: referralId,
  bookingId,
  patientId,
  specialtyId = null,
  medTitle = null,
  note = '',
  status,
}: DoctorReferralRaw) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const doctorReferralsRef = db.collection('doctorReferrals');

  const referralData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    specialtyId,
    medTitle,
    note,
    status,
    updatedAt: timestampNow(),
  };

  if (!referralId) {
    return doctorReferralsRef.add({
      ...referralData,
      createdAt: timestampNow(),
    });
  }

  return mergeData(`doctorReferrals/${referralId}`, referralData);
}

export const upsertDoctorReferral = createAction({
  type: 'upsertDoctorReferral',
  callback: upsertDoctorReferralCallback,
});

export async function deleteDoctorReferralCallback(referralId: string) {
  return deleteData(`doctorReferrals/${referralId}`);
}

export const deleteDoctorReferral = createAction({
  type: 'deleteDoctorReferral',
  callback: deleteDoctorReferralCallback,
});
