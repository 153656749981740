import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  SubmitField,
  AutoField,
  ErrorField,
} from 'uniforms-material';

import { doCreateProfile } from '../api/actions/auth';
import { showInfoToast } from '../api/actions/uiControls';
import {
  CreatePatientProfileWithAuth,
  CreatePatientProfileNoAuth,
} from '../api/schemas/CreateProfile';
import { UserRoleTypes } from '../api/types';
import { useUser } from '../api/usages';
import CustomSelectField from '../components/CustomSelectField';
import PhoneNumberField from '../components/PhoneNumberField';
import PhoneVerification from '../components/PhoneVerification';
import UploadAreaUniformField from '../components/UploadAreaUniformField';
import { genderOptions } from '../constants/ConfigProfile';
import { goToPatientsLandingPage } from '../lib/goToRouteHelpers';
import { momentToFormat } from '../lib/utils';
import { i18next } from '../locales';
import ReauthWithPhoneDialog from './ReauthWithPhoneDialog';

const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      margin: `8px 0`,
    },
  }),
);
type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

interface Props {
  /**
   * if true, SignUpForm will omit default logic and do nothing.
   * if was passed an function, this function will be called instead of `default logic`
   * if omitted or undefined will be executed `default logic`.
   * `default logic` in most cases will redirect the user to his home
   * or to place where need fill the profile if is missing.
   */
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
}

const SignUpForm = ({ onAuthorized }: Props) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const user = useUser();
  const lang = i18next.language;
  const [reAuthNeeded, setReauthNeeded] = useState<boolean>(false);

  if (!onAuthorized) {
    onAuthorized = goToPatientsLandingPage;
  }

  const handleSubmit = (profile: object) => {
    const onLinkCredsErr = (e: any) => {
      if (e.code === 'auth/requires-recent-login') {
        setReauthNeeded(true);
      }
    };

    const birthday = get(profile, 'birthday', '');
    const convertedBirthday = momentToFormat(lang, birthday, '');
    const updatedProfile = {
      ...profile,
      birthday: convertedBirthday,
    };
    doCreateProfile(
      updatedProfile,
      UserRoleTypes.PATIENT,
      onAuthorized,
      onLinkCredsErr,
    );
  };

  const closeReauth = () => {
    setReauthNeeded(false);
  };

  const onReauthenticated = () => {
    closeReauth();
    showInfoToast(t('common.tryResubmit'));
  };

  if (!user) {
    return (
      <PhoneVerification
        profileType={UserRoleTypes.PATIENT}
        onAuthorized={onAuthorized}
      />
    );
  }

  const schema = user.email
    ? CreatePatientProfileNoAuth
    : CreatePatientProfileWithAuth;

  return (
    <>
      {reAuthNeeded && (
        <ReauthWithPhoneDialog
          onClose={closeReauth}
          onReauthenticated={onReauthenticated}
        />
      )}
      <AutoForm
        model={{ phoneNumber: user.phoneNumber, email: user.email }}
        schema={schema}
        onSubmit={handleSubmit}
      >
        <AutoField
          name="email"
          inputProps={{
            readOnly: user.email,
          }}
          label={t('profile.email')}
          helperText={
            user.emailVerified
              ? '✅ ' + t('profile.emailVerified')
              : t('profile.needEmailVerificationInfo')
          }
        />
        <ErrorField name="email" errorMessage={t('profile.emailError')} />
        {!user.email && (
          <>
            <AutoField name="password" label={t('profile.password')} />
            <ErrorField name="password" />
            <AutoField
              name="confirmPassword"
              label={t('profile.confirmPassword')}
            />
            <ErrorField name="confirmPassword" />
          </>
        )}

        <AutoField name="name" label={t('profile.name')} />
        <ErrorField name="name" />
        <PhoneNumberField
          name="phoneNumber"
          label={t('profile.phoneNumber')}
          readOnly
        />
        <AutoField
          name="birthday"
          label={t('profile.birthday')}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <ErrorField name="birthday" />
        <CustomSelectField
          name="gender"
          label={t('profile.gender')}
          options={genderOptions}
          fullWidth
        />
        <ErrorField name="gender" />
        <UploadAreaUniformField
          name="photoURL"
          text={t(`profile.uploadPhoto`)}
          filesLimit={1}
          acceptedFiles={['image/*']}
        />
        <ErrorField name="photoURL" />
        <Box>
          <SubmitField
            className={cx.submit}
            color="primary"
            label={t('common.submit')}
          />
        </Box>
      </AutoForm>
    </>
  );
};

export default SignUpForm;
