// Random Apps constants
export const __DEV__ = process.env.NODE_ENV !== 'production';

// to easy access
window.__DEV__ = __DEV__;
// useful for developers to temporarily share some method for debugging in the browser console
window._devs = {
  env: process.env,
};

export enum AvailableLanguages {
  EN = 'en',
  AR = 'ar',
  //  XX = 'xx',
}

export enum RuntimeTypes {
  MobileApp = 'mobileApp',
  Browser = 'browser',
}

export const CURRENT_RUNTIME: RuntimeTypes = window.Capacitor
  ? RuntimeTypes.MobileApp
  : RuntimeTypes.Browser;

export const GOOGLE_MAPS_SCRIPT_URL =
  'https://maps.googleapis.com/maps/api/js?libraries=places&key=';

export const APP_URL =
  process.env.REACT_APP_URL || process.env.PUBLIC_URL || '';

export const PUBLIC_URL = window.Capacitor
  ? window.origin
  : process.env.PUBLIC_URL;

// (1 - fee) is multiplier to receive the net amount
export const fee = 0.3728;
