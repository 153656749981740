import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface PanelProps {
  summary: string;
  children: React.ReactNode;
}

const Panel = ({ summary, children }: PanelProps) => {
  const cx = useStyles();

  return (
    <Accordion className={cx.details}>
      <AccordionSummary
        disableRipple={false}
        disableTouchRipple={false}
        expandIcon={<ExpandMore />}
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails className={cx.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Panel;
