import React, { useCallback } from 'react';

import { doAvatarUpdate } from '../api/actions/auth';
import Avatar from '../components/Avatar';
import { Entry, getLoggedInStorageRef } from '../lib/firebase';
import { UploadArea } from './UploadArea';

type UploadAvatarProps = {
  name?: string;
  photoURL?: string;
};

const UploadAvatar = ({ name, photoURL }: UploadAvatarProps) => {
  const handleUpdateAvatar = useCallback(
    photoURL => doAvatarUpdate({ photoURL }),
    [],
  );

  const initEntries: Entry[] = photoURL ? [{ url: photoURL }] : [];
  return (
    <UploadArea
      initEntries={initEntries}
      filesLimit={1}
      acceptedFiles={['image/*']}
      alwaysReplace
      storageRef={getLoggedInStorageRef('avatar')}
      hideDefaultContentForPreviews
      hideFileNames
      onUploaded={handleUpdateAvatar}
      disabledDelete
    >
      <Avatar photoURL={photoURL} name={name} />
    </UploadArea>
  );
};

export default UploadAvatar;
