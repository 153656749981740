import { makeStyles, createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../../api/types';
import { useContextRole } from '../../api/usages';
import { useVerificationStatus } from '../../api/usages/utils/useVerificationStatus';

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      color: 'red',
      fontWeight: 'bold',
      marginBottom: '16px',
      maxWidth: '90%',
    },
  }),
);

const VerificationStatusAlert = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  const role = useContextRole();

  const isVerified = useVerificationStatus();

  if (isVerified) {
    return null;
  }
  return (
    <Box flexGrow="1" display="flex" justifyContent="center">
      <Typography variant="h5" className={cx.text}>
        {t(
          role === UserRoleTypes.PATIENT
            ? 'common.accountDeactivated'
            : 'common.accountNotActivated',
        )}
      </Typography>
    </Box>
  );
};

export default VerificationStatusAlert;
