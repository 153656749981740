import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Typography variant="h5" color="primary" paragraph>
        {t(`help.help_about_title_2`)}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        <a
          href="mailto:support@shoofdoctor.com"
          style={{ textDecoration: 'none' }}
        >
          {t(`help.help_about_title_2_text_1`)}
        </a>
      </Typography>
    </Grid>
  );
};

export default Contact;
