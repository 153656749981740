import { Grid } from '@material-ui/core';
import React from 'react';

import { privacyCounts, fillArray } from '../../constants/ConfigHelp';
import { Point } from './common/Helpers';

const PrivacyPolicy = () => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Point
        section="privacyPolicy"
        noTitle
        bold
        texts={['admission_1', 'admission_2']}
      />
      {Object.entries(privacyCounts).map(([key, value]) => (
        <Point
          key={key}
          section="privacyPolicy"
          title={`title_${key}`}
          texts={fillArray(value, key)}
        />
      ))}
    </Grid>
  );
};

export default PrivacyPolicy;
