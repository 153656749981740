import React from 'react';

import { UserRoleTypes } from '../api/types';
import { useContextRole } from '../api/usages';
import BookingListForDoctor from '../components/BookingList/BookingListForDoctor';
import BookingListForPatient from '../components/BookingList/BookingListForPatient';
import LayoutDefault from '../components/LayoutDefault';

const Bookings = () => {
  const userRole = useContextRole();

  return (
    <LayoutDefault>
      {userRole === UserRoleTypes.PATIENT ? (
        <BookingListForPatient />
      ) : (
        <BookingListForDoctor />
      )}
    </LayoutDefault>
  );
};

export default Bookings;
