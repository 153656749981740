import Grid from '@material-ui/core/Grid';
import React from 'react';

import { ExtendedFileEntry, FileEntry } from '../../lib/firebase/storage';
import { PreviewItem } from './PreviewItem';

type PreviewList = {
  fileEntries: FileEntry[];
  onDelete: (index: number, fileEntry: FileEntry) => void;
  hideFileNames?: boolean;
  disabled: boolean;
  disabledDelete?: boolean;
};

const PreviewList = ({
  fileEntries,
  hideFileNames = false,
  onDelete,
  disabled,
  disabledDelete,
}: PreviewList) => {
  return (
    <Grid spacing={8} container>
      {fileEntries.map((fileEntry, i) => {
        return (
          <PreviewItem
            key={`${
              (fileEntry as ExtendedFileEntry).file?.name ?? 'file'
            }-${i}`}
            index={i}
            fileEntry={fileEntry}
            disabledDelete={disabledDelete}
            onDelete={onDelete}
            hideFileNames={hideFileNames}
            disabled={disabled}
          />
        );
      })}
    </Grid>
  );
};

export default PreviewList;
