import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Patient, Query, UserRoleTypes } from '../../../api/types';
import { BookingWithFirebase } from '../../../api/types/Booking';
import { Timestamp } from '../../../api/types/Timestamp';
import {
  useContextRole,
  useDocument,
  usePrescriptions,
  useUserId,
} from '../../../api/usages';
import { i18next } from '../../../locales';
import Title from '../../Title';
import BackButton from '../BackButton';
import DoctorInfo from './DoctorInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    secRoot: {
      width: '90%',
      margin: 'auto',
      padding: theme.spacing(3),
    },
    fields: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    value: {
      display: 'flex',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      width: '80%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '150px',
      },
      whiteSpace: 'pre-line',
      textAlign: 'left',
    },
    spacing: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    text: {
      whiteSpace: 'pre-line',
      textAlign: 'left',
      width: '20%',
    },
    label: {
      width: '20%',
      textAlign: 'left',
    },
  }),
);

interface VisitInfoPanelProps {
  label: string;
  value: string;
}

const VisitInfoPanel = ({ label, value }: VisitInfoPanelProps) => {
  const cx = useStyles();
  return (
    <div className={cx.fields}>
      <Title variant="caption" title={label} className={cx.label} />
      <Title variant="caption" title={value ?? ''} className={cx.value} />
    </div>
  );
};

interface PrescriptionDetailsProps {
  bookingId: string;
  disableButton?: boolean;
}
const PrescriptionDetails = ({
  bookingId,
  disableButton,
}: PrescriptionDetailsProps) => {
  const cx = useStyles();
  const { t } = useTranslation();

  const role = useContextRole();
  const userId = useUserId();
  const isPatient = role === UserRoleTypes.PATIENT;

  const query: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: isPatient ? 'patientId' : 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  const {
    prescriptions,
    error: presErr,
    loading: presLoading,
  } = usePrescriptions(query);
  const prescription = prescriptions[0]?.content ?? '';

  const {
    result: booking,
    error: bookingErr,
    loading: bookingLoading,
  } = useDocument<BookingWithFirebase>(
    {
      path: `bookings/${bookingId}`,
    },
    { skip: !bookingId },
  );

  const patientId = booking?.patientId;

  const {
    result: patient,
    error: patientErr,
    loading: patientLoading,
  } = useDocument<Patient>(
    {
      path: `patients/${patientId}`,
    },
    { skip: !patientId },
  );

  const patientName = patient?.name;
  const doctorId = booking?.doctorId;
  const type = booking?.type;
  const complaint = booking?.complaint;
  const startTime = ((booking?.startDate as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18next.language], {
      dateStyle: 'medium',
      timeStyle: 'short',
    });

  const weekday = ((booking?.startDate as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18next.language], {
      weekday: 'long',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const dateToDisplay = `${weekday}, ${startTime}`;

  if (
    presErr ||
    presLoading ||
    bookingErr ||
    bookingLoading ||
    patientErr ||
    patientLoading
  ) {
    return null;
  }

  return (
    <Box className={cx.root}>
      <Paper className={cx.secRoot}>
        <Typography className={cx.spacing} variant="h6">
          {t('healthRecords.prescriptionFor', { patientName })}
        </Typography>
        <Divider />
        <DoctorInfo doctorId={doctorId ?? ''} callSummaryView={disableButton} />
        <Divider />
        <VisitInfoPanel
          label={t('booking.dateAndTime')}
          value={dateToDisplay}
        />
        <VisitInfoPanel
          label={t('booking.serviceType')}
          value={t('booking.type_' + type)}
        />
        <VisitInfoPanel
          label={t('booking.complaint')}
          value={complaint ?? ''}
        />
        <Divider />
        <div className={cx.fields}>
          {' '}
          <Title
            variant="caption"
            className={cx.text}
            title={t('healthRecords.prescription')}
          />
          <Typography variant="body1" className={cx.value}>
            {' '}
            {prescription ?? ''}
          </Typography>
        </div>
        {isPatient && !disableButton && <BackButton />}
      </Paper>
    </Box>
  );
};

export default PrescriptionDetails;
