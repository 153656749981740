import { createBridge, JSONSchema } from '../../lib/utils';

const emailSchema: JSONSchema = {
  title: 'ForgetPass - email',
  type: 'object',
  properties: {
    email: { type: 'string' },
  },
  required: ['email'],
};
const passwordsSchema: JSONSchema = {
  title: 'ForgetPass - pass',
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
    confirmPassword: {
      const: {
        $data: '1/password',
      },
      type: 'string',
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'password', 'confirmPassword'],
};

export const ForgotPassByEmailSchema = createBridge(emailSchema);
export const ForgotPasswordsSchema = createBridge(passwordsSchema);
