import React from 'react';

interface VideoContextType {
  state: boolean;
  handleDialog: () => void;
  bookingId: string;
}
const VideoEndContext = React.createContext({} as VideoContextType);

export default VideoEndContext;
