import { Timestamp } from '../../lib/firebase';
import { Price } from './Payments';
import { Specialty } from './Specialty';

type Currency = 'EGP';

export type Doctor = {
  readonly id: string;
  photoURL?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  name: {
    en: string;
    ar?: string;
  };

  nationality: string;
  address: string;
  price?: Price;
  rating: number;
  email?: string;
  idNumber: number;
  idOrPassportPhoto?: string;
  specialtyId: string;
  arabicProficiency: string;
  englishProficiency: string;
  frenchProficiency: string;
  resellerCode?: string;
  medTitle: string;
  about?: {
    en: string;
    ar?: string;
  };
  yearsOfExperience?: number;
  verified?: boolean;
  testing: boolean;
};

export type DoctorWithSpecialty = Omit<Doctor, 'specialtyId'> & {
  specialty: Specialty;
};

export function instanceOfADoctor(object: any): object is Doctor {
  return (object as Doctor)?.medTitle !== undefined;
}
