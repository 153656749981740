import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import { TimePicker } from '@material-ui/pickers';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      justifyContent: 'center',
      '& input': {
        'text-align': 'center',
        padding: '14px 0',
      },
    },
    wrapper: {
      flex: 1,
      textAlign: 'center',
      padding: '0 8px',
    },
  }),
);

const DayRange = ({
  range: initDayRange,
  index,
  timeslot,
  asRepeated,
  error,
  initStartDate,
  initEndDate,
  changeRange,
  deleteRange,
  isPast,
  fromIndex,
}: any) => {
  const cx = useStyles();
  let startDate = get(initDayRange, 'startDate', initStartDate);
  let endDate = get(initDayRange, 'endDate', initEndDate);

  if (startDate.toDate) {
    startDate = moment(startDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
  }

  if (endDate.toDate) {
    endDate = moment(endDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
  }
  const handleStartTimeChange = (value: any) => {
    const dateMoment = moment(value);
    if (dateMoment.isBefore(endDate)) {
      changeRange(
        {
          startDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
          endDate,
        },
        index,
      );
    } else {
      changeRange(
        {
          startDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
        },
        index,
      );
    }
  };
  const handleEndTimeChange = (value: any) => {
    const dateMoment = moment(value);
    if (dateMoment.isAfter(startDate)) {
      changeRange(
        {
          startDate,
          endDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
        },
        index,
      );
    } else {
      changeRange(
        {
          startDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dateMoment.format('YYYY-MM-DDTHH:mm:ss'),
        },
        index,
      );
    }
  };
  return (
    <>
      <Box className={cx.root}>
        <Box className={cx.wrapper}>
          <TimePicker
            label={'start time'}
            value={startDate}
            minutesStep={timeslot}
            onChange={handleStartTimeChange}
            ampm
            disabled={asRepeated}
            inputVariant="outlined"
            error={error}
          />
        </Box>
        <Box className={cx.wrapper}>
          <TimePicker
            label={'end time'}
            value={endDate}
            minutesStep={timeslot}
            onChange={handleEndTimeChange}
            ampm
            disabled={asRepeated}
            inputVariant="outlined"
            error={error}
          />
        </Box>
        <Box flex="0 0 50px">
          {index !== 0 && (
            <IconButton
              aria-label="delete"
              onClick={() => deleteRange(index)}
              disabled={asRepeated || (!asRepeated && !fromIndex && isPast)}
            >
              <Delete fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DayRange;
