import { Backdrop, CircularProgress } from '@material-ui/core';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm } from 'uniforms-material';

import { addNewHeight, addNewWeight } from '../../api/actions/healthRecords';
import {
  HealthRecordsFormSchemaHeight,
  HealthRecordsFormSchemaWeight,
} from '../../api/schemas/HealthRecordsForm';
import { Patient, RecordType } from '../../api/types';
import { useDocument } from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import { calcAge } from '../../lib/utils';
import { i18next } from '../../locales';
import DeliveredOrdersList from './DeliveredOrdersList';
import DoctorVisit from './DoctorVisits';
import Fields from './Fields';
import Panel from './Panel';
import PrescriptionList from './Prescriptions';
import TableHistory from './Table/TableHistory';

interface HealthRecordsFormProps {
  profile: Patient;
  readonly?: boolean;
  patientId: string;
}

export default function HealthRecordsForm({
  profile,
  readonly = false,
  patientId,
}: HealthRecordsFormProps) {
  const { t } = useTranslation();

  const { result: healthRecords, error, loading } = useDocument<any>(
    {
      path: `healthRecords/${patientId}`,
    },
    {
      skip: !patientId,
    },
  );
  const lang = i18next.language;

  const fetchedBirthday = get(profile, 'birthday', '');
  // Temp fix to weid date conversion> Needs additional investigation as it is global.
  moment.locale('en');
  const age = calcAge(fetchedBirthday);
  moment.locale(lang);

  const currentTimestamp = Timestamp.fromMillis(moment.now());

  const weights = get(healthRecords, 'weights', { values: [], dates: [] });
  const heights = get(healthRecords, 'heights', { values: [], dates: [] });

  const model = {
    ...profile,
    weights: 0,
    heights: 0,
  };
  const handleSubmitWeight = (values: object) => {
    const weightValue: number = get(values, 'weights', 0);

    addNewWeight({ values: weightValue, dates: currentTimestamp });
  };

  const handleSubmitHeight = (values: object) => {
    const heightValue: number = get(values, 'heights', 0);

    addNewHeight({ values: heightValue, dates: currentTimestamp });
  };

  if (loading || error) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <>
      <Panel summary={t('healthRecords.weight')}>
        {!readonly ? (
          <AutoForm
            schema={HealthRecordsFormSchemaWeight}
            model={model}
            disabled={loading}
            onSubmit={(profile: object) => handleSubmitWeight(profile)}
          >
            <Fields
              label={t('healthRecords.newWeight')}
              name="weights"
              unit="kg"
            />
          </AutoForm>
        ) : null}
        <TableHistory
          type={RecordType.WEIGHT}
          rows={weights}
          unit="kg"
          readonly={readonly}
        />
      </Panel>

      <Panel summary={t('healthRecords.height')}>
        {!readonly ? (
          <AutoForm
            schema={HealthRecordsFormSchemaHeight}
            model={model}
            onSubmit={(profile: object) => handleSubmitHeight(profile)}
          >
            <Fields
              label={t('healthRecords.newHeight')}
              name="heights"
              unit="cm"
            />
          </AutoForm>
        ) : null}
        <TableHistory
          type={RecordType.HEIGHT}
          rows={heights}
          unit="cm"
          readonly={readonly}
        />
      </Panel>

      <Panel summary={t('healthRecords.age')}>{age}</Panel>
      <Panel summary={t('healthRecords.doctorVisits')}>
        <DoctorVisit patientId={patientId} />
      </Panel>
      <Panel summary={t('healthRecords.prescriptions')}>
        <PrescriptionList patientId={patientId} />
      </Panel>
      <Panel summary={t('healthRecords.labTests')}>
        <DeliveredOrdersList
          patientId={patientId}
          collectionPath={'labBookings'}
        />
      </Panel>
      <Panel summary={t('healthRecords.radiology')}>
        <DeliveredOrdersList
          patientId={patientId}
          collectionPath={'radioBookings'}
        />
      </Panel>
    </>
  );
}
