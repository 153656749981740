import { Card, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import SignInForm from '../components/SignInForm';
import Title from '../components/Title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: '40px auto 0',
      '& .MuiTextField-root': {
        width: '100%',
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const SignIn = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <Box className={cx.header}>
            <Title title={t('common.signIn')} />
          </Box>
          <SignInForm profileType={UserRoleTypes.PATIENT} />
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default SignIn;
