import { Card, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import PhoneVerification from '../components/PhoneVerification';
import Title from '../components/Title';
import { goToPatientsLandingPage } from '../lib/goToRouteHelpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: '40px auto 0',
      '& .MuiTextField-root': {
        width: '100%',
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    warning: {
      marginBottom: theme.spacing(2),
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
  }),
);

const SignUp = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <Box className={cx.header}>
            <Title title={t('common.registration')} />
          </Box>
          <Typography
            variant="body2"
            color="textPrimary"
            className={cx.warning}
          >
            <WarningIcon fontSize="small" className={cx.warningIcon} />
            {t('singUp.singUpPhoneWarning')}
          </Typography>
          <PhoneVerification
            profileType={UserRoleTypes.PATIENT}
            onAuthorized={goToPatientsLandingPage}
          />
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default SignUp;
