import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LayoutDefault from '../components/LayoutDefault';
import Scheduler from '../components/Scheduler';

const Schedule = () => {
  const { t } = useTranslation();
  return (
    <LayoutDefault>
      <h1>{t('schedule.availability')}</h1>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Scheduler />
      </MuiPickersUtilsProvider>
    </LayoutDefault>
  );
};

export default Schedule;
