import { Card, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { UserRoleTypes } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import PhoneVerification from '../components/PhoneVerification';
import Title from '../components/Title';
import { goToRadiosLandingPage } from '../lib/goToRouteHelpers';
import { getI18nOptions } from '../lib/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: '40px auto 0',
      '& .MuiTextField-root': {
        width: '100%',
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    warning: {
      marginBottom: theme.spacing(2),
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
  }),
);

const SignUpRadio = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  const location = useLocation();
  const options = getI18nOptions(location.pathname);

  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <Box className={cx.header}>
            <Title title={t('common.registration', options)} />
          </Box>
          <Typography
            variant="body2"
            color="textPrimary"
            className={cx.warning}
          >
            <WarningIcon fontSize="small" className={cx.warningIcon} />
            {t('singUp.singUpPhoneWarning')}
          </Typography>
          <PhoneVerification
            profileType={UserRoleTypes.RADIO}
            onAuthorized={goToRadiosLandingPage}
          />
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default SignUpRadio;
