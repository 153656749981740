import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Query } from '../../api/types';
import { useUserId } from '../../api/usages';
import {
  useDoctorReferrals,
  useLabReferrals,
  useRadioReferrals,
} from '../../api/usages/referrals';
import DoctorReferralModalItem from '../SessionSummary/DoctorReferralModalItem';
import LabOrRadioReferralModalItem from '../SessionSummary/LabOrRadioReferralModalItem';
import { Referral, ReferralProps } from './Referral';

const useStyles = makeStyles(() =>
  createStyles({
    spacingTop: {
      marginTop: 16,
    },
  }),
);

type SummaryReferralsProps = {
  bookingId: string;
  disabled: boolean;
  patientVerified?: boolean;
};

const SummaryReferrals = ({
  bookingId,
  disabled,
  patientVerified,
}: SummaryReferralsProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const userId = useUserId();

  const query = useMemo<Query>(
    () => [
      { fieldPath: 'bookingId', opStr: '==', value: bookingId },
      { fieldPath: 'patientId', opStr: '==', value: userId },
    ],
    [bookingId, userId],
  );

  const labRadioQuery = useMemo<Query>(
    () => [
      { fieldPath: 'bookingId', opStr: '==', value: bookingId },
      { fieldPath: 'serviceId', opStr: '>', value: '' },
      { fieldPath: 'patientId', opStr: '==', value: userId },
    ],
    [bookingId, userId],
  );

  const {
    doctorReferrals,
    loading: doctorLoading,
    error: doctorError,
  } = useDoctorReferrals(query);
  const {
    labReferrals,
    loading: labLoading,
    error: labError,
  } = useLabReferrals(labRadioQuery);
  const {
    radioReferrals,
    loading: radioLoading,
    error: radioError,
  } = useRadioReferrals(labRadioQuery);

  // Due to autosave in doctor notes referrals can be only partially filled.
  // There is a need to filter doctor referrals on client's side, because
  // range comparisons in query can be performed only on a single field.
  const validDoctorReferrals =
    doctorLoading || doctorError
      ? []
      : doctorReferrals.filter(referral => referral.specialtyId);

  const referrals: ReferralProps[] = [
    {
      title: t('callSummary.doctorsCall'),
      disabled: !validDoctorReferrals.length || disabled,
      // eslint-disable-next-line react/display-name
      extraContent: () => (
        <>
          {validDoctorReferrals.map(referral => (
            <DoctorReferralModalItem key={referral.id} referral={referral} />
          ))}
        </>
      ),
    },
    {
      title: t('callSummary.lab'),
      disabled: labLoading || !!labError || !labReferrals.length || disabled,
      // eslint-disable-next-line react/display-name
      extraContent: () => (
        <>
          {labReferrals.map(referral => (
            <LabOrRadioReferralModalItem
              key={referral.id}
              referral={referral}
              bookingId={bookingId}
              isLab
            />
          ))}
        </>
      ),
    },
    {
      title: t('callSummary.radio'),
      disabled:
        radioLoading || !!radioError || !radioReferrals.length || disabled,
      // eslint-disable-next-line react/display-name
      extraContent: () => (
        <>
          {radioReferrals.map(referral => (
            <LabOrRadioReferralModalItem
              key={referral.id}
              referral={referral}
              bookingId={bookingId}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <>
      <Typography className={cx.spacingTop}>
        {t('callSummary.referralsByTheDoctor')}
      </Typography>
      <Box display="flex">
        {referrals.map(props => (
          <Referral
            key={props.title}
            {...props}
            patientVerified={patientVerified}
          />
        ))}
      </Box>
    </>
  );
};
export default SummaryReferrals;
