import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Patient } from '../api/types';
import { useProfile, useUserId } from '../api/usages';
import HealthRecordsForm from '../components/HealthRecords/HealthRecordsForm';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const HealthRecords = () => {
  const { t } = useTranslation();
  const profile = useProfile();
  const userId = useUserId();

  if (!profile) {
    return <LayoutDefault>No profile info</LayoutDefault>;
  }

  return (
    <LayoutDefault>
      <Box>
        <Title title={t('common.healthRecords')} />
      </Box>
      <HealthRecordsForm
        profile={profile as Patient}
        patientId={userId || ''}
      />
    </LayoutDefault>
  );
};

export default HealthRecords;
