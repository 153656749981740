import { createBridge, JSONSchema } from '../../lib/utils';
import GeoPointSchema from './GeoPointSchema';

const schemaLabRadio: JSONSchema = {
  title: 'Update Lab or Radio Profile',
  type: 'object',
  properties: {
    name: { type: 'string', minLength: 1 },
    email: {
      type: 'string',
      format: 'email',
    },
    phoneNumber: { type: 'string' },
    contactPerson: {
      type: 'string',
    },
    receptionPhone: {
      type: 'string',
      pattern: '^[0-9]*$',
      minLength: 1,
    },
    openTimes: {
      type: 'string',
      minLength: 1,
    },
    about: {
      type: 'string',
      minLength: 1,
    },
    commercialRegistration: {
      type: 'string',
      minLength: 1,
    },
    commercialRegistrationUpload: {
      type: 'string',
      minLength: 1,
    },
    mohLicence: {
      type: 'string',
      minLength: 1,
    },
    mohLicenceUpload: {
      type: 'string',
      minLength: 1,
    },
    address: {
      type: 'array',
      minItems: 1,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          governorateId: {
            type: 'string',
            minLength: 1,
          },
          areaId: {
            type: 'string',
            minLength: 1,
          },
          buildingNumberName: {
            type: 'string',
            minLength: 1,
          },
          streetBlockCompound: {
            type: 'string',
            minLength: 1,
          },
          districtTownVillage: {
            type: 'string',
            minLength: 1,
          },
          floorNumber: {
            type: 'string',
          },
          appartmentNumber: {
            type: 'string',
          },
          directions: {
            type: 'string',
          },
          savedName: {
            type: 'string',
          },
          geolocation: GeoPointSchema,
        },
        required: [
          'governorateId',
          'areaId',
          'buildingNumberName',
          'streetBlockCompound',
          'districtTownVillage',
        ],
      },
    },
    arabicProficiency: {
      type: 'string',
    },
    englishProficiency: {
      type: 'string',
    },
    frenchProficiency: {
      type: 'string',
    },
    otherLang: { type: 'string' },
    offerDelivery: {
      type: 'boolean',
    },
    resellerCode: {
      type: 'string',
      maxLength: 4,
      pattern: '[a-zA-Z0-9]{4}|^$',
    },
  },
  required: [
    'email',
    'name',
    'phoneNumber',
    'receptionPhone',
    'openTimes',
    'commercialRegistration',
    'commercialRegistrationUpload',
    'mohLicence',
    'mohLicenceUpload',
    'about',
  ],
};

export const UpdateProfileLabRadio = createBridge(schemaLabRadio);
