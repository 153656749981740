import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { OrderStatus } from '../api/types/Payments';
import { usePaymentOrder } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import PaymentSuccess from '../components/Payments/PaymentSuccess';
import PaymobGateway from '../components/Payments/vendors/Paymob';

const PaymentPay = () => {
  const { t } = useTranslation();
  const { orderId = null } = useParams();
  const { order, loading, error } = usePaymentOrder(orderId);
  const issuer = order?.issuer;

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (error || !order || order.status === OrderStatus.ERROR) {
    return (
      <LayoutDefault>
        <h1>{t('payments.error')}</h1>
      </LayoutDefault>
    );
  }

  if (order.status === OrderStatus.COMPLETED && issuer !== 'AMAN') {
    return (
      <LayoutDefault>
        <PaymentSuccess order={order} />
      </LayoutDefault>
    );
  }

  switch (order.vendor) {
    case 'PAYMOB':
      return (
        <LayoutDefault>
          <PaymobGateway order={order} />
        </LayoutDefault>
      );
    default:
      return (
        <LayoutDefault>
          <h1>{t('payments.error')}</h1>
        </LayoutDefault>
      );
  }
};

export default PaymentPay;
