import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { updateBooking } from '../api/actions/bookings';
import { Language } from '../api/types';
import { useDocument } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import getDoctorName from '../lib/utils/getDoctorName';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 700,
      padding: 16,
    },
    avatar: {
      maxWidth: '80%',
      // margin: 'auto',
    },
    currency: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: 10, // TODO: rtl
    },
    price: {
      fontSize: theme.typography.pxToRem(32),
    },
    duration: {
      fontSize: theme.typography.pxToRem(28),
    },
    time: {
      fontSize: theme.typography.pxToRem(24),
    },
    spacing: {
      margin: 16,
    },
    spacingTop: {
      marginTop: 16,
    },
    button: {
      margin: 8,
    },
  }),
);

const CallReview = () => {
  const cx = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { result: booking } = useDocument<any>({ path: `bookings/${id}` });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { result: doctor } = useDocument<any>({
    path: `doctors/${booking?.doctorId}`,
  });

  const ratingFromBooking: number = booking?.rating ?? 0;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rating, setRating] = useState<number | null>(null);

  useEffect(() => {
    setRating(ratingFromBooking);
  }, [ratingFromBooking]);

  const handleRatingChange = useCallback((_event, value: number | null) => {
    setRating(value);
  }, []);

  useRedirectWhenNotExists(`bookings/${id}`);

  const handleSubmit = useCallback(() => {
    setDialogOpen(true);
    updateBooking(id, { rating });
  }, [id, rating]);

  const redirectToSummary = useCallback(() => {
    history.push(`/call-summary/${id}`);
  }, [history, id]);

  const lang = i18n.language as Language;
  const doctorName = getDoctorName(doctor, lang);

  return (
    <LayoutDefault>
      <Grid container direction="row" justify="center" alignItems="center">
        <Card className={cx.root}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src={doctor?.photoURL}
              alt={doctorName}
              className={cx.avatar}
            />

            <Typography variant="h5" className={cx.spacingTop}>
              {t('callReview.howWasYourSession', { doctorName })}
            </Typography>

            <Rating
              name={t('callReview.rating')}
              size={'large'}
              value={rating}
              className={cx.spacingTop}
              onChange={handleRatingChange}
            />

            <Button
              variant="contained"
              size="large"
              color="primary"
              className={cx.spacing}
              onClick={handleSubmit}
              disabled={!rating}
            >
              {t('callReview.rateCaller')}
            </Button>

            <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('callReview.thanksForReview')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  component="div"
                >
                  <p>{t('callReview.thanksForReviewText_1')}</p>
                  <p>{t('callReview.thanksForReviewText_2')}</p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={redirectToSummary} color="primary" autoFocus>
                  {t('common.ok')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={Boolean(ratingFromBooking) && !dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('callReview.alreadyRatedBooking')}
              </DialogTitle>
              <DialogActions>
                <Button onClick={redirectToSummary} color="primary" autoFocus>
                  {t('common.ok')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Card>
      </Grid>
    </LayoutDefault>
  );
};

export default CallReview;
