import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDumps } from '../api/usages/dumps';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { storage } from '../lib/firebase/storage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '40px auto 0',
    },
    avatar: {
      marginRight: 8,
    },
    wrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },
  }),
);

const AdminHome = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  const { dumps } = useDumps({ orderBy: 'createdAt' });

  const handleDownload = async (filename: string) => {
    const url = await storage.ref(filename).getDownloadURL();
    window.open(url, '_blank');
  };

  return (
    <LayoutDefault>
      <Title title={t('admin.dataDumps')} />
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          {dumps.map(dump => (
            <Box
              key={dump.id}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginBottom="16px"
            >
              <Box display="flex" flexDirection="column">
                <span>{dump.createdAt.toDate().toLocaleString()}</span>
                <span>{dump.filename}</span>
              </Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleDownload(dump.filename)}
              >
                {t('admin.download')}
              </Button>
            </Box>
          ))}
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default AdminHome;
