/* eslint-disable @typescript-eslint/require-await */
import { changeReferrals } from '../../components/SessionSummary/EditReferrals';
import { createAction } from '../../lib/utils';
import { updateBookingCallback } from './bookings';
import { upsertDiagnosisCallback } from './diagnoses';
import {
  upsertDoctorReferralCallback,
  deleteDoctorReferralCallback,
} from './doctorReferrals';
import {
  upsertLabReferralCallback,
  deleteLabReferralCallback,
} from './labReferrals';
import { upsertObservationCallback } from './observations';
import { upsertPrescriptionCallback } from './prescriptions';
import {
  upsertRadioReferralCallback,
  deleteRadioReferralCallback,
} from './radioReferrals';

export const saveDoctorNotes = createAction({
  type: 'saveDoctorNotes',
  async callback({ booking, observation, diagnosis, prescription }) {
    const bookingId = booking.id;

    const common = {
      bookingId,
      patientId: booking?.patientId,
    };

    Promise.allSettled([
      updateBookingCallback(bookingId, {
        complaint: booking.complaint ?? '',
      }),

      upsertObservationCallback({
        ...common,
        ...observation,
      }),

      upsertDiagnosisCallback({
        ...common,
        ...diagnosis,
      }),

      upsertPrescriptionCallback({
        ...common,
        ...prescription,
      }),
    ]);
  },
});

export const saveDoctorReferrals = createAction({
  type: 'saveDoctorNotes',
  async callback({ booking, doctorReferrals, fetchedDoctorReferrals }) {
    const bookingId = booking.id;

    const common = {
      bookingId,
      patientId: booking?.patientId,
    };

    changeReferrals(
      doctorReferrals,
      fetchedDoctorReferrals,
      upsertDoctorReferralCallback,
      deleteDoctorReferralCallback,
      common,
    );
  },
});

export const saveLabReferrals = createAction({
  type: 'saveDoctorNotes',
  async callback({ booking, labReferrals, fetchedLabReferrals }) {
    const bookingId = booking.id;

    const common = {
      bookingId,
      patientId: booking?.patientId,
    };

    changeReferrals(
      labReferrals,
      fetchedLabReferrals,
      upsertLabReferralCallback,
      deleteLabReferralCallback,
      common,
    );
  },
});

export const saveRadioReferrals = createAction({
  type: 'saveDoctorNotes',
  async callback({ booking, radioReferrals, fetchedRadioReferrals }) {
    const bookingId = booking.id;

    const common = {
      bookingId,
      patientId: booking?.patientId,
    };

    changeReferrals(
      radioReferrals,
      fetchedRadioReferrals,
      upsertRadioReferralCallback,
      deleteRadioReferralCallback,
      common,
    );
  },
});
