import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { BookingTypes } from '../api/types';
import LayoutDefault from '../components/LayoutDefault';
import SetBooking from '../components/SetBooking';

const FilteredDoctors = () => {
  const { specialtyId, medTitle } = useParams<{
    specialtyId: string;
    medTitle: string;
  }>();
  const location = useLocation();
  const { t } = useTranslation();

  const referralId = (location.state as any)?.referralId;

  if (!referralId) {
    return (
      <LayoutDefault>
        <h1>{t('common.sorrySomethingWentWrong')}</h1>
      </LayoutDefault>
    );
  }

  const model = {
    referralId,
    type: BookingTypes.INITIAL,
  };

  return (
    <LayoutDefault>
      {medTitle === 'null' || medTitle === 'any' ? (
        <SetBooking specialtyId={specialtyId} model={model} withSpecialties />
      ) : (
        <SetBooking
          specialtyId={specialtyId}
          medTitle={medTitle}
          model={model}
          withSpecialties
        />
      )}
    </LayoutDefault>
  );
};

export default FilteredDoctors;
