import { createBridge, JSONSchema } from '../../lib/utils';

const schema: JSONSchema = {
  title: 'CreateAdmin',
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: {
      type: 'string',
      minLength: 6,
      pattern: '^[^\\s]*$',
      uniforms: {
        type: 'password',
      },
    },
    name: { type: 'string' },
  },
  required: ['email', 'password', 'name'],
};

export default createBridge(schema);
