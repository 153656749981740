import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as colors from '@material-ui/core/colors';
import CompletedIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import HistoryIcon from '@material-ui/icons/History';
import ClockIcon from '@material-ui/icons/WatchLater';
import React from 'react';

import { OrderStatus, PaymentOrder } from '../../api/types/Payments';
import { formatPrice } from '../../lib/utils';

const getStatusImage = (order: PaymentOrder) => {
  switch (order.status) {
    case OrderStatus.PENDING:
      return (
        <HistoryIcon fontSize="large" style={{ color: colors.blue[500] }} />
      );
    case OrderStatus.COMPLETED:
      return (
        <CompletedIcon fontSize="large" style={{ color: colors.green[500] }} />
      );
    case OrderStatus.TIMEOUT:
      return <ClockIcon fontSize="large" style={{ color: colors.red[500] }} />;
    case OrderStatus.ERROR:
      return <ErrorIcon fontSize="large" style={{ color: colors.red[500] }} />;
    default:
      return <HelpIcon fontSize="large" style={{ color: colors.grey[500] }} />;
  }
};

type Props = {
  orders: PaymentOrder[];
};

const OrdersListing = ({ orders }: Props) => {
  return (
    <List>
      {orders.map(order => (
        <ListItem key={order.id} divider>
          <ListItemIcon>{getStatusImage(order)}</ListItemIcon>
          <ListItemText
            primary={formatPrice(order.price)}
            secondary={order.createdAt.toDate().toLocaleString()}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default OrdersListing;
