import React from 'react';

import { Doctor } from '../../api/types';
import { instanceOfADoctor } from '../../api/types/Doctor';
import { useDocument } from '../../api/usages';
import DoctorRow from './DoctorRow';

interface FavouriteDoctorRowProps {
  doctorIdForQuery: string;
  onChooseDoctor?: (doctor: any) => void;
  testing: boolean;
}

const FavouriteDoctorRow = ({
  doctorIdForQuery,
  onChooseDoctor,
  testing,
}: FavouriteDoctorRowProps) => {
  const { result: doctor, error, loading } = useDocument<Doctor>(
    {
      path: `doctors/${doctorIdForQuery}`,
    },
    { skip: !doctorIdForQuery },
  );

  if (
    !doctor ||
    error ||
    loading ||
    testing !== !!doctor?.testing ||
    !instanceOfADoctor(doctor)
  ) {
    return null;
  }

  return (
    <DoctorRow
      key={doctorIdForQuery}
      doctor={doctor}
      onChooseDoctor={onChooseDoctor}
    />
  );
};

export default FavouriteDoctorRow;
