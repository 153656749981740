import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { removeBookings } from '../../api/actions/schedule';

const CancelAvailability = ({
  open,
  handleCloseDialog,
  bookings,
  isTemplate,
}: any) => {
  const { t } = useTranslation();
  const defaultMsg = t('englishOnly.schedule_cancelDefaultMsg');
  const bookingLength = bookings.length;
  const bookingStr1 = bookingLength === 1 ? '' : 's';
  const bookingStr2 = bookingLength === 1 ? 'it' : 'them';

  const standardMsg = t('englishOnly.schedule_cancelStandardMsg', {
    bookingLength,
    bookingStr1,
    bookingStr2,
  });

  const msg = isTemplate ? defaultMsg : standardMsg;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleCloseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Cancel your availability'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} color="primary">
            {isTemplate ? 'OK' : 'Disagree'}
          </Button>
          {!isTemplate && (
            <Button
              onClick={() => {
                handleCloseDialog(true);
                removeBookings(bookings);
              }}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelAvailability;
