import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-use-promise';
import {
  AutoField,
  AutoForm,
  ErrorField,
  SubmitField,
} from 'uniforms-material';

import { doCreateProfile } from '../api/actions/auth';
import { showInfoToast } from '../api/actions/uiControls';
import {
  CreatePatientProfileNoAuth,
  CreatePatientProfileWithAuth,
} from '../api/schemas/CreateProfile';
import { UserRoleTypes } from '../api/types';
import { useNationalities, useUser } from '../api/usages';
import CustomSelectField from '../components/CustomSelectField';
import LayoutDefault from '../components/LayoutDefault';
import PreventNavigationInChangedForm from '../components/PreventNavigationInChangedForm';
import AddressAreaList from '../components/Profile/AddressAreaList';
import BilingualAutocompleteField from '../components/Profile/BilingualAutocompleteField';
import ReauthWithPhoneDialog from '../components/ReauthWithPhoneDialog';
import SectionTitle from '../components/SectionTitle';
import Title from '../components/Title';
import UploadAreaUniformField from '../components/UploadAreaUniformField';
import {
  genderOptions,
  proficiencyOptions,
  workOptions,
} from '../constants/ConfigProfile';
import { functions, timestampNow } from '../lib/firebase';
import { goToPatientsLandingPage } from '../lib/goToRouteHelpers';
import { momentToFormat } from '../lib/utils';
import { i18next } from '../locales';

const useStyles = makeStyles({
  root: {
    maxWidth: '700px',
    margin: '0 auto',
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiInput-root': {
      marginBottom: '16px',
    },
    '& .Mui-error': {
      marginBottom: '12px',
    },
  },
  submitButton: {
    marginTop: `24px`,
  },
  header: {
    marginBottom: `32px`,
  },
});

const CreateProfile = () => {
  const { t } = useTranslation();
  const user = useUser();
  const cx = useStyles();
  const lang = i18next.language;
  const { nationalities } = useNationalities();
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fromSubmit, setFromSubmit] = useState(false);
  const [idNumber, setIdNumber] = useState<string | null>(null);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [readyToCreateProfile, setReadyToCreateProfile] = useState(false);
  const [reAuthNeeded, setReauthNeeded] = useState<boolean>(false);
  const closeDialog = () => {
    setDialogOpen(false);
    setReadyToCreateProfile(true);
  };

  const openDialog = () => setDialogOpen(true);

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  // useEffect(() => {
  //   if (user?.uid) {
  //     const phoneNumber = get(user, 'phoneNumber', '');
  //     const email = get(user, 'email', '');
  //     const profileData = {
  //       kind: 'patient',
  //       email,
  //       phoneNumber,
  //       testing: false,
  //       verified: true,
  //     };
  //     doProfileUpdate(profileData);
  //   }
  // }, [user]);

  const [result, error, state] = usePromise(
    () =>
      functions.httpsCallable('checkPatientIdNumber')({
        idNumber,
        isCreatingProfile: true,
      }),
    [idNumber],
  );

  useEffect(() => {
    const haveToReturn =
      error ||
      !state ||
      state === 'pending' ||
      idNumber === null ||
      result?.data.wasIdNumberNull;

    if (haveToReturn) {
      return;
    }

    if (fromSubmit) {
      const isDuplicated = result?.data.isDuplicated;

      if (isDuplicated && !readyToCreateProfile) {
        openDialog();
        setFromSubmit(false);
      } else {
        setReadyToCreateProfile(true);
      }
    }
  }, [error, result, state, fromSubmit, idNumber, readyToCreateProfile]);

  useEffect(() => {
    if (readyToCreateProfile) {
      const onLinkCredsErr = (e: any) => {
        if (e.code === 'auth/requires-recent-login') {
          setReauthNeeded(true);
          setReadyToCreateProfile(false);
        }
      };

      doCreateProfile(
        updatedProfile,
        UserRoleTypes.PATIENT,
        goToPatientsLandingPage,
        onLinkCredsErr,
      );
      setBlockNavigation(false);
    }
  }, [readyToCreateProfile, updatedProfile]);

  const closeReauth = () => {
    setReauthNeeded(false);
  };

  const onReauthenticated = () => {
    closeReauth();
    showInfoToast(t('common.tryResubmit'));
  };

  const handleSubmit = (profile: object) => {
    setFromSubmit(true);
    const newIdNumber = get(profile, 'idNumber', '');
    setIdNumber(newIdNumber);
    const birthday = get(profile, 'birthday', '');
    const convertedBirthday = momentToFormat(lang, birthday, '');
    setUpdatedProfile({
      ...profile,
      birthday: convertedBirthday,
      testing: false,
      verified: true,
      createdAt: timestampNow(),
    });
  };

  if (!user) {
    return (
      <LayoutDefault>
        <Card className={cx.root} />
      </LayoutDefault>
    );
  }
  const schema = user.email
    ? CreatePatientProfileNoAuth
    : CreatePatientProfileWithAuth;

  return (
    <LayoutDefault>
      <Card className={cx.root}>
        {reAuthNeeded && (
          <ReauthWithPhoneDialog
            onClose={closeReauth}
            onReauthenticated={onReauthenticated}
          />
        )}
        <Box p={3}>
          <Box className={cx.header}>
            <Title title={t('common.registration')} />
          </Box>
          <AutoForm
            model={{
              phoneNumber: user.phoneNumber,
              email: user.email,
              address: [{}],
            }}
            className={cx.root}
            schema={schema}
            onSubmit={handleSubmit}
          >
            <PreventNavigationInChangedForm prevent={blockNavigation} />
            <SectionTitle title={t('profile.personalInfo')} />
            <AutoField name="name" label={t('profile.name')} />
            <ErrorField name="name" />
            <UploadAreaUniformField
              name="photoURL"
              text={t(`profile.uploadPhoto`)}
              filesLimit={1}
              acceptedFiles={['image/*']}
            />
            <ErrorField name="photoURL" />
            <CustomSelectField
              name="gender"
              label={t('profile.gender')}
              options={genderOptions}
              fullWidth
            />
            <ErrorField name="gender" />
            <AutoField
              name="birthday"
              label={t('profile.birthday')}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ErrorField name="birthday" />
            <AutoField
              name="resellerCode"
              label={t('profile.resellerCode')}
              inputProps={{
                maxLength: 4,
              }}
            />
            <ErrorField
              name="resellerCode"
              errorMessage={t('profile.resellerError')}
            />
            <AutoField name="idNumber" label={t('profile.iDNumber')} />
            <ErrorField name="idNumber" />
            <UploadAreaUniformField
              name="idOrPassportPhoto"
              text={t(`profile.uploadID`)}
            />
            <ErrorField name="idOrPassportPhoto" />
            <BilingualAutocompleteField
              id="nationality"
              name="nationality"
              options={nationalitiesSorted}
              label={t('profile.nationality')}
            />
            <AutoField
              name="nativeLanguage"
              label={t('profile.nativeLanguage')}
            />
            <CustomSelectField
              name="englishProficiency"
              label={t('profile.englishProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="arabicProficiency"
              label={t('profile.arabicProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="frenchProficiency"
              label={t('profile.frenchProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <AddressAreaList />
            <SectionTitle title={t('profile.lifestyleSection')} />
            <CustomSelectField
              name="workType"
              label={t('profile.workType')}
              options={workOptions}
              fullWidth
            />
            <AutoField
              name="married"
              label={t('profile.married')}
              color="primary"
            />
            <Box>
              <SubmitField
                color="primary"
                label={t('common.submit')}
                className={cx.submitButton}
              />
            </Box>
          </AutoForm>
          <Dialog onClose={closeDialog} open={dialogOpen}>
            <DialogContent>
              <Typography variant="subtitle1" align="justify">
                {t('profile.duplicatedIdNumberMessage')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>{t('common.close')}</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default CreateProfile;
