import List from '@material-ui/core/List';
import React from 'react';

import { PaymentWalletLog } from '../../api/types/Payments';
import WalletLog from './WalletLog';

type Props = {
  logs: PaymentWalletLog[];
};

const WalletListing = ({ logs }: Props) => {
  return (
    <List>
      {logs.map((log, key) => (
        <WalletLog log={log} key={key} />
      ))}
    </List>
  );
};

export default WalletListing;
