import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableHeadProps {
  selectedLength: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  type: string;
  readonly?: boolean;
}

const TableHistoryHead = ({
  type,
  onSelectAllClick,
  rowCount,
  selectedLength,
  readonly,
}: TableHeadProps) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {!readonly && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selectedLength > 0 && selectedLength < rowCount}
              checked={rowCount > 0 && selectedLength === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'uselect all desserts' }}
            />
          </TableCell>
        )}
        <TableCell>{t('healthRecords.date')}</TableCell>
        <TableCell align="right">{t(`healthRecords.${type}`)}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHistoryHead;
