import { SvgIcon } from '@material-ui/core';
import AppBarRaw from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { UserRoleTypes } from '../../api/types';
import { useContextRole } from '../../api/usages';
import {
  sideNavWidth,
  mainNavConfigAnonymous,
  mainNavConfigDoctor,
  mainNavConfigPatient,
  mainNavConfigLabRadio,
  mainNavConfigAdmin,
  LayoutItem,
  LayoutRouteItem,
  LayoutActionItem,
} from '../../constants/ConfigLayout';
import LanguageSelector from '../LanguageSelector';
import LoaderLinear from '../LoaderLinear';
import SvgHeart from '../SvgHeart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${sideNavWidth}px)`,
        marginLeft: sideNavWidth,
      },
    },
    toolBar: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex',
    },
    group: {
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    mainNavButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        minWidth: 30,
        fontWeight: 'bolder',
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
    },
    hidden: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    heartLogo: {
      width: '3em',
      height: '2em',
      marginLeft: theme.spacing(1),
    },
  }),
);

interface AppBarProps {
  handleDrawerToggle: () => void;
}

function getItemProps(item: LayoutItem, defaultProps = {}) {
  const route = (item as LayoutRouteItem).route;
  const onClick = (item as LayoutActionItem).callback;
  if (route) {
    return { component: NavLink, to: route, ...defaultProps };
  } else if (typeof onClick === 'function') {
    return {
      component: 'button',
      onClick: onClick as () => void,
      ...defaultProps,
    };
  }
  return { disabled: true, ...defaultProps };
}

const AppBar = (props: AppBarProps) => {
  const { handleDrawerToggle } = props;
  const { t } = useTranslation();
  const cx = useStyles();
  const role = useContextRole();

  const {
    palette: { primary },
  } = useTheme();

  const selectNavConfig = () => {
    switch (role) {
      case UserRoleTypes.DOCTOR:
        return mainNavConfigDoctor;
      case UserRoleTypes.LAB:
        return mainNavConfigLabRadio;
      case UserRoleTypes.RADIO:
        return mainNavConfigLabRadio;
      case UserRoleTypes.PATIENT:
        return mainNavConfigPatient;
      case UserRoleTypes.ADMIN:
        return mainNavConfigAdmin;
      default:
        return mainNavConfigAnonymous;
    }
  };

  const mainNavConfig = selectNavConfig();

  return (
    <AppBarRaw position="fixed" className={cx.appBar}>
      <Toolbar className={cx.toolBar}>
        <div className={cx.group}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={cx.menuButton}
          >
            <MenuIcon className="joyride_menu" />
          </IconButton>
          {mainNavConfig.map(subpage => {
            const StartIcon = subpage.icon;
            const itemProps = getItemProps(subpage, {
              ...(StartIcon && { startIcon: <StartIcon /> }),
              color: 'inherit',
              className: cx.mainNavButton,
              activeStyle: { backgroundColor: primary.dark },
            });
            return (
              <Button key={subpage.label} {...itemProps}>
                <span className={cx.hidden}>{t(subpage.label)}</span>
              </Button>
            );
          })}
        </div>
        <div className={cx.group}>
          <LanguageSelector />
          <SvgIcon
            component={SvgHeart}
            viewBox="0 0 170 110"
            htmlColor="white"
            classes={{ root: cx.heartLogo }}
          />
        </div>
      </Toolbar>
      <LoaderLinear key="AppBarLoader" />
    </AppBarRaw>
  );
};

export default AppBar;
