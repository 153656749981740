import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

import { useMediaRefs, useRoom } from '../../../api/usages/video';
import AudioButton from './AudioButton';
import ChangeCameraBtn from './ChangeCameraBtn';
import EndButton from './EndButton';
import VideoButton from './VideoButton';

const useStyles = makeStyles({
  button: {
    flexShrink: 0,
    margin: '0 5px 0 5px',
  },
  audio: {
    gridArea: 'audio',
  },
  end: {
    gridArea: 'end',
  },
  video: {
    gridArea: 'video',
  },
});

const Controls = () => {
  const cx = useStyles();
  const room = useRoom();
  const { audioTrack, videoTrack } = useMediaRefs(room?.localParticipant);

  return (
    <>
      <AudioButton
        className={`${cx.audio} ${cx.button}`}
        track={audioTrack as LocalAudioTrack | undefined}
      />
      <EndButton className={`${cx.end} ${cx.button}`} />
      <VideoButton
        className={`${cx.video} ${cx.button}`}
        track={videoTrack as LocalVideoTrack | undefined}
      />
      <ChangeCameraBtn className={cx.button} />
    </>
  );
};

export default Controls;
