import { Box, Card } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useContextRole, useUser } from '../api/usages';
import Title from '../components/Title';
import { goToLandingByContextRole } from '../lib/goToRouteHelpers';
import PhoneVerification from './PhoneVerification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '0 auto',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(2),
      },
      '& .Mui-error': {
        marginBottom: '12px',
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const ChangePhone = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const profileType = useContextRole();
  const user = useUser();
  if (!user || !profileType) {
    return null;
  }
  return (
    <Card className={cx.root}>
      <Box p={3} className={cx.wrapper}>
        <Box className={cx.header}>
          <Title variant="h3" title={t('auth.changePhone')} />
        </Box>
        <PhoneVerification
          profileType={profileType}
          updateNumber
          onAuthorized={goToLandingByContextRole}
        />
      </Box>
    </Card>
  );
};

export default ChangePhone;
