import { ListItem, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fields: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    item: {
      minWidth: '220px',
    },
  }),
);

interface FieldsPanelProps {
  label?: string;
  value?: string | React.ReactNode;
}

const FieldsPanel = ({ label, value }: FieldsPanelProps) => {
  const cx = useStyles();
  return (
    <div className={cx.fields}>
      {' '}
      <ListItem>
        <ListItemText className={cx.item} primary={label} secondary={value} />
      </ListItem>
    </div>
  );
};

export default FieldsPanel;
