import { Box, FormLabel } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  SubmitField,
  ErrorField,
  NumField,
} from 'uniforms-material';

import { doProfileUpdate } from '../../api/actions/auth';
import { UpdateProfileDoctor } from '../../api/schemas/UpdateProfileDoctor';
import { useSpecialties, useNationalities } from '../../api/usages';
import {
  genderOptions,
  proficiencyOptions,
  medTitleOptions,
  countriesOptions,
  askForArabicAbout,
} from '../../constants/ConfigProfile';
import { momentToFormat } from '../../lib/utils';
import { i18next } from '../../locales';
import { getTranslatedSpeciality } from '../../screens/Specialists';
import CustomPriceField from '../CustomPriceField';
import CustomSelectField from '../CustomSelectField';
import PhoneNumberField from '../PhoneNumberField';
import SectionTitle from '../SectionTitle';
import UploadAreaUniformField from '../UploadAreaUniformField';
import AddressAreaList from './AddressAreaList';
import BilingualAutocompleteField from './BilingualAutocompleteField';
import CertificatesListField from './CertificatesListField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: '16px',
      },
      '& fieldset': {
        margin: '0 0 16px',
      },
    },
    spaceBottom: {
      marginBottom: '16px',
    },
    label: {
      marginBottom: '5px',
      marginTop: 0,
    },
    aboutField: {
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.error.main,
      },
    },
  }),
);

const ProfileInfo = (props: any) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const lang = i18next.language;
  const profile = get(props, 'profile', {});
  const user = get(props, 'user', {});
  const fetchedBirthday = get(profile, 'birthday', '');
  const email = get(user, 'email', '');
  const emailVerified = get(user, 'emailVerified', false);
  const specialtyId = get(profile, 'specialtyId', '');
  const certificates = get(profile, 'certificates', []);
  const { specialties } = useSpecialties();
  const { nationalities } = useNationalities();
  const translatedSpecialties = specialties.map(specialty =>
    getTranslatedSpeciality(specialty),
  );
  const speciality = translatedSpecialties.filter(el => el.id === specialtyId);
  const price = get(profile, 'price', {});
  const amount = get(profile, 'price.amount', 0);
  const centsToDollars = amount / 100;
  const newPrice = { ...price, amount: centsToDollars };
  const address = get(profile, 'address', []);
  const [nationalityId, setNationalityId] = useState<string>(
    get(profile, 'nationality', ''),
  );
  const resellerCode = get(profile, 'resellerCode', ' ');

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const model = {
    ...profile,
    birthday: momentToFormat(lang, fetchedBirthday, 'YYYY-MM-DD'),
    specialtyId,
    price: newPrice,
    nationality: nationalityId,
  };

  const handleNationalityIdChange = (value: string) => {
    setNationalityId(value);
  };

  const handleSubmit = (newValues: object) => {
    const birthday = get(newValues, 'birthday', '');
    const newBirthday = momentToFormat(lang, birthday, '');
    const price = get(newValues, 'price', {});
    const amount = get(newValues, 'price.amount', 0);
    const dollarsToCents = amount * 100;
    const newPrice = { ...price, amount: dollarsToCents };
    const updatedProfile = {
      ...newValues,
      birthday: newBirthday,
      price: newPrice,
    };

    doProfileUpdate(updatedProfile);
  };

  // @ts-ignore
  return (
    <AutoForm
      className={cx.root}
      model={model}
      schema={UpdateProfileDoctor}
      onSubmit={(profile: object) => handleSubmit(profile)}
    >
      <SectionTitle title={t('profile.personalInfo')} />
      <AutoField
        name="email"
        inputProps={{
          readOnly: email,
        }}
        label={t('profile.email')}
        margin="dense"
        helperText={
          emailVerified
            ? '✅ ' + t('profile.emailVerified')
            : t('profile.needEmailVerificationInfo')
        }
      />
      <div className={cx.spaceBottom} />
      <PhoneNumberField
        name="phoneNumber"
        label={t('profile.phoneNumber')}
        readOnly
      />
      <Box display="flex">
        <Box flex={1}>
          <AutoField name="name.en" label={t('profile.nameEn')} />
          <ErrorField name="name.en" />
        </Box>
        <Box flex={1}>
          <AutoField name="name.ar" label={t('profile.nameAr')} />
          <ErrorField name="name.ar" />
        </Box>
      </Box>
      <AutoField name="birthday" label={t('profile.birthday')} />
      <ErrorField name="birthday" />
      <CustomSelectField
        name="gender"
        label={t('profile.gender')}
        options={genderOptions}
        fullWidth
      />
      <ErrorField name="gender" />
      <BilingualAutocompleteField
        id="nationality"
        name="nationality"
        options={nationalitiesSorted}
        label={t('profile.nationality')}
        handleFunc={handleNationalityIdChange}
      />
      <ErrorField name="nationality" />
      <AutoField name="idNumber" label={t('profile.iDNumber')} />
      <ErrorField name="idNumber" />
      <UploadAreaUniformField
        name="idOrPassportPhoto"
        text={t(`profile.uploadID`)}
        filesLimit={1}
        acceptedFiles={['image/*', 'application/pdf']}
      />
      <ErrorField name="idOrPassportPhoto" />
      <AutoField
        name="resellerCode"
        label={t('profile.resellerCode')}
        inputProps={{ readOnly: resellerCode }}
      />
      <AutoField name="nativeLanguage" label={t('profile.nativeLanguage')} />
      <CustomSelectField
        name="arabicProficiency"
        label={t('englishOnly.profile_arabicProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="englishProficiency"
        label={t('englishOnly.profile_englishProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="frenchProficiency"
        label={t('englishOnly.profile_frenchProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <AddressAreaList name="address" address={address} />
      <SectionTitle title={t('englishOnly.profile_professionalSection')} />
      <CustomSelectField
        name="specialtyId"
        label={t('profile.specialtyId')}
        options={speciality}
        readOnly
        fullWidth
      />
      <ErrorField name="specialtyId" />
      <CustomSelectField
        name="medTitle"
        label={t('englishOnly.profile_medTitle')}
        options={medTitleOptions}
        fullWidth
      />
      <ErrorField name="medTitle" />
      <CertificatesListField />
      <Box display="flex">
        <Box flex={1}>
          <AutoField
            name="about.en"
            label={t('profile.aboutEn')}
            placeholder={askForArabicAbout}
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{ root: cx.aboutField }}
          />
          <ErrorField name="about.en" />
        </Box>
        <Box flex={1}>
          <AutoField
            name="about.ar"
            label={t('profile.aboutAr')}
            placeholder={askForArabicAbout}
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
            classes={{ root: cx.aboutField }}
          />
          <ErrorField name="about.ar" />
        </Box>
      </Box>
      <CustomSelectField
        name="countries"
        label={t('profile.practiceCountries')}
        options={countriesOptions}
        fullWidth
      />
      <ErrorField name="countries" />
      <NumField name="yearsOfExperience" type="number" min={0} max={100} />
      <ErrorField name="yearsOfExperience" />
      <SectionTitle title={t('englishOnly.profile_priceSection')} />
      <FormLabel component="p" className={cx.label}>
        {t('profile.consultPice')}
      </FormLabel>
      <CustomPriceField
        name="price"
        label={`${t('englishOnly.profile_priceAmount')}`}
      />
      <ErrorField
        name="price.amount"
        errorMessage={t('profile.primaryPriceValidate')}
      />
      <SubmitField color="primary" />
    </AutoForm>
  );
};

export default ProfileInfo;
