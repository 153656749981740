import React from 'react';
import { Router } from 'react-router-dom';

import AppRouter from './AppRouter';
import AppUrlListener from './AppUrlListener';
import { initAuth } from './lib/firebase';
import { history } from './lib/utils';

import './api/actions/auth';
import './lib/syncTime';

// initialize firebase auth
const unsubscribe = initAuth();

export const App = () => (
  <React.StrictMode>
    <Router history={history}>
      <AppUrlListener />
      <AppRouter />
    </Router>
  </React.StrictMode>
);

window.addEventListener('unload', () => unsubscribe());
