import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AreaWithGovernorate } from '../../../api/types/Address';
import { Lab } from '../../../api/types/Lab';
import { useDocument } from '../../../api/usages/document';
import Avatar from '../../Avatar';
import BookingInfoItem from '../../BookingList/BookingInfoItem';

const getTranslatedName = (name: any): string => {
  if (!name) {
    return '';
  }
  const translatedName = name[i18next.language];
  return translatedName || name['en'];
};

interface LabRadioItemProps {
  labRadio: Lab;
}
const LabRadioItem = ({ labRadio }: LabRadioItemProps) => {
  const { t } = useTranslation();

  const areaId = labRadio.address?.[0].areaId ?? '';

  const { result: area } = useDocument<AreaWithGovernorate>(
    {
      path: `areas/${areaId}`,
      dependencies: [
        {
          collectionPath: 'governorates',
          mapFrom: 'governorateId',
          mapTo: 'governorate',
        },
      ],
    },
    { skip: !areaId },
  );

  const govName = area?.governorate.translations ?? '';
  const areaName = area?.translations ?? '';
  const name = labRadio.name ?? '';
  const address = labRadio.address?.[0].streetBlockCompound ?? '';
  const photoURL = labRadio.photoURL ?? '';
  const deliverIcon = labRadio.offerDelivery ?? '';

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem>
            <ListItemAvatar style={{ marginRight: '15px', marginLeft: '15px' }}>
              <Avatar photoURL={photoURL} name={name} />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={<Typography variant="h6">{name}</Typography>}
              secondary={
                <React.Fragment>
                  {labRadio.address && (
                    <>
                      <BookingInfoItem
                        primaryText={t('labs.address')}
                        secondaryText={`${getTranslatedName(
                          govName,
                        )}, ${getTranslatedName(areaName)}`}
                      />
                      <BookingInfoItem
                        primaryText={t('labs.street')}
                        secondaryText={address}
                      />
                    </>
                  )}
                  <BookingInfoItem
                    primaryText={t('labs.offerDelivery')}
                    secondaryText={deliverIcon ? <CheckIcon /> : <ClearIcon />}
                  />
                </React.Fragment>
              }
            />
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LabRadioItem;
