import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ExpansionElement from '../components/ExpansionElement';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import ConfigHelp from '../constants/ConfigHelp';
import useQuery from '../lib/utils/hooks/useQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('xs')]: {
        width: '90%',
        margin: '10px auto',
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
    },
  }),
);

const Help = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const query = useQuery();
  return (
    <LayoutDefault>
      <Title title={t('common.help')} />
      <div className={cx.root}>
        {ConfigHelp.map(({ title, Component }, index) => (
          <ExpansionElement
            key={title}
            isExpanded={query.has(title)}
            index={index}
            title={t(title)}
            Component={Component}
          />
        ))}
      </div>
    </LayoutDefault>
  );
};

export default Help;
