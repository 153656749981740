import { List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import haversine from 'haversine-distance';
import React from 'react';
import { Link } from 'react-router-dom';

import { Lab, Services } from '../../../api/types/Lab';
import { useCurrentGeoLocation } from '../../../api/usages/geo';
import { GeoPoint } from '../../../lib/firebase';
import LabRadioItem from '../common/LabRadioItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    link: {
      textDecoration: 'none',
      marginBottom: theme.spacing(1),
    },
  }),
);

interface ResultListProps {
  type: string;
  result: Array<Lab>;
  serviceId: string;
  bookingId: string;
  referralId: string;
}

// "Other" services with const id.
const otherLabServiceId = '90da8c95f91d4fbb9f7525f855e1dbb2';
const otherRadioServiceId = 'b6c9878cc7d4439c809b5287b1144985';

const ResultList = ({
  type,
  result,
  serviceId,
  bookingId,
  referralId,
}: ResultListProps) => {
  const cx = useStyles();

  const currentLocation = useCurrentGeoLocation();

  const hasSomeServiceId = (serviceId: string) => (result: Lab) =>
    result.services?.some(
      (service: Services) => service.serviceId === serviceId,
    );

  const addDistance = (institutions: Array<Lab>, currentLocation: GeoPoint) => {
    return institutions.map((institution: Lab) => {
      const geolocation = institution?.address?.[0]?.geolocation;
      const comparedLatitude = geolocation?.latitude;
      const comparedLongitude = geolocation?.longitude;
      const currentLatitude = currentLocation.latitude;
      const currentLongitude = currentLocation.longitude;

      const currentLoc = {
        latitude: currentLatitude,
        longitude: currentLongitude,
      };
      const comparedLoc = {
        latitude: comparedLatitude,
        longitude: comparedLongitude,
      };

      const distance = haversine(currentLoc, comparedLoc);

      return { ...institution, distance };
    });
  };

  const sortedByNearest = (results: Array<Lab & { distance: number }>) => {
    return results.sort(
      (a: Lab & { distance: number }, b: Lab & { distance: number }) =>
        a.distance > b.distance ? 1 : -1,
    );
  };

  const filteredInstitutions =
    serviceId === otherLabServiceId || serviceId === otherRadioServiceId
      ? result
      : result.filter(hasSomeServiceId(serviceId));
  const institutionsWithDistance = addDistance(
    filteredInstitutions,
    currentLocation,
  );
  const sortedInstitutions = sortedByNearest(institutionsWithDistance);
  const finalInstitutions = sortedInstitutions.filter(
    inst => inst.address?.[0]?.areaId !== undefined,
  );

  return (
    <List className={cx.root}>
      {finalInstitutions.map((result: Lab) => {
        const offerDelivery = result.offerDelivery;
        const areaId = result.address?.[0]?.areaId ?? '';
        return (
          <Link
            to={{
              pathname:
                type === 'lab'
                  ? `/lab_details/${result.id}`
                  : `/radio_details/${result.id}`,
              state: {
                serviceId,
                bookingId,
                offerDelivery,
                areaId,
                referralId,
              },
            }}
            key={result.id}
            className={cx.link}
          >
            <LabRadioItem labRadio={result} />
          </Link>
        );
      })}
    </List>
  );
};

export default ResultList;
