import CustomDateField from '../../components/CustomDateField';
import { createBridge, JSONSchema } from '../../lib/utils';
import GeoPointSchema from './GeoPointSchema';

const schemaPatient: JSONSchema = {
  title: 'Update Patient Profile',
  type: 'object',
  properties: {
    name: { type: 'string', minLength: 1 },
    email: {
      type: 'string',
      format: 'email',
    },
    phoneNumber: { type: 'string' },
    birthday: {
      type: 'string',
      format: 'date',
      uniforms: {
        component: CustomDateField,
      },
    },
    gender: {
      type: 'string',
    },
    resellerCode: {
      type: 'string',
      maxLength: 4,
      pattern: '[a-zA-Z0-9]{4}|^$',
    },
    idNumber: { type: 'string' },
    idOrPassportPhoto: {
      type: 'string',
      uniforms: {
        acceptedFiles: ['image/*', 'application/pdf'],
      },
    },
    address: {
      type: 'array',
      minItems: 1,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          governorateId: {
            type: 'string',
            minLength: 1,
          },
          areaId: {
            type: 'string',
            minLength: 1,
          },
          buildingNumberName: {
            type: 'string',
            minLength: 1,
          },
          streetBlockCompound: {
            type: 'string',
            minLength: 1,
          },
          districtTownVillage: {
            type: 'string',
            minLength: 1,
          },
          floorNumber: {
            type: 'string',
          },
          appartmentNumber: {
            type: 'string',
          },
          directions: {
            type: 'string',
          },
          savedName: {
            type: 'string',
          },
          geolocation: GeoPointSchema,
        },
        required: [
          'governorateId',
          'areaId',
          'buildingNumberName',
          'streetBlockCompound',
          'districtTownVillage',
        ],
      },
    },
    married: { type: 'boolean' },
    workType: {
      type: 'string',
    },
    nationality: { type: 'string' },
    nativeLanguage: { type: 'string' },
    arabicProficiency: {
      type: 'string',
    },
    englishProficiency: {
      type: 'string',
    },
    frenchProficiency: {
      type: 'string',
    },
  },
  required: ['email', 'name', 'gender', 'phoneNumber', 'birthday'],
};

export const UpdateProfile = createBridge(schemaPatient);
