import { Grid, List, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { i18next } from '../../locales';
import BookingListItem from './BookingListItem';

type DayWithBookingsProps = {
  bookings: any[];
  dayIndex: number;
  minDateMidnight: moment.Moment;
  maxDateMidnight: moment.Moment;
  pastBookings?: boolean;
};

const DayWithBookings = ({
  bookings,
  dayIndex,
  minDateMidnight,
  maxDateMidnight,
  pastBookings,
}: DayWithBookingsProps) => {
  const { t } = useTranslation();
  const date = (pastBookings
    ? moment(maxDateMidnight).subtract(dayIndex, 'days')
    : moment(minDateMidnight).add(dayIndex, 'days')
  ).toDate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Grid container alignItems="center" style={{ padding: '10px' }}>
            <Grid item md={2} sm={12} xs={12}>
              <Typography component="span" variant="h6" color="primary">
                {new Intl.DateTimeFormat([i18next.language], {
                  weekday: 'long',
                }).format(date)}
              </Typography>
              <br />
              <Typography component="span" variant="h6" color="textPrimary">
                {date.toLocaleDateString([i18next.language], {
                  day: 'numeric',
                  month: 'long',
                })}
              </Typography>
            </Grid>

            {bookings.length ? (
              <Grid item md={10}>
                <List>
                  {bookings.map((booking: any) => (
                    <BookingListItem key={booking.id} booking={booking} />
                  ))}
                </List>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {t('booking.noBookings')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DayWithBookings;
