import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  UserRoleTypes,
  Booking,
  BookingStatuses,
  Language,
} from '../../api/types';
import { useDocument, useContextRole } from '../../api/usages';
import { isVisitCallable, isAfterVisit } from '../../lib/utils';
import getDoctorName from '../../lib/utils/getDoctorName';
import Avatar from '../Avatar';
import BookingInfo from './BookingInfo';
import BookingInfoItem from './BookingInfoItem';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    flexShrink: 0,
    margin: 5,
  },
  summary: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    alignItems: 'center',
  },
  noShowInfo: {
    margin: 5,
  },
}));

interface BookingItemProps {
  booking: Booking;
  withDoctorInfo?: boolean;
  withCallButton?: boolean;
  buttonNameCallVideo?: boolean;
}

// eslint-disable-next-line complexity
const BookingListItem = ({
  booking,
  withDoctorInfo,
  withCallButton,
  buttonNameCallVideo,
}: BookingItemProps) => {
  const { t, i18n } = useTranslation();
  const cx = useStyles();
  const [callable, setCallable] = useState(
    isVisitCallable(booking.startDate, booking.endDate),
  );

  const [callableUpdateStarted, setCallableUpdateStarted] = useState(false);
  const collectionName = withDoctorInfo ? 'doctors' : 'patients';
  const personId = withDoctorInfo ? booking.doctorId : booking.patientId;

  const role = useContextRole();

  const { result: person, error, loading }: any = useDocument(
    {
      path: `${collectionName}/${personId}`,
      dependencies: withDoctorInfo
        ? [
            {
              collectionPath: 'specialties',
              mapFrom: 'specialtyId',
              mapTo: 'specialty',
            },
          ]
        : [],
    },
    { skip: !personId },
  );

  if (error || loading) {
    return null;
  }

  let timerId: NodeJS.Timeout | number;

  let isAfter =
    isAfterVisit(booking.endDate) ||
    booking.status === BookingStatuses.FINISHED ||
    booking.status === BookingStatuses.FAILED;

  function checkIfVisitIsCallable() {
    setCallable(isVisitCallable(booking.startDate, booking.endDate));

    if (isAfterVisit(booking.endDate)) {
      clearInterval(timerId as number);
    }
  }

  if (withCallButton && !callableUpdateStarted) {
    // Check every 30 seconds if visit is callable
    timerId = setInterval(checkIfVisitIsCallable, 30 * 1000);
    setCallableUpdateStarted(true);
  }

  let callButtonShown = withCallButton && !isAfter;

  const bookingEndTime = booking?.endDate?.toDate();
  const visitedByDoctor = booking?.visitedByDoctor ?? false;
  const visitedByPatient = booking?.visitedByPatient ?? false;
  const bookingCanceled = booking.status === BookingStatuses.CANCELED;
  const displaySummary =
    booking.status === BookingStatuses.FINISHED ||
    isAfterVisit(booking.endDate);

  if (!bookingEndTime) {
    return null;
  }

  if (booking.status === BookingStatuses.CANCELED) {
    callButtonShown = false;
    isAfter = false;
  }

  const lang = i18n.language as Language;
  const personName = withDoctorInfo
    ? getDoctorName(person, lang) || getDoctorName(booking.doctorDetails, lang)
    : person.name;
  const specialty = person?.specialty || booking.doctorDetails?.specialty;
  const medTitle = person?.medTitle || booking.doctorDetails?.medTitle;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem key={booking.id} className={cx.listItem}>
            <ListItemAvatar style={{ marginRight: '15px' }}>
              <Avatar photoURL={person.photoURL} name={personName} />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                !withDoctorInfo ? (
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                      <Typography variant="subtitle1">
                        {personName && `${t('booking.appointmentWith')}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        <Box fontWeight="fontWeightBold">{personName}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="h6">
                    {t('videoCall.dr')} {personName}
                  </Typography>
                )
              }
              secondary={
                <React.Fragment>
                  {withDoctorInfo && specialty && (
                    <BookingInfoItem
                      primaryText={specialty.translations[lang]}
                    />
                  )}
                  {withDoctorInfo && medTitle && (
                    <BookingInfoItem
                      primaryText={t(`profile.title_${medTitle}`)}
                    />
                  )}
                  <BookingInfo booking={booking} />
                </React.Fragment>
              }
            />
            {callButtonShown && (
              <Button
                className={cx.button}
                component={Link}
                to={'/call/' + booking.id}
                disabled={!callable}
                color="primary"
                variant="contained"
              >
                {buttonNameCallVideo
                  ? t('booking.callVideo')
                  : t('booking.call')}
              </Button>
            )}
            {isAfter && (
              <div className={cx.summary}>
                <div>
                  {!visitedByDoctor && (
                    <Typography
                      color="error"
                      variant="body2"
                      className={cx.noShowInfo}
                    >
                      {t('booking.doctorNoShow')}
                    </Typography>
                  )}
                  {!visitedByPatient && (
                    <Typography
                      color="error"
                      variant="body2"
                      className={cx.noShowInfo}
                    >
                      {t('booking.patientNoShow')}
                    </Typography>
                  )}
                </div>
                {displaySummary && (
                  <Button
                    className={cx.button}
                    component={Link}
                    to={
                      (role === UserRoleTypes.DOCTOR
                        ? '/session-summary/'
                        : '/call-summary/') + booking.id
                    }
                    color="primary"
                    variant="contained"
                  >
                    {t('common.summary')}
                  </Button>
                )}
              </div>
            )}
            {bookingCanceled && (
              <Typography color="error" variant="body2">
                {t('booking.canceled')}
              </Typography>
            )}
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BookingListItem;
