import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import React, { RefObject, useState, useEffect } from 'react';
import { createLocalVideoTrack, LocalParticipant } from 'twilio-video';

import { getDevices } from '../../../lib/video';

type Props = {
  className?: string;
  localParticipant: LocalParticipant;
  localParticipantVideoRef: RefObject<HTMLVideoElement>;
};

export const ChooseCameraBtn = ({
  className,
  localParticipant,
  localParticipantVideoRef,
}: Props) => {
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  useEffect(() => {
    getDevices().then(({ video }) => setVideoDevices(video));
  }, [setVideoDevices]);

  useEffect(() => {
    const videoRef = localParticipantVideoRef.current;
    if (!videoRef || !selectedDeviceId) {
      return;
    }

    createLocalVideoTrack({
      deviceId: selectedDeviceId,
    }).then(localVideoTrack => {
      const tracks = Array.from(localParticipant.videoTracks.values()).map(
        p => p.track,
      );

      localParticipant.unpublishTracks(tracks);

      /**
       * Needs to be manually detached, since 'trackUnpublished' event
       * doesn't fire on localParticipant and the logic in usePublication hook won't work.
       *
       * TODO: track this issue https://github.com/twilio/twilio-video.js/issues/656
       */
      tracks.forEach(track => {
        track.detach(videoRef);
        track.stop();
      });

      localParticipant.publishTrack(localVideoTrack);

      localVideoTrack.attach(videoRef);
    });
  }, [
    selectedDeviceId,
    setSelectedDeviceId,
    localParticipantVideoRef,
    localParticipant,
  ]);

  const openMenu = (e: any) => setMenuAnchor(e.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  const handleChoose = (deviceId: string) => {
    setSelectedDeviceId(deviceId);
    closeMenu();
  };

  return (
    <>
      <Fab className={className} onClick={openMenu}>
        <SwitchVideoIcon />
      </Fab>
      <Menu anchorEl={menuAnchor} onClose={closeMenu} open={!!menuAnchor}>
        {videoDevices.map(d => (
          <MenuItem key={d.deviceId} onClick={() => handleChoose(d.deviceId)}>
            {d.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
