import { Paper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorField, ListItemField, LongTextField } from 'uniforms-material';

import { LabCategory, ReferralTypes } from '../../api/types';
import { useLabServices } from '../../api/usages';
import CustomAutocompleteField from '../CustomAutocompleteField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      '& button': {
        display: 'none',
      },
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  }),
);

interface ListItemProps {
  name: string;
  categories: LabCategory[];
  handleFieldChange: (field: string, value: any) => void;
  labReferrals: any[];
}

const LabReferralListItem = ({
  name,
  categories,
  handleFieldChange,
  labReferrals,
}: ListItemProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const index = parseInt(name);
  const labCategoryId = labReferrals[index]?.categoryId;
  const status = labReferrals[index]?.status;
  const disabled = status && status !== ReferralTypes.OPEN;

  // Don't show services used in another referral
  const usedServices = labReferrals.map(
    (referral, i) => i !== index && referral.serviceId,
  );

  const { labServices } = useLabServices(
    [
      {
        fieldPath: 'labCategoryId',
        opStr: '==',
        value: labCategoryId,
      },
    ],
    { skip: !labCategoryId },
  );

  return (
    <ListItemField name={name} removeIcon={disabled ? null : <Delete />}>
      <Paper className={cx.root}>
        <CustomAutocompleteField
          disabled={disabled}
          label={'doctorNotes.labCategory'}
          name="categoryId"
          options={categories}
          onChange={val => {
            handleFieldChange(`labReferrals.${name}.serviceId`, null);
            handleFieldChange(`labReferrals.${name}.categoryId`, val);
          }}
        />
        <ErrorField name="categoryId" />
        <CustomAutocompleteField
          disabled={disabled}
          label={'doctorNotes.labService'}
          name="serviceId"
          options={
            labCategoryId
              ? labServices.filter(
                  service => !usedServices.includes(service.id),
                )
              : []
          }
        />
        <ErrorField name="serviceId" />
        <LongTextField
          disabled={disabled}
          label={t<string>('doctorNotes.note')}
          name="note"
        />
      </Paper>
    </ListItemField>
  );
};

export default LabReferralListItem;
