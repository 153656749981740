import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm, AutoField, SubmitField } from 'uniforms-material';

import { doSignInWithEmailAndPassword } from '../api/actions/auth';
import SignInSchema from '../api/schemas/SignIn';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { goToLandingByContextRole } from '../lib/goToRouteHelpers';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '40px auto 0',
    },
    wrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },
    header: {
      marginBottom: `32px`,
    },
  }),
);

const AdminSignIn = () => {
  const cx = useStyles();
  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    doSignInWithEmailAndPassword({
      ...values,
      profileType: 'admin',
      onAuthorized: goToLandingByContextRole,
    });
  };
  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <Box className={cx.header}>
            <Title title="Admin panel" />
          </Box>
          <AutoForm schema={SignInSchema} onSubmit={handleSubmit}>
            <AutoField name="email" label={t('profile.email')} />
            <AutoField name="password" label={t('profile.password')} />
            <Box>
              <SubmitField color="primary" label={t('common.submit')} />
            </Box>
          </AutoForm>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default AdminSignIn;
