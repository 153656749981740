import moment from 'moment';

import {
  auth,
  mergeData,
  writeData,
  existsDoc,
  removeData,
  timestampNow,
} from '../../lib/firebase';
import { createAction } from '../../lib/utils';

export const createSchedule = createAction({
  type: 'createSchedule',
  async callback(schdeule, timeslot: number) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const doctorId = user.uid;
    const { weekNo, daysData, isTemplate } = schdeule;
    let { startDate, endDate } = schdeule;

    if (startDate.toDate) {
      startDate = moment(startDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (endDate.toDate) {
      endDate = moment(endDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
    }

    if (isTemplate) {
      return await mergeData(['schedules', doctorId], {
        updatedAt: timestampNow(),
        doctorId,
        timeslot: Number(timeslot),
        daysData,
      });
    }

    if (!isTemplate && !(await existsDoc(`schedules/${doctorId}`))) {
      return await writeData(['schedules', doctorId], {
        updatedAt: timestampNow(),
        doctorId,
        timeslot: Number(timeslot),
      });
    }

    const weekId = `${moment(startDate).week()}-${moment(
      startDate,
    ).weekYear()}`;

    return await writeData(['schedules', doctorId, 'weeks', weekId], {
      doctorId,
      timeslot: Number(timeslot),
      weekNo,
      startDate,
      endDate,
      daysData,
    });
  },
});

export const updateTimeslot = createAction({
  type: 'updateTimeslot',
  async callback(timeslot: number) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const doctorId = user.uid;
    if (!(await existsDoc(`schedules/${doctorId}`))) {
      return await writeData(['schedules', doctorId], {
        updatedAt: timestampNow(),
        doctorId,
        timeslot: Number(timeslot),
      });
    }
    return await mergeData(['schedules', doctorId], {
      updatedAt: timestampNow(),
      doctorId,
      timeslot: Number(timeslot),
    });
  },
});

export const removeSchedule = createAction({
  type: 'removeSchedule',
  async callback(schdeule) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const doctorId = user.uid;
    let { startDate } = schdeule;

    if (startDate.toDate) {
      startDate = moment(startDate.toDate()).format('YYYY-MM-DDTHH:mm:ss');
    }

    const weekId = `${moment(startDate).week()}-${moment(
      startDate,
    ).weekYear()}`;

    return await removeData(['schedules', doctorId, 'weeks', weekId]);
  },
});

export const removeBookings = createAction({
  type: 'removeBookings',
  async callback(bookings) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }

    for (const booking of bookings) {
      await removeData(['bookings', booking.id]);
    }
  },
});
