import { isEqual, flatten, uniq } from 'lodash';
import { collectionData, docData } from 'rxfire/firestore';
import { of, combineLatest } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import {
  catchError,
  map,
  switchMap,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';

import { db, getQueryRef } from '../../lib/firebase';
import { AnyRecord } from '../../lib/types';
import { normalize } from '../../lib/utils';
import { showErrorToast } from '../actions/uiControls';
import { Query } from '../types';
import { DepConfig, mergeOriginalDocWithDeps } from './document';

type Dependency = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type UseCollectionParams = {
  collectionPath: string;
  query?: Query;
  dependencies?: DepConfig[];
  skip?: boolean;
};

export type UseCollectionOptions = {
  skip?: boolean;
};

type UseCollectionResult<T> = {
  results: T[];
  loading: boolean;
  error: null | Error;
};

const mergeOriginalDocsWithDeps = <T extends AnyRecord>(
  depsConfigNormalized: Record<string, DepConfig>,
  originalDocs: T[],
  dependencies: Dependency[],
) => {
  const depsNormalized = normalize('id', dependencies);

  return originalDocs.map(originalDoc =>
    mergeOriginalDocWithDeps(depsConfigNormalized, originalDoc, depsNormalized),
  );
};

// TODO: optimize this function to fetch multiple
// documents at once when we have custom IDs implemented
const fetchDependencies = <T extends AnyRecord>(
  depsConfigNormalized: Record<string, DepConfig>,
  originalDocs: T[],
) => {
  const depsPaths = uniq(
    flatten(
      originalDocs.map(doc =>
        Object.values(depsConfigNormalized).map(
          depConfig => `${depConfig.collectionPath}/${doc[depConfig.mapFrom]}`,
        ),
      ),
    ),
  );

  const deps$ = depsPaths.map(path => docData<Dependency>(db.doc(path), 'id'));

  return combineLatest(deps$).pipe(
    map(deps =>
      mergeOriginalDocsWithDeps(depsConfigNormalized, originalDocs, deps),
    ),
  );
};

const initialResult = { results: [], loading: true, error: null };

export function useCollection<T extends AnyRecord>(
  params: UseCollectionParams,
  options: UseCollectionOptions = { skip: false },
) {
  const { collectionPath, dependencies: depsConfig = [] } = params;

  const depsConfigNormalized = normalize('mapFrom', depsConfig);

  const result = useObservable<
    UseCollectionResult<T>,
    [UseCollectionOptions, Query | undefined]
  >(
    (state$, inputs$) =>
      inputs$.pipe(
        distinctUntilChanged(isEqual),
        filter(([opts]) => !opts.skip),
        switchMap(([, query]) =>
          collectionData<T>(getQueryRef(collectionPath, query), 'id'),
        ),
        switchMap(results =>
          depsConfig.length
            ? fetchDependencies(depsConfigNormalized, results)
            : of(results),
        ),
        map(results => ({ results, loading: false, error: null })),
        catchError(error => {
          showErrorToast(`${error}`);
          return of({ results: [], loading: false, error });
        }),
      ),
    initialResult,
    [options, params.query],
  );

  return result;
}
