import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import DetailInfo from '../../components/LabRadio/common/DetailInfo';
import LayoutDefault from '../../components/LayoutDefault';
import Title from '../../components/Title';

const LabDetails = () => {
  const { t } = useTranslation();
  const { labId } = useParams();

  const {
    state: { serviceId, bookingId, offerDeiliver, areaId, referralId },
  } = useLocation<{
    serviceId: string;
    bookingId: string;
    offerDeiliver: boolean;
    areaId: string;
    referralId: string;
  }>();

  return (
    <LayoutDefault>
      <Title title={t('labs.labDetails')} />
      <DetailInfo
        id={labId}
        type="lab"
        serviceId={serviceId}
        bookingId={bookingId}
        offerDeiliver={offerDeiliver}
        areaId={areaId}
        referralId={referralId}
      />
    </LayoutDefault>
  );
};

export default LabDetails;
