import { Currency, PaymentWallet, Price } from '../../api/types/Payments';
import { t } from '../../locales/index';
export const centsToDollars = (amount: string | number = 0): string =>
  (+amount / 100).toFixed(2);

export const formatPrice = (price: {
  amount: number | null;
  currency: Price['currency'];
}): string => {
  switch (price.currency) {
    case Currency.EGP:
    default:
      return `${centsToDollars(price.amount || 0)} ${
        t(`common.currency${price.currency}`) || t('common.currencyEGP')
      }`;
  }
};

export const getWalletAmount = (
  wallet?: PaymentWallet | null,
  currency?: Currency | null,
): number => {
  if (!wallet || !currency) {
    return 0;
  }
  return wallet?.accounts?.[currency] ?? 0;
};
