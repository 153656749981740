import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, styled } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../api/actions/uiControls';
import { Doctor, Language } from '../api/types';
import { useDoctors } from '../api/usages';
import ManagementButtons from '../components/Admin/ManagementButtons';
import AvatarCustom from '../components/Avatar';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { Timestamp } from '../lib/firebase';
import getDoctorName from '../lib/utils/getDoctorName';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '700px',
    margin: '40px auto 0',
  },
  avatar: {
    marginRight: 8,
  },
});

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminManageDoctors = () => {
  const { t, i18n } = useTranslation();
  const cx = useStyles();

  const { doctors, loading, error } = useDoctors();

  const doctorCreatedAt: any = doctors.map((doctor: Doctor) => {
    let createdAt = null;
    if (doctor.createdAt) {
      createdAt = doctor.createdAt.seconds
        ? new Timestamp(doctor.createdAt.seconds, doctor.createdAt.nanoseconds)
        : doctor.createdAt;
    }

    return createdAt
      ? { ...doctor, createdAt: createdAt?.toDate() }
      : { ...doctor, createdAt: new Date(190000000000) };
  });

  const sortedDoctors = orderBy(doctorCreatedAt, ['createdAt', 'asc']);

  useEffect(() => {
    error && showErrorToast(error.message);
  }, [error]);

  const lang = i18n.language as Language;

  return (
    <LayoutDefault>
      <Title title={t('admin.manageDoctors')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <List>
            {loading
              ? null
              : sortedDoctors.map((doctor: Doctor) => (
                  <ListItem key={doctor.id}>
                    <ListItemAvatar className={cx.avatar}>
                      <AvatarCustom
                        photoURL={doctor.photoURL}
                        name={getDoctorName(doctor, lang)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={getDoctorName(doctor, lang)}
                      secondary={doctor.email}
                    />
                    <ManagementButtons
                      collection="doctors"
                      id={doctor.id}
                      verified={!!doctor.verified}
                      testing={doctor.testing}
                    />
                  </ListItem>
                ))}
          </List>
        </Container>
      </Card>
    </LayoutDefault>
  );
};

export default AdminManageDoctors;
