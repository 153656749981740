import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FullQuery,
  SimpleQuery,
  BookingStatuses,
  Booking,
} from '../../api/types';
import {
  useBookings,
  useUnrealizedBookings,
  useUserId,
} from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import DayWithBookings from './DayWithBookings';

const PastBookingsCallendarView = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  // currentTime is in state to avoid infinite updates
  // added 45 minutes to take into account finished booking before endDate time
  const [currentTime] = useState(moment().add(45, 'minute').toDate());
  // dateInPast is midnight 31 days ago
  const [dateInPast, setDateInPast] = useState(
    moment().startOf('day').subtract(31, 'days'),
  );

  const queryPast: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'endDate',
      opStr: '<',
      value: Timestamp.fromDate(currentTime),
    },
    {
      fieldPath: 'endDate',
      opStr: '>',
      value: Timestamp.fromDate(dateInPast.toDate()),
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [
        BookingStatuses.FINISHED,
        BookingStatuses.FAILED,
        BookingStatuses.CANCELED,
      ],
    },
  ];

  const fullQueryPast: FullQuery = {
    where: queryPast,
    orderBy: ['endDate', 'desc'],
  };

  // Bookings visited by no one or only the patient
  // are visible only one hour after their completion,
  // therefore we fetch them separately.
  const queryDoctorMissedBookings: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [BookingStatuses.OPENED, BookingStatuses.GOING],
    },
    {
      fieldPath: 'visitedByDoctor',
      opStr: '==',
      value: false,
    },
    {
      fieldPath: 'endDate',
      opStr: '<',
      value: Timestamp.fromDate(new Date()),
    },
  ];

  const fullQueryDoctorMissedBookings: FullQuery = {
    where: queryDoctorMissedBookings,
    orderBy: ['endDate', 'desc'],
  };

  const { error, loading, bookings } = useBookings(fullQueryPast, {
    skip: !userId,
  });

  const {
    error: doctorMissedBookingsError,
    loading: doctorMissedBookingsLoading,
    bookings: doctorMissedBookings,
  } = useBookings(fullQueryDoctorMissedBookings, { skip: !userId });

  const {
    error: unrealizedError,
    loading: unrealizedLoading,
    unrealizedBookings,
  } = useUnrealizedBookings(fullQueryPast, {
    skip: !userId,
  });

  if (error || unrealizedError || doctorMissedBookingsError) {
    return null;
  }

  if (loading || unrealizedLoading || doctorMissedBookingsLoading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  const allBookings = bookings
    .concat(unrealizedBookings)
    .concat(doctorMissedBookings);

  if (!allBookings.length) {
    return <h1>{t('booking.emptyPastVisits')}</h1>;
  }
  const midnightToday = moment().startOf('day');
  const midnightTomorrow = moment().startOf('day').add(1, 'days');
  const pastDateMidnight = moment(dateInPast).startOf('day');
  const differenceInDays = midnightTomorrow.diff(pastDateMidnight, 'days');

  const daysWithBookings: Booking[][] = [
    ...Array(differenceInDays),
  ].map(() => []);

  allBookings.forEach((booking: Booking) => {
    const dayindex = midnightTomorrow.diff(
      moment(booking.startDate.toDate()),
      'days',
    );
    daysWithBookings[dayindex].push(booking);
  });

  const handleLoadMore = () => {
    setDateInPast(dateInPast => moment(dateInPast).subtract(31, 'days'));
  };

  return (
    <>
      {daysWithBookings.map((allBookings, index) =>
        allBookings.length ? (
          <DayWithBookings
            key={index}
            bookings={allBookings}
            dayIndex={index}
            minDateMidnight={pastDateMidnight}
            maxDateMidnight={midnightToday}
            pastBookings
          />
        ) : null,
      )}
      <Grid container justify="center" style={{ marginTop: '15px' }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            {t('booking.loadThirtyOneDaysMore')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PastBookingsCallendarView;
