import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

function CustomSelectField(props: any) {
  const {
    value = '',
    onChange,
    options,
    label,
    disabled,
    readOnly = false,
    required,
    handleChange,
    fullWidth,
    className,
    defaultValue,
  } = props;
  const { t } = useTranslation();
  function onSelectChange({ target: { value } }: any) {
    handleChange && handleChange(value);
    onChange(value);
  }

  return (
    <FormControl
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      className={className}
    >
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        fullWidth
        labelId={label}
        id={label}
        {...(value && { value })}
        onChange={onSelectChange}
        inputProps={{ readOnly }}
        {...(defaultValue && { defaultValue })}
      >
        {options.length > 0
          ? options.map((option: any, i: number) => {
              const value = get(option, 'value', '');
              const name = get(option, 'name', '');
              const id = get(option, 'id', '');
              const label = get(option, 'label', '');
              return (
                <MenuItem key={i} value={value ? value : id}>
                  {label ? t(label) : name}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
}

export default connectField(CustomSelectField);
