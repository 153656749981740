import { auth, db, mergeData, writeData } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { HealthRecords, Record, RecordType } from '../types';

export const updateHealthRecords = createAction({
  type: 'updateHealthRecords',
  async callback(records: HealthRecords) {
    const user = auth.currentUser;
    if (user) {
      return await writeData('healthRecords/' + user.uid, records);
    }
    throw new Error('No auth User');
  },
});

async function upsertRecord({ values, dates }: Record, type: RecordType) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No auth User');
  }

  const healthRecordsData = (
    await db.collection('healthRecords').doc(user.uid).get()
  ).data();

  if (type === RecordType.WEIGHT) {
    const recordsArray = healthRecordsData?.weights || {
      values: [],
      dates: [],
    };

    const newRecords = {
      values: [...recordsArray?.values, values],
      dates: [...recordsArray?.dates, dates],
    };

    return mergeData(`healthRecords/${user.uid}`, {
      ...healthRecordsData,
      weights: newRecords,
    });
  }

  if (type === RecordType.HEIGHT) {
    const recordsArray = healthRecordsData?.heights || {
      values: [],
      dates: [],
    };
    const newRecords = {
      values: [...recordsArray?.values, values],
      dates: [...recordsArray?.dates, dates],
    };
    return mergeData(`healthRecords/${user.uid}`, {
      ...healthRecordsData,
      heights: newRecords,
    });
  }
  throw new Error('Unknown type of record');
}

async function deleteHealtRecord(dates: any, type: RecordType) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No auth User');
  }

  const healthRecordsData = (
    await db.collection('healthRecords').doc(user.uid).get()
  ).data();

  const recordsArray =
    type === RecordType.WEIGHT
      ? healthRecordsData?.weights
      : healthRecordsData?.heights;

  for (let i = 0; i < dates.length; i++) {
    const indexToDelete = recordsArray.dates.findIndex(
      (j: { seconds: number; nanoseconds: number }) =>
        j.seconds === dates[i].seconds &&
        j.nanoseconds === dates[i].nanoseconds,
    );

    if (indexToDelete === -1) {
      throw new Error('Unknown type of record');
    }

    recordsArray.dates.splice(indexToDelete, 1);
    recordsArray.values.splice(indexToDelete, 1);
  }

  if (type === RecordType.WEIGHT) {
    return mergeData(`healthRecords/${user.uid}`, {
      ...healthRecordsData,
      weights: recordsArray,
    });
  }
  if (type === RecordType.HEIGHT) {
    return mergeData(`healthRecords/${user.uid}`, {
      ...healthRecordsData,
      heights: recordsArray,
    });
  }
  throw new Error('Unknown type of record');
}

export const addNewWeight = createAction({
  type: 'addNewRecord',
  callback: (record: Record) => upsertRecord(record, RecordType.WEIGHT),
});

export const addNewHeight = createAction({
  type: 'addNewRecord',
  callback: (record: Record) => upsertRecord(record, RecordType.HEIGHT),
});

export const deleteHealthRecords = createAction({
  type: 'deleteHealthRecords',
  callback: (record: any, type: RecordType) => deleteHealtRecord(record, type),
});
