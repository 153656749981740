import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations, Language } from '../api/types';

export type Option = {
  id: string;
  name: string;
  translations: Translations;
  [key: string]: any;
};

export type Props = {
  label: string;
  options: Option[];
  onChange: (value: any) => void;
  textFieldProps?: TextFieldProps;
  value?: any;
  disabled?: boolean;
};

const CustomAutocomplete = (props: Props) => {
  const { options, onChange, label, value, textFieldProps, disabled } = props;
  const { t, i18n } = useTranslation();

  const autocompleteOptions = options.map(o => ({
    value: o.id,
    label: o.translations[i18n.language as Language] ?? o.translations.en,
  }));

  const selectedOption = autocompleteOptions.find(o => o.value === value);

  return (
    <Autocomplete
      value={selectedOption?.label ?? ''}
      onChange={(e: any, val: string | null) => {
        onChange(autocompleteOptions.find(o => o.label === val)?.value);
      }}
      options={autocompleteOptions.map(o => o.label)}
      renderInput={params => (
        <TextField {...params} label={t(label)} {...textFieldProps} />
      )}
      disableClearable
      disabled={disabled}
    />
  );
};

export default CustomAutocomplete;
