import { JSONSchema7Definition } from 'json-schema';

import { createBridge, JSONSchema } from '../../lib/utils';
import GeoPointSchema from './GeoPointSchema';

const propertiesCommon: {
  [key: string]: JSONSchema7Definition;
} = {
  name: { type: 'string', minLength: 1 },
  phoneNumber: { type: 'string' },
  photoURL: {
    type: 'string',
  },
  contactPerson: {
    type: 'string',
  },
  receptionPhone: {
    type: 'string',
    pattern: '^[0-9]*$',
    minLength: 1,
  },
  openTimes: {
    type: 'string',
    minLength: 1,
  },
  about: {
    type: 'string',
  },
  commercialRegistration: {
    type: 'string',
    minLength: 1,
  },
  commercialRegistrationUpload: {
    type: 'string',
    minLength: 1,
  },
  mohLicence: {
    type: 'string',
    minLength: 1,
  },
  mohLicenceUpload: {
    type: 'string',
    minLength: 1,
  },
  address: {
    type: 'array',
    minItems: 1,
    uniqueItems: true,
    items: {
      type: 'object',
      properties: {
        governorateId: {
          type: 'string',
          minLength: 1,
        },
        areaId: {
          type: 'string',
          minLength: 1,
        },
        buildingNumberName: {
          type: 'string',
          minLength: 1,
        },
        streetBlockCompound: {
          type: 'string',
          minLength: 1,
        },
        districtTownVillage: {
          type: 'string',
          minLength: 1,
        },
        floorNumber: {
          type: 'string',
        },
        appartmentNumber: {
          type: 'string',
        },
        directions: {
          type: 'string',
        },
        savedName: {
          type: 'string',
        },
        geolocation: GeoPointSchema,
      },
      required: [
        'governorateId',
        'areaId',
        'buildingNumberName',
        'streetBlockCompound',
        'districtTownVillage',
      ],
    },
  },
  arabicProficiency: {
    type: 'string',
  },
  englishProficiency: {
    type: 'string',
  },
  frenchProficiency: {
    type: 'string',
  },
  otherLang: {
    type: 'string',
  },
  offerDelivery: {
    type: 'boolean',
  },
  resellerCode: {
    type: 'string',
    maxLength: 4,
    pattern: '[a-zA-Z0-9]{4}|^$',
  },
};

const schemaLAB: JSONSchema = {
  title: 'Create Profile Lab or Radio',
  type: 'object',
  properties: {
    ...propertiesCommon,
  },
  required: [
    'phoneNumber',
    'name',
    'openTimes',
    'receptionPhone',
    'commercialRegistration',
    'commercialRegistrationUpload',
    'mohLicence',
    'mohLicenceUpload',
    'about',
    'photoURL',
    'address',
  ],
};

export const CreateLabProfileWithAuth = createBridge(schemaLAB);

const schemaEmailLabRadio: JSONSchema = {
  title: 'Guest Create Profile',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
    },
    password: {
      type: 'string',
      minLength: 6,
      pattern: '^[^\\s]*$',
      uniforms: {
        type: 'password',
      },
    },
    confirmPassword: {
      const: {
        $data: '1/password',
      },
      type: 'string',
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'password', 'confirmPassword'],
};

export const CreateLabRadioEmail = createBridge(schemaEmailLabRadio);

const schemaLabNoAuth: JSONSchema = {
  title: 'Create Profile Lab with no auth',
  type: 'object',
  properties: propertiesCommon,
  required: [
    'phoneNumber',
    'name',
    'openTimes',
    'receptionPhone',
    'commercialRegistration',
    'commercialRegistrationUpload',
    'mohLicence',
    'mohLicenceUpload',
    'about',
    'photoURL',
    'address',
  ],
};

export const CreateLabProfileNoAuth = createBridge(schemaLabNoAuth);

const schemaLabServices: JSONSchema = {
  title: 'Lab Services',
  type: 'object',
  properties: {
    services: {
      type: 'array',
      minItems: 1,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          tests: {
            type: 'array',
            minItems: 0,
            items: {
              type: 'string',
            },
          },
        },
        required: ['categoryId', 'tests'],
      },
    },
  },
};
export const CreateLabServices = createBridge(schemaLabServices);
