import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudOffIcon from '@material-ui/icons/LinkOff';
import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';
import React, { FunctionComponent, useCallback } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { showErrorToast } from '../../api/actions/uiControls';
import { readFileAsUrl } from '../../lib/fileHelpers';
import { FileBlob, FileEntry } from '../../lib/firebase';
import { t } from '../../locales';

type UploadAreaBasicProp = {
  acceptedFiles?: string[];
  filesLimit?: number;
  fileEntries: FileEntry[]; //Currently loaded files
  maxFileSize?: number; //in bytes
  inputProps?: any;
  style?: any;
  disabled?: boolean;
  onAdd: (fileEntries: FileEntry[]) => void;
  onDelete: (fileEntry: FileEntry, index: number) => void;
  onDrop?: (file: FileBlob, event: Event) => void;
  onDropRejected?: (files: FileRejection[], event: Event) => void;
};

const useStyles = makeStyles(({ palette, shape, spacing }: Theme) =>
  createStyles({
    '@keyframes progress': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '100%': {
        backgroundPosition: '-70px 0',
      },
    },
    root: {
      position: 'relative',
      backgroundColor: palette.background.paper,
      border: 'dashed',
      borderColor: palette.divider,
      borderRadius: shape.borderRadius,
      boxSizing: 'border-box',
      cursor: (props: any) => props.cursor,
      overflow: 'hidden',
    },
    active: {
      animation: '$progress 2s linear infinite !important',
      // eslint-disable-next-line max-len
      backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
      backgroundSize: '150% 100%',
      border: 'solid',
      borderColor: palette.primary.light,
    },
    invalid: {
      // eslint-disable-next-line max-len
      backgroundImage: `repeating-linear-gradient(-45deg, ${palette.error.light}, ${palette.error.light} 25px, ${palette.error.dark} 25px, ${palette.error.dark} 50px)`,
      borderColor: palette.error.main,
    },
    textContainer: {
      textAlign: 'center',
    },
    text: {
      marginBottom: spacing(3),
      marginTop: spacing(3),
    },
    icon: {
      width: 51,
      height: 51,
      color: palette.text.secondary,
    },
  }),
);

const createDropRejectMessage = (
  rejectedFile: FileRejection,
  acceptedFiles: string[],
  maxFileSize: number,
) => {
  let message = t('errors.fileRejected', { fileName: rejectedFile.file.name });

  if (!acceptedFiles.includes(rejectedFile.file.type)) {
    message += t('errors.fileTypeRejected', {
      fileName: rejectedFile.file.name,
    });
  }
  if (rejectedFile.file.size > maxFileSize) {
    message += ' ' + t('errors.fileTooBig') + prettyBytes(maxFileSize) + '. ';
  }
  return message;
};

export const UploadAreaBasic: FunctionComponent<UploadAreaBasicProp> = ({
  acceptedFiles = ['image/*', 'video/*', 'application/*'],
  children,
  fileEntries,
  filesLimit = 3,
  inputProps,
  maxFileSize = 5000000,
  onAdd,
  style,
  disabled = false,
  onDrop,
  onDropRejected,
}) => {
  const handleDropAccepted = useCallback(
    async (acceptedFiles, evt) => {
      if (disabled) {
        return;
      }
      if (
        filesLimit > 1 &&
        fileEntries.length + acceptedFiles.length > filesLimit
      ) {
        showErrorToast(t('errors.fileLimitExceed', { filesLimit }));
        return;
      }

      // Notify Drop event
      if (onDrop) {
        onDrop(acceptedFiles, evt);
      }

      // Retrieve fileEntries data
      const fileObjs = await Promise.all(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        acceptedFiles.map(
          async (file: any): Promise<FileEntry> => {
            const url = await readFileAsUrl(file);
            return {
              file,
              url,
            };
          },
        ),
      );

      if (onAdd) {
        onAdd(fileObjs as FileEntry[]);
      }
    },
    [fileEntries, filesLimit, onAdd, onDrop, disabled],
  );
  const handleDropRejected = useCallback(
    (rejectedFiles: [FileRejection], evt) => {
      if (disabled) {
        return;
      }
      let message = '';
      if (fileEntries.length + rejectedFiles.length > filesLimit) {
        showErrorToast(t('errors.fileLimitExceed', { filesLimit }));
        return;
      }
      rejectedFiles.forEach(rejectedFile => {
        message = createDropRejectMessage(
          rejectedFile,
          acceptedFiles,
          maxFileSize,
        );
      });

      if (onDropRejected) {
        onDropRejected(rejectedFiles, evt);
      }
      showErrorToast(message);
    },
    [
      fileEntries,
      filesLimit,
      acceptedFiles,
      maxFileSize,
      onDropRejected,
      disabled,
    ],
  );
  const styleProps = {
    cursor: disabled ? 'default' : 'pointer',
  };
  const cx = useStyles(styleProps);
  const acceptFiles = acceptedFiles?.join(',');
  const isMultiple = filesLimit > 1;
  let selectMsg =
    filesLimit > 1
      ? t('common.dragFilesOrClickHere')
      : t('common.dragFileOrClickHere');

  if (disabled) {
    selectMsg = t('common.noFileUploaded');
  }

  const isContent = React.Children.toArray(children).some(child => !!child);

  return (
    <>
      <Dropzone
        disabled={disabled}
        accept={acceptFiles}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        maxSize={maxFileSize}
        multiple={isMultiple}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={clsx(
              cx.root,
              isDragActive && cx.active,
              isDragReject && cx.invalid,
            )}
            style={style}
            title={selectMsg}
          >
            <input {...inputProps} {...getInputProps()} />

            {isContent ? (
              children
            ) : (
              <div className={cx.textContainer}>
                {disabled ? (
                  <CloudOffIcon className={cx.icon} />
                ) : (
                  <CloudUploadIcon className={cx.icon} />
                )}

                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  component="p"
                >
                  {selectMsg}
                </Typography>
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </>
  );
};
