import { Query, RadioCategory, RadioService } from '../types';
import { Radio } from '../types/Radio';
import { UseCollectionOptions, useCollection } from './collection';
import { useVerifiedTestingQuery } from './utils/useVerifiedTestingQuery';

export const useRadioCategories = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioCategories, loading, error } = useCollection<
    RadioCategory
  >(
    {
      collectionPath: 'radioCategories',
      query,
    },
    options,
  );

  return { radioCategories, loading, error };
};

export const useRadioServices = (
  query?: Query,
  options?: UseCollectionOptions,
) => {
  const { results: radioServices, loading, error } = useCollection<
    RadioService
  >(
    {
      collectionPath: 'radioServices',
      query,
    },
    options,
  );

  return { radioServices, loading, error };
};

export function useRadios(query?: Query, options?: UseCollectionOptions) {
  const { results, loading, error } = useCollection<Radio>(
    {
      collectionPath: 'radios',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    radios: results,
  };
}

export function useVerifiedRadios(
  query?: Query,
  options?: UseCollectionOptions,
) {
  const newQuery = useVerifiedTestingQuery(query);

  return useRadios(newQuery, options);
}
