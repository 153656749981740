import { Backdrop, CircularProgress, List } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FullQuery, SimpleQuery, BookingStatuses } from '../../api/types';
import { useUserId, useBookings } from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import BookingListItem from './BookingListItem';

const TodaysBookingsListView = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  // currentTime is in state to avoid infinite updates
  const [currentTime] = useState(moment().toDate());
  const midnightTomorrow = moment().startOf('day').add(1, 'days').toDate();

  const queryUpcoming: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'endDate',
      opStr: '>=',
      value: Timestamp.fromDate(currentTime),
    },
    {
      fieldPath: 'endDate',
      opStr: '<=',
      value: Timestamp.fromDate(midnightTomorrow),
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [BookingStatuses.OPENED, BookingStatuses.GOING],
    },
  ];

  const fullQueryUpcoming: FullQuery = {
    where: queryUpcoming,
    orderBy: ['endDate', 'asc'],
  };

  const { error, loading, bookings } = useBookings(fullQueryUpcoming, {
    skip: !userId,
  });

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <List>
      {bookings.length ? (
        bookings.map((booking: any) => (
          <BookingListItem
            key={booking.id}
            booking={booking}
            withCallButton
            buttonNameCallVideo
          />
        ))
      ) : (
        <h1>{t('booking.emptyTodayVisits')}</h1>
      )}
    </List>
  );
};

export default TodaysBookingsListView;
