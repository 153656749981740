import { get, omit } from 'lodash';

import RouteTypes from '../../constants/routes';
import {
  auth,
  EmailAuthProvider,
  PhoneAuthProvider,
  mergeData,
  writeData,
  db,
  mobileAuth,
  mobileReauthenticate,
} from '../../lib/firebase';
import { goToLandingByContextRole } from '../../lib/goToRouteHelpers';
import {
  clearLoggedInSessionDataAndLogOut,
  getCurrentContextRole,
  setSessionData,
  forceTokenRefresh,
} from '../../lib/sessionData';
import { createAction } from '../../lib/utils';
import { t } from '../../locales';
import { UserProfile, UserRoleTypes } from '../types';
import * as navigation from './navigation';
import { showInfoToast, showSuccessToast, showErrorToast } from './uiControls';

interface AuthEmailAndPassword {
  email: string;
  password: string;
}

interface ProfileDetails {
  email: string;
  password: string;
  phone?: string;
  name: string;
  gender: string;
  birthday: string;
  photoURL?: string;
}

interface AuthEmail {
  email: string;
}

interface AuthPassword {
  password: string;
  currentPassword?: string;
}

interface AuthUpdateEmail {
  newEmail: string;
  password: string;
}

// log out
export const signOut = createAction({
  type: 'signOut',
  callback: clearLoggedInSessionDataAndLogOut,
  complete: () => navigation.replace(RouteTypes.HOME),
});

export const reauthenticateWithPhoneNumber = createAction({
  type: 'reauthenticateWithPhoneNumber',
  multistep: true,
  callback: () => {
    return mobileReauthenticate();
  },
  complete: async ([confirmationResult, code], args) => {
    await confirmationResult.confirm(code);
    const onReauthenticated = get(args, '[0].onAuthorized', null);
    if (typeof onReauthenticated === 'function') {
      onReauthenticated();
    }
  },
});

// verify user by phone
export const verifyPhone = createAction({
  type: 'verifyPhone',
  multistep: true,
  callback: ({ phoneNumber }) => {
    return mobileAuth(phoneNumber);
  },
  complete: async ([confirmationResult, code], args) => {
    await confirmationResult.confirm(code);
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const profileType = get(args, '[0].profileType', UserRoleTypes.PATIENT);
    await setSessionData({ contextRole: profileType });
    const onAuthorized = get(args, '[0].onAuthorized', null);
    if (typeof onAuthorized === 'function') {
      await onAuthorized({ user, profileType });
    }
  },
});

// log In
export const doSignInWithEmailAndPassword = createAction({
  type: 'signIn',
  callback({ email, password }: AuthEmailAndPassword) {
    return auth.signInWithEmailAndPassword(email, password);
  },
  async complete(result, args) {
    const profileType = get(args, '[0].profileType', UserRoleTypes.PATIENT);
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    if (profileType === UserRoleTypes.ADMIN) {
      const token = await user.getIdTokenResult();
      if (!token.claims.admin) {
        showErrorToast(t('admin.youCantAccessAdminPanel'));
        await auth.signOut();
        return;
      }
    }
    await setSessionData({ contextRole: profileType });
    const onAuthorized = get(args, '[0].onAuthorized', null);
    if (typeof onAuthorized === 'function') {
      await onAuthorized({ user, profileType });
    }
  },
});

// Fill patient profile
export const doCreateProfile = createAction({
  type: 'sign_up',
  async callback(
    profile: ProfileDetails,
    profileType: UserRoleTypes,
    _onAuthorized,
    onLinkCredsError: (e: any) => void,
  ) {
    const user = auth.currentUser;
    if (user) {
      const { password, email } = profile;
      if (!user.email && email) {
        const credential = EmailAuthProvider.credential(email, password);
        await user.linkWithCredential(credential).catch((e: any) => {
          onLinkCredsError(e);
          throw e;
        });
      }
      const shrinkedProfile = omit(profile, ['password', 'confirmPassword']);
      const shrinkedProfileWithPhone = {
        ...shrinkedProfile,
        phoneNumber: user.phoneNumber,
      };
      switch (profileType) {
        case UserRoleTypes.DOCTOR:
          await writeData('doctors/' + user.uid, shrinkedProfileWithPhone);
          break;
        case UserRoleTypes.LAB:
          await writeData('labs/' + user.uid, shrinkedProfileWithPhone);
          break;
        case UserRoleTypes.RADIO:
          await writeData('radios/' + user.uid, shrinkedProfileWithPhone);
          break;
        case UserRoleTypes.PATIENT:
          await writeData('patients/' + user.uid, {
            ...shrinkedProfileWithPhone,
            name: profile.name ?? '', // Patients are fetched in `getPatients` cloud function, which uses `orderBy` and orders the docs the by `name` field.
            // Due to how the `orderBy` clause works, docs without the `name` will be filtered out, therefore we set `name` to the empty string.
          });
          break;
        default:
          throw new TypeError(
            '`role` field is required to create the proper profile',
          );
      }

      if (!user.emailVerified) {
        try {
          await user.sendEmailVerification();
        } catch (e) {
          console.error('sendEmailVerification', e);
        }
      }
      setTimeout(() => forceTokenRefresh(), 5000);
      return;
    }
    throw new Error('User not authenticated');
  },
  async complete(_, args) {
    const user = auth.currentUser;
    const profileType = get(args, '[1]', UserRoleTypes.PATIENT);
    const onAuthorized = get(args, '[2]', null);
    if (typeof onAuthorized === 'function') {
      await onAuthorized({ user, profileType });
    }
  },
});

// Password Reset
export const doPasswordReset = createAction({
  type: 'passwordReset',
  callback({ email }: AuthEmail) {
    const res = auth.sendPasswordResetEmail(email);
    navigation.navigate(RouteTypes.HOME);
    showInfoToast(t('auth.versificationEmailWasSend'));
    return res;
  },
});

// Password Change
export const doPasswordUpdate = createAction({
  type: 'updatePassword',
  async callback({ password, currentPassword }: AuthPassword) {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No auth User or user has no email');
    }
    if (currentPassword) {
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword,
      );
      await user.reauthenticateWithCredential(credentials);
    }
    await user.updatePassword(password);
    await goToLandingByContextRole();
    showSuccessToast(t('auth.passwordChangedSuccessfully'));
  },
});

// Email Change
export const doEmailUpdate = createAction({
  type: 'updateEmail',
  async callback({ password, newEmail }: AuthUpdateEmail) {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No auth User or user has no email');
    }
    if (password) {
      const credentials = EmailAuthProvider.credential(user.email, password);
      await user.reauthenticateWithCredential(credentials);
    }
    await user.updateEmail(newEmail);
    await user.sendEmailVerification();
    await goToLandingByContextRole();

    const patientRef = db.collection('patients').doc(user.uid);
    const doctorRef = db.collection('doctors').doc(user.uid);
    const labRef = db.collection('labs').doc(user.uid);
    const radioRef = db.collection('radios').doc(user.uid);

    try {
      await db.runTransaction(async (t: any) => {
        const patient = await t.get(patientRef);
        const doctor = await t.get(doctorRef);
        const lab = await t.get(labRef);
        const radio = await t.get(radioRef);

        const emailsWithRefs = [
          { ref: patientRef, email: patient.data()?.email },
          { ref: doctorRef, email: doctor.data()?.email },
          { ref: labRef, email: lab.data()?.email },
          { ref: radioRef, email: radio.data()?.email },
        ];

        emailsWithRefs.forEach(er => {
          if (er.email) {
            t.update(er.ref, { email: newEmail });
          }
        });
      });
    } catch (e) {
      console.error('Error updating email:', e);
    }

    showSuccessToast(t('auth.emailChangedSuccessfully'));
  },
});

export const doPhoneUpdate = createAction({
  type: 'updatePhone',
  multistep: true,
  async callback({ phoneNumber }) {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No auth User or user has no email');
    }
    return mobileAuth(phoneNumber);
  },
  complete: async ([confirmationResult, code], args) => {
    const credentials = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      code,
    );
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    await user.updatePhoneNumber(credentials);
    const profileType = get(args, '[0].profileType', UserRoleTypes.PATIENT);
    const onAuthorized = get(args, '[0].onAuthorized', null);
    if (typeof onAuthorized === 'function') {
      await onAuthorized({ user, profileType });
    }

    const patientRef = db.collection('patients').doc(user.uid);
    const doctorRef = db.collection('doctors').doc(user.uid);
    const labRef = db.collection('labs').doc(user.uid);
    const radioRef = db.collection('radios').doc(user.uid);
    const newPhoneNumber = get(args, '[0].phoneNumber', '');

    try {
      await db.runTransaction(async (t: any) => {
        const patient = await t.get(patientRef);
        const doctor = await t.get(doctorRef);
        const lab = await t.get(labRef);
        const radio = await t.get(radioRef);

        const phoneNumbersWithRefs = [
          { ref: patientRef, phoneNumber: patient.data()?.phoneNumber },
          { ref: doctorRef, phoneNumber: doctor.data()?.phoneNumber },
          { ref: labRef, phoneNumber: lab.data()?.phoneNumber },
          { ref: radioRef, phoneNumber: radio.data()?.phoneNumber },
        ];

        phoneNumbersWithRefs.forEach(pr => {
          if (pr.phoneNumber) {
            t.update(pr.ref, { phoneNumber: newPhoneNumber });
          }
        });
      });
    } catch (e) {
      console.error('Error updating phone number:', e);
    }

    showSuccessToast(t('auth.phoneChangedSuccessfully'));
  },
});

// Updating Profile
export const doProfileUpdate = createAction({
  type: 'updateProfile',
  async callback(profile: UserProfile) {
    const user = auth.currentUser;
    if (user) {
      switch (profile.kind) {
        case UserRoleTypes.DOCTOR:
          await mergeData('doctors/' + user.uid, profile);
          break;
        case UserRoleTypes.LAB:
          await mergeData('labs/' + user.uid, profile);
          break;
        case UserRoleTypes.RADIO:
          await mergeData('radios/' + user.uid, profile);
          break;
        case UserRoleTypes.PATIENT:
          await mergeData('patients/' + user.uid, {
            ...profile,
            verified: true,
          });
          break;
        default:
          throw new TypeError(
            '`kind` field is required to update the proper profile',
          );
      }
      showSuccessToast(t('profile.updateSuccess'));
      return;
    }
    throw new Error('No auth User');
  },
});

// Updating Avatar
export const doAvatarUpdate = createAction({
  type: 'updateAvatarUpdate',
  async callback({ photoURL }: { photoURL: string | null }) {
    if (auth.currentUser) {
      const { uid } = auth.currentUser;
      const contextRole = await getCurrentContextRole();
      if (contextRole) {
        await mergeData(`${contextRole}s/${uid}`, { photoURL });
      }
      return;
    }
    throw new Error('No auth User');
  },
});
