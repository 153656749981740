import { List } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router-dom';

import { LabBooking, LabOrRadioBookingStatus } from '../../api/types';
import { Lab } from '../../api/types/Lab';
import { useLabBookings, useUserId } from '../../api/usages';
import { useVerifiedLabs } from '../../api/usages/labs';
import ResultList from './common/ResultList';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface UniqueIdBookings {
  labId: string;
  status: LabOrRadioBookingStatus;
}
function getUniqueLabsIds(labs: Array<LabBooking>): Array<UniqueIdBookings> {
  const uniqueBookings: Set<UniqueIdBookings> = new Set();
  labs.forEach((lab: LabBooking) =>
    uniqueBookings.add({ labId: lab.labId, status: lab.status }),
  );

  return Array.from(uniqueBookings);
}

function removeUsedLabs(
  labs: Array<Lab>,
  usedLabs: Array<UniqueIdBookings>,
): Array<Lab> {
  const uniqueId: Set<string> = new Set();

  usedLabs.forEach(lab =>
    lab.status !== LabOrRadioBookingStatus.CANCELED
      ? uniqueId.add(lab.labId)
      : null,
  );
  const result: Array<string> = Array.from(uniqueId);

  return labs.filter(lab => !result.includes(lab.id));
}

interface LabsListProps {
  bookingId: string;
  referralId: string;
}

const LabsList = ({ bookingId, referralId }: LabsListProps) => {
  const cx = useStyles();
  const { serviceId } = useParams<{ serviceId: string }>();
  const userId = useUserId();
  const { labs, loading, error } = useVerifiedLabs();
  const { error: labError, loading: labLoading, labBookings } = useLabBookings(
    [
      {
        fieldPath: 'patientId',
        opStr: '==',
        value: userId,
      },
      {
        fieldPath: 'serviceId',
        opStr: '==',
        value: serviceId,
      },
      {
        fieldPath: 'referralId',
        opStr: '==',
        value: referralId,
      },
    ],
    {
      skip: !referralId,
    },
  );

  const usedLabs = getUniqueLabsIds(labBookings);
  const freeLabs = removeUsedLabs(labs, usedLabs);

  if (loading || error || labError || labLoading) {
    return null;
  }

  return (
    <List className={cx.root}>
      <ResultList
        serviceId={serviceId}
        type="lab"
        result={freeLabs}
        bookingId={bookingId}
        referralId={referralId}
      />
    </List>
  );
};

export default LabsList;
