import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../../../api/types';
import { useContextRole } from '../../../api/usages';
import Controls from '../Controls';
import CallAboutDoctor from './CallAboutDoctor';
import CallHealthRecords from './CallHealthRecords';
import NotesIndex from './NotesIndex';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateAreas: `
      "doctorBtns controls ." 
    `,
    padding: '15px 50px',
    justifyItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "controls"
        "doctorBtns"
      `,
    },
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  doctorSection: {
    height: '45%',
    overflow: 'auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  controls: {
    gridArea: 'controls',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  toggleSectionBtns: {
    gridArea: 'doctorBtns',
    width: '200px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
    },
  },
}));

type Section = 'doctorNotes' | 'ehr' | 'aboutDoctor';

const sectionComponents: Record<Section, FunctionComponent> = {
  doctorNotes: NotesIndex,
  ehr: CallHealthRecords,
  aboutDoctor: CallAboutDoctor,
};

type DoctorSectionBtnProps = {
  section: Section;
  selectedSection: null | Section;
  setDoctorSection: (section: null | Section) => void;
  [key: string]: any;
};

const ToggleSectionBtn = ({
  section,
  selectedSection,
  setDoctorSection,
  ...props
}: DoctorSectionBtnProps) => {
  const active = selectedSection === section;

  return (
    <Button
      {...props}
      variant={active ? 'outlined' : undefined}
      onClick={() => setDoctorSection(active ? null : section)}
      endIcon={active ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    />
  );
};

const Toolbar = () => {
  const cx = useStyles();
  const role = useContextRole();
  const { t } = useTranslation();

  const [openedSection, setOpenedSection] = useState<null | Section>(null);

  const SectionComp = openedSection ? sectionComponents[openedSection] : null;

  return (
    <>
      <div className={cx.toolbar}>
        <div className={cx.toggleSectionBtns}>
          {role === UserRoleTypes.DOCTOR ? (
            <>
              <ToggleSectionBtn
                selectedSection={openedSection}
                setDoctorSection={setOpenedSection}
                section="doctorNotes"
              >
                Add
              </ToggleSectionBtn>
              <ToggleSectionBtn
                selectedSection={openedSection}
                setDoctorSection={setOpenedSection}
                section="ehr"
              >
                EHR
              </ToggleSectionBtn>
            </>
          ) : (
            <ToggleSectionBtn
              selectedSection={openedSection}
              setDoctorSection={setOpenedSection}
              section="aboutDoctor"
            >
              {t('help.about')}
            </ToggleSectionBtn>
          )}
        </div>
        <div className={cx.controls}>
          <Controls />
        </div>
      </div>
      {SectionComp ? (
        <div className={cx.doctorSection}>
          <SectionComp />
        </div>
      ) : null}
    </>
  );
};

export default Toolbar;
