import { createBrowserHistory } from 'history';
import { Subject, Observable, from, combineLatest } from 'rxjs';

import {
  updateInitialisingProgress,
  showErrorToast,
} from '../../api/actions/uiControls';
import { logEvent } from '../firebase/analytics';

export const actions$ = new Subject<[string, Observable<any>]>();
export const history = createBrowserHistory();

type ActionCallback = (...arr: any) => Promise<any>;
type ActionNext = (result?: any, args?: any[]) => void;
type ActionComplete = (result?: any, args?: any[]) => void;
type ActionError = (errorArg?: any, type?: string) => void;
type Action = {
  type: string;
  callback: ActionCallback;
  step?: ActionNext | undefined;
  complete?: ActionComplete | undefined;
  error?: ActionError | undefined;
  multistep?: boolean | undefined;
};

const castCallback2ActionType = (callback: ActionCallback): Action => {
  return { callback, type: 'action' };
};

const defaultErrorHandle: ActionError = (errorArg, type) => {
  updateInitialisingProgress(false);
  const message: string = (errorArg as Error).message || errorArg.toString();
  showErrorToast(message);
  if (!type) {
    type = 'action';
  }
  console.error(`💣 ActionError[${type}]`, errorArg);
};

/**
 * Wraps passed method with the Observable that observes processing of calling.
 * e.g. loading, error, success.
 * It can be used to attaching default and error support as a default behavior to all actions.
 */
export function createAction(actionDef: Action | ActionCallback): Function {
  if (typeof actionDef === 'function') {
    actionDef = castCallback2ActionType(actionDef);
  }
  const {
    callback,
    type,
    complete,
    error,
    step,
    multistep = false,
  } = actionDef;

  const actionFn = (action$: Observable<any>, args: any[]): void => {
    updateInitialisingProgress(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lastValue: any;
    action$.subscribe({
      next(result?: any) {
        updateInitialisingProgress(false);
        lastValue = result;
        if (step) {
          step(result, args);
        }
      },
      error(errorArg: any) {
        updateInitialisingProgress(false);
        if (typeof error === 'function') {
          error(errorArg, type);
        } else {
          defaultErrorHandle(errorArg, type);
        }
      },
      async complete() {
        if (typeof complete === 'function') {
          try {
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await complete(lastValue, args);
          } catch (errorArg) {
            if (typeof error === 'function') {
              error(errorArg, type);
            } else {
              defaultErrorHandle(errorArg, type);
            }
          }
        }
        logEvent(type, args);
      },
    });
    actions$.next([type, action$]);
  };

  if (!multistep) {
    return (...args: any) => actionFn(from(callback(...args)), args);
  }

  return (...args: any) => {
    const steps$ = new Subject();
    const action$ = combineLatest(from(callback(...args)), steps$);
    actionFn(action$, args);
    return {
      isCompleted: () => steps$.isStopped,
      next(values: any) {
        steps$.next(values);
      },
      error(err: any) {
        steps$.error(err);
      },
      complete(values: any) {
        if (values) {
          steps$.next(values);
        }
        steps$.complete();
      },
    };
  };
}
