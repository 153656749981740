import { TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectField } from 'uniforms';

import { Translations } from '../../api/types';

export type Option = {
  id: string;
  name: string;
  translations: Translations;
  [key: string]: any;
};

export type Props = {
  label: any;
  options: Option[];
  onChange: (value: any) => void;
  handleFunc?: (value: any) => void;
  textFieldProps?: TextFieldProps;
  value?: any;
  required?: boolean;
  disabled?: boolean;
};

const BilingualAutocompleteField = (props: Props) => {
  const {
    options,
    onChange,
    label,
    value,
    textFieldProps,
    handleFunc,
    required,
    disabled,
  } = props;
  const { t } = useTranslation();

  const autocompleteOptions = options.map(o => {
    const label = o.translations.ar
      ? `${o.translations.en} | ${o.translations.ar}`
      : o.translations.en;
    return {
      value: o.id,
      label,
    };
  });

  const selectedOption = autocompleteOptions.find(o => o.value === value);

  const onSelectChange = (e: any, val: string | null) => {
    handleFunc &&
      handleFunc(autocompleteOptions.find(o => o.label === val)?.value);
    onChange(autocompleteOptions.find(o => o.label === val)?.value);
  };

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedOption?.label ?? ''}
      onChange={onSelectChange}
      options={autocompleteOptions.map(o => o.label)}
      renderInput={params => (
        <TextField
          {...params}
          label={t(label)}
          {...textFieldProps}
          required={required}
        />
      )}
      disableClearable
    />
  );
};

export default connectField(BilingualAutocompleteField);
