import { JSONSchema7Definition } from 'json-schema';

import CustomDateField from '../../components/CustomDateField';
import { createBridge, JSONSchema } from '../../lib/utils';
import GeoPointSchema from './GeoPointSchema';

const properties: {
  [key: string]: JSONSchema7Definition;
} = {
  name: {
    type: 'object',
    properties: {
      en: { type: 'string', minLength: 1 },
      ar: { type: 'string' },
    },
    required: ['en'],
  },
  phoneNumber: { type: 'string' },
  birthday: {
    type: 'string',
    format: 'date',
    uniforms: {
      component: CustomDateField,
    },
  },
  gender: {
    type: 'string',
  },
  photoURL: {
    type: 'string',
  },
  idNumber: { type: 'string', minLength: 1 },
  idOrPassportPhoto: {
    type: 'string',
  },
  specialtyId: {
    type: 'string',
  },
  medTitle: {
    type: 'string',
  },
  certificates: {
    type: 'array',
    minItems: 1,
    uniqueItems: true,
    items: {
      type: 'object',
      properties: {
        certificateCategoryId: {
          type: 'string',
          minLength: 1,
        },
        certificateTypeId: {
          type: 'string',
          minLength: 1,
        },
        name: {
          type: 'string',
          minLength: 1,
        },
        certificateUrl: {
          type: 'string',
          minLength: 1,
        },
      },
      required: [
        'certificateCategoryId',
        'certificateTypeId',
        'name',
        'certificateUrl',
      ],
    },
  },
  about: {
    type: 'object',
    properties: {
      en: { type: 'string' },
      ar: { type: 'string' },
    },
  },
  countries: {
    type: 'string',
  },
  yearsOfExperience: {
    type: 'integer',
    minimum: 0,
    default: 0,
  },
  rating: {
    type: 'number',
    default: 0,
  },
  ratingsCount: {
    type: 'number',
    default: 0,
  },
  nationality: { type: 'string' },
  nativeLanguage: { type: 'string' },
  englishProficiency: {
    type: 'string',
  },
  arabicProficiency: {
    type: 'string',
  },
  frenchProficiency: {
    type: 'string',
  },
  price: {
    type: 'object',
    properties: {
      currency: {
        type: 'string',
        default: 'EGP',
      },
      amount: {
        type: 'number',
        minimum: 6,
      },
    },
    required: ['amount'],
  },
  address: {
    type: 'array',
    minItems: 1,
    uniqueItems: true,
    items: {
      type: 'object',
      properties: {
        governorateId: {
          type: 'string',
          minLength: 1,
        },
        areaId: {
          type: 'string',
          minLength: 1,
        },
        buildingNumberName: {
          type: 'string',
          minLength: 1,
        },
        streetBlockCompound: {
          type: 'string',
          minLength: 1,
        },
        districtTownVillage: {
          type: 'string',
          minLength: 1,
        },
        floorNumber: {
          type: 'string',
        },
        appartmentNumber: {
          type: 'string',
        },
        directions: {
          type: 'string',
        },
        savedName: {
          type: 'string',
        },
        geolocation: GeoPointSchema,
      },
      required: [
        'governorateId',
        'areaId',
        'buildingNumberName',
        'streetBlockCompound',
        'districtTownVillage',
      ],
    },
  },
  resellerCode: {
    type: 'string',
    maxLength: 4,
    pattern: '[a-zA-Z0-9]{4}|^$',
  },
};

const schemaDOCTOR: JSONSchema = {
  title: 'Guest Create Profile',
  type: 'object',
  properties: {
    ...properties,
  },
  if: {
    properties: {
      specialtyId: { const: 'obhPwt9YdkSKPqVa2Mkh' },
    },
  },
  then: {
    properties: {
      price: {
        type: 'object',
        properties: {
          currency: {
            type: 'string',
            default: 'EGP',
          },
          amount: {
            type: 'number',
            minimum: 6,
            maximum: 60,
          },
        },
        required: ['amount'],
      },
    },
  },
  else: {
    properties: {
      price: {
        type: 'object',
        properties: {
          currency: {
            type: 'string',
            default: 'EGP',
          },
          amount: {
            type: 'number',
            minimum: 6,
          },
        },
        required: ['amount'],
      },
    },
  },
  required: [
    'name',
    'gender',
    'birthday',
    'specialtyId',
    'medTitle',
    'certificates',
    'photoURL',
    'idNumber',
    'nationality',
    'address',
  ],
};

export const CreateDoctorProfileWithAuth = createBridge(schemaDOCTOR);

const schemaEmailDOCTOR: JSONSchema = {
  title: 'Guest Create Profile',
  type: 'object',
  properties: {
    email: {
      type: 'string',
      format: 'email',
    },
    password: {
      type: 'string',
      minLength: 6,
      pattern: '^[^\\s]*$',
      uniforms: {
        type: 'password',
      },
    },
    confirmPassword: {
      const: {
        $data: '1/password',
      },
      type: 'string',
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'password', 'confirmPassword'],
};

export const CreateDoctorEmail = createBridge(schemaEmailDOCTOR);

const schemaDoctorNoAuth: JSONSchema = {
  title: 'Guest Create Profile with no auth',
  type: 'object',
  properties,

  if: {
    properties: {
      specialtyId: { const: 'obhPwt9YdkSKPqVa2Mkh' },
    },
  },
  then: {
    properties: {
      price: {
        type: 'object',
        properties: {
          currency: {
            type: 'string',
            default: 'EGP',
          },
          amount: {
            type: 'number',
            minimum: 6,
            maximum: 60,
          },
        },
        required: ['amount'],
      },
    },
  },
  else: {
    properties: {
      price: {
        type: 'object',
        properties: {
          currency: {
            type: 'string',
            default: 'EGP',
          },
          amount: {
            type: 'number',
            minimum: 6,
          },
        },
        required: ['amount'],
      },
    },
  },
  required: [
    'name',
    'gender',
    'birthday',
    'specialtyId',
    'medTitle',
    'certificates',
    'photoURL',
    'idNumber',
    'nationality',
    'address',
  ],
};

export const CreateDoctorProfileNoAuth = createBridge(schemaDoctorNoAuth);
