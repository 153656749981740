import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HelpTitleProps {
  text?: string | null;
  section?: 'about' | 'privacyPolicy' | 'terms' | 'contact';
  prefix?: string;
}

export const HelpTitle = ({ text, section, prefix }: HelpTitleProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h5" color="primary" paragraph>
      {t(`${prefix}.help_${section}_${text}`)}
    </Typography>
  );
};

interface HelpTextProps {
  text?: string;
  bold?: boolean;
  section?: 'about' | 'privacyPolicy' | 'terms' | 'contact';
  prefix?: string;
}

export const HelpText = ({ text, bold, section, prefix }: HelpTextProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant={bold ? 'subtitle2' : 'body2'} paragraph>
      {t(`${prefix}.help_${section}_${text}`)}
    </Typography>
  );
};

interface PointProps {
  title?: string;
  texts: Array<string>;
  section: 'about' | 'privacyPolicy' | 'terms' | 'contact';
  bold?: boolean;
  noTitle?: boolean;
}

export const Point = ({ texts, section, bold, noTitle, title }: PointProps) => {
  const prefix = section === 'about' ? 'help' : 'englishOnly';

  return (
    <>
      {!noTitle && (
        <HelpTitle section={section} text={title ?? null} prefix={prefix} />
      )}
      {texts.map(text => (
        <HelpText
          key={text}
          section={section}
          text={text}
          bold={bold ?? false}
          prefix={prefix}
        />
      ))}
    </>
  );
};
