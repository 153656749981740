import React from 'react';
import { useTranslation } from 'react-i18next';

import { Language, SimpleQuery } from '../../../../../api/types';
import {
  useCollection,
  UseCollectionOptions,
  UseCollectionParams,
} from '../../../../../api/usages/collection';
import CardText from '../../CardText';

interface ReferralToLabProps {
  bookingId: string;
  query?: SimpleQuery;
  isLab?: boolean;
}

const ReferralToLab = ({ bookingId, isLab, query }: ReferralToLabProps) => {
  const { t, i18n } = useTranslation();

  const params: UseCollectionParams = {
    collectionPath: isLab ? 'labReferrals' : 'radioReferrals',
    query,
    dependencies: [
      {
        collectionPath: isLab ? 'labServices' : 'radioServices',
        mapFrom: 'serviceId',
        mapTo: 'service',
      },
      {
        collectionPath: isLab ? 'labCategories' : 'radioCategories',
        mapFrom: 'categoryId',
        mapTo: 'category',
      },
    ],
  };

  const options: UseCollectionOptions = {
    skip: !bookingId,
  };

  const { results: referrals, error, loading } = useCollection<any>(
    params,
    options,
  );

  const createReferrals = (referrals: any) => {
    const refs: any = [];
    referrals
      .filter((ref: any) => {
        return !(ref.category.id === 'null' || ref.service.id === 'null');
      })
      .map((ref: any) => {
        return refs.push([
          {
            label: t(`doctorNotes.${isLab ? 'labCategory' : 'radioCategory'}`),
            text: ref.category.translations?.[i18n.language as Language],
          },
          {
            label: t('orders.service'),
            text: ref.service.translations?.[i18n.language as Language],
          },
          { label: t('healthRecordsVisit.note'), text: ref.note },
        ]);
      });
    return refs;
  };

  const referralsResult = createReferrals(referrals);

  if (loading || error) {
    return null;
  }

  return referralsResult.map((ref: any) => (
    <CardText key={ref.note} records={ref} />
  ));
};

export default ReferralToLab;
