import { Divider, Button, Box, ButtonGroup } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import firebase from 'firebase';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AutoForm, AutoField, SubmitField } from 'uniforms-material';

import { doSignInWithEmailAndPassword } from '../api/actions/auth';
import { navigate } from '../api/actions/navigation';
import SignInSchema from '../api/schemas/SignIn';
import { UserRoleTypes } from '../api/types';
import PhoneVerification from '../components/PhoneVerification';
import RouteTypes from '../constants/routes';
import { goToLandingByContextRole } from '../lib/goToRouteHelpers';
import { getI18nOptions } from '../lib/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(2),
      },
      '& .Mui-error': {
        marginBottom: theme.spacing(1),
      },
      '& form': {
        marginBottom: theme.spacing(2),
      },
    },
    submit: {
      margin: `8px 0`,
    },
  }),
);

type onAuthorizedArgs = { user: firebase.User; profileType: UserRoleTypes };

interface SignInForm {
  profileType: string;
  onAuthorized?: ((arg: onAuthorizedArgs) => any) | true;
}

interface NewValues {
  email: string;
  password: string;
}

const SignInForm = ({ profileType, onAuthorized }: SignInForm) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const [toggle, setToggle] = useState(false);
  if (!onAuthorized) {
    onAuthorized = goToLandingByContextRole;
  }
  const change = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  const goToForgotPass = useCallback(() => {
    navigate(RouteTypes.FORGOT_PASSWORD, { profileType });
  }, [profileType]);
  const location = useLocation();
  const options = getI18nOptions(location.pathname);

  const handleSubmit = (values: NewValues) => {
    doSignInWithEmailAndPassword({ ...values, profileType, onAuthorized });
  };

  return (
    <Box className={cx.wrapper}>
      {toggle ? (
        <PhoneVerification
          profileType={profileType}
          onAuthorized={onAuthorized}
        />
      ) : (
        <AutoForm schema={SignInSchema} onSubmit={handleSubmit}>
          <AutoField name="email" label={t('profile.email', options)} />
          <AutoField name="password" label={t('profile.password', options)} />
          <Box>
            <SubmitField
              className={cx.submit}
              color="primary"
              label={t('common.submit', options)}
            />
          </Box>
        </AutoForm>
      )}
      <Divider />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        fullWidth
      >
        <Button onClick={change}>
          {t(
            toggle ? 'common.changeToEmail' : 'common.changeToMobilePhone',
            options,
          )}
        </Button>
        <Button onClick={goToForgotPass}>{t('auth.forgotPassword')}</Button>
      </ButtonGroup>
    </Box>
  );
};

export default SignInForm;
