import { Tooltip } from '@material-ui/core';
import React from 'react';

import { useRoom, useMediaRefs } from '../../../api/usages';
import { isMobile } from '../../../lib/utils';
import { ChooseCameraBtn } from './ChooseCameraBtn';
import ReverseCamerBtn from './ReverseCameraBtn';

type Props = {
  className?: string;
};

const ChangeCameraBtn = ({ className }: Props) => {
  const room = useRoom();
  const { videoRef } = useMediaRefs(room?.localParticipant);

  const btnElement = room ? (
    isMobile ? (
      <ReverseCamerBtn
        className={className}
        localParticipant={room.localParticipant}
      />
    ) : (
      <ChooseCameraBtn
        className={className}
        localParticipant={room?.localParticipant}
        localParticipantVideoRef={videoRef}
      />
    )
  ) : (
    <div />
  );

  return (
    <Tooltip
      title="Change camera"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <div>{btnElement}</div>
    </Tooltip>
  );
};

export default ChangeCameraBtn;
