import { CircularProgress } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { cloneDeep, isEmpty, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm } from 'uniforms';
import { ErrorField, ListField } from 'uniforms-material';

import { saveRadioReferrals } from '../../../api/actions/doctorNotes';
import { radioRefsSchema } from '../../../api/schemas/DoctorNotes';
import { useRadioCategories, useRadioReferrals } from '../../../api/usages';
import RadioReferralListItem from '../../SessionSummary/RadioReferralListItem';
import DoctorNotesSection from './DoctorNotesSection';

const transformFetchedRadioRefs = ({ bookingRes, radioReferralsRes }: any) => ({
  booking: bookingRes.result,
  radioReferrals: radioReferralsRes.radioReferrals,
});

interface RadioReferralsProps {
  loading: string;
  bookingIdQuery: object;
  bookingRes: any;
}

const RadioReferrals = ({
  loading,
  bookingIdQuery,
  bookingRes,
}: RadioReferralsProps) => {
  const { t } = useTranslation();
  const { radioCategories } = useRadioCategories();

  const radioReferralsRes = useRadioReferrals(bookingIdQuery);
  const radioRefsLoadedRef = useRef<boolean>(false);
  const [radioRefModel, setRadioRefModel] = useState<any>({});

  useEffect(() => {
    if (radioRefsLoadedRef.current) {
      return;
    }

    if ([bookingRes, radioReferralsRes].every(res => !res.loading)) {
      radioRefsLoadedRef.current = true;

      const { radioReferrals } = transformFetchedRadioRefs({
        bookingRes,
        radioReferralsRes,
      });

      setRadioRefModel((prevModel: any) => ({
        ...prevModel,
        radioReferrals,
      }));
    }
  }, [bookingRes, radioReferralsRes]);

  const handleSubmitRadioRefs = (submitModel: any) => {
    const fetchedData = transformFetchedRadioRefs({
      bookingRes,
      radioReferralsRes,
    });

    saveRadioReferrals({
      booking: {
        id: fetchedData.booking?.id,
        patientId: fetchedData.booking?.patientId,
        complaint: submitModel.complaint ?? '',
      },
      radioReferrals: submitModel.radioReferrals,
      fetchedRadioReferrals: fetchedData.radioReferrals,
    });
  };

  const handleFieldChangeRadioRefs = (field: string, value: any) => {
    const newModel = set(cloneDeep(radioRefModel), field, value);
    setRadioRefModel(newModel);
    return newModel;
  };

  if (isEmpty(radioRefModel)) {
    return (
      <div className={loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <AutoForm
      schema={radioRefsSchema}
      onChange={handleFieldChangeRadioRefs}
      model={radioRefModel}
      onSubmit={handleSubmitRadioRefs}
      autosave
      autosaveDelay={700}
    >
      <DoctorNotesSection
        label={t('doctorNotes.radioReferral')}
        count={radioRefModel.radioReferrals.length}
      >
        <ListField name="radioReferrals" addIcon={<Add />} label="">
          <RadioReferralListItem
            name="$"
            categories={radioCategories}
            handleFieldChange={handleFieldChangeRadioRefs}
            radioReferrals={radioRefModel.radioReferrals}
          />
        </ListField>
        <ErrorField name="radioReferrals" />
      </DoctorNotesSection>
    </AutoForm>
  );
};

export default RadioReferrals;
