import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  ListField,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import { doCreateProfile } from '../../api/actions/auth';
import { showInfoToast } from '../../api/actions/uiControls';
import { CreateLabServices } from '../../api/schemas/CreateProfileLabRadio';
import { useRadioCategories, useLabCategories } from '../../api/usages';
import {
  goToRadiosLandingPage,
  goToLabsLandingPage,
} from '../../lib/goToRouteHelpers';
import { arrayToObject, objectToArray } from '../../lib/transformHelpers';
import ReauthWithPhoneDialog from '../ReauthWithPhoneDialog';
import LabListItem from './LabListItem';
import RadioListItem from './RadioListItem';

interface Services {
  model: object;
  role: string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiListSubheader-root': {
      display: 'none',
    },
    '& .MuiListItem-root': {
      padding: '0',
      flexDirection: 'column',
    },
    '& > .MuiFormControl-root': {
      width: 'auto',
      display: 'block',
      margin: '0 auto',
      textAlign: 'center',
    },
  },
});

const AddServices = ({ model, role }: Services) => {
  const { t } = useTranslation();
  const cx = useStyles();

  const [services, setServices] = useState<any>([]);
  const { radioCategories } = useRadioCategories();
  const { labCategories } = useLabCategories();
  useEffect(() => {
    const categories = role === 'lab' ? labCategories : radioCategories;
    const rawServices = categories.map(({ id }) => ({ categoryId: id }));
    const fullServices = rawServices.concat(get(model, 'services', []));
    setServices(objectToArray(fullServices));
  }, [labCategories, radioCategories, model, role]);

  const [reAuthNeeded, setReauthNeeded] = useState<boolean>(false);
  const onAuthorizedFunc =
    role === 'lab' ? goToLabsLandingPage : goToRadiosLandingPage;

  const handleChange = (data: any) => {
    const actualServices = get(data, 'services', []);
    setServices(actualServices);
  };

  const handleSelectAll = (data: any) => {
    const { categoryId } = data;
    const newServices = services.map((o: any) =>
      o.categoryId === categoryId ? data : o,
    );
    setServices(newServices);
  };

  const handleSubmit = (data: any) => {
    const onLinkCredsErr = (e: any) => {
      if (e.code === 'auth/requires-recent-login') {
        setReauthNeeded(true);
      }
    };

    const initServices = get(data, 'services', []);
    const services = arrayToObject(initServices);
    const fullProfile = { ...model, services, testing: false };
    doCreateProfile(fullProfile, role, onAuthorizedFunc, onLinkCredsErr);
  };

  const closeReauth = () => {
    setReauthNeeded(false);
  };

  const onReauthenticated = () => {
    closeReauth();
    showInfoToast(t('common.tryResubmit'));
  };

  const hideAddIcon =
    role === 'lab'
      ? services.length >= labCategories.length
      : services.length >= radioCategories.length;

  return (
    <>
      {reAuthNeeded && (
        <ReauthWithPhoneDialog
          onClose={closeReauth}
          onReauthenticated={onReauthenticated}
        />
      )}
      <AutoForm
        schema={CreateLabServices}
        onSubmit={handleSubmit}
        className={cx.root}
        model={{ services }}
        onChangeModel={handleChange}
      >
        <ListField
          name="services"
          initialCount={1}
          addIcon={hideAddIcon ? null : <Add />}
        >
          {role === 'lab' ? (
            <LabListItem
              name="$"
              services={services}
              onSelectAll={handleSelectAll}
            />
          ) : (
            <RadioListItem
              name="$"
              services={services}
              onSelectAll={handleSelectAll}
            />
          )}
        </ListField>
        <ErrorField name="services" />
        <Box>
          <SubmitField color="primary" label={t('common.submit')} />
        </Box>
      </AutoForm>
    </>
  );
};

export default AddServices;
