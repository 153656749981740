import { createBridge, JSONSchema } from '../../lib/utils';

const passwordsSchema: JSONSchema = {
  title: 'ChangePass - pass',
  type: 'object',
  properties: {
    email: { type: 'string' },
    currentPassword: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
    password: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
    confirmPassword: {
      const: {
        $data: '1/password',
      },
      type: 'string',
      uniforms: {
        type: 'password',
      },
    },
  },
  required: ['email', 'currentPassword', 'password', 'confirmPassword'],
};

export const ChangePasswordsSchema = createBridge(passwordsSchema);

const EmailsSchema: JSONSchema = {
  title: 'ChangeEmail',
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: {
      type: 'string',
      minLength: 6,
      uniforms: {
        type: 'password',
      },
    },
    newEmail: {
      type: 'string',
      format: 'email',
    },
    confirmEmail: {
      const: {
        $data: '1/newEmail',
      },
      type: 'string',
    },
  },
  required: ['email', 'password', 'newEmail', 'confirmEmail'],
};

export const ChangeEmailsSchema = createBridge(EmailsSchema);
