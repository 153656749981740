import React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { useForm } from 'uniforms';

type PreventNavigationProps = {
  prevent: boolean;
  message?: string;
};

const PreventNavigationInChangedForm = (props: PreventNavigationProps) => {
  const { t } = useTranslation();
  const {
    prevent,
    message = t('common.areYouSureYouWantToLeave') ?? '',
  } = props;

  const { changed } = useForm();

  return <Prompt when={prevent && changed} message={message} />;
};

export default PreventNavigationInChangedForm;
