import { Plugins } from '@capacitor/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { UserRoleTypes } from '../../api/types';
import { useContextRole } from '../../api/usages';
import {
  useInitialisingFirebase,
  useRuntime,
} from '../../api/usages/uiControls';
import {
  sideNavWidth,
  sideNavConfigAnonymous,
  sideNavConfigDoctor,
  sideNavConfigPatient,
  sideNavConfigLabRadio,
  sideNavConfigAdmin,
  LayoutRouteItem,
  LayoutActionItem,
  LayoutItem,
  LayoutCustomItem,
} from '../../constants/ConfigLayout';
import { logoOnlyText } from '../../constants/ConfigLogo';
import RouteTypes from '../../constants/routes';
import SignOutListItem from './SignOutListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: sideNavWidth,
        flexShrink: 0,
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: sideNavWidth,
    },
    logo: {
      width: 'calc(100%)',
      minHeight: 64,
      padding: `calc(0.1*${sideNavWidth}px)`,
      paddingTop: `calc(0.05*${sideNavWidth}px)`,
    },
  }),
);

interface SideBarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function getItemProps(item: LayoutItem, defaultProps = {}) {
  const route = (item as LayoutRouteItem).route;
  const callback = (item as LayoutActionItem).callback;
  if (route) {
    return { component: NavLink, to: route, ...defaultProps };
  } else if (typeof callback === 'function') {
    return {
      component: 'button',
      onClick: callback as () => void,
      ...defaultProps,
    };
  }
  return { disabled: true, ...defaultProps };
}

const OmittedRoutesForMobileApp = [
  RouteTypes.FOR_DOCTOR,
  RouteTypes.FOR_LAB,
  RouteTypes.FOR_RADIO,
];

const SideBar = (props: SideBarProps) => {
  const { Browser } = Plugins;
  const { mobileOpen, handleDrawerToggle } = props;
  const isInitialisingFirebase = useInitialisingFirebase();
  const role = useContextRole();
  const { t } = useTranslation();
  const location = useLocation();
  const { isMobileApp } = useRuntime();
  const cx = useStyles();
  const {
    palette: { primary },
  } = useTheme();

  const selectNavConfig = () => {
    switch (role) {
      case UserRoleTypes.DOCTOR:
        return sideNavConfigDoctor;
      case UserRoleTypes.LAB:
        return sideNavConfigLabRadio;
      case UserRoleTypes.RADIO:
        return sideNavConfigLabRadio;
      case UserRoleTypes.PATIENT:
        return sideNavConfigPatient;
      case UserRoleTypes.ADMIN:
        return sideNavConfigAdmin;
      default:
        return sideNavConfigAnonymous;
    }
  };

  const sideNavConfig = selectNavConfig();

  const drawer = isInitialisingFirebase ? null : (
    <div>
      <img alt={logoOnlyText.alt} src={logoOnlyText.src} className={cx.logo} />
      <div className={cx.toolbar} />
      <List>
        {sideNavConfig.map(item => {
          const route = (item as LayoutRouteItem).route;
          const selected = !!route && route === location.pathname;
          const itemProps = getItemProps(
            item,
            route
              ? {
                  selected,
                  exact: route === '/',
                  activeStyle: {
                    backgroundColor: primary.main,
                    color: primary.contrastText,
                  },
                }
              : {},
          );
          const content = (
            <>
              <ListItemIcon
                style={{
                  color: selected ? primary.contrastText : undefined,
                }}
              >
                {item.icon && <item.icon />}
              </ListItemIcon>
              <ListItemText primary={t(item.label)} />
            </>
          );
          if ((item as LayoutCustomItem).custom === 'sign-out') {
            return (
              <SignOutListItem key={item.label}>{content}</SignOutListItem>
            );
          }
          if (isMobileApp && OmittedRoutesForMobileApp.includes(route)) {
            return (
              <ListItem
                button
                key={item.label}
                {...itemProps}
                onClick={async () => {
                  await Browser.open({
                    url: `https://shoofdoctor.com${route}`,
                  });
                }}
                className={`${item.label.replace('.', '_')}`}
              >
                {content}
              </ListItem>
            );
          }
          return (
            <ListItem
              button
              key={item.label}
              {...itemProps}
              className={`${item.label.replace('.', '_')}`}
            >
              {content}
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <nav className={cx.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: cx.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: cx.drawerPaper,
          }}
          variant="permanent"
          open={!isInitialisingFirebase}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideBar;
