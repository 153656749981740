import { Card, Typography, Box, Tab, Tabs } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile, useUser, useContextRole } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import ProfileInfoLabRadio from '../components/Profile/ProfileInfoLabRadio';
import UpdateServices from '../components/Profile/UpdateServices';
import TabPanel from '../components/TabPanel';
import UploadAvatar from '../components/UploadAvatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 700,
    },
    avatar: {
      backgroundColor: red[500],
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    headerWrapper: {
      marginBottom: theme.spacing(4),
    },
  }),
);

const ProfileLabRadio = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const role = useContextRole() || '';
  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };
  const profile: { [index: string]: any } | null = useProfile();
  const user = useUser();
  const cx = useStyles();
  const { t } = useTranslation();
  const profileName = get(profile, 'name', '');

  if (!profile) {
    return null;
  }

  return (
    <LayoutDefault>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Card className={cx.root}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={cx.headerWrapper}
            >
              <Box display="flex" alignItems="center">
                <UploadAvatar {...profile} />
                <Typography style={{ padding: '10px' }} variant="h5">
                  {profileName ? profileName : t('profile.anonymous')}
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginBottom: '8px' }}>
              <Typography variant="body2" color="error">
                {t('profile.languageInfo')}
              </Typography>
            </Box>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
            >
              <Tab label={t('englishOnly.profile_basicInfo')} />
              <Tab label={t('englishOnly.profile_services')} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              <ProfileInfoLabRadio profile={profile} user={user} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <UpdateServices
                model={profile}
                role={role}
                editDisabled={false}
              />
            </TabPanel>
          </Box>
        </Card>
      </Box>
    </LayoutDefault>
  );
};
export default ProfileLabRadio;
