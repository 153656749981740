import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback } from 'react';
import { of } from 'rxjs';
import { useObservable } from 'rxjs-hooks';

import { isImage } from '../../lib/fileHelpers';
import {
  UploadTaskSnapshot,
  ExtendedFileEntry,
  FileEntry,
} from '../../lib/firebase';
import LinearProgressWithLabel from '../LinearProgressWithLabel';

type PreviewItemProps = {
  index: number;
  hideFileNames?: boolean;
  fileEntry: FileEntry | ExtendedFileEntry;
  onDelete: (index: number, fileObject: FileEntry) => void;
  disabled: boolean;
  disabledDelete?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      maxHeight: theme.spacing(14),
      minHeight: theme.spacing(10),
      width: theme.spacing(12),
      position: 'relative',
      zIndex: 1,
      display: 'block',
      opacity: (props: any) => props.opacity,
      cursor: (props: any) => props.cursor,
    },
    progress: {
      zIndex: 10,
      width: '100%',
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      opacity: 0.5,
      top: 0,
      left: 0,
    },
    image: {
      height: theme.spacing(10),
      maxWidth: theme.spacing(12),
      color: theme.palette.text.primary,
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      boxSizing: 'border-box',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
      borderRadius: theme.shape.borderRadius,
      zIndex: 5,
      opacity: 1,
    },
    removeButton: {
      position: 'absolute',
      opacity: 1,
      top: theme.spacing(-1),
      right: theme.spacing(-1),
      width: 40,
      height: 40,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const PreviewItem = ({
  hideFileNames = false,
  fileEntry,
  onDelete,
  index,
  disabled,
  disabledDelete,
}: PreviewItemProps) => {
  const styleProps = {
    opacity: disabled ? '0.4' : '1',
    cursor: disabled ? 'default' : 'pointer',
  };
  const cx = useStyles(styleProps);
  const handleDelete = useCallback(
    e => {
      e.stopPropagation();
      onDelete(index, fileEntry);
    },
    [index, fileEntry, onDelete],
  );
  const snap = useObservable<UploadTaskSnapshot | null>(
    () => (fileEntry as ExtendedFileEntry).upload$ || of(null),
  );
  const progress = !snap
    ? 0
    : Math.round((snap.bytesTransferred / snap.totalBytes) * 100);

  return (
    <Grid xs={4} item>
      <div className={cx.imageContainer}>
        {isImage(fileEntry.file) ? (
          <img className={cx.image} alt="presentation" src={fileEntry.url} />
        ) : (
          <AttachFileIcon className={cx.image} />
        )}
        {!hideFileNames && (
          <Typography variant="body1" component="p">
            {fileEntry.file.name}
          </Typography>
        )}
        {progress < 100 && progress > 0 && (
          <LinearProgressWithLabel className={cx.progress} value={progress} />
        )}
        {!disabled && !disabledDelete && (
          <IconButton
            onClick={handleDelete}
            aria-label="Delete"
            className={cx.removeButton}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </Grid>
  );
};
