import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoleTypes } from '../api/types';
import SignInForm from '../components/SignInForm';
import SignUpForm from '../components/SignUpForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(4),
    },
  }),
);

interface BookingRegistrationProps {
  onAuthorization: () => void;
}

const BookingRegistration = ({ onAuthorization }: BookingRegistrationProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const [signup, setSignup] = useState(false);

  return (
    <Card>
      <Box p={3} className={cx.wrapper}>
        {signup ? (
          <SignUpForm onAuthorized />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="h5" gutterBottom>
                {t('common.signUp')}
              </Typography>
              <div style={{ marginTop: '16px' }}>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('common.sign_in_text1')}</strong>{' '}
                  {t('common.sign_in_text2')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('common.sign_in_text3')}</strong>{' '}
                  {t('common.sign_in_text4')}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSignup(true)}
                >
                  {t('common.signUp')}
                </Button>
              </div>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h5" gutterBottom>
                {t('common.orSignInAsBelow')}
              </Typography>
              <SignInForm
                profileType={UserRoleTypes.PATIENT}
                onAuthorized={onAuthorization}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default BookingRegistration;
