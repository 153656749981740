import { Button, Grid, List } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FullQuery,
  LabOrRadioBookingStatus,
  SimpleQuery,
} from '../../api/types';
import { useUserId } from '../../api/usages';
import {
  useCollection,
  UseCollectionOptions,
  UseCollectionParams,
} from '../../api/usages/collection';
import OrderListItem from '../Orders/OrderListItem';

type DeliveredOrdersListProps = {
  patientId?: string;
  collectionPath: string;
};

// eslint-disable-next-line complexity
const DeliveredOrdersList = ({
  patientId,
  collectionPath,
}: DeliveredOrdersListProps) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(20);
  const userId = useUserId();

  const query: SimpleQuery = [
    {
      fieldPath: 'patientId',
      opStr: '==',
      value: patientId || userId,
    },
    {
      fieldPath: 'status',
      opStr: '==',
      value: LabOrRadioBookingStatus.DELIVERED,
    },
  ];

  const fullQuery: FullQuery = {
    where: query,
    orderBy: ['updatedAt', 'desc'],
    limit,
  };

  const params: UseCollectionParams = {
    collectionPath,
    query: fullQuery,
  };

  const options: UseCollectionOptions = {
    skip: !patientId && !userId,
  };

  const { results: orders, error, loading } = useCollection<any>(
    params,
    options,
  );

  if (error || loading) {
    return null;
  }

  if (orders.length === 0) {
    return (
      <h1>
        {t('orders.emptyOrders', {
          status: t(
            `orders.${LabOrRadioBookingStatus.DELIVERED}`,
          ).toLowerCase(),
        })}
      </h1>
    );
  }

  const handleLoadMore = () => {
    setLimit(limit => limit + 20);
  };

  const canLoadMore = orders.length === limit;

  return (
    <>
      <List>
        {orders.map((order: any) => (
          <OrderListItem
            key={order.id}
            order={order}
            withLabOrRadioInfo
            withDownloadButton
          />
        ))}
      </List>
      {canLoadMore && (
        <Grid container justify="center" style={{ marginTop: '15px' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
            >
              {t('booking.loadMore')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DeliveredOrdersList;
