import * as React from 'react';

type SvgHeartProps = {
  filled?: boolean;
  color?: string;
  className?: string;
};

function SvgHeart(props: SvgHeartProps) {
  return props.filled ? (
    <svg color="#000" viewBox="0 0 176 114" width={176} height={114} {...props}>
      <defs />
      <path
        fill="#dc00e4"
        d="M51.714 75.013l45.63-46.384 22.222 21.86-45.63 46.384z"
      />
      <path
        fill="#dc00e4"
        d="M64.826 87.26l45.63-46.384 22.222 21.86-45.63 46.384z"
      />
      <circle cx="110.627" cy="37.145" r="33.173" fill="#dc00e4" />
      <circle cx="56.571" cy="36.403" r="33.173" fill="#dc00e4" />
      <g fill="none" fillRule="evenodd">
        <path
          fill={props.color}
          d="M29.831 54.544l-1.283.28c.13.204.258.407.393.605z"
        />
        <path
          fill={props.color}
          d="M18.083 3.11c-8.631 0-15.652 7.02-15.652 15.652 0 3.003.857 5.923 2.478 8.445l.193.297a15.676 15.676 0 0012.579 6.899h16.023l-2.843-6.587.459-.71a15.606 15.606 0 002.415-8.344 15.65 15.65 0 00-6.844-12.936 15.498 15.498 0 00-5.498-2.362 15.745 15.745 0 00-3.31-.354zm19.099 33.58H17.651a17.994 17.994 0 01-14.458-7.926l-.205-.318a17.866 17.866 0 01-2.843-9.684C.145 8.87 8.191.822 18.083.822c1.268 0 2.543.138 3.791.406a17.781 17.781 0 016.305 2.709 17.933 17.933 0 017.842 14.825c0 3.272-.89 6.466-2.58 9.26z"
          mask="url(#heart_svg__b)"
          transform="translate(20.595 -1.24) scale(2.0149)"
        />
        <path
          fill={props.color}
          d="M36.945 36.729c.08-6.932 2.47-12.757 7.177-17.625.828-.858 2.101-.906 2.937-.137.84.78.899 2.021.067 2.932-1.31 1.429-2.53 2.916-3.46 4.622-2.188 4.01-2.994 8.281-2.391 12.815.596 4.471 2.502 8.31 5.597 11.571.568.599.997 1.252.794 2.136-.18.78-.647 1.304-1.409 1.552-.8.26-1.509.05-2.077-.53-1.753-1.795-3.254-3.782-4.419-6.01a25.052 25.052 0 01-2.685-8.914c-.093-.855-.095-1.717-.131-2.412"
        />
        <path
          fill={props.color}
          d="M45.681 36.673c.032-3.903 1.384-7.28 4.074-10.117.907-.955 2.138-1.07 3.016-.266.877.804.921 2.08.033 3.024-1.792 1.906-2.843 4.13-2.984 6.748-.154 2.803.685 5.287 2.496 7.435.204.24.427.464.625.707.717.887.656 2.104-.133 2.841a2.058 2.058 0 01-2.894-.068c-1.37-1.386-2.448-2.976-3.177-4.788a14.333 14.333 0 01-1.056-5.516m9.789 35.989a31.427 31.427 0 007.506 12.005l22.28 22.28 48.128-48.125c12.287-12.287 12.287-32.279 0-44.565-12.289-12.287-32.28-12.287-44.567 0l-1.94 1.94c4.128 6.016 6.298 13.016 6.298 20.366a36.032 36.032 0 01-5.199 18.66l7.536 17.463h-39.35c-.23-.006-.462-.014-.692-.024zm29.786 40.802l-25.54-25.54a36.01 36.01 0 01-9.632-17.343l-.755-3.264 3.318.461c1.247.175 2.416.272 3.573.302l32.286-.002-5.728-13.272.925-1.43a31.445 31.445 0 004.866-16.813c0-7.005-2.26-13.642-6.539-19.197l-1.235-1.604 4.766-4.763c14.082-14.085 36.997-14.085 51.08 0h.001c14.082 14.082 14.082 36.997 0 51.081z"
        />
      </g>
    </svg>
  ) : (
    <svg width={176} height={114} {...props}>
      <defs>
        <path id="heart_svg__a" d="M.145.823h37.037V36.69H.145z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M29.831 54.544l-1.283.28c.13.204.258.407.393.605z"
          fill={props.color}
        />
        <g transform="matrix(2.0149 0 0 2.0149 20.595 -1.24)">
          <mask id="heart_svg__b" fill="#fff">
            <use xlinkHref="#heart_svg__a" width="100%" height="100%" />
          </mask>
          <path
            d="M18.083 3.11c-8.631 0-15.652 7.02-15.652 15.652 0 3.003.857 5.923 2.478 8.445l.193.297a15.676 15.676 0 0012.579 6.899h16.023l-2.843-6.587.459-.71a15.606 15.606 0 002.415-8.344 15.65 15.65 0 00-6.844-12.936 15.498 15.498 0 00-5.498-2.362 15.745 15.745 0 00-3.31-.354zm19.099 33.58H17.651a17.994 17.994 0 01-14.458-7.926l-.205-.318a17.866 17.866 0 01-2.843-9.684C.145 8.87 8.191.822 18.083.822c1.268 0 2.543.138 3.791.406a17.781 17.781 0 016.305 2.709 17.933 17.933 0 017.842 14.825c0 3.272-.89 6.466-2.58 9.26z"
            fill={props.color}
            mask="url(#heart_svg__b)"
          />
        </g>
        <path
          d="M36.945 36.729c.08-6.932 2.47-12.757 7.177-17.625.828-.858 2.101-.906 2.937-.137.84.78.899 2.021.067 2.932-1.31 1.429-2.53 2.916-3.46 4.622-2.188 4.01-2.994 8.281-2.391 12.815.596 4.471 2.502 8.31 5.597 11.571.568.599.997 1.252.794 2.136-.18.78-.647 1.304-1.409 1.552-.8.26-1.509.05-2.077-.53-1.753-1.795-3.254-3.782-4.419-6.01a25.052 25.052 0 01-2.685-8.914c-.093-.855-.095-1.717-.131-2.412"
          fill={props.color}
        />
        <path
          d="M45.681 36.673c.032-3.903 1.384-7.28 4.074-10.117.907-.955 2.138-1.07 3.016-.266.877.804.921 2.08.033 3.024-1.792 1.906-2.843 4.13-2.984 6.748-.154 2.803.685 5.287 2.496 7.435.204.24.427.464.625.707.717.887.656 2.104-.133 2.841a2.058 2.058 0 01-2.894-.068c-1.37-1.386-2.448-2.976-3.177-4.788a14.333 14.333 0 01-1.056-5.516M55.47 72.662a31.427 31.427 0 007.506 12.005l22.28 22.28 48.128-48.125c12.287-12.287 12.287-32.279 0-44.565-12.289-12.287-32.28-12.287-44.567 0l-1.94 1.94c4.128 6.016 6.298 13.016 6.298 20.366a36.032 36.032 0 01-5.199 18.66l7.536 17.463h-39.35c-.23-.006-.462-.014-.692-.024zm29.786 40.802l-25.54-25.54a36.01 36.01 0 01-9.632-17.343l-.755-3.264 3.318.461c1.247.175 2.416.272 3.573.302l32.286-.002-5.728-13.272.925-1.43a31.445 31.445 0 004.866-16.813c0-7.005-2.26-13.642-6.539-19.197l-1.235-1.604 4.766-4.763c14.082-14.085 36.997-14.085 51.08 0h.001c14.082 14.082 14.082 36.997 0 51.081z"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default SvgHeart;
