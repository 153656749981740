import Typography from '@material-ui/core/Typography';
import React from 'react';

interface SectionTitleProps {
  title: string;
  variant?: 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: any;
}

const SectionTitle = ({ title, variant, className }: SectionTitleProps) => (
  <Typography
    variant={variant || 'h5'}
    className={className}
    style={{ margin: '10px 0 5px 0' }}
  >
    {title}
  </Typography>
);

export default SectionTitle;
