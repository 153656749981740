import get from 'lodash/get';
import React, { useState, useCallback, useEffect } from 'react';

import { doProfileUpdate } from '../../api/actions/auth';
import { replace } from '../../api/actions/navigation';
import { BookingTypes, Booking, Doctor } from '../../api/types';
import { useUserId, useProfile } from '../../api/usages';
import { stepsDescription } from '../../constants/ConfigStepper';
import ROUTES from '../../constants/routes';
import { setSessionData } from '../../lib/sessionStorage';
import BookingRegistration from '../BookingRegistration';
import Doctors from '../Doctors/Doctors';
import DoctorDetails from '../Doctors/DoctorsDetails';
import Complaint from './Complaint';
import GeoLimitationPopup from './GeoLimitationPopup';
import Payment from './Payment';
import Specialties from './Specialties';
import Stepper from './Stepper';
import DateTime from './TimePicker/DateTime';

// Constant id of primary care / family medicine specialty
const primaryCareSpecialtyId = 'obhPwt9YdkSKPqVa2Mkh';

// eslint-disable-next-line complexity
const SetBooking = (props: any) => {
  const {
    specialtyId: forcedSpecialtyId,
    medTitle,
    stepNo,
    doctor,
    model: forcedModel,
    withSpecialties,
  } = props;
  const [specialtyId, setSpecialtyId] = useState(forcedSpecialtyId);
  const [step, setStep] = useState(stepNo || 1);
  const [model, updateModel] = useState<Booking>(
    forcedModel || { type: BookingTypes.INITIAL },
  );
  const [doctorToConfirm, setDoctorToConfirm] = useState(doctor);
  const [timeslot, setTimeslot] = useState<number>(15);
  const noUser = !useUserId();
  const profile = useProfile() || {};
  const userEmail = get(profile, 'email', undefined);
  const handleStepChange = useCallback((step: any) => {
    if (step === 0) {
      replace(ROUTES.HOME);
    } else {
      setStep(step);
    }
  }, []);

  const handleConfirmDoctor = (doctor: Doctor) => {
    setDoctorToConfirm(doctor);
    setStep(step + 1);
  };

  const handleGoBack = () => {
    setStep(step + -1);
  };

  const handleGoBackWithSpecialtyId = (specialtyId: string) => {
    setStep((step: number) => step - 1);
    setSpecialtyId(specialtyId);
  };

  const handleStepSubmit = (value: any) => {
    let updatedModel;
    if (typeof value === 'object') {
      updatedModel = {
        ...model,
        ...value,
      };
    } else {
      updatedModel = {
        ...model,
        doctorId: value,
      };
    }
    updateModel(updatedModel);
    setStep(step + 1);
  };

  useEffect(() => {
    if (step === 1 && specialtyId !== primaryCareSpecialtyId) {
      setSpecialtyId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const handleSetFavouriteDoctor = (
    doctorId: string,
    makeFavourite: boolean,
  ) => {
    let favouriteDoctorsIds = get(profile, 'favouriteDoctorsIds', []);
    if (makeFavourite) {
      favouriteDoctorsIds.push(doctorId);
    } else {
      favouriteDoctorsIds = favouriteDoctorsIds.filter(
        (id: string) => id !== doctorId,
      );
    }
    const updatedProfile = {
      ...profile,
      favouriteDoctorsIds,
    };
    doProfileUpdate(updatedProfile);
  };

  const handleUpdateTimeslot = (timeslot: number) => {
    setTimeslot(timeslot);
  };

  const onAuthorized = () => {
    setSessionData('booking', { model, step, doctor: doctorToConfirm });
  };

  return (
    <Stepper
      stepsList={stepsDescription}
      activeStep={step}
      onStepChange={handleStepChange}
    >
      {step === 1 && (
        <>
          <GeoLimitationPopup />
          {withSpecialties &&
          (!specialtyId || specialtyId !== primaryCareSpecialtyId) ? (
            <Specialties
              choosenSpecialtyId={specialtyId}
              setSpecialtyId={setSpecialtyId}
              primaryCareSpecialtyId={primaryCareSpecialtyId}
            />
          ) : null}
          {specialtyId ? (
            <Doctors
              specialtyId={specialtyId}
              medTitle={medTitle}
              onChooseDoctor={handleConfirmDoctor}
              profile={profile}
              handleSetFavouriteDoctor={handleSetFavouriteDoctor}
            />
          ) : null}
        </>
      )}

      {step === 2 && (
        <DoctorDetails
          doctor={doctorToConfirm}
          setNextStep={handleStepSubmit}
          setPreviousStep={handleGoBackWithSpecialtyId}
          profile={profile}
          handleSetFavouriteDoctor={handleSetFavouriteDoctor}
        />
      )}
      {step === 3 && (
        <DateTime
          doctorId={doctorToConfirm.id}
          updateTimeslot={handleUpdateTimeslot}
          onSubmit={handleStepSubmit}
          setPreviousStep={handleGoBack}
          startDate={model.startDate}
        />
      )}
      {step === 4 && (
        <Complaint onSubmit={handleStepSubmit} complaint={model.complaint} />
      )}
      {step === 5 && (noUser || !userEmail) && (
        <BookingRegistration onAuthorization={onAuthorized} />
      )}
      {step === 5 && !noUser && userEmail && (
        <Payment
          booking={{
            ...model,
            visitedByDoctor: false,
            visitedByPatient: false,
          }}
          doctor={doctorToConfirm}
          timeslot={timeslot}
        />
      )}
    </Stepper>
  );
};
export default SetBooking;
