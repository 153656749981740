import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useContextRole } from '../../api/usages';
import '../../assets/styles.css';
import ConfigTheme from '../../constants/ConfigTheme';

const generateClassName = createGenerateClassName({
  productionPrefix: 'shoof',
});

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

interface MyProps {
  children?: React.ReactNode;
}

const ThemesProvider = (props: MyProps) => {
  const { i18n } = useTranslation();
  const role = useContextRole();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  useEffect(() => {
    if (direction === 'rtl') {
      document.getElementsByTagName('body')[0].setAttribute('dir', direction);
    } else {
      document.getElementsByTagName('body')[0].setAttribute('dir', direction);
    }
  }, [direction]);

  const theme = createMuiTheme({
    direction,
    palette: {
      ...(ConfigTheme.find(elem => elem.name === role) || ConfigTheme[0])
        .palette,
    },
  });

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemesProvider;
