import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FullQuery, SimpleQuery, BookingStatuses } from '../../api/types';
import {
  useUserId,
  useBookings,
  useUnrealizedBookings,
} from '../../api/usages';
import { Timestamp } from '../../lib/firebase/firestore';
import DayWithBookings from './DayWithBookings';

const UpcomingBookingsCallendarView = () => {
  const { t } = useTranslation();
  const userId = useUserId();
  const midnightTomorrow = moment().startOf('day').add(1, 'days');
  // dateInPast is midnight 30 days forward
  const [dateInFuture, setDateInFuture] = useState(
    moment().startOf('day').add(30, 'days'),
  );

  const queryUpcoming: SimpleQuery = [
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
    {
      fieldPath: 'startDate',
      opStr: '>',
      value: Timestamp.fromDate(midnightTomorrow.toDate()),
    },
    {
      fieldPath: 'startDate',
      opStr: '<',
      value: Timestamp.fromDate(dateInFuture.toDate()),
    },
    {
      fieldPath: 'status',
      opStr: 'in',
      value: [
        BookingStatuses.OPENED,
        BookingStatuses.GOING,
        BookingStatuses.CANCELED,
      ],
    },
  ];

  const fullQueryUpcoming: FullQuery = {
    where: queryUpcoming,
    orderBy: ['startDate', 'asc'],
  };

  const { error, loading, bookings } = useBookings(fullQueryUpcoming, {
    skip: !userId,
  });

  const {
    error: unErr,
    loading: unLoading,
    unrealizedBookings,
  } = useUnrealizedBookings(fullQueryUpcoming, {
    skip: !userId,
  });

  if (error || unErr) {
    return null;
  }

  if (loading || unLoading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  const allBookings = bookings.concat(unrealizedBookings);
  if (!allBookings.length) {
    return <h1>{t('booking.emptyUpcomingVisits')}</h1>;
  }

  const futureDateMidnight = moment(dateInFuture.toDate()).startOf('day');
  const differenceInDays = futureDateMidnight.diff(midnightTomorrow, 'days');

  const daysWithBookings: any[][] = [...Array(differenceInDays)].map(() => []);

  allBookings.forEach((booking: any) => {
    const diff = futureDateMidnight.diff(
      moment(booking.startDate.toDate()),
      'days',
    );
    const dayindex = differenceInDays - diff - 1;
    daysWithBookings[dayindex].push(booking);
  });

  const handleLoadMore = () => {
    setDateInFuture(dateInFuture => moment(dateInFuture).add(30, 'days'));
  };

  return (
    <>
      {daysWithBookings.map((bookings, index) => (
        <DayWithBookings
          key={index}
          bookings={bookings}
          dayIndex={index}
          minDateMidnight={midnightTomorrow}
          maxDateMidnight={futureDateMidnight}
        />
      ))}
      <Grid container justify="center" style={{ marginTop: '15px' }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            {t('booking.loadThirtyDaysMore')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpcomingBookingsCallendarView;
