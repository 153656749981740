import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type LinearProgressWithLabelProps = {
  value: number;
  className?: any;
};

const LinearProgressWithLabel = ({
  className,
  value,
}: LinearProgressWithLabelProps) => {
  return (
    <Box display="flex" alignItems="center" className={className}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
