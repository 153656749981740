import { createStyles, makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import { doProfileUpdate } from '../../api/actions/auth';
import { UpdateProfileLabRadio } from '../../api/schemas/UpdateProfileLabRadio';
import { proficiencyOptions } from '../../constants/ConfigProfile';
import CustomSelectField from '../CustomSelectField';
import PhoneNumberField from '../PhoneNumberField';
import SectionTitle from '../SectionTitle';
import UploadAreaUniformField from '../UploadAreaUniformField';
import AddressAreaList from './AddressAreaList';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'block',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: '16px',
      },
      '& fieldset': {
        margin: '0 0 16px',
      },
    },
    spaceBottom: {
      marginBottom: '10px',
    },
  }),
);

const ProfileInfoLabRadio = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const profile = get(props, 'profile', {});
  const user = get(props, 'user', {});
  const email = get(user, 'email', '');
  const emailVerified = get(user, 'emailVerified', false);
  const address = get(profile, 'address', []);
  const resellerCode = get(profile, 'resellerCode', ' ');

  const handleSubmit = (newProfile: object) => {
    doProfileUpdate(newProfile);
  };

  // @ts-ignore
  return (
    <AutoForm
      className={classes.root}
      model={profile}
      schema={UpdateProfileLabRadio}
      onSubmit={(profile: object) => handleSubmit(profile)}
    >
      <SectionTitle title={t('englishOnly.profile_businessInfo')} />
      <PhoneNumberField
        name="phoneNumber"
        label={t('profile.phoneNumber')}
        readOnly
      />
      <AutoField
        name="email"
        inputProps={{
          readOnly: email,
        }}
        label={t('profile.email')}
        margin="dense"
        helperText={
          emailVerified
            ? '✅ ' + t('profile.emailVerified')
            : t('profile.needEmailVerificationInfo')
        }
      />
      <div className={classes.spaceBottom} />
      <AutoField
        name="contactPerson"
        label={t('englishOnly.profile_contactPerson')}
      />
      <ErrorField name="contactPerson" />
      <AutoField
        name="receptionPhone"
        label={t('englishOnly.profile_receptionPhone')}
      />
      <ErrorField
        name="receptionPhone"
        errorMessage={t('englishOnly.profile_receptionPhoneError')}
      />
      <AutoField name="openTimes" label={t('englishOnly.profile_openTimes')} />
      <ErrorField name="openTimes" />
      <AutoField name="name" label={t('profile.fullBusinessName')} />
      <ErrorField name="name" />
      <AutoField name="about" label={t('profile.about')} />
      <ErrorField name="about" />
      <AutoField
        name="commercialRegistration"
        label={t('englishOnly.profile_commercialRegistration')}
        placeholder={t('englishOnly.profile_commercialRegistrationPlaceholder')}
        multiline
        InputLabelProps={{
          shrink: true,
        }}
      />
      <ErrorField name="commercialRegistration" />
      <UploadAreaUniformField
        name="commercialRegistrationUpload"
        text={t('englishOnly.profile_commercialRegistrationUpload')}
        filesLimit={3}
        acceptedFiles={['image/*', 'application/pdf']}
        margin="16px"
      />
      <ErrorField name="commercialRegistrationUpload" />
      <AutoField
        name="mohLicence"
        label={t('englishOnly.profile_mohLicence')}
      />
      <ErrorField name="mohLicence" />
      <UploadAreaUniformField
        name="mohLicenceUpload"
        text={t('englishOnly.profile_mohLicenceUpload')}
        filesLimit={3}
        acceptedFiles={['image/*', 'application/pdf']}
        margin="16px"
      />
      <ErrorField name="mohLicenceUpload" />
      <AutoField
        name="resellerCode"
        label={t('profile.resellerCode')}
        inputProps={{ readOnly: resellerCode }}
      />
      <CustomSelectField
        name="arabicProficiency"
        label={t('englishOnly.profile_arabicProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="englishProficiency"
        label={t('englishOnly.profile_englishProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <CustomSelectField
        name="frenchProficiency"
        label={t('englishOnly.profile_frenchProficiency')}
        options={proficiencyOptions}
        fullWidth
      />
      <AutoField name="otherLang" label={t('englishOnly.profile_otherLang')} />
      <ErrorField name="otherLang" />
      <AutoField name="offerDelivery" label={t('labs.offerDelivery')} />
      <ErrorField name="offerDelivery" />
      <AddressAreaList name="address" address={address} />
      <SubmitField color="primary" />
    </AutoForm>
  );
};

export default ProfileInfoLabRadio;
