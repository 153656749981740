import { Button, Grid, List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BookingStatuses, Query, UserRoleTypes } from '../../../api/types';
import { useBookings, useUserId } from '../../../api/usages';
import { useContextRole } from '../../../api/usages/sessionData';
import DoctorVisitsItem from './DoctorVisitsItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    link: {
      textDecoration: 'none',
    },
  }),
);
interface DoctorVisitProps {
  patientId: string | undefined;
}
const DoctorVisit = ({ patientId }: DoctorVisitProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  const [limit, setLimit] = useState(20);
  const userId = useUserId();
  const role = useContextRole();
  const isDoctor = role === UserRoleTypes.DOCTOR ? '_blank' : '';

  const handleLoadMore = () => {
    setLimit(limit => limit + 20);
  };

  const query: Query = {
    where: [
      { fieldPath: 'patientId', opStr: '==', value: patientId || userId },
      {
        fieldPath: 'status',
        opStr: 'in',
        value: [
          BookingStatuses.FINISHED,
          BookingStatuses.FAILED,
          BookingStatuses.CANCELED,
        ],
      },
    ],
    orderBy: ['startDate', 'desc'],
    limit,
  };

  const { error, loading, bookings } = useBookings(query);
  const canLoadMore = bookings.length === limit;

  if (error || loading) {
    return null;
  }

  if (bookings.length === 0) {
    return <h1>{t('healthRecords.noDoctorConsults')}</h1>;
  }

  return (
    <List className={cx.root}>
      {bookings.map((booking: any) => (
        <Link
          className={cx.link}
          to={'/health_records_visit/' + booking.id}
          key={booking.id}
          target={isDoctor}
        >
          <DoctorVisitsItem booking={booking} />
        </Link>
      ))}
      {canLoadMore && (
        <Grid container justify="center" style={{ marginTop: '15px' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
            >
              {t('booking.loadMore')}
            </Button>
          </Grid>
        </Grid>
      )}
    </List>
  );
};

export default DoctorVisit;
