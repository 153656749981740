import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Doctor, UserRoleTypes, Language } from '../../api/types';
import { useContextRole } from '../../api/usages';
import getDoctorName from '../../lib/utils/getDoctorName';
import Avatar from '../Avatar';

const useStyles = makeStyles(() =>
  createStyles({
    rootVerified: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    rating: {
      color: '#ffb400',
    },
    zeroRating: {
      color: '#00000060',
    },
  }),
);

export const roundRating = (rating: number) =>
  (Math.round(rating * 10) / 10).toFixed(1);

interface DoctorRowProps {
  doctor: Doctor;
  onChooseDoctor?: (doctor: any) => void;
  favouriteDoctorsIds?: string[];
  handleSetFavouriteDoctor?: (doctorId: string, makeFavourite: boolean) => void;
}

const DoctorRow = ({
  doctor,
  onChooseDoctor,
  favouriteDoctorsIds,
  handleSetFavouriteDoctor,
}: DoctorRowProps) => {
  const cx = useStyles();
  const isFavourite = favouriteDoctorsIds?.includes(doctor.id);
  const role = useContextRole();
  const { t, i18n } = useTranslation();
  const verified = doctor.verified;

  const lang = i18n.language as Language;
  const doctorName = getDoctorName(doctor, lang);

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={doctor.id}
      onClick={
        verified && onChooseDoctor ? () => onChooseDoctor(doctor) : undefined
      }
      className={verified ? cx.rootVerified : undefined}
    >
      <TableCell padding="checkbox" size="small">
        <Avatar photoURL={doctor.photoURL} name={doctorName} />
      </TableCell>
      <TableCell align="left" size="small">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {!verified && (
              <Typography variant="h6" color="error">
                {' '}
                {t('admin.deactivate')}
              </Typography>
            )}
            <Typography variant="h6">{doctorName}</Typography>
            <Typography component="span" variant="body2" color="textPrimary">
              {t(`profile.title_${doctor?.medTitle}`)}
            </Typography>
          </Grid>
          {doctor.rating >= 0 && (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Typography
                  className={doctor.rating > 0 ? cx.rating : cx.zeroRating}
                  variant="subtitle1"
                >
                  {roundRating(doctor.rating)}{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Rating precision={0.1} value={doctor.rating} readOnly />
              </Grid>
            </Grid>
          )}
        </Grid>
      </TableCell>
      {handleSetFavouriteDoctor && role === UserRoleTypes.PATIENT && (
        <TableCell align="right" padding="checkbox" size="small">
          <IconButton
            onClick={event => {
              event.stopPropagation();
              handleSetFavouriteDoctor &&
                handleSetFavouriteDoctor(doctor.id, !isFavourite);
            }}
          >
            {isFavourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DoctorRow;
