export const askForArabic = 'من فضلك باللغة العربية';
export const askForArabicAbout =
  'من فضلك باللغة العربية . سطرين مثل ملخص الأقاب والدرجات المكتوب أعلا الروشتة ';

export const genderOptions = [
  { label: 'profile.male', value: 'male' },
  { label: 'profile.female', value: 'female' },
];

export const medTitleOptions = [
  { label: 'profile.title_consultant', value: 'consultant' },
  { label: 'profile.title_specialist', value: 'specialist' },
  { label: 'profile.title_practitioner', value: 'practitioner' },
];

export const extraMedTitleOptions = [
  ...medTitleOptions,
  { label: 'profile.title_any', value: 'any' },
];

export const countriesOptions = [
  { label: 'countries.egypt', value: 'Egypt' },
  { label: 'countries.uae', value: 'UAE' },
  { label: 'countries.saudiArabia', value: 'Saudi Arabia' },
];

export const proficiencyOptions = [
  { label: 'profile.proficiency_noKnowledge', value: 'noKnowledge' },
  { label: 'profile.proficiency_elementary', value: 'elementary' },
  { label: 'profile.proficiency_intermediate', value: 'intermediate' },
  { label: 'profile.proficiency_advanced', value: 'advanced' },
  { label: 'profile.proficiency_fluent', value: 'fluent' },
];

export const workOptions = [
  { label: 'profile.desk', value: 'desk' },
  { label: 'profile.manual', value: 'manual' },
];
