import { auth, db, mergeData, timestampNow } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { ReferralTypes } from '../types';
import {
  LabOrRadioBookingStatus,
  RadioBooking,
  RadioBookingRaw,
} from '../types/Booking';

export const createRadioBooking = createAction({
  type: 'createRadioBooking',
  async callback(radioBookingRaw: RadioBookingRaw) {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user');
    }
    const patientId = user.uid;
    const { radioId, serviceId, bookingId, referralId } = radioBookingRaw;

    const newRadioBookingRef = db.collection('radioBookings').doc();
    const referralRef = db.collection('radioReferrals').doc(referralId);

    const newRadioBooking = {
      referralId,
      patientId,
      radioId,
      serviceId,
      bookingId,
      createdAt: timestampNow(),
      updatedAt: timestampNow(),
      status: LabOrRadioBookingStatus.UNDELIVERED,
    };

    try {
      await db.runTransaction(async (t: any) => {
        const referralDoc = await t.get(referralRef);
        const referralStatus = referralDoc.data()?.status;
        const referralNote = referralDoc.data()?.note;

        if (referralStatus !== ReferralTypes.OPEN) {
          throw new Error('Referral already used.');
        }

        t.set(newRadioBookingRef, { ...newRadioBooking, note: referralNote });
      });
    } catch (e) {
      console.error('Error creating booking:', e);
    }
  },
});

export const updateRadioBooking = createAction({
  type: 'updateRadioBooking',
  callback(radioBookingId: string, data: Omit<RadioBooking, 'id'>) {
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user');
    }

    return mergeData(`radioBookings/${radioBookingId}`, data);
  },
});
