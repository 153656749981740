import { CircularProgress } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { cloneDeep, isEmpty, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm } from 'uniforms';
import { ErrorField, ListField } from 'uniforms-material';

import { saveLabReferrals } from '../../../api/actions/doctorNotes';
import { labRefsSchema } from '../../../api/schemas/DoctorNotes';
import { useLabCategories, useLabReferrals } from '../../../api/usages';
import LabReferralListItem from '../../SessionSummary/LabReferralListItem';
import DoctorNotesSection from './DoctorNotesSection';

const transformFetchedLabRefs = ({ bookingRes, labReferralsRes }: any) => ({
  booking: bookingRes.result,
  labReferrals: labReferralsRes.labReferrals,
});

interface LabReferralsProps {
  loading: string;
  bookingIdQuery: object;
  bookingRes: any;
}

const LabReferrals = ({
  loading,
  bookingIdQuery,
  bookingRes,
}: LabReferralsProps) => {
  const { t } = useTranslation();
  const { labCategories } = useLabCategories();

  const labReferralsRes = useLabReferrals(bookingIdQuery);

  const labRefsLoadedRef = useRef<boolean>(false);
  const [labRefModel, setLabRefModel] = useState<any>({});

  useEffect(() => {
    if (labRefsLoadedRef.current) {
      return;
    }

    if ([bookingRes, labReferralsRes].every(res => !res.loading)) {
      labRefsLoadedRef.current = true;

      const { labReferrals } = transformFetchedLabRefs({
        bookingRes,
        labReferralsRes,
      });

      setLabRefModel((prevModel: any) => ({
        ...prevModel,
        labReferrals,
      }));
    }
  }, [bookingRes, labReferralsRes]);

  const handleSubmitLabRefs = (submitModel: any) => {
    const fetchedData = transformFetchedLabRefs({
      bookingRes,
      labReferralsRes,
    });

    saveLabReferrals({
      booking: {
        id: fetchedData.booking?.id,
        patientId: fetchedData.booking?.patientId,
        complaint: submitModel.complaint ?? '',
      },
      labReferrals: submitModel.labReferrals,
      fetchedLabReferrals: fetchedData.labReferrals,
    });
  };

  const handleFieldChangeLabRefs = (field: string, value: any) => {
    const newModel = set(cloneDeep(labRefModel), field, value);
    setLabRefModel(newModel);
    return newModel;
  };

  if (isEmpty(labRefModel)) {
    return (
      <div className={loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <AutoForm
      schema={labRefsSchema}
      onChange={handleFieldChangeLabRefs}
      model={labRefModel}
      onSubmit={handleSubmitLabRefs}
      autosave
      autosaveDelay={700}
    >
      <DoctorNotesSection
        label={t('doctorNotes.labReferral')}
        count={labRefModel.labReferrals.length}
      >
        <ListField name="labReferrals" addIcon={<Add />} label="">
          <LabReferralListItem
            name="$"
            categories={labCategories}
            handleFieldChange={handleFieldChangeLabRefs}
            labReferrals={labRefModel.labReferrals}
          />
        </ListField>
        <ErrorField name="labReferrals" />
      </DoctorNotesSection>
    </AutoForm>
  );
};

export default LabReferrals;
