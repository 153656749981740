import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PrescriptionDetails from '../components/HealthRecords/Prescriptions/PrescriptionDetails';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const HealthRecordsPrescription = () => {
  const { t } = useTranslation();
  const { bookingId } = useParams();
  return (
    <LayoutDefault>
      <Title title={t('doctorNotes.presDetails')} />
      <PrescriptionDetails bookingId={bookingId} />
    </LayoutDefault>
  );
};

export default HealthRecordsPrescription;
