import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EmailIcon from '@material-ui/icons/Email';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigate } from '../api/actions/navigation';
import { UserRoleTypes } from '../api/types';
import { useContextRole, useProfile } from '../api/usages';
import { roundRating } from '../components/Doctors/DoctorRow';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import RouteTypes from '../constants/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: '0 auto',
      '& .MuiTextField-root': {
        margin: '0',
      },
      '& .MuiInput-root': {
        marginBottom: '16px',
      },
      '& .Mui-error': {
        marginBottom: '12px',
      },
    },
    wrapper: {
      maxWidth: 500,
      margin: '0 auto',
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    rating: {
      color: '#ffb400',
    },
    zeroRating: {
      color: '#00000060',
    },
    ratingMargin: {
      marginRight: theme.spacing(2),
    },
  }),
);

const goToChangePass = () => {
  navigate(RouteTypes.CHANGE_PASSWORD);
};
const goToChangeEmail = () => {
  navigate(RouteTypes.CHANGE_EMAIL);
};
const goToChangePhone = () => {
  navigate(RouteTypes.CHANGE_PHONE);
};
const goToWallet = () => {
  navigate(RouteTypes.PAYMENT_WALLET);
};
const goToPayments = () => {
  navigate(RouteTypes.PAYMENT_CASHIN);
};

const Settings = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const role = useContextRole();
  const profile: { [index: string]: any } | null = useProfile();
  const isDoctor = role === UserRoleTypes.DOCTOR;
  const isPatient = role === UserRoleTypes.PATIENT;

  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3} className={cx.wrapper}>
          <Box className={cx.header}>
            <Title title={t('common.settings')} />
          </Box>
          <List component="nav" aria-label="main folders">
            {profile && isDoctor && (
              <ListItem>
                <Typography className={cx.ratingMargin} variant="subtitle1">
                  {t('booking.rating')}:{' '}
                </Typography>
                {profile.rating >= 0 && (
                  <Typography
                    className={profile.rating > 0 ? cx.rating : cx.zeroRating}
                    variant="subtitle1"
                  >
                    {roundRating(profile.rating)}{' '}
                  </Typography>
                )}
                <Grid item>
                  <Rating precision={0.1} value={profile.rating} readOnly />
                </Grid>
              </ListItem>
            )}
            <ListItem button onClick={goToChangePass}>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText>{t('auth.changePassword')}</ListItemText>
            </ListItem>
            <ListItem button onClick={goToChangeEmail}>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText>{t('auth.changeEmail')}</ListItemText>
            </ListItem>
            <ListItem button onClick={goToChangePhone}>
              <ListItemIcon>
                <SettingsPhoneIcon />
              </ListItemIcon>
              <ListItemText>{t('auth.changePhone')}</ListItemText>
            </ListItem>
          </List>
        </Box>

        {(isPatient || isDoctor) && (
          <Box p={3} className={cx.wrapper}>
            <Box className={cx.header}>
              <Title title={t('payments.menuTitle')} />
            </Box>
            <List component="nav" aria-label="main folders">
              {isDoctor && (
                <ListItem button onClick={goToWallet}>
                  <ListItemIcon>
                    <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText>{t('payments.menuEarnings')}</ListItemText>
                </ListItem>
              )}
              {isPatient && (
                <>
                  <ListItem button onClick={goToWallet}>
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText>{t('payments.shoofWallet')}</ListItemText>
                  </ListItem>
                  <ListItem button onClick={goToPayments}>
                    <ListItemIcon>
                      <PaymentIcon />
                    </ListItemIcon>
                    <ListItemText>{t('payments.menuMethods')}</ListItemText>
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        )}
      </Card>
    </LayoutDefault>
  );
};

export default Settings;
