import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';

import { navigate } from '../api/actions/navigation';
import { Doctor } from '../api/types';
import { useDocument, useUserId } from '../api/usages';
import FromLinkUserLogin from '../components/Doctors/FromLinkUserLogin';
import FromLinkUserLogout from '../components/Doctors/FromLinkUserLogout';
import LayoutDefault from '../components/LayoutDefault';
import RouteTypes from '../constants/routes';

const DoctorDetailsFromLink = () => {
  const userId = useUserId();
  const doctorId = window.location.href.split('/doctor/')[1];

  useEffect(() => {
    if (userId === doctorId) {
      navigate(RouteTypes.HOME);
    }
  }, [userId, doctorId]);

  const { result: doctor, loading, error } = useDocument<Doctor>(
    {
      path: `doctors/${doctorId}`,
    },
    { skip: !doctorId },
  );

  if (error) {
    return <LayoutDefault />;
  }
  if (loading) {
    return (
      <LayoutDefault>
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      </LayoutDefault>
    );
  }

  return (
    <LayoutDefault>
      {userId ? (
        <FromLinkUserLogin userId={userId} doctor={doctor} />
      ) : (
        <FromLinkUserLogout doctor={doctor} />
      )}
    </LayoutDefault>
  );
};

export default DoctorDetailsFromLink;
