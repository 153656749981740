import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TFunctionResult } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DoctorWithSpecialty, Language } from '../../../api/types';
import { useDocument } from '../../../api/usages';
import getDoctorName from '../../../lib/utils/getDoctorName';
import Avatar from '../../Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      paddingRight: theme.spacing(20),
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(0),
      },
    },
    lists: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    list: {
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    text: {
      whiteSpace: 'pre-line',
      minHeight: '25px',
    },
  }),
);

interface DoctorListItemProps {
  label: string;
  value: string | number | TFunctionResult | Function;
}

const DoctorListItem = ({ label, value }: DoctorListItemProps) => {
  const cx = useStyles();
  return (
    <ListItem divider>
      <ListItemText
        primary={label}
        secondary={value}
        classes={{ primary: cx.text, secondary: cx.text }}
      />
    </ListItem>
  );
};
interface DoctorInfoProps {
  doctorId: string;
  callSummaryView?: boolean;
}

const DoctorInfo = ({ doctorId, callSummaryView }: DoctorInfoProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const { result: doctor, error, loading } = useDocument<DoctorWithSpecialty>(
    {
      path: `doctors/${doctorId}`,
      dependencies: [
        {
          collectionPath: 'specialties',
          mapFrom: 'specialtyId',
          mapTo: 'specialty',
        },
      ],
    },
    { skip: !doctorId },
  );

  const lang = i18n.language as Language;

  const name = getDoctorName(doctor, lang) ?? '';
  const about = (doctor?.about?.[lang] || doctor?.about?.en) ?? '';
  const photoURL = doctor?.photoURL ?? '';
  const specialty = doctor?.specialty?.translations[lang] ?? '';
  const medTitle = doctor?.medTitle ?? '';

  if (loading || error) {
    return null;
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Paper elevation={0} className={cx.lists}>
          <List className={cx.list}>
            {name && <DoctorListItem label="" value={name} />}
            {specialty && <DoctorListItem label="" value={specialty} />}
          </List>
          <List className={cx.list}>
            {medTitle && (
              <DoctorListItem label="" value={t(`profile.title_${medTitle}`)} />
            )}
          </List>
        </Paper>
      </Grid>
      <Grid item>
        <Paper elevation={0} className={cx.avatar}>
          <Avatar photoURL={photoURL} name={name} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={cx.avatar}>
          <List classes={{ root: cx.list }}>
            {about && <DoctorListItem label={''} value={about} />}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DoctorInfo;
