import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { upsertPrescription } from '../../api/actions/prescriptions';
import { Query } from '../../api/types';
import { Booking } from '../../api/types/Booking';
import { usePrescriptions, useUserId } from '../../api/usages';

type EditPrescriptionProps = {
  booking: Booking;
  onClose: () => void;
};

const EditPrescription = ({ booking, onClose }: EditPrescriptionProps) => {
  const userId = useUserId();

  const bookingIdQuery: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: booking.id,
    },
    {
      fieldPath: 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  const { prescriptions } = usePrescriptions(bookingIdQuery);
  const { t } = useTranslation();
  const prescription = prescriptions?.[0];

  const [prescriptionText, setPrescription] = useState(prescription?.content);

  useEffect(() => {
    setPrescription(prescription?.content);
  }, [prescription]);

  const handlePrescriptionChange = () => {
    upsertPrescription({
      id: prescription?.id,
      bookingId: booking.id,
      patientId: booking.patientId,
      content: prescriptionText,
    });

    onClose();
  };

  return (
    <>
      <Box display="grid" gridGap="10px" minWidth="400px">
        <Box>
          <Typography>prescription:</Typography>
          <TextField
            id="standard-multiline-flexible"
            fullWidth
            multiline
            value={prescriptionText}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setPrescription(event.target.value)
            }
          />
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePrescriptionChange}
        style={{ marginTop: '30px' }}
      >
        {t('common.submit')}
      </Button>
    </>
  );
};
export default EditPrescription;
