import { CardContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PayoutIcon from '@material-ui/icons/LocalAtm';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Currency, PaymentWallet, Price } from '../../api/types/Payments';
import { formatPrice } from '../../lib/utils';

type Props = {
  wallet: PaymentWallet | null;
  onPayout?: (currency: Currency) => void;
};

const WalletStatus = ({ wallet, onPayout }: Props) => {
  const { t } = useTranslation();

  if (!wallet) {
    return null;
  }

  return (
    <Grid container spacing={2} direction="row">
      {Object.entries(wallet.accounts || {}).map(([currency, amount = 0]) => (
        <Grid key={currency} item md={6} lg={3}>
          <Card variant="outlined">
            <CardContent>
              <Box marginY={2}>
                <Typography align="center">
                  <AccountBalanceWalletIcon fontSize="large" color="primary" />
                </Typography>
                <Typography variant="h5" align="center">
                  {formatPrice({ amount, currency } as Price)}
                </Typography>
              </Box>
            </CardContent>
            {onPayout && (
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<PayoutIcon />}
                  onClick={() => onPayout(currency as Currency)}
                >
                  {t('payments.payout')}
                </Button>
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default WalletStatus;
