import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import capitalize from 'lodash/capitalize';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AutoForm } from 'uniforms';
import { AutoField, ErrorField, SubmitField } from 'uniforms-material';

import { showSuccessToast, showErrorToast } from '../../api/actions/uiControls';
import { UpdateProfileLabRadio } from '../../api/schemas/UpdateProfileLabRadio';
import { Lab } from '../../api/types/Lab';
import { Radio } from '../../api/types/Radio';
import { useDocument } from '../../api/usages';
import { proficiencyOptions } from '../../constants/ConfigProfile';
import RouteTypes from '../../constants/routes';
import { editUser } from '../../lib/admin';
import { arrayToObject } from '../../lib/transformHelpers';
import { mapAddress } from '../../lib/utils/mapAddress';
import CustomSelectField from '../CustomSelectField';
import DownloadPDFBtn from '../DownloadPDFBtn';
import PhoneNumberField from '../PhoneNumberField';
import AddressAreaList from '../Profile/AddressAreaList';
import UpdateServices from '../Profile/UpdateServices';
import SectionTitle from '../SectionTitle';
import TabPanel from '../TabPanel';
import UploadAreaUniformField from '../UploadAreaUniformField';

type EditLabRadioProps = {
  type: 'lab' | 'radio';
  id: string;
};

const EditLabRadio = ({ type, id }: EditLabRadioProps) => {
  const { t } = useTranslation();

  const collection = (type + 's') as 'labs' | 'radios';

  const { result: labRadio } = useDocument<Lab | Radio>({
    path: `${collection}/${id}`,
  });

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  const [updateInProgress, setUpdateInProgress] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (data: any, updateServices?: boolean) => {
    setUpdateInProgress(true);
    const servicesToSend = updateServices
      ? arrayToObject(data.services)
      : labRadio?.services;

    data.address = (data.address ?? labRadio?.address).map(mapAddress);

    const { success, message } = await editUser({
      id,
      collection,
      data: {
        ...labRadio,
        ...data,
        services: servicesToSend,
      },
    });
    if (success) {
      showSuccessToast(t(`admin.${type}Edited`));
    } else {
      showErrorToast(message ?? t(`admin.errorEditing${capitalize(type)}`));
    }

    setUpdateInProgress(false);
  };

  if (!labRadio) {
    return null;
  }

  const backTo =
    type === 'lab' ? RouteTypes.MANAGE_LABS : RouteTypes.MANAGE_RADIOS;

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
      >
        <Tab label={t('englishOnly.profile_basicInfo')} />
        <Tab label={t('englishOnly.profile_services')} />
      </Tabs>
      <TabPanel value={currentTab} index={0}>
        <AutoForm
          disabled={updateInProgress}
          model={labRadio}
          schema={UpdateProfileLabRadio}
          onSubmit={handleSubmit}
        >
          <SectionTitle title={t('englishOnly.profile_businessInfo')} />
          <PhoneNumberField
            name="phoneNumber"
            label={t('profile.phoneNumber')}
          />
          <AutoField name="email" label={t('profile.email')} margin="dense" />
          <AutoField
            name="contactPerson"
            label={t('englishOnly.profile_contactPerson')}
          />
          <ErrorField name="contactPerson" />
          <AutoField
            name="receptionPhone"
            label={t('englishOnly.profile_receptionPhone')}
          />
          <ErrorField
            name="receptionPhone"
            errorMessage={t('englishOnly.profile_receptionPhoneError')}
          />
          <AutoField
            name="openTimes"
            label={t('englishOnly.profile_openTimes')}
          />
          <ErrorField name="openTimes" />
          <AutoField name="name" label={t('profile.fullBusinessName')} />
          <ErrorField name="name" />
          <AutoField
            name="about"
            label={t('profile.about')}
            placeholder={t('profile.aboutLabPlaceholder')}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
          <ErrorField name="about" />
          <AutoField
            name="commercialRegistration"
            label={t('englishOnly.profile_commercialRegistration')}
          />
          <ErrorField name="commercialRegistration" />
          <UploadAreaUniformField
            name="commercialRegistrationUpload"
            text={t('englishOnly.profile_commercialRegistrationUpload')}
            filesLimit={3}
            acceptedFiles={['image/*', 'application/pdf']}
            margin="16px"
          />
          <ErrorField name="commercialRegistrationUpload" />
          <DownloadPDFBtn url={labRadio.commercialRegistrationUpload || ''} />
          <AutoField
            name="mohLicence"
            label={t('englishOnly.profile_mohLicence')}
          />
          <ErrorField name="mohLicence" />
          <UploadAreaUniformField
            name="mohLicenceUpload"
            text={t('englishOnly.profile_mohLicenceUpload')}
            filesLimit={3}
            acceptedFiles={['image/*', 'application/pdf']}
            margin="16px"
          />
          <ErrorField name="mohLicenceUpload" />
          <DownloadPDFBtn url={labRadio.mohLicenceUpload || ''} />
          <AutoField
            name="resellerCode"
            label={t('profile.resellerCode')}
            inputProps={{ maxLength: 4 }}
          />
          <ErrorField
            name="resellerCode"
            errorMessage={t('profile.resellerError')}
          />
          <CustomSelectField
            name="arabicProficiency"
            label={t('englishOnly.profile_arabicProficiency')}
            options={proficiencyOptions}
            fullWidth
          />
          <CustomSelectField
            name="englishProficiency"
            label={t('englishOnly.profile_englishProficiency')}
            options={proficiencyOptions}
            fullWidth
          />
          <CustomSelectField
            name="frenchProficiency"
            label={t('englishOnly.profile_frenchProficiency')}
            options={proficiencyOptions}
            fullWidth
          />
          <AutoField
            name="otherLang"
            label={t('englishOnly.profile_otherLang')}
          />
          <ErrorField name="otherLang" />
          <AutoField name="offerDelivery" label={t('labs.offerDelivery')} />
          <ErrorField name="offerDelivery" />
          <AddressAreaList
            name="address"
            address={labRadio.address ?? []}
            disabled={updateInProgress}
          />
          <Box display="flex" justifyContent="space-between">
            <SubmitField color="primary" />
            <Button color="secondary" component={Link} to={backTo}>
              {t('common.back')}
            </Button>
          </Box>
        </AutoForm>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <UpdateServices
          model={labRadio}
          role={type}
          editDisabled={updateInProgress}
          onSubmit={handleSubmit}
          backTo={backTo}
        />
      </TabPanel>
    </>
  );
};

export default EditLabRadio;
