import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { RemoteParticipant } from 'twilio-video';

import { useMediaRefs } from '../../api/usages/video';
import { ParticipantOverlay } from './ParticipantOverlay';

type Props = {
  participant: RemoteParticipant;
};

const useStyles = makeStyles({
  video: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    backgroundColor: '#000',
    objectFit: 'contain',
  },
});

const Participant = ({ participant }: Props) => {
  const cx = useStyles();
  const { audioRef, videoRef } = useMediaRefs(participant);

  return (
    <ParticipantOverlay uid={participant.identity}>
      <video ref={videoRef} className={cx.video} autoPlay playsInline />
      <audio ref={audioRef} />
    </ParticipantOverlay>
  );
};

export default Participant;
