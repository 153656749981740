import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EditLabRadio from '../components/Admin/EditLabRadio';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { RouteTypes } from '../constants/routes';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '700px',
      margin: '40px auto 20px',
    },
    buttons: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
);

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminEditRadio = () => {
  const { radioId } = useParams<{ radioId: string }>();
  const { t } = useTranslation();
  const cx = useStyles();

  useRedirectWhenNotExists(`radios/${radioId}`, RouteTypes.MANAGE_RADIOS);

  return (
    <LayoutDefault>
      <Title title={t('admin.editRadio')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <EditLabRadio type="radio" id={radioId} />
        </Container>
      </Card>
    </LayoutDefault>
  );
};
export default AdminEditRadio;
