import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as colors from '@material-ui/core/colors';
import BankIcon from '@material-ui/icons/AccountBalance';
import EventOkIcon from '@material-ui/icons/EventAvailable';
import EventFailIcon from '@material-ui/icons/EventBusy';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PaymentOrder,
  PaymentWalletLog,
  WalletOperation,
} from '../../api/types/Payments';
import { useDocument } from '../../api/usages';
import { formatPrice } from '../../lib/utils';

const getStatusImage = (log: PaymentWalletLog) => {
  switch (log.type) {
    case WalletOperation.CASH_IN:
      return <BankIcon fontSize="large" style={{ color: colors.green[500] }} />;
    case WalletOperation.CASH_OUT:
      return <BankIcon fontSize="large" style={{ color: colors.red[200] }} />;
    case WalletOperation.VISIT_PAY:
      return (
        <EventOkIcon fontSize="large" style={{ color: colors.red[200] }} />
      );
    case WalletOperation.VISIT_RECEIVE:
      return (
        <EventOkIcon fontSize="large" style={{ color: colors.green[500] }} />
      );
    case WalletOperation.VISIT_REFUND:
      return (
        <EventFailIcon fontSize="large" style={{ color: colors.green[500] }} />
      );
    default:
      return <HelpIcon fontSize="large" style={{ color: colors.grey[500] }} />;
  }
};

interface WalletLogProps {
  log: PaymentWalletLog;
}

const WalletLog = ({ log }: WalletLogProps) => {
  const { t } = useTranslation();

  const { result: order } = useDocument<PaymentOrder>(
    {
      path: `paymentOrders/${log.orderId}`,
    },
    { skip: !log.orderId },
  );

  return (
    <ListItem dense divider>
      <ListItemIcon>{getStatusImage(log)}</ListItemIcon>
      <ListItemText
        primary={t(`payments.log-${log.type}`)}
        secondary={
          order?.paymentMethod ? t(`paymentLog.${order?.paymentMethod}`) : null
        }
      />
      <ListItemText
        style={{ textAlign: 'right' }}
        primary={formatPrice(log.price)}
        secondary={log?.createdAt?.toDate().toLocaleString()}
      />
    </ListItem>
  );
};

export default WalletLog;
