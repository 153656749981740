export type { Doctor, DoctorWithSpecialty } from './Doctor';
export type { Query, SimpleQuery, FullQuery } from './Query';

export type { Specialty } from './Specialty';
export * from './UserProfile';
export * from './Translations';
export * from './Labs';
export * from './Radios';
export * from './Diagnosis';
export * from './Observation';
export * from './Prescription';
export * from './DoctorReferral';
export * from './Patient';
export * from './Booking';
export * from './Certificates';
export * from './Address';
export * from './DoctorReferral';
export * from './LabReferral';
export * from './RadioReferral';
export * from './Nationality';
export * from './HealthRecords';
