// @ts-ignore
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AutoForm, SubmitField } from 'uniforms-material';

import { CodeFormSchema } from '../../api/schemas/CreateProfile';
import PhoneCodeField from '../../components/PhoneCodeField';
import { getI18nOptions } from '../../lib/utils';

type ConfirmProps = {
  phoneNumber: string;
  onConfirmCode: Function;
  disabled?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      marginTop: `24px`,
    },
  }),
);

const ConfirmCode = ({
  phoneNumber,
  onConfirmCode,
  disabled = false,
}: ConfirmProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const location = useLocation();
  const options = getI18nOptions(location.pathname);
  const properCodeLength = 6;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleChangeCodeLength = (codeLength: number) => {
    setIsSubmitDisabled(codeLength !== properCodeLength);
  };

  return (
    <AutoForm
      schema={CodeFormSchema}
      onSubmit={({ code }: { code: number }) => {
        onConfirmCode(code);
      }}
    >
      <PhoneCodeField
        name="code"
        label={t('common.enterPhoneCode', options)}
        phoneNumber={phoneNumber}
        properCodeLength={properCodeLength}
        handleChangeCodeLength={handleChangeCodeLength}
        disabled={disabled}
      />
      <SubmitField
        color="primary"
        label={t('common.submit', options)}
        className={cx.submit}
        disabled={isSubmitDisabled}
      />
    </AutoForm>
  );
};

export default ConfirmCode;
