import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SendNotification from '../components/Admin/SendNotification';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '700px',
      margin: '40px auto 0',
    },
  }),
);

const AdminNotifications = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  return (
    <LayoutDefault>
      <Title title={t('admin.sendNotification')} />
      <Box className={cx.root}>
        <SendNotification />
      </Box>
    </LayoutDefault>
  );
};

export default AdminNotifications;
