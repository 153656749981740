import {
  Button,
  Typography,
  Grid,
  Card,
  Box,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { Booking } from '../api/types/Booking';
import { Timestamp } from '../api/types/Timestamp';
import { useDocument } from '../api/usages';
import SummarySection from '../components/CallSummary/SummaryDetails';
import SummaryDialog, {
  useToggle,
} from '../components/CallSummary/SummaryDialog';
import LayoutDefault from '../components/LayoutDefault';
import BasicNotes from '../components/SessionSummary/BasicNotes';
import EditPrescription from '../components/SessionSummary/EditPrescription';
import EditReferrals from '../components/SessionSummary/EditReferrals';
import Title from '../components/Title';
import CallHealthRecords from '../components/VideoCall/Toolbar/CallHealthRecords';
import { fee } from '../constants/app';
import { centsToDollars } from '../lib/utils';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 700,
      padding: 16,
    },
    currency: {
      fontSize: theme.typography.pxToRem(16),
      margin: 10,
    },
    price: {
      fontSize: theme.typography.pxToRem(28),
    },
    duration: {
      fontSize: theme.typography.pxToRem(24),
      margin: 10,
    },
    time: {
      fontSize: theme.typography.pxToRem(24),
    },
    spacing: {
      margin: 16,
    },
    noShowInfo: {
      width: '80%',
      margin: '10px auto',
    },
    spacingTop: {
      marginTop: 16,
    },
    specialityNotes: {
      margin: 16,
      '& > *': {
        margin: 16,
      },
    },
    stars: {
      marginLeft: 8,
    },
    closeButton: {
      alignSelf: 'flex-end',
      marginRight: 16,
    },
  }),
);

const isPrescriptionEditionDisabled = (
  disabled: boolean,
  booking: Booking | null,
): boolean => {
  if (!booking) {
    return disabled;
  }

  const endDate = booking.endDate.toDate();
  // The doctor may edit the prescription for 2 weeks after the teleconsultation.
  return disabled || moment(endDate).add(2, 'weeks').isBefore(moment());
};

const SessionSummary = () => {
  const cx = useStyles();
  const { bookingId } = useParams<{ bookingId: string }>();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useRedirectWhenNotExists(`bookings/${bookingId}`);

  const { result: booking, error, loading } = useDocument<Booking>({
    path: `bookings/${bookingId}`,
  });

  const [seeEHR, toggleSeeEHR] = useToggle();
  const [editPrescription, toggleEditPrescription] = useToggle();
  const [editReferrals, toggleEditReferrals] = useToggle();

  const visitedByDoctor = booking?.visitedByDoctor ?? false;
  const visitedByPatient = booking?.visitedByPatient ?? false;

  const disabled = !visitedByDoctor || !visitedByPatient;
  const prescriptionEditionDisabled = isPrescriptionEditionDisabled(
    disabled,
    booking,
  );
  const price = {
    amount: visitedByDoctor ? booking?.price?.amount ?? 0 : 0,
    currency: booking?.price?.currency ?? t('common.currencyEGP'),
  };

  const priceAmountString = centsToDollars(price.amount);
  const netAmount = Math.floor(price.amount * (1 - fee));
  const feeAmount = price.amount - netAmount;

  const duration =
    booking && !disabled
      ? (((booking.endDate as unknown) as Timestamp).seconds -
          ((booking.startDate as unknown) as Timestamp).seconds) /
        60
      : 0;

  const startTime = ((booking?.startDate as unknown) as Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      dateStyle: 'medium',
      timeStyle: 'short',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const bookingType = booking?.type ? t(`booking.type_${booking.type}`) : '';

  const handleClose = useCallback(() => {
    history.push('/');
  }, [history]);

  if (error || loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  return (
    <LayoutDefault>
      <Title title={t('sessionSummary.sessionDetails')} />
      {!visitedByDoctor && (
        <Typography
          variant="subtitle1"
          align="center"
          color="error"
          className={cx.noShowInfo}
        >
          {t('englishOnly.forDoctor_doctorNoShow')}
        </Typography>
      )}
      {!visitedByPatient && (
        <Typography
          variant="subtitle1"
          align="center"
          color="error"
          className={cx.noShowInfo}
        >
          {t('englishOnly.forDoctor_patientNoShow')}
        </Typography>
      )}
      <Grid container direction="row" justify="center" alignItems="center">
        <Card className={`${cx.root} ${cx.spacing}`}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {booking && <BasicNotes booking={booking} disabled={disabled} />}
            <div className={cx.specialityNotes}>
              <Button
                onClick={toggleSeeEHR}
                variant="contained"
                color="primary"
                disabled={disabled}
              >
                {t('englishOnly.forDoctor_seeEHR')}
              </Button>
              <Button
                onClick={toggleEditPrescription}
                variant="contained"
                color="primary"
                disabled={prescriptionEditionDisabled}
              >
                {t('sessionSummary.editPrescription')}
              </Button>
              <Button
                onClick={toggleEditReferrals}
                variant="contained"
                color="primary"
                disabled={disabled}
              >
                {t('sessionSummary.editReferrals')}
              </Button>
            </div>
            <SummaryDialog
              title={t('englishOnly.forDoctor_seeEHR')}
              open={seeEHR}
              onClose={toggleSeeEHR}
              extraContent={() => booking && <CallHealthRecords />}
              maxWidth="lg"
            />
            <SummaryDialog
              title={t('sessionSummary.editPrescription')}
              open={editPrescription}
              onClose={toggleEditPrescription}
              extraContent={() =>
                booking && (
                  <EditPrescription
                    onClose={toggleEditPrescription}
                    booking={booking}
                  />
                )
              }
            />
            <SummaryDialog
              title={t('sessionSummary.editReferrals')}
              open={editReferrals}
              onClose={toggleEditReferrals}
              extraContent={() =>
                booking && (
                  <EditReferrals
                    onClose={toggleEditReferrals}
                    booking={booking}
                  />
                )
              }
            />

            <Typography>Session earning</Typography>
            <Box display="flex" alignItems="center">
              <Typography className={cx.currency}>
                {price.currency ?? t('common.currencyEGP')}
              </Typography>
              <Typography className={cx.price}>{priceAmountString}</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Box className={cx.duration}>{duration}</Box>
              <Box className={cx.time}>min consult</Box>
            </Box>

            <SummarySection
              data={[
                [t('callSummary.dateAndTime'), startTime],
                [t('callSummary.serviceType'), bookingType],
                [t('callSummary.complaint'), booking?.complaint ?? ''],
              ]}
            />
            <Typography className={cx.spacingTop}>
              {t('sessionSummary.receipt')}
            </Typography>
            <SummarySection
              data={[
                [t('sessionSummary.sessionEarning'), priceAmountString],
                [t('sessionSummary.discount'), '0'],
                [t('sessionSummary.total'), priceAmountString],
                [t('sessionSummary.serviceCharged'), centsToDollars(feeAmount)],
                [t('sessionSummary.youReceived'), centsToDollars(netAmount)],
              ]}
            />
            <Button
              color="secondary"
              className={cx.closeButton}
              onClick={handleClose}
            >
              {t('common.close')}
            </Button>
          </Box>
        </Card>
      </Grid>
    </LayoutDefault>
  );
};

export default SessionSummary;
