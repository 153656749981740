import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { deleteHealthRecords } from '../../../api/actions/healthRecords';
import { RecordType } from '../../../api/types';
import { Timestamp } from '../../../lib/firebase';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface TableToolbarProps {
  selected: Array<string>;
  type: RecordType;
  onDelete: () => void;
}

const TableToolbar = ({ type, selected, onDelete }: TableToolbarProps) => {
  const cx = useToolbarStyles();
  const { t } = useTranslation();

  const toTimestamp = selected.map((time: string) =>
    Timestamp.fromDate(new Date(parseInt(time))),
  );

  const handleDelete = () => {
    deleteHealthRecords(toTimestamp, type);
    onDelete();
  };

  return (
    <Toolbar
      className={clsx(cx.root, {
        [cx.highlight]: selected.length > 0,
      })}
    >
      {selected.length > 0 ? (
        <Typography
          className={cx.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} {t(`healthRecords.selected`)}
        </Typography>
      ) : (
        <Typography
          className={cx.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t(`healthRecords.${type.toLowerCase()}`)}
        </Typography>
      )}
      {selected.length > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => handleDelete()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
