import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Paper,
  Radio,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment, { Moment } from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-use-promise';

import { functions } from '../../../lib/firebase';
import { generateId } from '../../../lib/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      marginTop: theme.spacing(2),
      padding: '0 10px',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    radio: {
      display: 'none',
      '& + $label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.9rem',
        },
      },
      '&$checked': {
        '& + $label': {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
        '$slot > &': {
          background: 'blue',
        },
      },
    },
    checked: {},
    label: {
      padding: theme.spacing(1),
      border: 'solid 1px',
      borderColor: 'transparent',
      borderRadius: '10%',
    },
    slot: {
      alignSelf: 'center',
      margin: '5px 0px',
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  }),
);

function isMidnight(checkTime: string) {
  const momentDate = moment(checkTime);
  const hours = momentDate.get('hours');
  const minutes = momentDate.get('minutes');
  return hours === 0 && minutes === 0;
}

function setDate(time: string, toDate: any) {
  const newDate = moment(time);
  newDate.set('date', toDate.get('date'));
  newDate.set('month', toDate.get('month'));
  newDate.set('year', toDate.get('year'));
  return newDate;
}

function setProperlyDate(
  ranges: {
    startDate: string;
    endDate: string;
  }[],
  date: Moment,
) {
  const newRanges = ranges.map(range => {
    return {
      startDate: setDate(
        range.startDate,
        isMidnight(range.startDate) ? date.clone().add(1, 'days') : date,
      ),
      endDate: setDate(
        range.endDate,
        isMidnight(range.endDate) ? date.clone().add(1, 'days') : date,
      ),
    };
  });

  return newRanges.sort((a, b) => a.startDate.diff(b.startDate));
}

type BookingsTimeslots = {
  startDate: Date;
  endDate: Date;
};

function clearExistingBookings(
  ranges: {
    startDate: string;
    endDate: string;
  }[],
  allBookings: BookingsTimeslots[],
  dayTime: Moment,
  timeslot?: number,
) {
  const bookings = allBookings.filter(booking =>
    moment(booking.startDate).isSame(dayTime, 'day'),
  );

  const dateRanges = setProperlyDate(ranges, dayTime);

  const freeSlots: Moment[] = [];
  // console.group('New Day: ', dayTime.format('DD'));

  dateRanges.forEach(rangeItem => {
    // console.group('currentRange ');
    // console.log('Start range', rangeItem.startDate.format('DD HH:mm'));
    // console.log('End range', rangeItem.endDate.format('DD HH:mm'));

    const inRangeBookings = bookings.filter(
      booking =>
        moment(booking.startDate).isSameOrAfter(moment(rangeItem.startDate)) ||
        moment(booking.endDate).isSameOrBefore(moment(rangeItem.endDate)),
    );

    // inRangeBookings.forEach(booking => {
    //   console.group();
    //   console.log(`Od:`, moment(booking.startDate).format('DD HH:mm'));
    //   console.log(`Do:`, moment(booking.endDate).format('DD HH:mm'));
    //   console.groupEnd();
    // });

    // console.log('DoctorStart', moment(rangeItem.startDate).format('DD HH:mm'));
    // console.log('DoctorEnd', moment(rangeItem.endDate).format('DD HH:mm'));

    let borderTime = moment(
      inRangeBookings[0] ? inRangeBookings[0]?.startDate : rangeItem.endDate,
    );
    let currentStartTime = moment(rangeItem.startDate);

    let currentEndTime = moment(currentStartTime).add(timeslot, 'minute');

    for (let i = 0; i <= inRangeBookings.length; i++) {
      // console.group();
      // console.log(`Loop number === ${i}`);
      // console.log('borderTime', borderTime.format('DD HH:mm'));
      // console.log('currentEndTime', currentEndTime.format('DD HH:mm'));

      while (currentEndTime.isBefore(moment(borderTime).add(1, 'second'))) {
        freeSlots.push(moment(currentStartTime));

        // console.log(
        //   'In while slot ===',
        //   moment(currentStartTime).format('DD HH:mm'),
        // );
        currentStartTime = moment(currentEndTime);
        currentEndTime = moment(currentStartTime).add(timeslot, 'minute');
      }

      currentStartTime = moment(
        inRangeBookings[i]
          ? inRangeBookings[i]?.endDate
          : borderTime.add(timeslot, 'minute'),
      );

      borderTime = moment(
        inRangeBookings[i + 1]
          ? inRangeBookings[i + 1]?.startDate
          : rangeItem.endDate,
      );
      currentEndTime = moment(currentStartTime).add(timeslot, 'minute');

      // console.groupEnd();
    }
    // console.groupEnd();
  });
  // console.groupEnd();
  return freeSlots;
}

interface TimeslotsProps {
  date: any;
  doctorId: string;
  ranges: any;
  timeslot: number;
  disabled: boolean;
  handleTimeslotChange: (timeslot: number) => void;
  checkedTimeslot: string;
}

const Timeslots = ({
  checkedTimeslot,
  date,
  doctorId,
  ranges,
  timeslot,
  disabled,
  handleTimeslotChange,
}: TimeslotsProps) => {
  const cx = useStyles();
  const { t, i18n } = useTranslation();
  const currentDayTimestamp = date.valueOf();
  const [result, error, state] = usePromise(
    () =>
      functions.httpsCallable('getAllReservedTimes')({
        doctorId,
        currentDayTimestamp,
      }),
    [doctorId],
  );

  if (error) {
    return null;
  }

  if (state === 'pending' || !state) {
    return (
      <Backdrop classes={{ root: cx.backdrop }} open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  // console.log(sortedTimes);
  // console.log({ sortedTimes });
  // console.log(result?.data);
  const filteredTimes: any[] = clearExistingBookings(
    ranges,
    result?.data,
    date,
    timeslot,
  );

  return filteredTimes.length ? (
    <Paper elevation={2} className={cx.root}>
      {filteredTimes.map(time => {
        const key = generateId();
        return (
          <FormControlLabel
            key={key}
            control={
              <Radio classes={{ root: cx.radio, checked: cx.checked }} />
            }
            disabled={disabled}
            onClick={() => handleTimeslotChange(timeslot)}
            name="startDate"
            value={time.toString()}
            label={time.toDate().toLocaleString('en', {
              hour: '2-digit',
              minute: '2-digit',
            })}
            checked={time.toString() === checkedTimeslot}
            classes={{
              root: cx.slot,
              label: cx.label,
            }}
          />
        );
      })}
    </Paper>
  ) : (
    <Typography variant="body1" color="textSecondary">
      {t('booking.doctorNotAvailableThisDay')}
    </Typography>
  );
};

export default Timeslots;
