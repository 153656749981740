import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import OutlinedFlagRoundedIcon from '@material-ui/icons/OutlinedFlagRounded';
import capitalize from 'lodash/capitalize';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { showSuccessToast, showErrorToast } from '../../api/actions/uiControls';
import { deleteUser, changeVerification, changeTesting } from '../../lib/admin';
import SummaryDialog, { useToggle } from '../CallSummary/SummaryDialog';

type ManagementButtonsProps = {
  collection: 'doctors' | 'patients' | 'labs' | 'radios';
  id: string;
  verified: boolean;
  testing: boolean;
  onSuccess?: () => {};
};

const ManagementButtons = ({
  collection,
  id,
  verified,
  testing,
  onSuccess,
}: ManagementButtonsProps) => {
  const { t } = useTranslation();
  const [inProgress, setInProgress] = useState(false);
  const [
    removalConfirmationDialogOpen,
    toggleRemovalConfirmationDialog,
  ] = useToggle();

  const lowercaseName = collection.slice(0, -1);
  const capitalizedName = capitalize(lowercaseName);

  const handleVerifyChange = async () => {
    setInProgress(true);

    const { success, message } = await changeVerification({
      id,
      collection,
      verified: !verified,
    });
    if (success) {
      showSuccessToast(
        t(`admin.${lowercaseName}${verified ? 'Deactivated' : 'Activated'}`),
      );
      onSuccess && onSuccess();
    } else {
      showErrorToast(message ?? t(`admin.errorActivating${capitalizedName}`));
    }
    setInProgress(false);
  };

  const handleTestingChange = async () => {
    setInProgress(true);

    const { success, message } = await changeTesting({
      id,
      collection,
      testing: !testing,
    });
    if (success) {
      showSuccessToast(
        t(`admin.user${testing ? 'Unmarked' : 'Marked'}AsTesting`),
      );
      onSuccess && onSuccess();
    } else {
      showErrorToast(message ?? t(`admin.errorMarkingUserAsTesting`));
    }
    setInProgress(false);
  };

  const handleDelete = async () => {
    setInProgress(true);

    const { success, message } = await deleteUser({ id, collection });
    if (success) {
      toggleRemovalConfirmationDialog();
      showSuccessToast(t(`admin.${lowercaseName}Deleted`));
      onSuccess && onSuccess();
    } else {
      showErrorToast(message ?? t(`admin.errorDeleting${capitalizedName}`));
      setInProgress(false);
    }
  };

  return (
    <Box
      display="grid"
      gridGap="8px"
      gridTemplateColumns="auto auto"
      gridTemplateRows="auto auto"
    >
      <Button
        color="primary"
        variant="outlined"
        disabled={inProgress}
        onClick={handleTestingChange}
      >
        {testing ? <FlagRoundedIcon /> : <OutlinedFlagRoundedIcon />}
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={inProgress}
        onClick={handleVerifyChange}
      >
        <span style={{ opacity: 0 }}>{t('admin.deactivate')}</span>
        <span style={{ position: 'absolute' }}>
          {t(verified ? 'admin.deactivate' : 'admin.activate')}
        </span>
      </Button>
      <Button
        color="primary"
        variant="outlined"
        component={Link}
        to={`/manage-${collection}/${id}`}
        disabled={inProgress}
      >
        {t('common.edit')}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        disabled={inProgress}
        onClick={toggleRemovalConfirmationDialog}
      >
        {t('admin.delete')}
      </Button>
      {removalConfirmationDialogOpen && (
        <SummaryDialog
          title={t('admin.confirmDeleteInfo')}
          open={removalConfirmationDialogOpen}
          onClose={toggleRemovalConfirmationDialog}
          extraButton={() => (
            <Button color="primary" variant="contained" onClick={handleDelete}>
              {t('admin.confirmDelete')}
            </Button>
          )}
          customCloseText={t('admin.discardDelete')}
        />
      )}
    </Box>
  );
};

export default ManagementButtons;
