import { Patient } from '../api/types';
import { functions } from './firebase';

export type CreateAdminRequest = {
  email: string;
  password: string;
  name: string;
};
export type CreateAdminResponse = {
  success: boolean;
  message?: string;
};

const firebaseCreateAdmin = functions.httpsCallable('createAdmin');

export async function createAdmin(
  data: CreateAdminRequest,
): Promise<CreateAdminResponse> {
  const result = await firebaseCreateAdmin(data);
  return result.data as CreateAdminResponse;
}

export type ChangeVerificationRequest = {
  id: string;
  collection: 'patients' | 'doctors' | 'labs' | 'radios';
  verified: boolean;
};
export type ChangeVerificationResponse = {
  success: boolean;
  message?: string;
};

const firebaseChangeVerification = functions.httpsCallable(
  'changeVerification',
);

export async function changeVerification(
  data: ChangeVerificationRequest,
): Promise<ChangeVerificationResponse> {
  const result = await firebaseChangeVerification(data);
  return result.data as ChangeVerificationResponse;
}

export type ChangeTestingRequest = {
  id: string;
  collection: 'patients' | 'doctors' | 'labs' | 'radios';
  testing: boolean;
};
export type ChangeTestingResponse = {
  success: boolean;
  message?: string;
};

const firebaseChangeTesting = functions.httpsCallable('changeTesting');

export async function changeTesting(
  data: ChangeTestingRequest,
): Promise<ChangeTestingResponse> {
  const result = await firebaseChangeTesting(data);
  return result.data as ChangeTestingResponse;
}

export type DeleteUserRequest = {
  id: string;
  collection: 'patients' | 'doctors' | 'labs' | 'radios';
};
export type DeleteUserResponse = {
  success: boolean;
  message?: string;
};

const firebaseDeleteUser = functions.httpsCallable('deleteUser');

export async function deleteUser(
  data: DeleteUserRequest,
): Promise<DeleteUserResponse> {
  const result = await firebaseDeleteUser(data);
  return result.data as DeleteUserResponse;
}

export type EditUserRequest = {
  id: string;
  collection: 'patients' | 'doctors' | 'labs' | 'radios';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
};
export type EditUserResponse = {
  success: boolean;
  message?: string;
};

const firebaseEditUser = functions.httpsCallable('editUser');

export async function editUser(
  data: EditUserRequest,
): Promise<EditUserResponse> {
  const result = await firebaseEditUser(data);
  return result.data as EditUserResponse;
}

export type GetPatientsCountRequest = {
  orderBy: { fieldPath: string; directionStr: 'asc' | 'desc' };
};

export type GetPatientsCountResponse = {
  success: boolean;
  totalCount?: number;
};

const firebaseGetPatientsCount = functions.httpsCallable('getPatientsCount');

export async function getPatientsCount(
  data: GetPatientsCountRequest,
): Promise<GetPatientsCountResponse> {
  const result = await firebaseGetPatientsCount(data);
  return result.data as GetPatientsResponse;
}

export type GetPatientsRequest = {
  limit: number;
  offset: number;
  orderBy: { fieldPath: string; directionStr: 'asc' | 'desc' };
};

export type GetPatientsResponse = {
  success: boolean;
  patients?: Patient[];
};

const firebaseGetPatients = functions.httpsCallable('getPatients');

export async function getPatients(
  data: GetPatientsRequest,
): Promise<GetPatientsResponse> {
  const result = await firebaseGetPatients(data);
  return result.data as GetPatientsResponse;
}
