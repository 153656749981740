import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoField,
  AutoForm,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import { billingFormSchema } from '../../api/schemas/Payments';
import { PaymentWallet } from '../../api/types/Payments';

type Props = {
  onSubmit: (model: PaymentWallet['billingInfo']) => void;
  wallet: PaymentWallet | null;
};

type FieldProps = {
  name: keyof NonNullable<PaymentWallet['billingInfo']>;
  size: 2 | 3 | 4 | 6 | 8 | 12;
  errorMessage?: string;
};

const BillingForm = ({ onSubmit, wallet }: Props) => {
  const { t } = useTranslation();
  const model = wallet?.billingInfo || {};

  const Field = ({ name, size = 12, errorMessage }: FieldProps) => (
    <Grid item md={size}>
      <AutoField name={name} label={t(`billingInfo.${name}`)} />
      {errorMessage ? (
        <ErrorField name={name} errorMessage={errorMessage} />
      ) : (
        <ErrorField name={name} />
      )}
    </Grid>
  );

  return (
    <Container maxWidth="md">
      <Paper>
        <Box margin={4}>
          <AutoForm
            model={model}
            schema={billingFormSchema}
            onSubmit={onSubmit}
          >
            <Grid container spacing={4}>
              <Field name="firstName" size={6} />
              <Field name="lastName" size={6} />

              <Field name="email" size={6} errorMessage="Should be an email" />
              <Field
                name="phoneNumber"
                size={6}
                errorMessage="Should be a phone number"
              />

              <Field name="street" size={4} />
              <Field name="building" size={3} />
              <Field name="apartment" size={3} />
              <Field name="floor" size={2} />

              <Field name="postalCode" size={4} />
              <Field name="city" size={4} />
              <Field name="country" size={4} />
              <Grid item xs={12}>
                <SubmitField color="primary" />
              </Grid>
            </Grid>
          </AutoForm>
        </Box>
      </Paper>
    </Container>
  );
};

export default BillingForm;
