import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { AutoForm } from 'uniforms';
import { AutoField, ErrorField, SubmitField } from 'uniforms-material';

import { showSuccessToast, showErrorToast } from '../api/actions/uiControls';
import { UpdateProfile } from '../api/schemas/UpdateProfile';
import { Patient } from '../api/types';
import { useDocument, useNationalities } from '../api/usages';
import CustomSelectField from '../components/CustomSelectField';
import DownloadPDFBtn from '../components/DownloadPDFBtn';
import LayoutDefault from '../components/LayoutDefault';
import PhoneNumberField from '../components/PhoneNumberField';
import AddressAreaList from '../components/Profile/AddressAreaList';
import BilingualAutocompleteField from '../components/Profile/BilingualAutocompleteField';
import SectionTitle from '../components/SectionTitle';
import Title from '../components/Title';
import UploadAreaUniformField from '../components/UploadAreaUniformField';
import {
  genderOptions,
  proficiencyOptions,
  workOptions,
} from '../constants/ConfigProfile';
import RouteTypes from '../constants/routes';
import { editUser } from '../lib/admin';
import { momentToFormat } from '../lib/utils';
import { useRedirectWhenNotExists } from '../lib/utils/hooks/useRedirectWhenNotExists';
import { mapAddress } from '../lib/utils/mapAddress';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '700px',
      margin: '40px auto 20px',
    },
    buttons: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    spacingBottom: {
      marginBottom: '16px',
    },
    spacingTop: {
      marginTop: '16px',
    },
    spacing: {
      margin: '8px 0',
    },
  }),
);

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminEditPatient = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t, i18n } = useTranslation();
  const cx = useStyles();
  const { nationalities } = useNationalities();

  const nationalitiesSorted = nationalities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const [updateInProgress, setUpdateInProgress] = useState(false);

  useRedirectWhenNotExists(`patients/${patientId}`, RouteTypes.MANAGE_PATIENTS);

  const { result: patient } = useDocument<Patient>({
    path: `patients/${patientId}`,
  });

  const model = {
    ...patient,
    birthday: momentToFormat(
      i18n.language,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      patient?.birthday ?? '',
      'YYYY-MM-DD',
    ),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (data: any) => {
    setUpdateInProgress(true);

    data.address = (data.address ?? []).map(mapAddress);

    const { success, message } = await editUser({
      id: patientId,
      collection: 'patients',
      data,
    });
    if (success) {
      showSuccessToast(t('admin.patientEdited'));
    } else {
      showErrorToast(message ?? t('admin.errorEditingPatient'));
    }

    setUpdateInProgress(false);
  };

  if (!patient) {
    return null;
  }

  return (
    <LayoutDefault>
      <Title title={t('admin.editPatient')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <AutoForm
            disabled={updateInProgress}
            model={model}
            schema={UpdateProfile}
            onSubmit={handleSubmit}
          >
            <SectionTitle title={t('profile.personalInfo')} />
            <AutoField name="email" label={t('profile.email')} margin="dense" />
            <PhoneNumberField
              name="phoneNumber"
              label={t('profile.phoneNumber')}
              className={cx.spacingTop}
            />
            <AutoField name="name" label={t('profile.name')} />
            <ErrorField name="name" />
            <CustomSelectField
              name="gender"
              label={t('profile.gender')}
              options={genderOptions}
              fullWidth
              className={cx.spacing}
            />
            <ErrorField name="gender" />
            <AutoField name="birthday" label={t('profile.birthday')} />
            <ErrorField name="birthday" />
            <AutoField
              name="resellerCode"
              label={t('profile.resellerCode')}
              inputProps={{
                maxLength: 4,
              }}
            />
            <ErrorField
              name="resellerCode"
              errorMessage={t('profile.resellerError')}
            />
            <AutoField name="idNumber" label={t('profile.iDNumber')} />
            <ErrorField name="idNumber" />
            <UploadAreaUniformField
              name="idOrPassportPhoto"
              text={t(`profile.uploadID`)}
            />
            <DownloadPDFBtn
              url={patient.idOrPassportPhoto || ''}
              className={cx.spacingBottom}
            />
            <ErrorField name="idOrPassportPhoto" />
            <BilingualAutocompleteField
              id="nationality"
              name="nationality"
              options={nationalitiesSorted}
              label={t('profile.nationality')}
            />
            <ErrorField name="nationality" />
            <AutoField
              name="nativeLanguage"
              label={t('profile.nativeLanguage')}
            />
            <CustomSelectField
              name="englishProficiency"
              label={t('profile.englishProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="arabicProficiency"
              label={t('profile.arabicProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <CustomSelectField
              name="frenchProficiency"
              label={t('profile.frenchProficiency')}
              options={proficiencyOptions}
              fullWidth
            />
            <AddressAreaList
              name="address"
              address={patient.address ?? []}
              disabled={updateInProgress}
            />
            <Typography variant="h5" gutterBottom>
              {t('profile.lifestyleSection')}
            </Typography>
            <CustomSelectField
              name="workType"
              label={t('profile.workType')}
              options={workOptions}
              fullWidth
            />
            <AutoField
              name="married"
              label={t('profile.married')}
              color="primary"
            />
            <Box className={cx.buttons}>
              <SubmitField color="primary" />
              <Button
                color="secondary"
                component={Link}
                to={RouteTypes.MANAGE_PATIENTS}
              >
                {t('common.back')}
              </Button>
            </Box>
          </AutoForm>
        </Container>
      </Card>
    </LayoutDefault>
  );
};
export default AdminEditPatient;
