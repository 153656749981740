import React from 'react';

import { useAuthReady, useUser } from '../api/usages/auth';
import ChangePassword from '../components/ChangePassword';
import LayoutDefault from '../components/LayoutDefault';

const ChangePass = () => {
  const isReady = useAuthReady();
  const user = useUser();
  if (!isReady || !user) {
    return <LayoutDefault />;
  }
  return (
    <LayoutDefault>
      <ChangePassword reauthentication />
    </LayoutDefault>
  );
};

export default ChangePass;
