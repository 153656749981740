import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import { generateId } from '../../lib/utils';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      minWidth: 300,
      maxWidth: 400,
      margin: 16,
      padding: 8,
    },
    line: {
      margin: 8,
    },
  }),
);

interface SummarySectionProps {
  data: [string, any][];
  withButton?: boolean;
  translation?: string;
  path?: string;
  params?: string;
}

const SummarySection = ({
  data,
  withButton,
  translation,
  path,
  params,
}: SummarySectionProps) => {
  const cx = useStyles();

  return (
    <Paper className={cx.wrapper} elevation={2}>
      {data.map(([left, right]) => {
        const key = generateId();

        return (
          <Box key={key} display="flex" justifyContent="space-between">
            <Typography className={cx.line}>{left}</Typography>
            <Typography className={cx.line}>{right}</Typography>
          </Box>
        );
      })}
      {withButton && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            className={cx.line}
            color="primary"
            variant="contained"
            component={Link}
            to={`${path}${params}`}
          >
            {translation}
          </Button>
        </Box>
      )}
    </Paper>
  );
};
export default SummarySection;
