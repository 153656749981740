import { createBridge, JSONSchema } from '../../lib/utils';

const schemaReferrals: JSONSchema = {
  title: 'Doctor referrals',
  type: 'object',
  properties: {
    doctorReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          specialtyId: {
            type: 'string',
          },
          medTitle: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
        required: ['specialtyId'],
      },
    },
    labReferrals: {
      type: 'array',
      maxItems: 12,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
        required: ['categoryId', 'serviceId'],
      },
    },
    radioReferrals: {
      type: 'array',
      maxItems: 5,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          categoryId: {
            type: 'string',
          },
          serviceId: {
            type: 'string',
          },
          note: {
            type: 'string',
          },
        },
        required: ['categoryId', 'serviceId'],
      },
    },
  },
};

export default createBridge(schemaReferrals);
