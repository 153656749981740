import { CircularProgress } from '@material-ui/core';
import { cloneDeep, isEmpty, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm } from 'uniforms';
import { LongTextField } from 'uniforms-material';

import { saveDoctorNotes } from '../../../api/actions/doctorNotes';
import { docNotesSchema } from '../../../api/schemas/DoctorNotes';
import {
  useDiagnoses,
  useObservations,
  usePrescriptions,
} from '../../../api/usages';
import DoctorNotesSection from './DoctorNotesSection';

const transformFetchedNotes = ({
  bookingRes,
  observationsRes,
  diagnosesRes,
  prescriptionsRes,
}: any) => ({
  booking: bookingRes.result,
  complaint: bookingRes.result?.complaint,
  observations: observationsRes.observations?.[0],
  diagnosis: diagnosesRes?.diagnoses?.[0],
  prescription: prescriptionsRes?.prescriptions?.[0],
});

interface DoctorNotesProps {
  loading: string;
  bookingIdQuery: object;
  bookingRes: any;
}

const DoctorNotes = ({
  loading,
  bookingIdQuery,
  bookingRes,
}: DoctorNotesProps) => {
  const { t } = useTranslation();
  const observationsRes = useObservations(bookingIdQuery);
  const diagnosesRes = useDiagnoses(bookingIdQuery);
  const prescriptionsRes = usePrescriptions(bookingIdQuery);
  const doctorNotesLoadedRef = useRef<boolean>(false);
  const [doctorNotes, setDoctorNotes] = useState<any>({});

  useEffect(() => {
    if (doctorNotesLoadedRef.current) {
      return;
    }

    if (
      [bookingRes, observationsRes, diagnosesRes, prescriptionsRes].every(
        res => !res.loading,
      )
    ) {
      doctorNotesLoadedRef.current = true;

      const {
        complaint,
        observations,
        diagnosis,
        prescription,
      } = transformFetchedNotes({
        bookingRes,
        observationsRes,
        diagnosesRes,
        prescriptionsRes,
      });

      setDoctorNotes((prevModel: any) => ({
        ...prevModel,
        complaint,
        observations: observations?.content,
        diagnosis: diagnosis?.content,
        prescription: prescription?.content,
      }));
    }
  }, [bookingRes, observationsRes, diagnosesRes, prescriptionsRes]);

  const handleSubmitDoctorNotes = (submitModel: any) => {
    const fetchedData = transformFetchedNotes({
      bookingRes,
      observationsRes,
      diagnosesRes,
      prescriptionsRes,
    });

    saveDoctorNotes({
      booking: {
        id: fetchedData.booking?.id,
        patientId: fetchedData.booking?.patientId,
        complaint: submitModel.complaint ?? '',
      },
      observation: {
        id: fetchedData.observations?.id,
        content: submitModel.observations ?? '',
      },
      diagnosis: {
        id: fetchedData.diagnosis?.id,
        content: submitModel.diagnosis ?? '',
      },
      prescription: {
        id: fetchedData.prescription?.id,
        content: submitModel.prescription ?? '',
      },
    });
  };

  const handleFieldChangeDoctorNotes = (field: string, value: any) => {
    const newModel = set(cloneDeep(doctorNotes), field, value);
    setDoctorNotes(newModel);
    return newModel;
  };

  if (isEmpty(doctorNotes)) {
    return (
      <div className={loading}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <AutoForm
      schema={docNotesSchema}
      onChange={handleFieldChangeDoctorNotes}
      model={doctorNotes}
      onSubmit={handleSubmitDoctorNotes}
      autosave
      autosaveDelay={700}
    >
      <DoctorNotesSection label={t('doctorNotes.doctorNotes')}>
        <LongTextField
          name="complaint"
          rows={4}
          label={t('healthRecordsVisit.complaint')}
        />
        <LongTextField
          name="observations"
          rows={4}
          label={t('healthRecordsVisit.resultOfInterview')}
        />
        <LongTextField
          name="diagnosis"
          rows={4}
          label={t('healthRecordsVisit.diagnosis')}
        />
      </DoctorNotesSection>

      <DoctorNotesSection label={t('common.prescriptions')}>
        <LongTextField name="prescription" rows={4} />
      </DoctorNotesSection>
    </AutoForm>
  );
};

export default DoctorNotes;
