import {
  Box,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { doProfileUpdate } from '../api/actions/auth';
import {
  CreateLabProfileNoAuth,
  CreateLabProfileWithAuth,
} from '../api/schemas/CreateProfileLabRadio';
import { useContextRole, useUser } from '../api/usages';
import LayoutDefault from '../components/LayoutDefault';
import AddServices from '../components/Profile/AddServices';
import BasicInfoForm from '../components/Profile/BasicInfoForm';
import TabPanel from '../components/TabPanel';
import Title from '../components/Title';
import { timestampNow } from '../lib/firebase';

const useStyles = makeStyles({
  root: {
    maxWidth: '700px',
    margin: '0 auto',
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiInput-root': {
      marginBottom: '16px',
    },
    '& .Mui-error': {
      marginBottom: '12px',
    },
    '& .MuiFormHelperText-root': {
      marginBottom: '8px',
    },
  },
  header: {
    marginBottom: `32px`,
  },
});

const CreateProfileLabRadio = () => {
  const cx = useStyles();
  const { t } = useTranslation();
  const user = useUser();
  const role = useContextRole() || '';
  const [currentTab, setCurrentTab] = useState(0);
  const [tabDisabled, setTabDisabled] = useState(true);
  const [model, setModel] = useState<any>({ address: [{}] });
  const email = get(user, 'email', '');
  const emailVerified = get(user, 'emailVerified', false);
  const phoneNumber = get(user, 'phoneNumber', '');
  const schema = email ? CreateLabProfileNoAuth : CreateLabProfileWithAuth;
  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  const validateBasicInfo = (basicInfo: object) => {
    setCurrentTab(1);
    setTabDisabled(false);
    setModel({ ...basicInfo, testing: false, createdAt: timestampNow() });
  };

  useEffect(() => {
    const phoneNumber = get(user, 'phoneNumber', '');
    const email = get(user, 'email', '');
    if (role) {
      const profileData = {
        kind: role,
        email,
        phoneNumber,
        testing: false,
      };

      doProfileUpdate(profileData);
    }
  }, [user, role]);

  if (!user) {
    return (
      <LayoutDefault>
        <Card className={cx.root}>
          <CircularProgress color="primary" />
        </Card>
      </LayoutDefault>
    );
  }
  return (
    <LayoutDefault>
      <Card className={cx.root}>
        <Box p={3}>
          <Box className={cx.header}>
            <Title title={t('common.registration')} />
          </Box>
          <Box style={{ marginBottom: '10px' }}>
            <Typography variant="body2" color="error">
              {t('profile.languageInfo')}
            </Typography>
          </Box>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant="fullWidth"
          >
            <Tab label={t('englishOnly.profile_basicInfo')} />
            <Tab
              label={t('englishOnly.profile_services')}
              disabled={tabDisabled}
            />
          </Tabs>
          <TabPanel value={currentTab} index={0}>
            <BasicInfoForm
              validateBasicInfo={validateBasicInfo}
              email={email}
              phoneNumber={phoneNumber}
              emailVerified={emailVerified}
              model={model}
              schema={schema}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <AddServices model={model} role={role} />
          </TabPanel>
        </Box>
      </Card>
    </LayoutDefault>
  );
};

export default CreateProfileLabRadio;
