import { Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { AutoField, ErrorField, SubmitField } from 'uniforms-material';

interface FieldsProps {
  label: string;
  name: string;
  unit: string;
}

const Fields = ({ label, name, unit }: FieldsProps) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item xs={8} sm={9} lg={10}>
        <AutoField label={label} name={name} />
        <ErrorField name={name} />
      </Grid>
      <Grid item xs={1} sm={1} lg={1}>
        {unit}
      </Grid>
      <Grid item xs={3} sm={2} lg={1}>
        <SubmitField color="primary">
          <AddCircle />
        </SubmitField>
      </Grid>
    </Grid>
  );
};

export default Fields;
