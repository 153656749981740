import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getI18nOptions } from '../../lib/utils';

type EnterPhoneNumberParams = { onSentCode: Function; canSend?: boolean };

const useStyles = makeStyles({
  root: {
    '& .MuiInputAdornment-root': {
      order: (props: any) => props.order,
    },
    '& .MuiInputBase-input': {
      textAlign: (props: any) => props.textAlign,
    },
  },
  button: {
    margin: '24px 0',
  },
});

const EnterPhoneNumber = ({ onSentCode, canSend }: EnterPhoneNumberParams) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const styleProps = {
    order: direction === 'rtl' ? '2' : 'initial',
    textAlign: direction === 'rtl' ? 'right' : 'left',
  };
  const cx = useStyles(styleProps);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const location = useLocation();
  const options = getI18nOptions(location.pathname);

  const sendSms = useCallback(() => {
    onSentCode(phoneNumber);
  }, [phoneNumber, onSentCode]);

  return (
    <Box className={cx.root}>
      <MuiPhoneNumber
        fullWidth
        defaultCountry="eg"
        regions={['european-union', 'middle-east', 'north-africa']}
        value={phoneNumber}
        onChange={setPhoneNumber}
        style={{ direction: 'ltr' }}
      />
      <Box>
        <Button
          className={cx.button}
          variant="contained"
          color="primary"
          onClick={sendSms}
          disabled={!canSend}
        >
          {t('common.sendSms', options)}
        </Button>
      </Box>
    </Box>
  );
};

export default EnterPhoneNumber;
