import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoForm, AutoField, SubmitField } from 'uniforms-material';

import { showSuccessToast, showErrorToast } from '../../api/actions/uiControls';
import CreateAdminSchema from '../../api/schemas/CreateAdmin';
import { createAdmin, CreateAdminRequest } from '../../lib/admin';

const useStyles = makeStyles(() =>
  createStyles({
    submitBox: {
      marginLeft: 'auto',
    },
  }),
);

const CreateAdminForm = () => {
  const { t } = useTranslation();
  const cx = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>();

  const handleSubmit = async (data: CreateAdminRequest) => {
    setIsLoading(true);
    const { success, message } = await createAdmin(data);
    if (success) {
      showSuccessToast(t('admin.adminCreated'));
      formRef.current.reset();
    } else {
      showErrorToast(message ?? t('admin.errorCreatingAdmin'));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Typography variant="h4">{t('admin.addNewAdmin')}</Typography>
      <AutoForm
        schema={CreateAdminSchema}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <AutoField name="email" label={t('profile.email')} />
        <AutoField name="password" label={t('profile.password')} />
        <AutoField name="name" label={t('profile.name')} />
        <Box className={cx.submitBox}>
          <SubmitField
            color="primary"
            label={t('admin.createAdmin')}
            disabled={isLoading}
          />
        </Box>
      </AutoForm>
    </>
  );
};

export default CreateAdminForm;
