import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import VideocamOn from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import React from 'react';
import { LocalVideoTrack } from 'twilio-video';

import { useToggleTrack } from '../../../api/usages/video';

type Props = {
  track?: LocalVideoTrack;
  className?: string;
};

const VideoButton = ({ className, track }: Props) => {
  const [isEnabled, toggleTrack] = useToggleTrack(track);
  return (
    <Tooltip
      title="Stop Video"
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab className={className} onClick={() => toggleTrack()}>
        {isEnabled ? <VideocamOn /> : <VideocamOff color="error" />}
      </Fab>
    </Tooltip>
  );
};

export default VideoButton;
