import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  ErrorField,
  SubmitField,
  AutoField,
} from 'uniforms-material';

import { showErrorToast, showSuccessToast } from '../api/actions/uiControls';
import { reauthPasswordSchema } from '../api/schemas/Payments';
import { auth, EmailAuthProvider } from '../lib/firebase';

type Props = {
  isOpen: boolean;
  onAuth: (state: boolean) => void;
  onClose: () => void;
};

const ReauthWithPasswordDialog = ({ isOpen, onAuth, onClose }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const reauthWithPassword = async (model: { password: string }) => {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No auth User or user has no email');
    }

    const { password } = model;

    try {
      if (password) {
        const credentials = EmailAuthProvider.credential(user.email, password);
        await user.reauthenticateWithCredential(credentials);

        onAuth(true);
      }
      showSuccessToast(t('auth.authenticated'));
    } catch (e) {
      showErrorToast(e);
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <AutoForm schema={reauthPasswordSchema} onSubmit={reauthWithPassword}>
        <DialogContent>
          <h2>{t('englishOnly.enterPass')}</h2>
          <AutoField name="password" />
          <ErrorField name="password" />
        </DialogContent>
        <DialogActions>
          <SubmitField color="primary">{t('common.submit')}</SubmitField>
          <Button onClick={onClose}>{t('common.close')}</Button>
        </DialogActions>
      </AutoForm>
    </Dialog>
  );
};

export default ReauthWithPasswordDialog;
