import firebase from 'firebase';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Booking } from '../../api/types';
import BookingInfoItem from './BookingInfoItem';

interface BookingInfoProps {
  booking: Booking;
}
const BookingInfo = ({ booking }: BookingInfoProps) => {
  const { t, i18n } = useTranslation();

  const startTime = ((booking?.startDate as unknown) as firebase.firestore.Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      dateStyle: 'medium',
      timeStyle: 'short',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const weekday = ((booking?.startDate as unknown) as firebase.firestore.Timestamp)
    ?.toDate()
    .toLocaleString([i18n.language], {
      weekday: 'long',
      // Typescript definitions for DateTimeFormatOptions are missing
      // those 2 fields (dateStyle and timeStyle) so this assertion is needed
    } as Intl.DateTimeFormatOptions);

  const dateToDisplay = `${weekday}, ${startTime}`;

  return (
    <>
      <BookingInfoItem
        primaryText={t('booking.dateAndTime')}
        secondaryText={dateToDisplay}
      />
      <BookingInfoItem
        primaryText={t('booking.serviceType')}
        secondaryText={t('booking.type_' + booking.type)}
      />
      <BookingInfoItem
        primaryText={t('booking.complaint')}
        secondaryText={booking.complaint}
      />
    </>
  );
};

export default BookingInfo;
