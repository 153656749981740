import React from 'react';

import { useDiagnoses, useObservations } from '../../../../api/usages';
import CardText from '../CardText';

interface DoctorNotesProps {
  complaint: string;
  query: object;
}

const DoctorNotes = ({ complaint, query }: DoctorNotesProps) => {
  const { observations } = useObservations(query);
  const { diagnoses } = useDiagnoses(query);

  const records = [
    {
      label: 'healthRecordsVisit.complaint',
      text: complaint,
    },
    {
      label: 'healthRecordsVisit.examination',
      text: observations[0]?.content ?? '',
    },
    {
      label: 'healthRecordsVisit.diagnosis',
      text: diagnoses[0]?.content ?? '',
    },
  ];
  return <CardText records={records} />;
};

export default DoctorNotes;
