import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Query, UserRoleTypes, Booking } from '../../../api/types';
import { useContextRole, useDocument, useUserId } from '../../../api/usages';
import DoctorNotes from './DoctorNotes';
import DoctorReferrals from './DoctorReferrals';
import LabReferrals from './LabReferrals';
import RadioReferrals from './RadioReferrals';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const DoctorNotesIndex = () => {
  const cx = useStyles();
  const { bookingId } = useParams();
  const bookingRes = useDocument<Booking>({
    path: `bookings/${bookingId}`,
  });
  const { t } = useTranslation();

  const role = useContextRole();
  const userId = useUserId();

  const isPatient = role === UserRoleTypes.PATIENT;

  const bookingIdQuery: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: isPatient ? 'patientId' : 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  return (
    <>
      <DoctorNotes
        bookingIdQuery={bookingIdQuery}
        loading={cx.loading}
        bookingRes={bookingRes}
      />
      <DoctorReferrals
        bookingIdQuery={bookingIdQuery}
        loading={cx.loading}
        bookingRes={bookingRes}
      />
      <LabReferrals
        bookingIdQuery={bookingIdQuery}
        loading={cx.loading}
        bookingRes={bookingRes}
      />
      <RadioReferrals
        bookingIdQuery={bookingIdQuery}
        loading={cx.loading}
        bookingRes={bookingRes}
      />
      <br />
      <Button variant="contained" color="primary">
        {t('common.submit')}
      </Button>
    </>
  );
};

export default DoctorNotesIndex;
