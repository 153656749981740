import { Query } from '../types';
import { Dump } from '../types/Dump';
import { useCollection, UseCollectionOptions } from './collection';

export function useDumps(query?: Query, options?: UseCollectionOptions) {
  const { results, error, loading } = useCollection<Dump>(
    {
      collectionPath: 'dumps',
      query,
    },
    options,
  );

  return {
    error,
    loading,
    dumps: results,
  };
}
