import get from 'lodash/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoField, ErrorField, ListItemField } from 'uniforms-material';

import { useGovernorates, useArea } from '../../api/usages';
import GeoPickerField from '../GeoPickerField';
import BilingualAutocompleteField from './BilingualAutocompleteField';

const AddressArea = (props: any) => {
  const { address, name } = props;
  const initGovernorateId = get(address, `[${name}].governorateId`, '');
  const { t } = useTranslation();
  const { governorates } = useGovernorates();
  const [governorateId, setGovernorateId] = useState<any>(initGovernorateId);
  const handleGovernorateChange = (value: any) => {
    setGovernorateId(value);
  };

  const { area } = useArea(
    [
      {
        fieldPath: 'governorateId',
        opStr: '==',
        value: governorateId,
      },
    ],
    { skip: !governorateId },
  );

  const governoratesSorted = governorates.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const areaSorted = area.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <ListItemField name={name}>
      <BilingualAutocompleteField
        id="governorateId"
        name="governorateId"
        options={governoratesSorted}
        label={t('profile.governorate')}
        handleFunc={handleGovernorateChange}
      />
      <ErrorField name="governorateId" />
      <BilingualAutocompleteField
        name="areaId"
        options={area ? areaSorted : []}
        label={t('profile.area')}
      />
      <ErrorField name="areaId" />
      <div>
        <AutoField
          name="buildingNumberName"
          label={t('profile.buildingNumberName')}
        />
        <ErrorField name="buildingNumberName" />
        <AutoField
          name="streetBlockCompound"
          label={t('profile.streetBlockCompound')}
        />
        <ErrorField name="streetBlockCompound" />
        <AutoField
          name="districtTownVillage"
          label={t('profile.districtTownVillage')}
        />
        <ErrorField name="districtTownVillage" />
        <AutoField name="floorNumber" label={t('profile.floorNumber')} />
        <AutoField
          name="appartmentNumber"
          label={t('profile.appartmentNumber')}
        />
        <AutoField
          name="directions"
          label={t('profile.directions')}
          multiline
        />
      </div>
      <GeoPickerField name="geolocation" title={t('profile.geolocation')} />
      <ErrorField name="geolocation" />
    </ListItemField>
  );
};

export default AddressArea;
