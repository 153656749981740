import { auth, db, mergeData, timestampNow } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { Prescription } from '../types';

export async function upsertPrescriptionCallback({
  id: prescriptionId,
  bookingId,
  patientId,
  content,
}: Partial<Prescription>) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const prescriptionsRef = db.collection('prescriptions');

  const prescriptionData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    content,
    updatedAt: timestampNow(),
  };

  if (!prescriptionId) {
    return prescriptionsRef.add({
      ...prescriptionData,
      createdAt: timestampNow(),
    });
  }

  return mergeData(`prescriptions/${prescriptionId}`, prescriptionData);
}

export const upsertPrescription = createAction({
  type: 'upsertPrescription',
  callback: upsertPrescriptionCallback,
});
