import { useObservable } from 'rxjs-hooks';

import { currentGEOLocation$ } from '../../lib/sessionData';

export function useCurrentGeoLocation() {
  return useObservable(
    () => currentGEOLocation$,
    currentGEOLocation$.getValue(),
  );
}
