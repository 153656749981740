import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import { cloneDeep, set, debounce, differenceWith } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoForm,
  ListField,
  SubmitField,
  ErrorField,
} from 'uniforms-material';

import {
  upsertDoctorReferral,
  deleteDoctorReferral,
} from '../../api/actions/doctorReferrals';
import {
  upsertLabReferral,
  deleteLabReferral,
} from '../../api/actions/labReferrals';
import {
  upsertRadioReferral,
  deleteRadioReferral,
} from '../../api/actions/radioReferrals';
import schemaReferrals from '../../api/schemas/EditReferrals';
import { Query, ReferralTypes, UserRoleTypes } from '../../api/types';
import { Booking } from '../../api/types/Booking';
import {
  useLabCategories,
  useRadioCategories,
  useDoctorReferrals,
  useLabReferrals,
  useRadioReferrals,
  useSpecialties,
  useContextRole,
  useUserId,
} from '../../api/usages';
import DoctorReferralListItem from './DoctorReferralListItem';
import LabReferralListItem from './LabReferralListItem';
import RadioReferralListItem from './RadioReferralListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: theme.spacing(3),
      '& .MuiIconButton-root': {
        margin: '0 auto',
      },
      minWidth: 400,
    },
  }),
);

export const customComparator = (a: any, b: any) => {
  return (
    a.categoryId === b.categoryId &&
    a.serviceId === b.serviceId &&
    a.note === b.note
  );
};

export const changeReferrals = (
  modelReferrals: any,
  oldReferrals: any,
  upsertFunc: Function,
  deleteFunc: Function,
  common: {
    bookingId: string;
    patientId: string;
  },
) => {
  const addedReferrals = differenceWith(
    modelReferrals,
    oldReferrals,
    customComparator,
  );
  const deletedReferrals = differenceWith(
    oldReferrals,
    modelReferrals,
    customComparator,
  );

  deletedReferrals.forEach(referral => {
    deleteFunc(referral.id);
  });
  addedReferrals.forEach(referral => {
    upsertFunc({
      status: ReferralTypes.OPEN,
      ...common,
      ...referral,
    });
  });
};

type EditReferralsProps = {
  booking: Booking;
  onClose: () => void;
};

const EditReferrals = ({ booking, onClose }: EditReferralsProps) => {
  const { t } = useTranslation();
  const cx = useStyles();
  const { specialties } = useSpecialties();
  const { labCategories } = useLabCategories();
  const { radioCategories } = useRadioCategories();

  const role = useContextRole();
  const userId = useUserId();
  const isPatient = role === UserRoleTypes.PATIENT;

  const bookingId = booking.id;

  const bookingIdQuery: Query = [
    {
      fieldPath: 'bookingId',
      opStr: '==',
      value: bookingId,
    },
    {
      fieldPath: isPatient ? 'patientId' : 'doctorId',
      opStr: '==',
      value: userId,
    },
  ];

  const { doctorReferrals, loading: doctorRefLoading } = useDoctorReferrals(
    bookingIdQuery,
  );
  const { labReferrals, loading: labRefLoading } = useLabReferrals(
    bookingIdQuery,
  );
  const { radioReferrals, loading: radioRefLoading } = useRadioReferrals(
    bookingIdQuery,
  );

  const [model, setModel] = useState<any>(() => ({
    doctorReferrals,
    labReferrals,
    radioReferrals,
  }));

  useEffect(() => {
    if ([doctorRefLoading, labRefLoading, radioRefLoading].some(Boolean)) {
      return;
    }

    setModel((prevModel: any) => ({
      ...prevModel,
      doctorReferrals,
      labReferrals,
      radioReferrals,
    }));
  }, [
    doctorReferrals,
    labReferrals,
    radioReferrals,
    doctorRefLoading,
    labRefLoading,
    radioRefLoading,
  ]);

  const handleFieldChange = (field: string, value: any) => {
    setModel(set(cloneDeep(model), field, value));
  };

  const handleSubmit = () => {
    Object.entries(model).map((refType: Array<any>) =>
      refType[1].map((ref: any) => delete ref.id),
    );
    const common = {
      bookingId,
      patientId: booking?.patientId,
    };

    changeReferrals(
      model.doctorReferrals,
      doctorReferrals,
      upsertDoctorReferral,
      deleteDoctorReferral,
      common,
    );
    changeReferrals(
      model.labReferrals,
      labReferrals,
      upsertLabReferral,
      deleteLabReferral,
      common,
    );
    changeReferrals(
      model.radioReferrals,
      radioReferrals,
      upsertRadioReferral,
      deleteRadioReferral,
      common,
    );

    onClose();
  };

  return (
    <AutoForm
      schema={schemaReferrals}
      onChange={debounce(handleFieldChange, 200)}
      model={model}
      onSubmit={handleSubmit}
    >
      <div className={cx.section}>
        <ListField
          name="doctorReferrals"
          addIcon={<Add />}
          label={t('doctorNotes.doctorReferral')}
        >
          <DoctorReferralListItem
            name="$"
            specialties={specialties}
            doctorReferrals={model.doctorReferrals}
          />
        </ListField>
        <ErrorField name="doctorReferrals" />
      </div>

      <div className={cx.section}>
        <ListField
          name="labReferrals"
          addIcon={<Add />}
          label={t('doctorNotes.labReferral')}
        >
          <LabReferralListItem
            name="$"
            categories={labCategories}
            handleFieldChange={handleFieldChange}
            labReferrals={model.labReferrals}
          />
        </ListField>
        <ErrorField name="labReferrals" />
      </div>

      <div className={cx.section}>
        <ListField
          name="radioReferrals"
          addIcon={<Add />}
          label={t('doctorNotes.radioReferral')}
        >
          <RadioReferralListItem
            name="$"
            categories={radioCategories}
            handleFieldChange={handleFieldChange}
            radioReferrals={model.radioReferrals}
          />
        </ListField>
        <ErrorField name="radioReferrals" />
      </div>
      <SubmitField color="primary">{t('common.submit')}</SubmitField>
    </AutoForm>
  );
};
export default EditReferrals;
