import Ajv from 'ajv';
import { JSONSchema7 } from 'json-schema';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';

const ajv = new Ajv({
  allErrors: true,
  useDefaults: true,
  $data: true,
});
export type JSONSchema = JSONSchema7;

declare module 'json-schema' {
  interface JSONSchema7 {
    uniforms?: {
      transform?(value: any): string;
      [index: string]: any;
      message?: string;
    };
  }
}
function createValidator(schema: JSONSchema, customValidation?: Function) {
  const validator = ajv.compile(schema);

  return (model: object) => {
    validator(model);
    if (customValidation) {
      customValidation(model);
    }
    if (validator.errors && validator.errors.length) {
      return { details: validator.errors };
    }
    return null;
  };
}

export function createBridge(
  schema: JSONSchema,
  customValidation?: Function | undefined,
) {
  const schemaValidator = createValidator(schema, customValidation);
  return new JSONSchemaBridge(schema, schemaValidator);
}
