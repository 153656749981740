import { Grid, Paper, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BookingInfoItem from '../../BookingList/BookingInfoItem';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  }),
);
interface CardTextProps {
  records: Array<{ label: string; text: string }>;
}
const CardText = ({ records }: CardTextProps) => {
  const cx = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <ListItem className={cx.listItem}>
            <ListItemText
              disableTypography
              secondary={records.map(record => (
                <BookingInfoItem
                  key={record.label}
                  primaryText={t(record.label)}
                  secondaryText={record.text}
                />
              ))}
            />
          </ListItem>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CardText;
