import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React from 'react';
import { connectField } from 'uniforms';

function CustomDateField(props: any) {
  const { value = '', onChange, label, disabled, required } = props;
  const maxDate = moment().format('YYYY-MM-DD');
  function onSelectChange({ target: { value } }: any) {
    onChange(value);
  }

  return (
    <TextField
      required={required}
      style={{ width: '100%' }}
      disabled={disabled}
      id="date"
      label={label}
      type="date"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ max: maxDate }}
      onChange={onSelectChange}
    />
  );
}

export default connectField(CustomDateField);
