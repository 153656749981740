import { Container } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnected } from '../../api/usages';
import Joyride from '../Joyride';
import Toast from '../Toast';
import AppBar from './AppBar';
import SideBar from './SideBar';
import ThemesProvider from './ThemesProvider';
import VerificationRedirect from './VerificationRedirect';
import VerificationStatusAlert from './VerificationStatusAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      marginTop: theme.spacing(10),
      maxWidth: '100vw',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(7),
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    offlineBar: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
  }),
);

interface LayoutDefaultProps {
  children?: any;
  fullSize?: boolean;
}

const LayoutDefault = (props: LayoutDefaultProps) => {
  const cx = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { children } = props;
  const handleDrawerToggle = useCallback(() => setMobileOpen(!mobileOpen), [
    mobileOpen,
  ]);
  const { t } = useTranslation();
  const isConnected = useConnected();

  // @ts-ignore
  return (
    <ThemesProvider>
      <CssBaseline />
      <Joyride />
      <div className={cx.root}>
        <AppBar handleDrawerToggle={handleDrawerToggle} />
        <SideBar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <main className={cx.content}>
          <VerificationStatusAlert />
          <VerificationRedirect />
          {props.fullSize ? (
            children
          ) : (
            <Container className={cx.container} maxWidth="lg">
              {children}
            </Container>
          )}
          {!isConnected && (
            <Alert severity="warning" className={cx.offlineBar}>
              {t('mobile.offlineInfo')}
            </Alert>
          )}
        </main>
        <Toast key="MainToast" />
      </div>
    </ThemesProvider>
  );
};

export default LayoutDefault;
