import { SvgIcon } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import ThemeProvider from '../components/LayoutDefault/ThemesProvider';
import SvgHeart from '../components/SvgHeart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: '#ff00ff',
      color: 'white',
    },
    heartLogo: {
      width: '6em',
      height: '4em',
      marginRight: theme.spacing(1),
      marginBottom: '5em',
      marginTop: '2em',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2em',
      },
    },
    text: {
      margin: theme.spacing(1),
      textAlign: 'center',
    },
    csoon: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1em',
      },
    },
  }),
);

const ComingSoon = () => {
  const cx = useStyles();
  return (
    <ThemeProvider>
      <CssBaseline />
      <div className={cx.root}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h3" gutterBottom className={cx.csoon}>
            Coming Soon
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center">
            <SvgIcon
              component={SvgHeart}
              viewBox="0 0 170 110"
              htmlColor="white"
              classes={{ root: cx.heartLogo }}
            />
            <Typography variant="h5" gutterBottom>
              Shoof Doctor
            </Typography>
          </Grid>
          <div className={cx.text}>
            <Typography variant="h6" gutterBottom paragraph>
              Telemedicine started much longer before NASA applies it to take
              care of its Astronauts in Space without needing to send Drs with
              them.
            </Typography>
            <Typography variant="h6" gutterBottom paragraph>
              Now it’s the fastest growing Healthcare delivery technology in the
              world because of its benefits at pandemic time to rich and poorer
              countries.
            </Typography>
            <Typography variant="h6" gutterBottom paragraph>
              ShoofDoctor realized the amazing benefits and designed its new
              platform launching with services VERY SOON, to serve everyone and
              bring the incredible benefits to its Egyptian society.
            </Typography>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default ComingSoon;
