import { auth, db, mergeData, timestampNow } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { DiagnosisRaw } from '../types';

export async function upsertDiagnosisCallback({
  id: diagnosisId,
  bookingId,
  patientId,
  content,
}: DiagnosisRaw) {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  const diagnosesRef = db.collection('diagnoses');

  const diagnosisData = {
    bookingId,
    patientId,
    doctorId: user.uid,
    content,
    updatedAt: timestampNow(),
  };

  if (!diagnosisId) {
    return diagnosesRef.add({
      ...diagnosisData,
      createdAt: timestampNow(),
    });
  }

  return mergeData(`diagnoses/${diagnosisId}`, diagnosisData);
}

export const upsertDiagnosis = createAction({
  type: 'upsertDiagnosis',
  callback: upsertDiagnosisCallback,
});
