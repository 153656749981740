import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexShrink: 0,
    },
  }),
);

interface ExpansionElementProps {
  title: string;
  text?: string;
  Component?: React.ComponentType;
  index: number;
  isExpanded: boolean;
}

const ExpansionElement = (props: ExpansionElementProps) => {
  const cx = useStyles();
  const { index, title, text, Component, isExpanded } = props;
  const [expanded, setExpanded] = useState<boolean>(isExpanded);
  const toggleTrueFalse = () => setExpanded(!expanded);

  return (
    <Accordion
      key={title}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={toggleTrueFalse}
    >
      <AccordionSummary
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={cx.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Component ? <Component /> : <Typography>{text}</Typography>}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionElement;
