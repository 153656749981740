import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';

import { hideToast } from '../api/actions/uiControls';
import { useToastNotifications } from '../api/usages/uiControls';

const Toast = () => {
  const notification = useToastNotifications();
  if (!notification) {
    return null;
  }
  return (
    <Snackbar open={!!notification}>
      <Alert
        onClose={() => hideToast()}
        severity={notification.type}
        elevation={6}
        variant="filled"
      >
        {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
