import emergency from '../assets/emergency.png';
import ROUTES from './routes';

export const homeButtons = [
  {
    title: 'home.primaryDoctor',
    text: 'home.coversAllSpecialtiesAndWillReferYouInRightDirection',
    route: ROUTES.PRIMARY_DOCTORS,
  },
  {
    title: 'home.specialistDoctor',
    text: 'home.ifEmergencyPleaseCallER',
    route: ROUTES.SPECIALISTS,
  },
];

export const emergencyIcon = {
  alt: 'Emergency',
  src: emergency,
};
