import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, styled } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../api/actions/uiControls';
import { Lab } from '../api/types/Lab';
import { useLabs } from '../api/usages';
import ManagementButtons from '../components/Admin/ManagementButtons';
import AvatarCustom from '../components/Avatar';
import LayoutDefault from '../components/LayoutDefault';
import Title from '../components/Title';
import { Timestamp } from '../lib/firebase';

const useStyles = makeStyles({
  wrapper: {
    maxWidth: '700px',
    margin: '40px auto 0',
  },
  avatar: {
    marginRight: 8,
  },
});

const Container = styled(Box)({
  maxWidth: '700px',
  margin: '0 auto',
});

const AdminManageLabs = () => {
  const { t } = useTranslation();
  const cx = useStyles();

  const { labs, loading, error } = useLabs();

  const labsCreatedAt: any = labs.map((lab: Lab) => {
    let createdAt = null;
    if (lab.createdAt) {
      createdAt = lab.createdAt.seconds
        ? new Timestamp(lab.createdAt.seconds, lab.createdAt.nanoseconds)
        : lab.createdAt;
    }

    return createdAt
      ? { ...lab, createdAt: createdAt?.toDate() }
      : { ...lab, createdAt: new Date(190000000000) };
  });

  const sortedLabs = orderBy(labsCreatedAt, ['createdAt', 'asc']);

  useEffect(() => {
    error && showErrorToast(error.message);
  }, [error]);

  return (
    <LayoutDefault>
      <Title title={t('admin.manageLabs')} />
      <Card className={cx.wrapper}>
        <Container p={3}>
          <List>
            {loading
              ? null
              : sortedLabs.map(lab => (
                  <ListItem key={lab.id}>
                    <ListItemAvatar className={cx.avatar}>
                      <AvatarCustom photoURL={lab.photoURL} name={lab.name} />
                    </ListItemAvatar>
                    <ListItemText primary={lab.name} secondary={lab.email} />
                    <ManagementButtons
                      collection="labs"
                      id={lab.id}
                      verified={!!lab.verified}
                      testing={!!lab.testing}
                    />
                  </ListItem>
                ))}
          </List>
        </Container>
      </Card>
    </LayoutDefault>
  );
};

export default AdminManageLabs;
