import React from 'react';

import LayoutDefault from '../components/LayoutDefault';
import BookingFinished from '../components/SetBooking/BookingFinished';

const Bookings = () => {
  return (
    <LayoutDefault>
      <BookingFinished />
    </LayoutDefault>
  );
};

export default Bookings;
