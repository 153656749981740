import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { history } from '../../lib/utils';
import VideoEndContext from './Controls/VideoContext';

const LeaveCallDialog = () => {
  const { t } = useTranslation();
  const { state, handleDialog, bookingId } = useContext(VideoEndContext);

  const handleLeave = () => {
    history.push(`/call-review/${bookingId}`);
  };

  return (
    <Dialog
      open={state}
      onClose={handleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('video.leaveCallTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('video.confirmLeave')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="secondary">
          {t('video.stay')}
        </Button>
        <Button onClick={handleLeave} color="primary" autoFocus>
          {t('video.leave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveCallDialog;
