import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BookingTypes } from '../api/types';
import { Doctor } from '../api/types/Doctor';
import { useDocument } from '../api/usages';
import SetBooking from './SetBooking';

type FollowUpBookingProps = {
  doctorId: string;
  bookingId: string;
};

const FollowUpBooking = ({ doctorId, bookingId }: FollowUpBookingProps) => {
  const { t } = useTranslation();
  const { result: doctor, loading, error } = useDocument<Doctor>(
    {
      path: `doctors/${doctorId}`,
    },
    { skip: !doctorId },
  );

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (!(doctor as any)?.name) {
    return <h1>{t('booking.errorDoctorNotFound')}</h1>;
  }

  const forcedModel = {
    prevBookingId: bookingId,
    doctorId,
    type: BookingTypes.FOLLOW_UP,
  };

  return <SetBooking stepNo={3} doctor={doctor} model={forcedModel} />;
};

export default FollowUpBooking;
