import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorField, AutoForm, SubmitField } from 'uniforms-material';

import { startDateSchema } from '../../../api/schemas/Booking';
import { i18next } from '../../../locales';
import DateTimeField from './DateTimeField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  submitButton: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(5),
  },
  errorText: {
    alignSelf: 'center',
  },
}));

interface DateTimeProps {
  doctorId: string;
  updateTimeslot: (timeslot: number) => void;
  onSubmit: (startDate: any) => void;
  setPreviousStep: () => void;
  startDate: any;
}

const DateTime = ({
  doctorId,
  updateTimeslot,
  onSubmit,
  setPreviousStep,
  startDate,
}: DateTimeProps) => {
  const [modelStartDate, setModelStartDate] = useState<any>(startDate);
  const [timeslot, setTimeslot] = useState<number>(15);
  const { language } = i18next;
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  const cx = useStyles();
  const { t } = useTranslation();

  const handleChangeStartDate = (newStartDate: any) => {
    setModelStartDate(newStartDate);
  };

  const handleSubmit = (startDate: any) => {
    updateTimeslot(timeslot);
    onSubmit(startDate);
  };

  return (
    <>
      <AutoForm
        schema={startDateSchema}
        model={{ startDate: modelStartDate }}
        onChange={handleChangeStartDate}
        onSubmit={handleSubmit}
        className={cx.root}
      >
        <SubmitField
          color="primary"
          className={cx.submitButton}
          label={t('common.submit')}
        />
        <ErrorField
          name="startDate"
          errorMessage={t('booking.timeslotError')}
          className={cx.errorText}
        />
        <DateTimeField
          name="startDate"
          doctorId={doctorId}
          value={modelStartDate}
          handleTimeslotChange={setTimeslot}
        />
      </AutoForm>
      <Button
        variant="contained"
        style={{ marginTop: '1em' }}
        onClick={() => setPreviousStep()}
        startIcon={direction === 'ltr' ? <ArrowBackIos /> : <ArrowForwardIos />}
      >
        {t('booking.backToDetails')}
      </Button>
    </>
  );
};

export default DateTime;
