import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Language } from '../../../api/types';
import { useBooking } from '../../../api/usages';
import { useDoctor } from '../../../api/usages/doctors';

const CallAboutDoctor = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language as Language;

  const { bookingId } = useParams<{ bookingId: string }>();
  const { booking } = useBooking(bookingId);
  const { doctor } = useDoctor(booking?.doctorId);

  return <Typography>{doctor?.about?.[lang] ?? 'No info'}</Typography>;
};

export default CallAboutDoctor;
