import { FormControl, Grid, InputBase } from '@material-ui/core';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Query, SimpleQuery, Doctor } from '../../api/types';
import DoctorFilters from './DoctorFilters';
import DoctorsTable from './DoctorsTable';

const priceLabels = [
  'priceUnder50',
  'priceFrom50To100',
  'priceFrom100To200',
  'priceFrom200',
];

const priceRanges = {
  [priceLabels[0]]: [0, 50],
  [priceLabels[1]]: [50, 100],
  [priceLabels[2]]: [100, 200],
  [priceLabels[3]]: [200, undefined],
};

interface DoctorsProps {
  specialtyId: string;
  medTitle?: string;
  onChooseDoctor: (doctor: Doctor) => void;
  profile: { [index: string]: any } | null;
  handleSetFavouriteDoctor: (doctorId: string, makeFavourite: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sortAsc: {
      transform: 'rotate(-180deg)',
    },
  }),
);

const Doctors = ({
  specialtyId,
  medTitle: forcedMedTitle,
  onChooseDoctor,
  profile,
  handleSetFavouriteDoctor,
}: DoctorsProps) => {
  const { t } = useTranslation();
  const [filteringName, setFilteringName] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [medTitle, setMedTitle] = useState(forcedMedTitle || '');
  const [gender, setGender] = useState('');
  const [language, setLanguage] = useState('');
  const [sortingField, setSortingField] = useState<string | null>('rating');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [query, setQuery] = useState<Query>();
  const [langugegProfs] = useState(['advanced', 'fluent']);
  const cx = useStyles();

  useEffect(() => {
    const newSimpleQuery: SimpleQuery = [
      { fieldPath: 'specialtyId', opStr: '==', value: specialtyId },
    ];

    if (language) {
      newSimpleQuery.push({
        fieldPath: language,
        opStr: 'in',
        value: ['advanced', 'fluent'],
      });
    }
    if (priceRange) {
      const from = priceRanges[priceRange][0];
      const to = priceRanges[priceRange][1];

      if (from) {
        newSimpleQuery.push({
          fieldPath: 'price.amount',
          opStr: '>=',
          value: from * 100,
        });
      }
      if (to) {
        newSimpleQuery.push({
          fieldPath: 'price.amount',
          opStr: '<=',
          value: to * 100,
        });
      }
    }
    if (medTitle) {
      newSimpleQuery.push({
        fieldPath: 'medTitle',
        opStr: '==',
        value: medTitle,
      });
    }
    if (gender) {
      newSimpleQuery.push({
        fieldPath: 'gender',
        opStr: '==',
        value: gender,
      });
    }

    const orderBy:
      | string
      | [string, 'asc' | 'desc']
      | [string, 'asc' | 'desc'][]
      | undefined = sortingField ? [sortingField, sortDirection] : undefined;

    const newQuery: Query = {
      where: newSimpleQuery,
      orderBy,
      limit: 5000,
    };

    setQuery(newQuery);
  }, [
    specialtyId,
    priceRange,
    medTitle,
    gender,
    sortingField,
    sortDirection,
    langugegProfs,
    language,
  ]);

  const handlePriceRangeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => {
    const newPriceRange = uncheck ? '' : event.target.value;
    setPriceRange(newPriceRange);
    setSortingField('price.amount');
  };

  const handleMedTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => {
    const newMedTitle = uncheck ? '' : event.target.value;
    setMedTitle(newMedTitle);
  };

  const handleGenderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uncheck: boolean,
  ) => {
    const newGender = uncheck ? '' : event.target.value;
    setGender(newGender);
  };

  const handleChangeSortingField = (
    event: React.MouseEvent<HTMLElement>,
    field: string | null,
  ) => {
    if (!priceRange) {
      setSortingField(field);
    }
  };

  const handleChangeSortDirection = (
    event: React.MouseEvent<HTMLElement>,
    direction: 'asc' | 'desc' | null,
  ) => {
    if (direction) {
      setSortDirection(direction);
    }
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    uncheck: boolean,
  ) => {
    const language = uncheck ? '' : (event.target.value as string);
    setLanguage(language);
  };

  return (
    <>
      <DoctorFilters
        priceRange={priceRange}
        medTitle={medTitle}
        gender={gender}
        language={language}
        handlePriceRangeChange={handlePriceRangeChange}
        handleMedTitleChange={handleMedTitleChange}
        handleGenderChange={handleGenderChange}
        handleLanguageChange={handleLanguageChange}
        disableMedTitleFilter={!!forcedMedTitle}
      />
      <h1>{t('booking.sortBy')}</h1>
      <Grid container spacing={1}>
        <Grid item>
          <FormControl>
            <ToggleButtonGroup
              value={sortingField}
              exclusive
              onChange={handleChangeSortingField}
              aria-label="sort field"
              style={{ marginRight: '10px' }}
            >
              <ToggleButton value="price.amount">
                {t('booking.priceForVisitShort')}
              </ToggleButton>
              <ToggleButton value="yearsOfExperience" disabled={!!priceRange}>
                {t('booking.yearsOfExperience')}
              </ToggleButton>
              <ToggleButton value="rating" disabled={!!priceRange}>
                {t('booking.rating')}
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <ToggleButtonGroup
              value={sortDirection}
              exclusive
              onChange={handleChangeSortDirection}
              aria-label="sort direction"
              style={{ marginRight: '10px' }}
            >
              <ToggleButton value="desc">
                <FilterListIcon />
              </ToggleButton>
              <ToggleButton value="asc">
                <FilterListIcon className={cx.sortAsc} />
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Grid>
      </Grid>
      <h1>{t('booking.search')}</h1>
      <div className={cx.search}>
        <div className={cx.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={t('booking.searchDoctorByName')}
          classes={{
            root: cx.inputRoot,
            input: cx.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={event => {
            setFilteringName(event.target.value);
          }}
        />
      </div>
      <DoctorsTable
        onChooseDoctor={onChooseDoctor}
        profile={profile}
        handleSetFavouriteDoctor={handleSetFavouriteDoctor}
        query={query}
        filteringName={filteringName}
      />
    </>
  );
};

export default Doctors;
