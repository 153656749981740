import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { existsDoc } from '../../firebase';

export const useRedirectWhenNotExists = (path: string, to = '/home') => {
  const history = useHistory();
  useEffect(() => {
    (async () => {
      const exists = await existsDoc(path);
      if (!exists) {
        history.replace(to);
      }
    })();
  }, [to, path, history]);
};
