import { FormControl, Typography } from '@material-ui/core';
import React from 'react';
import { connectField } from 'uniforms';

import { GeoPoint } from '../lib/firebase';
import GeoPicker from './GeoPicker';

type GeoPickerFieldProps = {
  value?: GeoPoint;
  onChange?(newPoint: GeoPoint): void;
  title?: string;
  zoom?: number;
  disabled?: boolean;
};

const GeoPickerField = connectField(
  ({ value, onChange, title, disabled }: GeoPickerFieldProps) => {
    if (
      value &&
      value.latitude !== undefined &&
      value.longitude !== undefined &&
      !((value as any) instanceof GeoPoint)
    ) {
      value = new GeoPoint(value.latitude, value.longitude);
    }
    return (
      <FormControl fullWidth disabled={disabled}>
        {title && (
          <Typography
            variant={'subtitle1'}
            color={'textSecondary'}
            component="span"
          >
            {title}
          </Typography>
        )}
        <GeoPicker value={value} onChange={onChange} readOnly={!!disabled} />
      </FormControl>
    );
  },
);

export default GeoPickerField;
