import moment from 'moment-timezone';

import RouteTypes from '../../constants/routes';
import { auth, functions, mergeData, Timestamp } from '../../lib/firebase';
import { createAction } from '../../lib/utils';
import { Booking } from '../types';
import { PaymentMethod, PaymentWallet } from '../types/Payments';
import { navigate } from './navigation';

type CreateBookingParams = {
  bookingModel: Booking;
  timeslot: number;
  paymentMethod: PaymentMethod;
  billingInfo?: PaymentWallet['billingInfo'];
};

export const createBooking = createAction({
  type: 'createBooking',
  async callback({
    bookingModel,
    timeslot,
    paymentMethod,
    billingInfo,
  }: CreateBookingParams) {
    const patientId = auth.currentUser?.uid;
    if (!patientId) {
      throw new Error('No user');
    }

    const startDate = moment(bookingModel.startDate);
    const transformedModel = {
      ...bookingModel,
      patientId,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      startDate: startDate.toISOString(),
      endDate: startDate.add(timeslot, 'm').toISOString(),
      timezone: moment.tz.guess(),
    };

    const {
      data: { orderId, bookingId },
    } = await functions.httpsCallable('createBooking')({
      bookingModel: transformedModel,
      paymentMethod,
      billingInfo,
    });

    if (orderId) {
      // Continue payment process
      navigate(RouteTypes.PAYMENT_PAY, { orderId });
    } else if (bookingId) {
      // Go to summary
      navigate(RouteTypes.BOOKING_SUCCESS, { bookingId });
    } else {
      throw new Error('Cannot make booking');
    }
  },
});

export const updateBookingCallback = (
  bookingId: string,
  data: Partial<Booking>,
) => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('No user');
  }

  return mergeData(`bookings/${bookingId}`, data);
};

export const updateBooking = createAction({
  type: 'updateBooking',
  callback: updateBookingCallback,
});
