import { httpsCallable } from 'rxfire/functions';
import { BehaviorSubject, of, combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  pluck,
  publishBehavior,
  refCount,
  shareReplay,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { connect, Room } from 'twilio-video';

import { showErrorToast } from '../api/actions/uiControls';
import { functions } from './firebase';
import { isMobile } from './utils';

enum RoomState {
  Disconnected = 'disconnected',
  Connected = 'connected',
  Reconnecting = 'reconnecting',
}

// TODO: Reuse those types in functions code
type getVideoTokenRequest = {
  roomId: string;
};
type getVideoTokenResponse = {
  token: string;
  metadata: {
    roomId: string;
    identity: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (msg: string, ...args: any[]) =>
  console.info(`[video] ${msg}`, ...args);

const getVideoToken = httpsCallable<
  getVideoTokenRequest,
  getVideoTokenResponse
>(functions, 'getVideoToken');

export const roomId$ = new BehaviorSubject<string | null>(null);
export const roomError$ = new BehaviorSubject<Error | null>(null);

export async function getDevices() {
  const allDevices = await navigator.mediaDevices.enumerateDevices();

  return {
    audio: allDevices.filter(d => d.kind === 'audioinput'),
    video: allDevices.filter(d => d.kind === 'videoinput'),
  };
}

/**
 * This function ensures that the user has granted the browser permission to use audio and video
 * devices. If permission has not been granted, it will cause the browser to ask for permission
 * for audio and video at the same time (as opposed to separate requests).
 */
export async function ensureMediaPermissions() {
  const devices = await getDevices();
  const needPermissions = [...devices.audio, ...devices.video].every(
    d => !(d.deviceId && d.label),
  );

  if (needPermissions) {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    mediaStream.getTracks().forEach(track => track.stop());
  }
}

const connectToRoom = (roomId: string, token: string) =>
  new Observable<Room | null>(observer => {
    if (!roomId || !token) {
      // No room until we have id and token
      return () => {
        // do nothing
      };
    }

    // Keep reference to disconnect on unsubscribe
    let _room: Room | null = null;
    getDevices()
      .then(({ audio, video }) =>
        // Connect to the Twilio API
        connect(token, {
          name: roomId,
          audio: audio.length > 0,
          video: video.length
            ? {
                frameRate: 24,
                width: 1280,
                height: 720,
                aspectRatio: 16 / 9,
                facingMode: 'user',
              }
            : false,
        }),
      )
      .then(room => {
        _room = room;
        log('New room', room);

        // Cleanup if user closes the browser tab
        // This is separate to the observables cleanup itself
        const disconnect = () => room.disconnect();
        window.addEventListener('beforeunload', disconnect);
        if (isMobile) {
          window.addEventListener('pagehide', disconnect);
        }
        room.once('disconnected', () => {
          window.removeEventListener('beforeunload', disconnect);
          if (isMobile) {
            window.removeEventListener('pagehide', disconnect);
          }
        });

        observer.next(room);
      })
      .catch((err: Error) => {
        console.error(`Cannot connect to room: ${err.message}`);
        _room = null;
        observer.next(null);
        roomError$.next(err);
        showErrorToast(err.message);
      });

    return () => {
      // Cleanup
      log('Room cleanup');
      _room?.disconnect();
    };
  });

const token$: Observable<string | null> = roomId$.pipe(
  distinctUntilChanged(),
  switchMap(roomId => {
    if (!roomId) {
      return of(null);
    }
    return getVideoToken({ roomId }).pipe(pluck('token'));
  }),
  shareReplay(1),
);

export const room$: Observable<Room | null> = combineLatest([
  roomId$,
  token$,
]).pipe(
  switchMap(([roomId, token]) => {
    if (!roomId || !token) {
      return of(null);
    }
    return connectToRoom(roomId, token);
  }),
  shareReplay(1),
  catchError((err: Error) => {
    console.error(err);
    roomError$.next(err);
    showErrorToast(err.message);
    return of(null);
  }),
);

export const roomState$: Observable<RoomState> = room$.pipe(
  distinctUntilChanged(),
  switchMap(room => {
    if (!room) {
      return of(RoomState.Disconnected);
    }
    return new Observable<RoomState>(observer => {
      const update = () => observer.next(room.state as RoomState);
      update();

      room
        .on('disconnected', update)
        .on('reconnected', update)
        .on('reconnecting', update);

      return () => {
        room
          .off('disconnected', update)
          .off('reconnected', update)
          .off('reconnecting', update);
      };
    });
  }),
  publishBehavior(RoomState.Disconnected),
  refCount(),
);
