import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { connectField } from 'uniforms';
import { NumField } from 'uniforms-material';

import CustomSelectField from './CustomSelectField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    currency: {
      flex: '0 0 100px',
      marginRight: theme.spacing(1),
    },
    amount: {
      flex: '1',
      '& input': {
        height: '22px',
      },
    },
    labelPrice: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  }),
);

const currencies = [
  {
    value: 'EGP',
    label: 'EGP',
  },
];

function CustomPriceField() {
  const cx = useStyles();
  return (
    <FormControl className={cx.root}>
      <CustomSelectField
        name="currency"
        options={currencies}
        className={cx.currency}
      />
      <NumField name="amount" className={cx.amount} />
    </FormControl>
  );
}

export default connectField(CustomPriceField);
