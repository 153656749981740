import { FileBlob } from './firebase';

export function isImage(file: { type: string }) {
  return file.type.split('/')[0] === 'image';
}

export async function createFileFromUrl(url: string): Promise<FileBlob> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error('Ajax error for ' + url + ': ' + xhr.status));
        }
      }
    };
    xhr.send();
  });
}

export function readFileAsUrl(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(
        typeof event?.target?.result === 'string' ? event.target.result : '',
      );
    };
    reader.onerror = event => {
      reader.abort();
      reject(event);
    };
    reader.readAsDataURL(file);
  });
}
